import { Typography } from 'cfa-react-components';
import LevelLegendTooltip from '../LevelLegendTooltip/LevelLegendTooltip';
import './PerformanceLevelLegend.scss';

const PerformanceLevelLegend = () => (
  <div
    className="performance-level-legend"
    data-testid="performance-level-legend-container"
  >
    <LevelLegendTooltip
      data-testid="performance-level-legend-elite-level-legend-tooltip"
      level="Elite"
    >
      <div
        className="performance-level-legend__level-container performance-level-legend__level-container--elite"
        data-testid="performance-level-legend-level-container-elite"
      >
        <Typography
          data-testid="performance-level-legend-level-elite"
          variant="caption1"
          color="tertiary"
          align="center"
        >
          1
        </Typography>
      </div>
    </LevelLegendTooltip>

    <LevelLegendTooltip
      data-testid="performance-level-legend-good-level-legend-tooltip"
      level="Good"
    >
      <div
        className="performance-level-legend__level-container performance-level-legend__level-container--good"
        data-testid="performance-level-legend-level-container-good"
      >
        <Typography
          data-testid="performance-level-legend-level-good-2"
          variant="caption1"
          color="tertiary"
          align="center"
          gutterBottom
        >
          2
        </Typography>
        <Typography
          data-testid="performance-level-legend-level-good-3"
          variant="caption1"
          color="tertiary"
          align="center"
          gutterBottom
        >
          3
        </Typography>
        <Typography
          data-testid="performance-level-legend-level-good-4"
          variant="caption1"
          color="tertiary"
          align="center"
        >
          4
        </Typography>
      </div>
    </LevelLegendTooltip>

    <LevelLegendTooltip
      data-testid="performance-level-legend-fair-level-legend-tooltip"
      level="Fair"
    >
      <div
        className="performance-level-legend__level-container performance-level-legend__level-container--fair"
        data-testid="performance-level-legend-level-container-fair"
      >
        <Typography
          data-testid="performance-level-legend-level-fair-5"
          variant="caption1"
          color="tertiary"
          align="center"
          gutterBottom
        >
          5
        </Typography>
        <Typography
          data-testid="performance-level-legend-level-fair-6"
          variant="caption1"
          color="tertiary"
          align="center"
          gutterBottom
        >
          6
        </Typography>
        <Typography
          data-testid="performance-level-legend-level-fair-7"
          variant="caption1"
          color="tertiary"
          align="center"
        >
          7
        </Typography>
      </div>
    </LevelLegendTooltip>

    <LevelLegendTooltip
      data-testid="performance-level-legend-unsatisfactory-level-legend-tooltip"
      level="Unsatisfactory"
    >
      <div
        className="performance-level-legend__level-container performance-level-legend__level-container--unsatisfactory"
        data-testid="performance-level-legend-level-container-unsatisfactory"
      >
        <Typography
          data-testid="performance-level-legend-level-unsatisfactory-8"
          variant="caption1"
          color="tertiary"
          align="center"
          gutterBottom
        >
          8
        </Typography>
        <Typography
          data-testid="performance-level-legend-level-unsatisfactory-9"
          variant="caption1"
          color="tertiary"
          align="center"
        >
          9
        </Typography>
      </div>
    </LevelLegendTooltip>

    <LevelLegendTooltip
      data-testid="performance-level-legend-sub-standard-level-legend-tooltip"
      level="Sub-Standard"
    >
      <div
        className="performance-level-legend__level-container performance-level-legend__level-container--sub-standard"
        data-testid="performance-level-legend-level-container-sub-standard"
      >
        <Typography
          data-testid="performance-level-legend-sub-standard-level-10"
          variant="caption1"
          color="tertiary"
          align="center"
        >
          10
        </Typography>
      </div>
    </LevelLegendTooltip>
  </div>
);

export default PerformanceLevelLegend;
