import PropTypes from 'prop-types';
import { Button, Typography } from 'cfa-react-components';

import './ConfirmationCard.scss';
import OccurrenceCard from './OccurrenceCard';

export default function ConfirmationCard({
  selectedQuestion,
  selectedLocations,
  selectedRound,
  selectedYear,
  occurrences,
  reasonInput,
  getLocationName,
  returnToForm,
  returnToAppeals,
  submit,
}) {
  return (
    <>
      <Typography
        variant="h3"
        data-testid="confirmation-card-admin-appeal-form-title"
      >
        Confirm Appeal Details
      </Typography>
      <div
        className="confirmation-card"
        data-testid="confirmation-card-container"
      >
        <div
          className="confirmation-card__container"
          data-testid="confirmation-card-finding-container"
        >
          <Typography
            fontWeight="bold"
            className="confirmation-card__label"
            data-testid="confirmation-card-finding-label"
          >
            Finding:{' '}
          </Typography>
          <Typography
            className="confirmation-card__info"
            data-testid="confirmation-card-finding-info"
          >
            {selectedQuestion.safeReportId}: {selectedQuestion.text}
          </Typography>
        </div>
        {selectedLocations.length === 1 && (
          <div
            className="confirmation-card__container"
            data-testid="confirmation-card-occurrence-container"
          >
            <Typography
              fontWeight="bold"
              className="confirmation-card__label"
              data-testid="confirmation-card-occurrence-label"
            >
              Occurrence(s):{' '}
            </Typography>
            <div
              className="confirmation-card__occurrences-container"
              data-testid="confirmation-card-occurrences-container"
            >
              {occurrences.map((occurrence, i) => {
                return (
                  <OccurrenceCard
                    data-testid="confirmation-card-occurrence-card"
                    key={`${occurrence.findingUUID}-${i}`}
                    issue={occurrence.details.issue}
                    zone={occurrence.details.zone}
                    item={occurrence.details.item}
                    selected={false}
                    index={i}
                    isDisabled
                  />
                );
              })}
            </div>
          </div>
        )}
        <div
          className="confirmation-card__container"
          data-testid="confirmation-card-store-container"
        >
          <Typography
            fontWeight="bold"
            className="confirmation-card__label"
            data-testid="confirmation-card-store-label"
          >
            Store(s):{' '}
          </Typography>
          <div
            className="confirmation-card__location-container"
            data-testid="confirmation-card-store-location-container"
          >
            {selectedLocations.map((location, index) => (
              <Typography
                className="confirmation-card__location-info"
                data-testid="confirmation-card-store-location-info"
              >
                {location.locationNumber}:{' '}
                {getLocationName(location.location.locationName, index)}
              </Typography>
            ))}
          </div>
        </div>
        <div
          className="confirmation-card__container"
          data-testid="confirmation-card-round-container"
        >
          <Typography
            fontWeight="bold"
            className="confirmation-card__label"
            data-testid="confirmation-card-round-label"
          >
            Round:{' '}
          </Typography>
          <Typography
            className="confirmation-card__info"
            data-testid="confirmation-card-round-info"
          >
            {selectedRound}-{selectedYear}
          </Typography>
        </div>
        <div
          className="confirmation-card__container"
          data-testid="confirmation-card-reason-container"
        >
          <Typography
            fontWeight="bold"
            className="confirmation-card__label"
            data-testid="confirmation-card-reason-label"
          >
            Reason:{' '}
          </Typography>
          <Typography
            className="confirmation-card__info"
            data-testid="confirmation-card-reason-info"
          >
            {reasonInput.toString()}
          </Typography>
        </div>
        <div
          className="confirmation-card__button-container"
          data-testid="confirmation-card-button-container"
        >
          <Button
            className="admin-appeal-form__submit-button"
            data-testid="confirmation-card-cancel-button"
            color="secondary"
            onClick={() => returnToAppeals()}
            variant="text"
            size="sm"
          >
            Cancel
          </Button>
          <Button
            className="admin-appeal-form__submit-button"
            data-testid="confirmation-card-edit-button"
            variant="outlined"
            color="secondary"
            onClick={() => returnToForm(false)}
            size="sm"
            fullWidth={false}
          >
            Edit
          </Button>
          <Button
            className="admin-appeal-form__submit-button"
            data-testid="confirmation-card-confirm-button"
            color="secondary"
            onClick={() => submit()}
            size="sm"
            fullWidth={false}
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
}

ConfirmationCard.propTypes = {
  selectedQuestion: PropTypes.object,
  selectedLocations: PropTypes.array,
  selectedRound: PropTypes.string,
  selectedYear: PropTypes.number,
  occurrences: PropTypes.array,
  reasonInput: PropTypes.string,
  getLocationName: PropTypes.func,
  returnToForm: PropTypes.func,
  returnToAppeals: PropTypes.func,
  submit: PropTypes.func,
};
