import { actions } from '../store/reducers';

/**
 * -----------
 *  Store
 * -----------
 */
export const initializeResponse = ({ dispatch, question }) => {
  const {
    effectiveVersionId,
    internalId,
    responseUniqueIdentifier,
    type,
    questionData,
  } = question;

  const { supplementaryData } = questionData;

  const responseObject = {
    internalId,
    responseUniqueIdentifier,
    questionVersionId: effectiveVersionId,
    type,
    responseData: {
      choicesMade: [],
      attachmentS3Urls: [],
      response: '',
    },
    supplementaryData,
  };

  dispatch({
    type: actions.updateResponses,
    data: { [responseUniqueIdentifier]: responseObject },
  });
};

export const updateResponseData = ({
  store,
  dispatch,
  question,
  type,
  data,
}) => {
  const {
    effectiveVersionId,
    internalId,
    responseUniqueIdentifier,
    type: questionType,
    questionData,
  } = question;

  const { supplementaryData } = questionData;

  const responseObject = {
    internalId,
    responseUniqueIdentifier,
    questionVersionId: effectiveVersionId,
    type: questionType || 'FreeResponse',
    responseData: store
      ? store.responses?.[responseUniqueIdentifier]?.responseData
      : {},
    supplementaryData,
  };

  switch (type) {
    case 'text':
      responseObject.responseData.response = data;
      break;
    case 'attachment':
      responseObject.responseData.attachmentS3Urls = data;
      break;
    default:
      responseObject.responseData.choicesMade = data;
      break;
  }

  dispatch({
    type: actions.updateResponses,
    data: { [responseUniqueIdentifier]: responseObject },
  });
};

export const updateSupplementaryData = ({ dispatch, data }) => {
  dispatch({
    type: actions.updateSupplementaryData,
    data,
  });
};

/**
 * -----------------
 *  Data formatting
 * -----------------
 */
export const syncResponseValidationStatus = ({ store }) => {
  const { questionnaireQuestions, responseValidationStatuses, responses } =
    store;

  const filtered = Object.entries(responses).reduce((obj, [key, value]) => {
    if (
      questionnaireQuestions.some(
        (question) => question.responseUniqueIdentifier === key,
      )
    ) {
      obj[key] = value;
    }
    return obj;
  }, {});

  const statuses = Object.keys(filtered).reduce((obj, key) => {
    obj[key] = responseValidationStatuses[key];
    return obj;
  }, {});

  store.responseValidationStatuses = statuses;
  store.responses = filtered;
};

/**
 * Extracts unique parent IDs from an array of IDs.
 *
 * @param {string[]} ids - The array of IDs. ex: ['{childId}_{parentId}', ...]
 * @returns {string[]} - The array of unique parent IDs.
 */
const extractUniqueParentIds = (ids) => {
  const parentIds = new Set();
  ids.forEach((id) => parentIds.add(id.split('_')[1]));
  return Array.from(parentIds);
};

/**
 * Retrieves the IDs of the tree nodes that need to be cleared from the store.
 *
 * @param {Object} store - The store object.
 * @param {Array} rootIds - The root IDs of the tree.
 * @param {Array} exclusionIds - The IDs to exclude from the result.
 * @returns {Array} - The IDs of the tree nodes to clear from the store.
 */
export const getIdsToClearFromStore = ({ store, rootIds, exclusionIds }) => {
  // Exit early if there are no rootIds
  if (rootIds.length === 0) return [];

  const result = [];
  const { responses } = store;
  const idsToExcludeSet = new Set(exclusionIds); // Convert to Set for efficient lookups

  // Extract unique parent IDs from the root IDs
  const parentIds = extractUniqueParentIds(rootIds);

  // Search for children of the parent IDs
  const searchChildren = (currentParentId) => {
    Object.keys(responses).forEach((key) => {
      // If the key is in the exclusion set, skip it
      if (idsToExcludeSet.has(key)) return;

      // If the key's parent matches the current parent, add it to the result
      const [childId, parentKey] = key.split('_');
      if (parentKey === currentParentId) {
        result.push(key);
        searchChildren(childId);
      }
    });
  };

  parentIds.forEach(searchChildren);

  return result;
};
