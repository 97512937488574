import { useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { restOpsWebEcosystemPerms, useIsDevTeam } from '../AuthUtils';

const useSmartShopAccess = () => {
  const { authState } = useOktaAuth();
  const hasDevTeamAccess = useIsDevTeam();
  const userToken = useSelector((state) => state.auth.userToken);
  const isOperator = userToken?.userType === 'Operator';
  const canAccessSmartShop =
    isOperator ||
    restOpsWebEcosystemPerms(authState?.accessToken?.claims, [
      'PROGRAM_SMARTSHOP',
    ]);

  return canAccessSmartShop || hasDevTeamAccess;
};

export default useSmartShopAccess;
