import { useOktaAuth } from '@okta/okta-react';
import { Container, Typography } from 'cfa-react-components';
import NavHeader from '../../components/NavHeader/NavHeader';

const Profile = () => {
  const { authState } = useOktaAuth();
  const { claims } = authState.idToken;
  return (
    <>
      <NavHeader
        data-testid="profile-nav-header"
        buildLocationChangeRedirectUrl={(locNumber) => `/${locNumber}/landing`}
      />
      <Container data-testid="profile-container">
        <div data-testid="profile-container-sub" style={{ marginTop: '3rem' }}>
          <Typography data-testid="profile-name-typography" gutterBottom>
            name: <b data-testid="profile-name">{claims.name}</b>
          </Typography>
          <Typography data-testid="profile-email-typography" gutterBottom>
            email: <b data-testid="profile-email">{claims.email}</b>
          </Typography>
          <Typography data-testid="profile-user-type-typography" gutterBottom>
            userType: <b data-testid="profile-user-type">{claims.userType}</b>
          </Typography>
          <Typography data-testid="profile-cfa-aud-typography" gutterBottom>
            cfa_aud: <b data-testid="profile-cfa-aud">{claims.cfa_aud}</b>
          </Typography>
          <Typography data-testid="profile-cfa-perms">cfa_perms:</Typography>
          <ul data-testid="profile-cfa-perms-list-container">
            {Object.keys(claims.cfa_perms.FOOD_SAFETY_WEB_APP).map((item) => (
              <li data-testid="profile-cfa-perms-list" key={item}>
                <Typography
                  data-testid="profile-cfa-perms-list-item-typography"
                  as="span"
                >
                  <b data-testid="profile-cfa-perms-list-item">{item}</b>
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </>
  );
};

export default Profile;
