import { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'cfa-react-components';
import OccurrenceCard from './OccurrenceCard';

import './OccurrencesContainer.scss';

// OccurrencesContainer returns a list of OccurrenceCard components and handles the selection logic for them.
export default function OccurrencesContainer({
  occurrences,
  handleOccurrenceSelection,
  selectedOccurrences,
}) {
  // helper function to initialize the index array
  const initializeIndexArray = () => {
    if (selectedOccurrences?.length > 0) return [];

    // if there are already selectedOccurrences include their index in the array
    return occurrences.reduce((acc, occurrence, i) => {
      if (
        selectedOccurrences.some(
          (finding) => finding.findingUUID === occurrence.findingUUID,
        )
      )
        acc.push(i);
      return acc;
    }, []);
  };

  // an array of indexes of the props.occurrences array that have been selected
  const [indexArray, setIndexArray] = useState(() => initializeIndexArray());

  // if the indexArray already includes the index remove it, otherwise add it. Then call the callback function
  const handleOnChange = (index) => {
    return () => {
      // local variable to hold the newArray
      let newArray = [];

      // Check if the index is already selected and act accordingly
      if (indexArray.includes(index)) {
        newArray = indexArray.filter((item) => item !== index);
      } else {
        newArray = [...indexArray, index];
      }

      // reset the indexArray and run the callback Function
      setIndexArray(newArray);
      handleOccurrenceSelection(newArray);
    };
  };

  return (
    <div
      className="occurrences-container__container"
      data-testid="occurrences-container-container"
    >
      <Typography
        variant="body2"
        gutterBottom
        className="occurrences-container__title"
        data-testid="occurrences-container-title"
      >
        Select Issues
      </Typography>
      <Typography
        variant="caption1"
        gutterBottom
        className="occurrences-container__sub-title"
        data-testid="occurrences-container-sub-title"
      >
        Choose single or multiple issues associated with a selected finding.{' '}
      </Typography>

      <div
        className="occurrences-container__card-container"
        data-testid="occurrences-container-card-container"
      >
        {occurrences.map((occurrence, i) => {
          // if the index of the occurrence is in the indexArray then it is selected
          const selectedLogic = indexArray.includes(i);

          return (
            <OccurrenceCard
              data-testid="occurrences-container-occurrence-card"
              key={`${occurrence.findingUUID}-${i}`}
              issue={occurrence.details.issue}
              zone={occurrence.details.zone}
              item={occurrence.details.item}
              selected={selectedLogic}
              index={i}
              onClickHandler={handleOnChange}
              isDisabled={false}
            />
          );
        })}
      </div>
    </div>
  );
}

OccurrencesContainer.propTypes = {
  occurrences: PropTypes.array.isRequired,
  handleOccurrenceSelection: PropTypes.func,
  selectedOccurrences: PropTypes.array,
};
