import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Row } from 'reactstrap';

import { LoadingIndicator, Typography } from 'cfa-react-components';
import { formatDateMonthsAndFullYear } from '../../../utils/DateUtil';
import { trackEvent } from '../../../utils';
import { occurrencesFilter } from '../../../utils/filters';
import {
  postAmendmentRequest,
  postConsultationRequest,
} from '../../../actions/AmendmentActions';
import {
  getAllAudits,
  getSelectedAuditFindings,
} from '../../../actions/AuditActions';

import MobileNestedHeader from '../../../components/NavHeader/MobileNestedHeader/MobileNestedHeader';
import NavHeader from '../../../components/NavHeader/NavHeader';
import FindingOccurrenceContainer from '../../../components/Ammendments/RequestAmendmentForm/FindingOccurrenceContainer';
import ReasonForReviewContainer from '../../../components/Ammendments/RequestAmendmentForm/ReasonForReviewContainer';
import SelectAuditOccurrence from '../../../components/Ammendments/RequestAmendmentForm/SelectAuditOccurrence';
import SubmitButtons from '../../../components/Ammendments/RequestAmendmentForm/SubmitButtons';
import CancelNextButtons from '../../../components/Ammendments/RequestAmendmentForm/CancelNextButtons';
import PathwayQuestion from '../../../components/Ammendments/RequestAmendmentForm/PathwayQuestion';
import ReviewTheProcedures from '../../../components/Ammendments/RequestAmendmentForm/ReviewTheProcedures';
import DisplayFindingOccurrence from '../../../components/Ammendments/RequestAmendmentForm/DisplayFindingOccurrence';
import ConsultationRequest from '../../../components/Ammendments/RequestAmendmentForm/ConsultationRequest';
import ConsultationQuestion from '../../../components/Ammendments/RequestAmendmentForm/ConsultationQuestion';
import FileUploader from '../../../components/FileUploader/FileUploader';
import { DOCUMENT_FILE_TYPES } from '../../../components/FileUploader/common/constants';

import './RequestAmendmentForm.scss';

const RequestAmendementForm = () => {
  // Holds the form order logic in one central spot with no complicated logic
  const formTransitionOrder = {
    init: 'SELECT_OCCURRENCE',
    pathwaysExist: 'PATHWAY_QUESTION',
    pathwaysDoNotExist: 'CONSULTATION_QUESTION',
    pathwayQuestionYes: 'CONSULTATION_QUESTION',
    pathwayQuestionNo: 'PATHWAY_LINK',
    pathWayLink: 'CONSULTATION_QUESTION',
    consultationQuestionYes: 'CONSULTATION_REQUEST',
    consultationQuestionNo: 'SUBMIT_APPEAL',
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const { locationNumber, round } = useParams();

  // Hooks State
  const [findingID, setFindingID] = useState('');
  const [isLoadingState, setLoadingState] = useState(false);
  const [findingOccurrences, setFindingOccurrences] = useState([]);
  const [consultationRequestValue, setConsultationRequest] = useState('');
  const [reasonForReviewValue, setReasonForReview] = useState('');
  const [formStepIndex, setFormSteptIndex] = useState(formTransitionOrder.init);
  const [selectedFindingObj, setSelectedFindingObj] = useState({});
  const [selectedOccurrenceObj, setSelectedOccurrenceObj] = useState({});
  const [s3AttachmentUrls, setS3AttachmentUrls] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const fileUploadConfig = {
    s3Policy: `${process.env.REACT_APP_SERVER_URL}/amendments/attachments/policy/${locationNumber}/${round}`,
    s3DirPath: `audit/${locationNumber}/${round}`,
    accept: DOCUMENT_FILE_TYPES,
  };

  // Redux Reducers
  const { loading } = useSelector(
    (state) => state.amendments.requestAmendmentForm,
  );
  const { selectedAudit, selectedAuditFindingsLoading } = useSelector(
    (state) => state.audit,
  );
  const selectedAuditFindings = occurrencesFilter(
    useSelector((state) => state.audit.selectedAuditFindings),
    selectedAudit,
  );
  const dateTimePublished = useSelector(
    (state) => state.audit.selectedAudit?.supplementaryData?.dateTimePublished,
  );
  const locationName = selectedAuditFindings
    ? selectedAuditFindings[0]?.locationName
    : '';
  const availableLocations = useSelector(
    (state) => state.location.availableLocations,
  );
  const { userToken } = useSelector((state) => state.auth);

  const visitDate = dateTimePublished
    ? formatDateMonthsAndFullYear(dateTimePublished)
    : '';
  const dropdownOptions = selectedAuditFindings.map((finding, i) => {
    return {
      value: i,
      label: `${finding.questionSafeReportId} ${finding.description}`,
    };
  });
  const selectedFindingTitle = `${selectedFindingObj.questionSafeReportId}: ${selectedFindingObj.description}`;
  const pathwayLinksList =
    selectedOccurrenceObj.pathwayLinks !== undefined
      ? selectedOccurrenceObj.pathwayLinks.map((link) => {
          return { linkStr: link.pathwayName, url: link.pathwayURL };
        })
      : [];

  useEffect(() => {
    if (selectedAuditFindings.length === 0) {
      dispatch(getAllAudits(locationNumber, round));
      dispatch(
        getSelectedAuditFindings({
          locationNumber,
          round,
        }),
      );
    }
  }, []);

  // Handle loading updates
  useEffect(() => {
    setLoadingState(loading);
  }, [loading]);

  useEffect(() => {
    const hasAccessToLocation = (locNumber) =>
      availableLocations
        ?.map((location) => location.locationNumber)
        .includes(locNumber);

    if (
      availableLocations &&
      locationNumber &&
      !hasAccessToLocation(locationNumber)
    ) {
      history.push(`/${locationNumber}/report-dashboard`);
    }
  }, [availableLocations, history, locationNumber]);

  const selectFindingOccurrence = ({ value }) => {
    setSelectedFindingObj(selectedAuditFindings[value]);
    setFindingOccurrences(selectedAuditFindings[value].findingHistory);
    setReasonForReview('');
    setFindingID('');
  };

  const handleConsultationRequestChange = (value) => {
    setConsultationRequest(value.target.value);
  };

  const handleReasonForReviewChange = (value) => {
    setReasonForReview(value.target.value);
  };

  const goBackToDashboard = () => {
    history.goBack();
  };

  const handleFindingOccurrence = (occurrenceObj) => {
    const strID = occurrenceObj.findingUUID;
    const occuranceString = findingID !== strID ? strID : '';
    setFindingID(occuranceString);
    setSelectedOccurrenceObj(occurrenceObj);
  };

  const submitAmendmentRequest = () => {
    trackEvent('appeal-submitted', userToken.userRole);
    const amendmentRequestPayload = {
      findingUUID: findingID,
      amendmentStatus: 'PENDING',
      amendmentType: 'DELETION',
      isAdminRequested: false,
      reasonForRequest: reasonForReviewValue,
      attachmentUrls: [...s3AttachmentUrls].filter(Boolean),
    };

    setLoadingState(true);
    dispatch(postAmendmentRequest(amendmentRequestPayload));
  };

  const submitConsultationRequest = () => {
    trackEvent('consultation-question-submitted', userToken.userRole);
    const consultationRequestPayload = {
      findingUUID: findingID,
      clarifyingQuestion: consultationRequestValue,
    };

    setLoadingState(true);
    dispatch(postConsultationRequest(consultationRequestPayload));
  };

  const handleCancelBtn = () => {
    goBackToDashboard();
  };

  // Holds the logic for for transitioning between steps by utilizing the formTransitionOrder object
  const formTransitionCallback = (completedStep, answer) => {
    return () => {
      switch (completedStep) {
        case 'SELECT_OCCURRENCE':
          setFormSteptIndex(
            selectedOccurrenceObj?.pathwayLinks?.length > 0
              ? formTransitionOrder.pathwaysExist
              : formTransitionOrder.pathwaysDoNotExist,
          );
          break;
        case 'PATHWAY_QUESTION':
          setFormSteptIndex(
            answer === 'YES'
              ? formTransitionOrder.pathwayQuestionYes
              : formTransitionOrder.pathwayQuestionNo,
          );
          break;
        case 'PATHWAY_LINK':
          setFormSteptIndex(formTransitionOrder.pathWayLink);
          break;
        case 'CONSULTATION_QUESTION':
          setFormSteptIndex(
            answer === 'YES'
              ? formTransitionOrder.consultationQuestionYes
              : formTransitionOrder.consultationQuestionNo,
          );
          break;
        default:
          break;
      }
      window.scrollTo(0, 0);
    };
  };

  return (
    <>
      <div
        className="d-none d-sm-block"
        data-testid="request-amendment-form-d-none-d-sm-block"
      >
        <NavHeader
          data-testid="request-amendment-form-nav-header"
          buildLocationChangeRedirectUrl={(locNumber) =>
            `/${locNumber}/report-dashboard`
          }
        />
      </div>
      <div
        className="d-block d-sm-none"
        data-testid="request-amendment-form-d-block-d-sm-none"
      >
        <MobileNestedHeader
          data-testid="request-amendment-form-mobile-nested-header"
          title="Report Dashboard"
          navigateBackRoute={false}
        />
      </div>
      <div
        className="request-amendment-form__container"
        data-testid="request-amendment-form-container"
      >
        <Row
          className="d-none d-sm-block container request-amendment-form__back-navegation"
          data-testid="request-amendment-form-back-navigation"
        >
          <Link
            data-testid="request-amendment-form-location-number-report-dashboard-round-link"
            to={`/${selectedAudit?.locationNumber}/report-dashboard/${selectedAudit?.round}`}
          >
            <img
              data-testid="request-amendment-form-desktop-back-arrow"
              alt=""
              src={require('../../../assets/icons/left-arrow.svg').default}
              height={15}
              width={15}
              className="desktop-back-arrow"
            />
            <span
              className="desktop-back-link"
              data-testid="request-amendment-form-desktop-back-link"
            >
              Food Safety Report
            </span>
          </Link>
        </Row>
        {loading || isLoadingState || selectedAuditFindingsLoading ? (
          <LoadingIndicator
            variant="page"
            data-testid="request-amendment-form-loading-indicator"
          />
        ) : (
          <div data-testid="request-amendment-form-loaded-parent-container">
            <h1
              className="request-amendment-form__page-title"
              data-testid="request-amendment-form-page-title"
            >
              Inquiry Form - {locationName}
            </h1>
            <p
              className="request-amendment-form__page-date-text"
              data-testid="request-amendment-form-page-date-text"
            >
              Visit Date: {visitDate}
            </p>

            <div
              className="request-amendment-form__panel-container"
              data-testid="request-amendment-form-panel-container"
            >
              {/* Form Step 1 */}
              {formStepIndex === 'SELECT_OCCURRENCE' && (
                <>
                  <SelectAuditOccurrence
                    data-testid="request-amendment-form-select-audit-occurrence"
                    dropdownOptions={dropdownOptions}
                    onChangeSelectFindingOccurrence={selectFindingOccurrence}
                  />

                  {findingOccurrences.length !== 0 && (
                    <FindingOccurrenceContainer
                      data-testid="request-amendment-form-finding-occurrence-container"
                      findingOccurrences={findingOccurrences}
                      handleFindingOccurrence={handleFindingOccurrence}
                    />
                  )}
                  <CancelNextButtons
                    data-testid="request-amendment-form-cancel-next-buttons"
                    handleCancel={handleCancelBtn}
                    handleNext={formTransitionCallback('SELECT_OCCURRENCE')}
                    isDisabled={
                      Object.keys(selectedFindingObj).length === 0 ||
                      findingID.length === 0
                    }
                  />
                </>
              )}

              {/* Form Step 2 */}
              {formStepIndex === 'PATHWAY_QUESTION' && (
                <PathwayQuestion
                  data-testid="request-amendment-form-pathway-questions"
                  findingTitle={selectedFindingTitle}
                  handleCancel={handleCancelBtn}
                  handleNext={formTransitionCallback}
                />
              )}

              {/* Form Step 3 */}
              {formStepIndex === 'PATHWAY_LINK' && (
                <ReviewTheProcedures
                  data-testid="request-amendment-form-review-the-procedures"
                  pathwayURL={pathwayLinksList}
                  findingTitle={selectedFindingTitle}
                  handleProceed={formTransitionCallback('PATHWAY_LINK')}
                />
              )}

              {/* Form Step 4 */}
              {formStepIndex === 'CONSULTATION_QUESTION' && (
                <ConsultationQuestion
                  data-testid="request-amendment-form-consultation-question"
                  findingTitle={selectedFindingTitle}
                  handleCancel={handleCancelBtn}
                  handleNext={formTransitionCallback}
                />
              )}

              {/* Form Step 5 */}
              {formStepIndex === 'CONSULTATION_REQUEST' && (
                <>
                  <h5
                    className="request-amendment-form__step-title"
                    data-testid="request-amendment-form-step-title"
                  >
                    Appeal Details
                  </h5>
                  <p
                    className="request-amendment-form__title--disabled"
                    data-testid="request-amendment-form-title-disabled"
                  >
                    {selectedFindingTitle}
                  </p>
                  <p data-testid="request-amendment-form-selected-zone-item-issue-option">
                    Selected Zone, Item, and Issue option
                  </p>
                  <DisplayFindingOccurrence
                    data-testid="request-amendment-form-display-finding-occurrence"
                    issueDescription={selectedOccurrenceObj?.issue}
                    zoneDescription={selectedOccurrenceObj?.zone}
                    itemDescription={selectedOccurrenceObj?.item}
                    checked={false}
                  />
                  <ConsultationRequest
                    data-testid="request-amendment-form-consultation-request"
                    handleConsultationTextInput={
                      handleConsultationRequestChange
                    }
                    consultationRequestTextValue={consultationRequestValue}
                    disabledConsultationTextInput={loading}
                  />
                  <SubmitButtons
                    data-testid="request-amendment-form-submit-question-buttons"
                    handleFormSubmit={submitConsultationRequest}
                    handleCancelBtn={goBackToDashboard}
                    disabledBtn={
                      findingOccurrences.length === 0 ||
                      findingID === '' ||
                      consultationRequestValue === ''
                    }
                    submitButtonText="Submit Question"
                  />
                </>
              )}

              {/* Form Step 6 */}
              {formStepIndex === 'SUBMIT_APPEAL' && (
                <>
                  <h5
                    className="request-amendment-form__step-title"
                    data-testid="request-amendment-form-form-step-title"
                  >
                    Appeal Details
                  </h5>
                  <p
                    className="request-amendment-form__title--disabled"
                    data-testid="request-amendment-form-form-title-disabled"
                  >
                    {selectedFindingTitle}
                  </p>
                  <p data-testid="request-amendment-form-selected-zone-item-issue-option-2">
                    Selected Zone, Item, and Issue option
                  </p>
                  <DisplayFindingOccurrence
                    data-testid="request-amendment-form-display-finding-occurence"
                    issueDescription={selectedOccurrenceObj?.issue}
                    zoneDescription={selectedOccurrenceObj?.zone}
                    itemDescription={selectedOccurrenceObj?.item}
                    checked={false}
                  />
                  <ReasonForReviewContainer
                    data-testid="request-amendment-form-reason-for-review-container"
                    handleReviewTextInput={handleReasonForReviewChange}
                    reasonForReviewTextValue={reasonForReviewValue}
                    disabledReviewTextInput={loading}
                  />
                  <Typography
                    data-testid="request-amendment-form-upload-supporting-documents"
                    variant="h4"
                  >
                    Upload Supporting Documents{' '}
                    <Typography
                      data-testid="request-amendment-form-upload-supporting-documents-optional"
                      as="span"
                      fontWeight="regular"
                    >
                      (optional)
                    </Typography>
                  </Typography>
                  <div
                    className="request-amendment-form__file-uploader-container"
                    data-testid="request-amendment-form-file-uploader-container"
                  >
                    <FileUploader
                      data-testid="request-amendment-form-file-uploader"
                      config={fileUploadConfig}
                      setStore={setS3AttachmentUrls}
                      initialData={initialData}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                    />
                  </div>
                  <SubmitButtons
                    data-testid="request-amendment-form-submit-appeal-buttons"
                    handleFormSubmit={submitAmendmentRequest}
                    handleCancelBtn={goBackToDashboard}
                    disabledBtn={
                      findingOccurrences.length === 0 ||
                      findingID === '' ||
                      reasonForReviewValue === ''
                    }
                    submitButtonText="Submit Appeal"
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestAmendementForm;
