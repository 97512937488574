export const SEND_SELF_ASSESSMENT = 'SEND_SELF_ASSESSMENT';
export const SEND_SELF_ASSESSMENT_SUCCESS = 'SEND_SELF_ASSESSMENT_SUCCESS';
export const SEND_SELF_ASSESSMENT_FAILURE = 'SEND_SELF_ASSESSMENT_FAILURE';
//
export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS';
export const GET_ALL_QUESTIONS_SUCCESS = 'GET_ALL_QUESTIONS_SUCCESS';
export const GET_ALL_QUESTIONS_FAILURE = 'GET_ALL_QUESTIONS_FAILURE';
//
export const SET_SELECTED_SAFEQUESTION = `SET_SELECTED_SAFEQUESTION`;
//
export const GET_SELECTED_SAFEQUESTION_DETAILS =
  'GET_SELECTED_SAFEQUESTION_DETAILS';
export const GET_SELECTED_SAFEQUESTION_DETAILS_SUCCESS =
  'GET_SELECTED_SAFEQUESTION_DETAILS_SUCCESS';
export const GET_SELECTED_SAFEQUESTION_DETAILS_FAILURE =
  'GET_SELECTED_SAFEQUESTION_DETAILS_FAILURE';
//
export const UPDATE_SELECTED_SAFEQUESTION_PUT =
  'UPDATE_SELECTED_SAFEQUESTION_PUT';
export const UPDATE_SELECTED_SAFEQUESTION_SUCCESS =
  'UPDATE_SELECTED_SAFEQUESTION_SUCCESS';
export const UPDATE_SELECTED_SAFEQUESTION_FAILURE =
  'UPDATE_SELECTED_SAFEQUESTION_FAILURE';
//
export const GET_VENDOR_QUESTIONS = 'GET_VENDOR_QUESTIONS';
export const GET_VENDOR_QUESTIONS_SUCCESS = 'GET_VENDOR_QUESTIONS_SUCCESS';
export const GET_VENDOR_QUESTIONS_FAILURE = 'GET_VENDOR_QUESTIONS_FAILURE';
//
export const UPDATE_SELECTED_ROUND = 'UPDATE_SELECTED_ROUND';
//
export const CLEAR_ADMIN_STORE = 'CLEAR_ADMIN_STORE';
