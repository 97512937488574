import {
  GET_STORE_LOCATIONS,
  GET_STORE_LOCATIONS_SUCCESS,
  GET_STORE_LOCATIONS_FAILURE,
  SET_SELECTED_LOCATION,
  SET_ASSIGNED_LOCATIONS,
  SET_LOCATION_COUNTRY_CODE,
  //
  END_USER_SESSION,
  SET_IS_LICENSEE_LOCATION,
  //
  GET_LOCATION_FEATURE_PERMISSIONS,
  GET_LOCATION_FEATURE_PERMISSIONS_SUCCESS,
  GET_LOCATION_FEATURE_PERMISSIONS_FAIL,
  //
  SET_SMARTSHOP_LOCATIONS,
} from '../actions';

const defaultArrayValue = [];

const initialState = {
  availableLocations: null,
  assignedLocations: null,
  selectedLocation: null,
  locationCountryCode: 'US',
  isLicenseeLocation: null,
  loading: false,
  error: null,
  smartShopLocations: null,
  amendmentLocationsPerms: [],
};

const doActions = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_LOCATIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_STORE_LOCATIONS_SUCCESS:
      return {
        ...state,
        availableLocations: action.payload,
        loading: false,
        error: null,
      };

    case GET_STORE_LOCATIONS_FAILURE:
      return {
        ...state,
        availableLocations: defaultArrayValue,
        loading: false,
        error: action.payload.error,
      };

    case SET_ASSIGNED_LOCATIONS:
      return {
        ...state,
        assignedLocations: action.payload,
        loading: false,
        error: action.payload.error,
      };

    case SET_SELECTED_LOCATION:
      return {
        ...state,
        loading: false,
        selectedLocation: action.payload,
      };

    case SET_LOCATION_COUNTRY_CODE:
      return {
        ...state,
        locationCountryCode: action.payload,
      };

    case SET_IS_LICENSEE_LOCATION:
      return {
        ...state,
        isLicenseeLocation: action.payload,
      };

    case END_USER_SESSION:
      return {
        ...initialState,
      };

    case GET_LOCATION_FEATURE_PERMISSIONS:
      return {
        ...state,
      };
    case GET_LOCATION_FEATURE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        amendmentLocationsPerms: action.payload,
      };

    case GET_LOCATION_FEATURE_PERMISSIONS_FAIL:
      return {
        ...state,
      };
    case SET_SMARTSHOP_LOCATIONS:
      return {
        ...state,
        smartShopLocations: action.payload,
      };

    default:
      return state;
  }
};
export default doActions;
