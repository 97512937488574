import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'cfa-react-components';
import { setSelectedAudit } from '../../actions';
import I18n from '../../../i18n/utils';
import './RoundSelector.scss';

const RoundSelector = ({ loading, buildRoundBasedUrl }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allAudits = useSelector((state) => state.audit.allAudits);
  const selectedAudit = useSelector((state) => state.audit.selectedAudit);

  const handleAuditSelection = (audit) => {
    if (audit.round !== selectedAudit.round) {
      history.push(buildRoundBasedUrl(audit.round));
    }
    dispatch(setSelectedAudit(audit));
  };

  return selectedAudit && allAudits ? (
    <div className="round-selector" data-testid="round-selector-round-selector">
      <Dropdown
        data-testid="round-selector-dropdown"
        compact
        label={I18n.t('APP_REPORT_DASHBOARD_FILTERS_ROUND')}
        value={selectedAudit}
        getOptionText={(option) => `${option.round}`}
        renderOption={(option) => `${option.round}`}
        onChange={handleAuditSelection}
        disabled={loading}
        placeholder="Select a Round..."
        options={allAudits}
      />
    </div>
  ) : null;
};

RoundSelector.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default RoundSelector;
