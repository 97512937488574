import { useMemo } from 'react';
import QuestionContainer from './QuestionContainer';
import { useLVRStore } from '../store/reducers';
import { syncResponseValidationStatus } from '../utils/questionsUtils';

import './Question.scss';

const SubQuestionContainers = ({ question }) => {
  const { responseUniqueIdentifier } = question;

  const lvrStore = useLVRStore();

  const existingResponse = useMemo(
    () => lvrStore.responses?.[responseUniqueIdentifier]?.responseData,
    [lvrStore.responses, responseUniqueIdentifier],
  );
  const choicesMade = existingResponse?.choicesMade;

  if (!choicesMade) return null;

  syncResponseValidationStatus({ store: lvrStore });

  const subQuestionIdsToRender = choicesMade.flatMap(
    (choice) => choice.responseUniqueIdentifiers || [],
  );

  if (
    !subQuestionIdsToRender?.length ||
    !lvrStore?.responses?.[question?.responseUniqueIdentifier]
  )
    return null;

  const subQuestionInternalIds = lvrStore?.responses[
    question?.responseUniqueIdentifier
  ].responseData.choicesMade
    .flatMap((choice) => choice.responseUniqueIdentifiers)
    .filter(Boolean);

  const subQuestionsToRender = lvrStore?.questionnaireQuestions.filter(
    ({ responseUniqueIdentifier: id }) => subQuestionInternalIds?.includes(id),
  );

  return subQuestionsToRender.map((sq) => (
    <div
      key={sq.responseUniqueIdentifier}
      className="sub-question-container"
      data-testid="sub-question-container-sub-question-container"
    >
      <QuestionContainer
        data-testid="sub-question-container-question-container"
        question={sq}
      />
    </div>
  ));
};

export default SubQuestionContainers;
