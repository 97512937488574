export const setFormData = ({ key, config, file, fileName }) => {
  const { formData } = config;
  const formDataObj = new FormData();

  formDataObj.append('X-Amz-Algorithm', formData?.['x-amz-algorithm']);
  formDataObj.append('X-Amz-Credential', formData?.['x-amz-credential']);
  formDataObj.append(
    'X-Amz-Security-Token',
    formData?.['x-amz-security-token'],
  );
  formDataObj.append('X-Amz-Date', formData?.['x-amz-date']);
  formDataObj.append('Policy', formData?.policy);
  formDataObj.append('X-Amz-Signature', formData?.['x-amz-signature']);
  formDataObj.append('key', key);
  formDataObj.append('file', file, fileName);

  return formDataObj;
};

export const checkAcceptableFileTypes = (file, accept) => {
  const fileType = `.${file.split('.').pop().toLowerCase()}`;
  return accept.includes(fileType);
};

export const getAttachmentDisplayName = (fileName) => {
  return fileName
    .split('/') // Split by directory
    .pop() // Take the last segment, which is the file name
    .split('.') // Split by dot to separate UUID and actual file name
    .slice(1) // Skip the UUID and take the remaining segments
    .join('.'); // Join the segments back into a single string
};
