import { useEffect, useState } from 'react';
import { Button, Icon } from 'cfa-react-components';
import { IconDownload } from '@tabler/icons-react';
import { API_GET } from '../../utils/api/API';

import './AmendmentCommentCard.scss';

const LbrDownloadButton = ({ location, round }) => {
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    if (!location || !round) return;

    const getDownloadUrl = async () => {
      const path = `${process.env.REACT_APP_SERVER_URL}/audit/${location}/${round}/lbr`;
      try {
        const { data } = await API_GET(path);
        setDownloadUrl(data);
      } catch (error) {
        console.log(error);
      }
    };
    getDownloadUrl();
  }, [location, round]);

  return downloadUrl ? (
    <div
      className="amendments-card__flex-button amendments-card__download-btn-container"
      data-testid="lbr-download-button-download-button-container"
    >
      <Button
        as="a"
        variant="text"
        color="secondary"
        className="amendments-card__download-btn"
        data-testid="lbr-download-button-download-button"
        href={downloadUrl}
        target="_blank"
      >
        <Icon
          className="amendments-card__icon"
          data-testid="lbr-download-button-download-button-icon"
          icon={IconDownload}
        />
        Download LBR
      </Button>
    </div>
  ) : null;
};

export default LbrDownloadButton;
