import {
  LVR_CLEAN_SAFE,
  LVR_GUEST_EXPERIENCE,
  LVR_GREAT_FOOD,
  LVR_OPERATOR_CONSULTING_NOTES,
  LVR_STAFF,
  RISK_LEVELS_LONG,
} from '../../../constants/constants';

export const categoryIdToName = (id = '') => {
  switch (id) {
    case 'GE':
    case 'FAS':
    case 'GH':
      return LVR_GUEST_EXPERIENCE;
    case 'GF':
      return LVR_GREAT_FOOD;
    case 'CS':
      return LVR_CLEAN_SAFE;
    case 'OC':
      return LVR_OPERATOR_CONSULTING_NOTES;
    case 'ST':
      return LVR_STAFF;

    default:
      return LVR_STAFF;
  }
};

export const sortFindings =
  (value = '') =>
  (a, b) => {
    switch (value) {
      case 'highRiskLevel':
        if (
          RISK_LEVELS_LONG.indexOf(a.riskLevel) <
          RISK_LEVELS_LONG.indexOf(b.riskLevel)
        )
          return -1;
        break;
      case 'lowRiskLevel':
        if (
          RISK_LEVELS_LONG.indexOf(a.riskLevel) >
          RISK_LEVELS_LONG.indexOf(b.riskLevel)
        )
          return -1;
        break;
    }
    return 1;
  };

export const flattenAnswers = (data = {}) => {
  let answers = [];

  if (data.choicesMade && Array.isArray(data.choicesMade)) {
    answers = data.choicesMade.map((choice) => choice.text);
  }

  if (data.response && typeof data.response === 'string') {
    answers.push(data.response);
  }

  return answers;
};

export const buildQuestionChain = (findings = []) => {
  const fetchChildQuestion = (finding) => {
    const subQuestions = [];

    finding?.responseData?.choicesMade?.forEach((choice) => {
      const responseId = choice.responseUniqueIdentifiers
        ? choice.responseUniqueIdentifiers[0]
        : null;
      if (responseId) {
        const splitIds = responseId.split('_')[0].split('-');
        const questionVersionId = parseInt(splitIds[0], 10);
        const questionInternalId = `${splitIds[1]}-${splitIds[2]}`;

        const child = findings.find(
          (f) =>
            f.questionVersionId === questionVersionId &&
            f.questionInternalId === questionInternalId,
        );

        subQuestions.push(fetchChildQuestion(child));
      }
    });
    return {
      parentFinding: {
        ...finding,
        responseData: {
          ...finding?.responseData,
          flattenAnswers: flattenAnswers(finding?.responseData),
        },
      },
      childrenFindings: subQuestions,
    };
  };

  const questionChain = [];
  findings.forEach((finding) =>
    questionChain.push(fetchChildQuestion(finding)),
  );
  return questionChain;
};
