import I18n from '../../i18n/utils';
import { getApprovedAmendmentCount } from './AmendmentActions';

import {
  GET_ALL_AUDITS,
  GET_ALL_AUDITS_SUCCESS,
  GET_ALL_AUDITS_SUCCESS_EMPTY,
  GET_ALL_AUDITS_FAILURE,
  //
  GET_AUDIT_SUMMARY,
  GET_AUDIT_SUMMARY_SUCCESS,
  GET_AUDIT_SUMMARY_FAILURE,
  //
  GET_LEAVE_BEHIND_REPORT_PDF,
  GET_LEAVE_BEHIND_REPORT_PDF_SUCCESS,
  GET_LEAVE_BEHIND_REPORT_PDF_FAILURE,
  //
  GET_SELECTED_AUDIT_FINDINGS,
  GET_SELECTED_AUDIT_FINDINGS_SUCCESS,
  GET_SELECTED_AUDIT_FINDINGS_FAILURE,
  //
  SET_SELECTED_AUDIT,
  //
  GET_SELECTED_FINDING_DETAILS,
  GET_SELECTED_FINDING_DETAILS_SUCCESS,
  GET_SELECTED_FINDING_DETAILS_FAILURE,
  CLEAR_SELECTED_AUDIT_FINDINGS,
  //
  CLEAR_AUDIT_SELECTION,
  //
  GET_HISTORICAL_ACTIONS,
  GET_HISTORICAL_ACTIONS_SUCCESS,
  GET_HISTORICAL_ACTIONS_FAILURE,
  //
  PUT_HISTORICAL_ACTION,
  PUT_HISTORICAL_ACTION_SUCCESS,
  PUT_HISTORICAL_ACTION_FAILURE,
  //
  GET_FINDINGS,
  GET_FINDINGS_SUCCESS,
  GET_FINDINGS_FAIL,
  GET_FINDINGS_SUMMARY,
  GET_FINDINGS_SUMMARY_SUCCESS,
} from '.';
import { API_GET, API_POST } from '../utils';

const FOOD_SAFETY_API_BASE_URL = process.env.REACT_APP_SERVER_URL;

export const getAllAudits = (locationNumber, auditRound) => {
  return async (dispatch, getState) => {
    const state = getState();

    /*
     * this method can be called when we already have `allAudits` for the specified `locationNumber`, so build a list of
     * location numbers corresponding to all of the audits that we currently have "cached" in our redux store
     */
    const cachedAuditsLocationNumbers = !state.audit?.allAudits
      ? []
      : state.audit.allAudits.map((audit) => audit.locationNumber);

    // if the requested `locationNumber` exists from the list built above, then we already have audits for the location
    // "cached" in our redux store, so early return out of the function before making another API request to get data
    // we already have
    if (cachedAuditsLocationNumbers.indexOf(locationNumber) >= 0) {
      return;
    }

    dispatch({ type: GET_ALL_AUDITS });
    // Assemble URL Path
    const path = `${FOOD_SAFETY_API_BASE_URL}/audit/by-location/${
      locationNumber || state.location.selectedLocation.locationNumber
    }`;

    try {
      const { data } = await API_GET(path);
      if (data.length === 0) {
        dispatch({ type: GET_ALL_AUDITS_SUCCESS_EMPTY, payload: [] });
        return;
      }
      dispatch({ type: GET_ALL_AUDITS_SUCCESS, payload: data });

      if (!data.length) {
        // Location does not have any audits, clear State and return before further processing
        dispatch({ type: CLEAR_AUDIT_SELECTION });
        return;
      }

      if (!auditRound) {
        const selectedAudit = data[0];
        dispatch(setSelectedAudit(selectedAudit));
        return;
      }
      const availableAuditsByRound = new Map(
        data.map((audit) => [audit.round, audit]),
      );
      const selectedAudit = availableAuditsByRound.get(auditRound) || data[0];
      dispatch(setSelectedAudit(selectedAudit));
    } catch (err) {
      console.log('err', err);
      dispatch({ type: GET_ALL_AUDITS_FAILURE });
    }
  };
};

export const getAuditSummary = (locationNumber, round) => {
  return async (dispatch) => {
    dispatch({ type: GET_AUDIT_SUMMARY });

    // Assemble URL Path
    const path = `${FOOD_SAFETY_API_BASE_URL}/audit/${locationNumber}/${round}/summary`;

    try {
      const { data } = await API_GET(path);
      dispatch({ type: GET_AUDIT_SUMMARY_SUCCESS, payload: data });
    } catch (err) {
      console.log('err', err);
      dispatch({ type: GET_AUDIT_SUMMARY_FAILURE });
    }
  };
};

export const getSelectedAuditFindings = (selectedAudit) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_SELECTED_AUDIT_FINDINGS });
    // Get query params
    const { uuid, locationNumber, round } =
      selectedAudit || getState().audit.selectedAudit;
    // Assemble URL Path
    const path = `${FOOD_SAFETY_API_BASE_URL}/audit/${locationNumber}/${round}/findings`;

    try {
      const { data } = await API_GET(path);
      if (uuid) {
        await dispatch(getHistoricalActions(uuid));
      }
      await dispatch({
        type: GET_SELECTED_AUDIT_FINDINGS_SUCCESS,
        payload: data,
      });
      return data;
    } catch (err) {
      console.log('err', err);
      dispatch({ type: GET_SELECTED_AUDIT_FINDINGS_FAILURE });
    }
  };
};

export const getLeaveBehindReportPDF = () => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_LEAVE_BEHIND_REPORT_PDF });

    // Get Effective Audit
    const { auditSummary } = getState().audit;

    if (auditSummary.lbrLink) {
      const link = document.createElement('a');
      link.href = auditSummary.lbrLink;
      link.setAttribute(
        'download',
        `${auditSummary.audit.round} ${I18n.t(
          'APP_REPORT_DASHBOARD_LEAVE_BEHIND',
        )}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      return dispatch({ type: GET_LEAVE_BEHIND_REPORT_PDF_SUCCESS });
    }
    dispatch({ type: GET_LEAVE_BEHIND_REPORT_PDF_FAILURE });
  };
};

export const setSelectedAudit = (audit) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_AUDIT, payload: audit });

    dispatch({ type: CLEAR_SELECTED_AUDIT_FINDINGS });

    if (audit.approvedAmendmentCount) {
      dispatch(getApprovedAmendmentCount(audit.locationNum, audit.round));
    }
  };
};

export const setSelectedAuditByRound = (auditRound) => {
  return (dispatch, getState) => {
    const state = getState();

    if (auditRound === state.audit?.selectedAudit?.round) {
      return;
    }

    if (!state.audit?.allAudits || state.audit.allAudits.length === 0) {
      dispatch(
        getAllAudits(
          state.location.selectedLocation.locationNumber,
          auditRound,
        ),
      );
    } else {
      const availableAudits = new Map(
        state.audit.allAudits.map((audit) => [audit.round, audit]),
      );
      dispatch(
        setSelectedAudit(availableAudits.get(auditRound) || state.allAudits[0]),
      );
    }
  };
};

// Fetch details of selected finding from Redux
export const getSelectedFindingDetails = (questionSafeReportId) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_SELECTED_FINDING_DETAILS });

    // Search for finding in selected audit findings
    const data = getState().audit.selectedAuditFindings?.find(
      (finding) => finding.questionSafeReportId === questionSafeReportId,
    );

    if (data) {
      dispatch({ type: GET_SELECTED_FINDING_DETAILS_SUCCESS, payload: data });
      return data;
    }
    dispatch({ type: GET_SELECTED_FINDING_DETAILS_FAILURE });
  };
};

export const clearAuditSelection = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_AUDIT_SELECTION });
  };
};

export const getHistoricalActions = (auditUUID) => {
  return async (dispatch) => {
    dispatch({ type: GET_HISTORICAL_ACTIONS });

    const path = `${FOOD_SAFETY_API_BASE_URL}/historical-action/${auditUUID}`;
    try {
      const { data } = await API_GET(path);
      dispatch({ type: GET_HISTORICAL_ACTIONS_SUCCESS, payload: data });
    } catch (err) {
      console.log('err', err);
      dispatch({ type: GET_HISTORICAL_ACTIONS_FAILURE });
    }
  };
};

export const putHistoricalAction = (
  audit,
  question = null,
  type,
  content = null,
) => {
  return async (dispatch, getState) => {
    dispatch({ type: PUT_HISTORICAL_ACTION });

    // Store any existing action in order to update it rather than make a new action
    const existingAction = getState().audit.historicalActions.filter(
      (action) =>
        (action.questionUUID ? action.questionUUID === question : true) &&
        action.historicalActionType === type &&
        (action.content ? action.content === content : true),
    )[0];

    // If an existing action is avaialble, pass on its Id
    const payload = {
      auditUUID: audit,
      reportQuestionUUID: question,
      historicalActionType: type,
      content,
      id: existingAction != null ? existingAction.id : null,
    };

    const path = `${FOOD_SAFETY_API_BASE_URL}/historical-action`;

    try {
      await API_POST(path, payload);
      dispatch({ type: PUT_HISTORICAL_ACTION_SUCCESS });
      // After Posting run getHistoricalActions and getSelectedFindingDetails to refresh page data
      await dispatch(getHistoricalActions(getState().audit.selectedAudit.uuid));
      dispatch(
        getSelectedFindingDetails(
          getState().audit.selectedFindingDetails.questionSafeReportId,
        ),
      );
    } catch (err) {
      console.log('err', err);
      dispatch({ type: PUT_HISTORICAL_ACTION_FAILURE });
    }
  };
};

export const getFindingsAction = (locationNumber, round) => {
  return async (dispatch) => {
    dispatch({ type: GET_FINDINGS });

    const path = `${FOOD_SAFETY_API_BASE_URL}/audit/${locationNumber}/${round}`;
    try {
      const { data } = await API_GET(path);
      dispatch({ type: GET_FINDINGS_SUCCESS, payload: data });
    } catch (err) {
      console.log('err', err);
      dispatch({ type: GET_FINDINGS_FAIL });
    }
  };
};

export const getAuditSummaryDataGraph = (locationNumber, round) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_FINDINGS_SUMMARY });
    const allAuditsData = getState().audit.allAudits;

    if (allAuditsData.length < 1) {
      dispatch({ type: GET_FINDINGS_SUMMARY_SUCCESS, payload: [] });
      return;
    }

    const auditObjects = getAuditData(allAuditsData);
    const auditSummaryData = await Promise.all(
      auditObjects.map(async (auditObject) => {
        const path = `${FOOD_SAFETY_API_BASE_URL}/audit/${auditObject.locationNumber}/${auditObject.round}/summary`;
        try {
          const { data } = await API_GET(path);
          return { round: data.audit.round, ...data.findingsSummary };
        } catch (err) {
          console.log(`err at ${round} from location ${locationNumber}`, err);
          return null;
        }
      }),
    );

    dispatch({ type: GET_FINDINGS_SUMMARY_SUCCESS, payload: auditSummaryData });
  };
};

function getAuditData([...auditsArry]) {
  return auditsArry.splice(0, 5).map((data) => ({
    uuid: data.uuid,
    locationNumber: data.locationNumber,
    round: data.round,
  }));
}
