import { useMemo, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from 'cfa-react-components';
import FileUploader from '../../../FileUploader/FileUploader';
import { useLVRDispatch, useLVRStore } from '../../store/reducers';
import { updateResponseData } from '../../utils/questionsUtils';
import { getAttachmentDisplayName } from '../../../FileUploader/common/dataUtils';
import useSelectedLocation from '../../../../utils/hooks/useSelectedLocation';
import { IMAGE_FILE_TYPES } from '../../../FileUploader/common/constants';

import './FreeResponseQuestion.scss';

const ImageUploadModal = ({ question }) => {
  const { responseUniqueIdentifier } = question;

  const [open, setOpen] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const lvrStore = useLVRStore();
  const lvrDispatch = useLVRDispatch();

  const selectedLocation = useSelectedLocation();
  const location = selectedLocation?.locationNumber;

  const fileUploadConfig = {
    s3Policy: `${process.env.REACT_APP_SERVER_URL}/assessment/lvr/attachments/policy/${location}`,
    s3DirPath: `LVR/${location}/attachment`,
    accept: IMAGE_FILE_TYPES,
  };

  const existingResponse = useMemo(
    () => lvrStore.responses?.[responseUniqueIdentifier]?.responseData,
    [lvrStore.responses, responseUniqueIdentifier],
  );

  const setStore = (data) =>
    updateResponseData({
      store: lvrStore,
      dispatch: lvrDispatch,
      type: 'attachment',
      question,
      data,
    });

  const putInitialData = () => {
    if (existingResponse?.attachmentS3Urls) {
      const { attachmentS3Urls } = existingResponse;

      setStore(attachmentS3Urls);

      const fileItems = attachmentS3Urls.map((attachmentUrl) => {
        const displayName = getAttachmentDisplayName(attachmentUrl);
        return {
          displayName,
          fileName: attachmentUrl.split('/').pop(),
          filePath: attachmentUrl,
        };
      });

      setInitialData(fileItems);
    }
  };

  return (
    <>
      <Button
        className="file-uploader__add-images-btn"
        data-testid="image-upload-modal-add-images-button"
        variant="text"
        color="secondary"
        size="sm"
        onClick={() => setOpen(true)}
      >
        + Add Images
      </Button>
      <Modal
        data-testid="image-upload-modal-modal"
        show={open}
        onEnter={putInitialData}
        onClose={() => {
          if (isUploading) return;
          setOpen(false);
        }}
        size="md"
      >
        <>
          <ModalHeader data-testid="image-upload-modal-modal-header">
            Add Images
          </ModalHeader>
          <Typography
            className="free-response-question__image-uploader-subtitle"
            data-testid="image-upload-modal-image-uploader-subtitle"
          >
            Only 1 image file can be uploaded at a time.
          </Typography>

          <ModalBody data-testid="image-upload-modal-modal-body">
            <div
              className="free-response-question__image-uploader-container"
              data-testid="image-upload-modal-image-uploader-container"
            >
              <FileUploader
                data-testid="image-upload-modal-file-uploader"
                config={fileUploadConfig}
                initialData={initialData}
                setStore={setStore}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            </div>
          </ModalBody>
          <ModalFooter data-testid="image-upload-modal-modal-footer">
            <Button
              data-testid="image-upload-modal-done-button"
              variant="filled"
              color="secondary"
              disabled={isUploading}
              onClick={() => setOpen(false)}
            >
              Done
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default ImageUploadModal;
