import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Dropdown,
  TextField,
  Button,
  Row,
  Col,
  Typography,
  Card,
  CardContent,
} from 'cfa-react-components';
import { Typeahead } from 'react-bootstrap-typeahead';
import map from 'lodash/map';
import { API_GET, API_POST, getAdminAppealFormYears } from '../../../utils';
import I18n from '../../../../i18n/utils';
import NavHeader from '../../../components/NavHeader/NavHeader';
import MobileNestedHeader from '../../../components/NavHeader/MobileNestedHeader/MobileNestedHeader';
import OccurrencesContainer from '../../../components/Ammendments/AdminAppealForm/OccurrencesContainer';
import ConfirmationCard from '../../../components/Ammendments/AdminAppealForm/ConfirmationCard';
import SubmissionCard from '../../../components/Ammendments/AdminAppealForm/SubmissionCard';

import './AdminAppealForm.scss';

// The AdminAppealForm is a form admins can use to make bulk removals and single store removals.
export default function AdminAppealForm() {
  // State hooks supplying options to the form
  const [effectiveQuestions, setEffectiveQuestions] = useState([]);
  const [reportFindings, setReportFindings] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  // State hooks storing selection by the form user
  const [selectedRound, setSelectedRound] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedOccurrences, setSelectedOccurrences] = useState([]);
  const [reasonInput, setReasonInput] = useState('');

  // Boolean State hooks determining some logic for the form
  const [loading, setLoading] = useState(false);
  const [selectAllStores, setSelectAllStores] = useState(false);
  const [showConfirmationCard, setShowConfirmationCard] = useState(false);
  const [showSubmissionPage, setShowSubmissionPage] = useState(false);

  // Because thie user is a safe admin, this is an array of all locations, which can be used to supply the form
  const availableLocations = useSelector(
    (state) => state.location.availableLocations,
  );

  // local definition of the useHistory hook
  const history = useHistory();

  // hook to retrieve questions upon a round AND year selection
  // Also resets state objects that need to be re-selected
  useEffect(() => {
    if (selectedRound !== '' && selectedYear !== '') {
      setReportFindings([]);
      setSelectedLocations([]);
      setSelectedQuestion('');
      retrieveEffectiveQuestions();
    }
  }, [selectedRound, selectedYear]);

  // hook to retrieve the locations/findings for a selected round/questionID combo
  // Also resets state objects that need to be reselected
  useEffect(() => {
    if (selectedQuestion !== '') {
      setSelectedLocations([]);
      retrieveReportFindings();
      setReasonInput('');
    }
  }, [selectedQuestion]);

  // hook to set the location options based on the reportFindings locationNumbers
  useEffect(() => {
    if (reportFindings.length !== 0 && availableLocations) {
      setLocationOptions(
        availableLocations.filter((location) =>
          Object.keys(reportFindings).includes(location.locationNumber),
        ),
      );
    }
  }, [reportFindings]);

  // hook to select all stores when the checkbox goes from false to true
  useEffect(() => {
    if (selectAllStores === true) {
      setSelectedLocations(locationOptions);
    }
  }, [selectAllStores]);

  // hook to reset state objects when the selectedLocations change
  useEffect(() => {
    setReasonInput('');
    setSelectedOccurrences([]);
  }, [selectedLocations]);

  // hook to scroll to top of the page when going to or from the confirmation page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showConfirmationCard]);

  // function to get the Effective questions for a specific quarter/year combo
  const retrieveEffectiveQuestions = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/question?round=${selectedRound}-${selectedYear}`;

    try {
      await setLoading(true);
      const result = await API_GET(url);
      const questions = result.data;
      await setEffectiveQuestions(
        questions.sort((a, b) => (a.safeReportId > b.safeReportId ? 1 : -1)),
      );
      await setLoading(false);
    } catch (err) {
      console.error('Failed to retrieve effective questions.', err);
    }
  };

  // function to retrieve report findings for a round/questionID combo
  const retrieveReportFindings = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/finding/${selectedRound}-${selectedYear}/${selectedQuestion.safeReportId}`;

    try {
      await setLoading(true);
      const result = await API_GET(url);
      await setReportFindings(result.data);
      await setLoading(false);
    } catch (err) {
      console.error('Failed to retrieve locations for the finding.', err);
    }
  };

  // helper function that returns a list of Finding objects based on the currently selectedLocation and reportFindings
  const getFindingObjectsFromSelectedLocations = () => {
    // build an array of location numbers to filter against from the selectedLocations
    const selectedLocationNumbers = selectedLocations.map(
      (location) => location.locationNumber,
    );

    // build an object based on reportFindings, but only include findings from stores in selectedLocationNumbers
    const findingsFromLocationNumbers = Object.keys(reportFindings)
      .filter((locationNumKey) =>
        selectedLocationNumbers.includes(locationNumKey),
      )
      .reduce((obj, key) => {
        obj[key] = reportFindings[key];
        return obj;
      }, {});

    return Object.values(findingsFromLocationNumbers).flat();
  };

  // helper function that returns an array of findingUUIDs from an array of finding objects
  const getFindingUUIDsFromFindingObjects = (findings) => {
    return Object.values(findings)
      .flat()
      .map((option) => option.findingUUID);
  };

  // callback function to set the selectedOccurrences when a single location is selected
  const handleOccurrenceSelection = (occurrences) => {
    const findings = getFindingObjectsFromSelectedLocations();
    // filter available the finding object list using the passed up index array from OccurrencesContainer,
    // and set the selected occurrences to that filtered list
    setSelectedOccurrences(
      map(occurrences, function (index) {
        return findings[index];
      }),
    );
  };

  // helper function to return to the appeals page
  const goToAppealsPage = (tabName) => {
    return () => {
      history.push(`/admin/appeals?tab=${tabName}`);
    };
  };

  // helper function to render the findings manually for the component
  const renderFindingOption = (option) => {
    return (
      <div className="admin-appeal-form__finding-dropdown-option">
        {`${option.safeReportId}: ${option.text}`}
      </div>
    );
  };

  // function to submit appeals for both bulk and single store requests
  const submitAppeals = async () => {
    // boolean to determine if this is a bulk or single-store request
    const isBulk =
      selectedLocations.length > 1 && selectedOccurrences.length === 0;

    // determine which endpoint to hit
    const endpoint = isBulk ? `bulk` : `admin-request`;

    // build the url
    const url = `${process.env.REACT_APP_SERVER_URL}/amendments/${endpoint}`;

    // build the payload
    const payload = {
      findingUUIDS: getFindingUUIDsFromFindingObjects(
        isBulk ? getFindingObjectsFromSelectedLocations() : selectedOccurrences,
      ),
      amendmentStatus: 'APPROVED',
      amendmentType: 'DELETION',
      adminComment: reasonInput,
    };

    // attempt to post the requests
    try {
      await API_POST(url, payload);
      setShowSubmissionPage(true);
    } catch (err) {
      console.error(
        `Failed to submit Admin Appeals${isBulk}` ? ' - bulk' : '',
        err,
      );
    }
  };

  const getLocationName = (locationName, index) => {
    let name = locationName;

    if (locationName.length > 25) {
      name = `${name.slice(0, 25)}...`;
    }

    return name + (index < selectedLocations.length - 1 ? ',' : '');
  };

  // reset all state hooks
  const resetForm = () => {
    return () => {
      setEffectiveQuestions([]);
      setReportFindings([]);
      setLocationOptions([]);
      setSelectedRound('');
      setSelectedYear('');
      setSelectedQuestion('');
      setSelectedLocations([]);
      setSelectedOccurrences([]);
      setReasonInput('');
      setSelectAllStores(false);
      setShowConfirmationCard(false);
      setShowSubmissionPage(false);
    };
  };

  return (
    <div>
      <div className="d-none d-sm-block">
        <NavHeader />
      </div>
      <div className="d-block d-sm-none">
        <MobileNestedHeader
          title={I18n.t('APP_ADMIN_AMENDMENTS_CONTAINER_TITLE')}
          navigateBackRoute={false}
        />
      </div>
      <div className="admin-appeal-form">
        <Row style={{ justifyContent: 'center' }}>
          <Col xl={8}>
            <div className="admin-appeal-form__flex-item">
              <div className="admin-appeal-form__header-container">
                <Link
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  to="/admin/appeals"
                >
                  <img
                    alt=""
                    src={
                      require('../../../assets/icons/big-left-arrow.svg')
                        .default
                    }
                    height={15}
                    width={15}
                    className="admin-appeal-form__header-arrow desktop-back-arrow"
                  />
                  <Typography variant="h4" color="secondary">
                    {I18n.t('APP_ADMIN_AMENDMENTS_CONTAINER_TITLE')}
                  </Typography>
                </Link>
              </div>
            </div>
            <Card
              style={{
                marginBottom: '5rem',
                padding: '5rem',
                borderRadius: '1.25em',
              }}
            >
              <CardContent>
                {showConfirmationCard === false &&
                  showSubmissionPage === false && (
                    <>
                      <Row>
                        <Typography variant="h3" gutterBottom>
                          Admin Appeal Form
                        </Typography>
                      </Row>
                      <Row>
                        <Typography gutterBottom>
                          This form is used to make an amendment to the Food
                          Safety Report outside the 10 day window that
                          Restaurants have to submit an Appeal. This form
                          includes single store amendment or multiple store
                          (bulk) amendment.
                        </Typography>
                      </Row>
                      <Row style={{ marginBottom: '1rem' }}>
                        <Dropdown
                          style={{ marginLeft: '0' }}
                          label="Select Quarter"
                          placeholder="Quarter"
                          margin="normal"
                          value={selectedRound}
                          disabled={loading}
                          onChange={setSelectedRound}
                          options={['Q1', 'Q2', 'Q3', 'Q4']}
                        />
                        <Dropdown
                          label="Select Year"
                          placeholder="Year"
                          onChange={setSelectedYear}
                          disabled={loading}
                          value={selectedYear}
                          margin="normal"
                          options={getAdminAppealFormYears()}
                        />
                      </Row>
                      <Row style={{ marginBottom: '1rem' }}>
                        <Dropdown
                          style={{ marginLeft: '0' }}
                          fullWidth
                          label="Choose Finding"
                          placeholder="Search by finding ID or keyword..."
                          value={selectedQuestion}
                          getOptionKey={(option) =>
                            `${option.safeReportId}: ${option.text}`
                          }
                          onChange={setSelectedQuestion}
                          renderOption={renderFindingOption}
                          getOptionText={(option) =>
                            `${option.safeReportId}: ${option.text}`
                          }
                          margin="normal"
                          disabled={loading || effectiveQuestions.length === 0}
                          className="admin-appeal-form__finding-dropdown"
                          options={effectiveQuestions}
                        />
                      </Row>
                      <Row
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight="regular"
                          style={{
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                          }}
                        >
                          Select Store
                        </Typography>
                        <Row>
                          <Col xl={12}>
                            <Typeahead
                              className="admin-appeal-form__typeahead"
                              id="basic-typeahead-multiple"
                              labelKey={(option) =>
                                `${option.locationNumber}: ${option.location.locationName}`
                              }
                              multiple
                              onChange={setSelectedLocations}
                              options={locationOptions}
                              placeholder="Select Location(s) ..."
                              selected={selectedLocations}
                              disabled={loading || reportFindings.length === 0}
                            />
                            <Row
                              style={{
                                marginTop: '0.5rem',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Col
                                style={{
                                  display: 'flex',
                                  alignContent: 'center',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  onClick={() =>
                                    setSelectAllStores(!selectAllStores)
                                  }
                                  disabled={
                                    loading || reportFindings.length === 0
                                  }
                                />
                                <Typography
                                  fontWeight="extralight"
                                  className="admin-appeal-form__all-locations-label"
                                >
                                  Select All Locations
                                </Typography>
                              </Col>
                              <Col
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <button
                                  type="button"
                                  className="admin-appeal-form__clear-locations-button"
                                  onClick={() => setSelectedLocations([])}
                                  disabled={
                                    loading || selectedLocations.length === 0
                                  }
                                >
                                  CLEAR
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                      {selectedLocations.length === 1 && (
                        <Row style={{ marginBottom: '1rem' }}>
                          <OccurrencesContainer
                            occurrences={getFindingObjectsFromSelectedLocations()}
                            selectedOccurrences={selectedOccurrences}
                            handleOccurrenceSelection={
                              handleOccurrenceSelection
                            }
                          />
                        </Row>
                      )}
                      <Row>
                        <TextField
                          fullWidth
                          maxLength="700"
                          helperText="Maximum 700 characters"
                          label="Reason for change"
                          multiline
                          // disabled if loading, if no locations are selected,
                          // or if one location is selected but no occurrences are selected
                          disabled={loading || selectedLocations.length === 0}
                          onChange={(option) =>
                            setReasonInput(option.target.value)
                          }
                          value={reasonInput}
                          placeholder="Add a reason..."
                          rows={3}
                        />
                      </Row>
                      <div className="admin-appeal-form__button-container">
                        <Button
                          className="admin-appeal-form__submit-button"
                          color="secondary"
                          onClick={goToAppealsPage('Pending')}
                          variant="text"
                          size="sm"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="admin-appeal-form__submit-button"
                          color="secondary"
                          onClick={() => setShowConfirmationCard(true)}
                          size="sm"
                          fullWidth={false}
                          disabled={
                            loading ||
                            reasonInput.length === 0 ||
                            (selectedLocations.length === 1 &&
                              selectedOccurrences.length === 0)
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}
                {showConfirmationCard === true &&
                  showSubmissionPage === false && (
                    <ConfirmationCard
                      selectedQuestion={selectedQuestion}
                      selectedLocations={selectedLocations}
                      selectedRound={selectedRound}
                      selectedYear={selectedYear}
                      occurrences={
                        selectedLocations.length === 1
                          ? selectedOccurrences
                          : []
                      }
                      reasonInput={reasonInput}
                      getLocationName={getLocationName}
                      returnToForm={setShowConfirmationCard}
                      returnToAppeals={goToAppealsPage('Pending')}
                      submit={submitAppeals}
                    />
                  )}
                {showSubmissionPage === true && (
                  <SubmissionCard
                    selectedQuestion={selectedQuestion}
                    resetForm={resetForm}
                    goToAppealsPage={goToAppealsPage}
                  />
                )}
              </CardContent>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
