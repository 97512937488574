import PropTypes from 'prop-types';
import AmendmentCard from './AmendmentCard';

function AmendmentCardContainer({
  amendmentList,
  statusChangeCallback,
  loadingAmendmentsCallback,
  cardType,
}) {
  return (
    <>
      {amendmentList.length !== 0 &&
        amendmentList.map((amendment, i) => {
          return (
            <AmendmentCard
              data-testid="amendment-card-container-amendment-card"
              key={`${i}-${amendment.id}`}
              cardType={cardType}
              amendment={amendment}
              loadingAmendmentsCallback={loadingAmendmentsCallback}
              statusChangeCallback={statusChangeCallback}
            />
          );
        })}
      {amendmentList.length === 0 && 'No Appeals'}
    </>
  );
}

AmendmentCardContainer.propTypes = {
  amendmentList: PropTypes.array.isRequired,
  cardType: PropTypes.string.isRequired,
};

export default AmendmentCardContainer;
