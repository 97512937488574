export const setAuthCookie = async (accessToken) => {
  try {
    document.cookie = `at=${accessToken};path=/;domain=${window.location.hostname}`;
  } catch (err) {
    console.log(err);
  }
};

export const clearAuthCookie = async () => {
  try {
    document.cookie = `at='';Max-Age=0;path=/;domain=${window.location.hostname}`;
  } catch (err) {
    console.log(err);
  }
};
