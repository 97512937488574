import PropTypes from 'prop-types';
import GeneralInformationForm from './GeneralInformation/GeneralInformation';
import QuestionnaireContainer from '../Questionnaire/QuestionnaireContainer';

const LicenseeVisitReportForm = ({ questionnaire }) => (
  <QuestionnaireContainer
    data-testid="licensee-visit-report-form-questionnaire-container"
    questionnaire={questionnaire}
  >
    <GeneralInformationForm data-testid="licensee-visit-report-form-general-information-form" />
  </QuestionnaireContainer>
);

LicenseeVisitReportForm.propTypes = {
  questionnaire: PropTypes.object,
};

export default LicenseeVisitReportForm;
