import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import ListCard from '../../../../components/ListCard/ListCard';
import FindingOverview from '../FindingOverview/FindingOverview';
import SelectedFinding from '../SelectedFinding/SelectedFinding';
import { buildQuestionChain } from '../helpers';
import useSelectedLocation from '../../../../utils/hooks/useSelectedLocation';

const AllFindingsCards = ({ findings = [] }) => {
  const history = useHistory();
  const { locationNumber } = useSelectedLocation();
  const { assessmentId } = useParams();

  const basePageUrl = `/${locationNumber}/assessment/${assessmentId}/findings`;

  return buildQuestionChain(findings).map(
    ({ parentFinding, childrenFindings = [] }) => (
      <ListCard
        key={parentFinding.questionInternalId}
        cardId={parentFinding.questionInternalId}
        cardContent={
          <FindingOverview
            finding={parentFinding}
            hasChildren={childrenFindings.length > 0}
          />
        }
        drawerContent={
          <SelectedFinding
            finding={parentFinding}
            childrenFindings={childrenFindings}
          />
        }
        onDrawerOpened={() =>
          history.push(`${basePageUrl}/${parentFinding.questionInternalId}`)
        }
        onDrawerClosed={() => history.push(basePageUrl)}
      />
    ),
  );
};

export default AllFindingsCards;
