import dayjs from 'dayjs';
import 'dayjs/locale/en';

export const generateDropdownOptions = () => {
  const currentDate = dayjs();
  const options = [];

  for (let i = 0; i <= 12; i++) {
    const monthDate = currentDate.subtract(i, 'month');
    const month = monthDate.month() + 1;
    const year = monthDate.year();
    const monthName = monthDate.format('MMMM');

    options.push({
      id: `${month}-${year}`,
      name: `${monthName}, ${year}`,
      month,
      year,
    });
  }

  return options;
};

export const getMonthName = (monthNum) => {
  return dayjs(new Date(2000, monthNum - 1)).format('MMMM');
};

export function formatSalesNumber(number) {
  return `$${(Math.round(number * 100) / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
}
