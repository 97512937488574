import { Row, Typography } from 'cfa-react-components';
import PropTypes from 'prop-types';
import RequestedAppealCardLabel from './RequestedAppealCardLabel';
import './RequestedAppealsWidget.scss';

const RequestedAppealCard = ({ appeal }) => {
  return (
    <Row
      className="requested-appeals-widget__appeals-card-info-container"
      data-testid="requested-appeal-card-appeal-card-info-container"
    >
      <div
        className="requested-appeals-widget__appeals-card-description"
        data-testid="requested-appeal-card-appeals-card-description"
      >
        <Typography
          data-testid="requested-appeal-card-appeal-location-num-name"
          variant="caption1"
          align="left"
          fontWeight="bold"
        >
          ({appeal.locationNum}) {appeal.locationName}
        </Typography>
        <Typography
          data-testid="requested-appeal-card-appeal-question-safe-report-id-finding-description"
          variant="body2"
          align="left"
          color="secondary"
        >
          {appeal.questionSafeReportId}: {appeal.findingDescription}
        </Typography>
      </div>
      <RequestedAppealCardLabel
        appeal={appeal}
        data-testid="requested-appeal-card-requested-appeal-card-label"
      />
    </Row>
  );
};

RequestedAppealCard.propTypes = {
  appeal: PropTypes.object.isRequired,
};

export default RequestedAppealCard;
