/* eslint-disable */
// TODO: review disabled eslint rules
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FindingsFilter from '../../FindingsFilter/FindingsFilter';
import './MobileNestedHeader.scss';

class MobileNestedHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      title: props.title,
      showFilterOptions: props.showFilterOptions,
      navigateBackRoute: props.navigateBackRoute,
    };
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleLinkClick() {
    this.setState({ menuOpen: false });
  }

  navigateBack() {
    return () => {
      this.props.history.push(this.props.navigateBackRoute);
    };
  }

  render() {
    return (
      <>
        <div
          className="nav-container"
          data-testid="mobile-nested-header-nav-container"
        >
          <img
            data-testid="mobile-nested-header-go-back-icon"
            alt="go back"
            src={require('../../../assets/icons/left-arrow.svg').default}
            height={17}
            width={17}
            onClick={
              this.props.navigateBackRoute !== false
                ? this.navigateBack()
                : this.props.history.goBack
            }
          />
          <div className="title" data-testid="mobile-nested-header-title">
            {this.state.title}
          </div>
          {this.state.showFilterOptions && (
            <MenuButton
              data-testid="mobile-nested-header-menu-button"
              open={this.state.menuOpen}
              onClick={() => this.handleMenuClick()}
            />
          )}
        </div>
        <Menu
          data-testid="mobile-nested-header-menu"
          open={this.state.menuOpen}
          {...this.props}
        />
      </>
    );
  }
}

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    return (
      <div
        className="menu-container"
        data-testid="mobile-nested-header-menu-container"
        style={{ height: this.state.open ? '95%' : 0 }}
      >
        {this.state.open ? (
          <div
            className="modal-container"
            data-testid="mobile-nested-header-modal-container"
            // Custom Styles since .scss file wasn't loading them dynamically
            style={{
              height: '95%',
              width: '100%',
              backgroundColor: 'white',
              zIndex: 100,
              position: 'fixed',
            }}
          >
            <FindingsFilter
              data-testid="mobile-nested-header-findings-filter"
              {...this.props}
              isMobile
            />
          </div>
        ) : null}
      </div>
    );
  }
}

class MenuButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
      color: this.props.color ? this.props.color : '#3c3c3c',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const styles = {
      container: {
        height: '32px',
        width: '32px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      },
      line: {
        height: '2px',
        width: '20px',
        background: this.state.color,
        transition: 'all 0.2s ease',
      },
      lineTop: {
        transform: this.state.open ? 'rotate(45deg)' : 'none',
        transformOrigin: 'top left',
        marginBottom: '5px',
        width: '20px',
      },
      lineMiddle: {
        opacity: this.state.open ? 0 : 1,
        transform: this.state.open ? 'translateX(-16px)' : 'none',
        width: '12px',
      },
      lineBottom: {
        zIndex: -10,
        transform: this.state.open ? 'translateX(-1px) rotate(-45deg)' : 'none',
        transformOrigin: 'top left',
        marginTop: '5px',
        width: '6px',
      },
      lineBottomOpen: {
        zIndex: -10,
        opacity: !this.state.open ? 0 : 1,
        transform: !this.state.open
          ? 'none'
          : 'translateX(-1px) rotate(-45deg)',
        transformOrigin: 'top left',
        marginTop: '5px',
        width: '20px',
      },
    };
    return (
      <div
        data-testid="mobile-nested-header-styles-container"
        style={styles.container}
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick();
              }
        }
      >
        <div
          data-testid="mobile-nested-header-styles-line-top"
          style={{ ...styles.line, ...styles.lineTop }}
        />
        <div
          data-testid="mobile-nested-header-styles-line-middle"
          style={{ ...styles.line, ...styles.lineMiddle }}
        />
        {this.state.open ? (
          <div
            data-testid="mobile-nested-header-styles-line-bottom-open"
            style={{ ...styles.line, ...styles.lineBottomOpen }}
          />
        ) : (
          <div
            data-testid="mobile-nested-header-styles-line-bottom"
            style={{ ...styles.line, ...styles.lineBottom }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(MobileNestedHeader);
