import {
  Button,
  Card,
  CardContent,
  Row,
  Typography,
} from 'cfa-react-components';
import { useContext } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RequestedAppealsCardContainer from './RequestedAppealCardContainer';
import { SelectedTabContext } from '../../../../components/Layout/TabLayout/TabLayout';
import I18n from '../../../../../i18n/utils';
import './RequestedAppealsWidget.scss';

const RequestedAppealsWidget = ({ appeals }) => {
  const [, setSelectedTab] = useContext(SelectedTabContext);

  if (appeals?.length === 0) return;

  return (
    <Card
      className="requested-appeals-widget"
      data-testid="requested-appeals-widget-card"
    >
      <CardContent
        className="requested-appeals-widget__container"
        data-testid="requested-appeals-widget-container"
      >
        <Row
          className="requested-appeals-widget__header-row"
          data-testid="requested-appeals-widget-header-row"
        >
          <Typography
            data-testid="requested-appeals-widget-app-report-dashboard-requested-appeals"
            variant="h4"
            fontWeight="bold"
            gutterBottom={false}
          >
            {I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS')}
          </Typography>
          <Button
            className="requested-appeals-widget__view-button"
            data-testid="requested-appeals-widget-view-button"
            as="a"
            color="secondary"
            href=""
            onClick={() => setSelectedTab('REQUESTEDAPPEALS')}
            size="sm"
            variant="text"
          >
            {I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_VIEW_APPEALS')}
          </Button>
        </Row>
        <Row
          className="requested-appeals-widget__header-row"
          data-testid="requested-appeals-widget-header-row"
        >
          <Typography
            className="requested-appeals-widget__column-header"
            data-testid="requested-appeals-widget-column-header-findings"
            variant="body2"
            fontWeight="bold"
            color="disabled"
            gutterBottom={false}
          >
            {I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FINDINGS')}
          </Typography>
          <Typography
            className="requested-appeals-widget__column-header"
            data-testid="requested-appeals-widget-column-header-status"
            variant="body2"
            fontWeight="bold"
            color="disabled"
            gutterBottom={false}
          >
            {I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_STATUS')}
          </Typography>
        </Row>
        <RequestedAppealsCardContainer
          appeals={appeals}
          data-testid="requested-appeals-widget-requested-appeals-card-container"
        />
      </CardContent>
    </Card>
  );
};

RequestedAppealsWidget.propTypes = {
  appeals: PropTypes.array.isRequired,
};

export default withOktaAuth(withRouter(RequestedAppealsWidget));
