/* eslint-disable jsx-a11y/label-has-associated-control */
import { Icon, Typography } from 'cfa-react-components';
import { CloudUpload } from '@cfa-icons/system';
import FileItem from './FileItem';

import '../FileUploader.scss';

const Uploader = ({
  fileList,
  onSelect,
  onDelete,
  accept,
  isUploading,
  children,
}) => (
  <>
    {fileList.map((file) => (
      <FileItem
        data-testid="uploader-file-item"
        key={file.fileName}
        name={file.displayName}
        file={file.fileName}
        isLoading={file.isLoading}
        isError={file?.isError}
        onDelete={onDelete}
      />
    ))}
    <div
      className={`file-uploader__form-container ${
        isUploading ? 'file-uploader__form-container--is-uploading' : ''
      }`}
    >
      <form className="file-uploader__form">
        <div className="file-uploader__input-wrapper">
          {children || (
            <label className="file-uploader__input-label">
              <Icon icon={CloudUpload} size="md" color="secondary" />
              <Typography fontWeight="bold">
                {isUploading ? 'Uploading file...' : 'Upload file'}
              </Typography>
              {isUploading && (
                <Typography>This could take a few moments...</Typography>
              )}
            </label>
          )}

          <input
            type="file"
            name="file-upload"
            className="file-uploader__hidden"
            data-testid="uploader-hidden-input"
            onChange={onSelect}
            accept={accept}
            draggable={false}
          />
        </div>
      </form>
    </div>
  </>
);

export default Uploader;
