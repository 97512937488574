import { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  LoadingIndicator,
  Switch,
  Typography,
} from 'cfa-react-components';
import { Row } from 'reactstrap';
import { API_GET } from '../../../../../utils';
import LvrWidgetTitle from '../../LicenseeVisitReportWidgetTitle';
import './OCNotes.scss';

const FOOD_SAFETY_API_BASE_URL = process.env.REACT_APP_SERVER_URL;

const NoDataCard = () => {
  return (
    <Card className="oc-notes-card__no-data-card">
      <CardContent>
        <Typography className="oc-notes-card__no-data-card-text" variant="h4">
          More data coming soon.
        </Typography>
      </CardContent>
    </Card>
  );
};

const ConsultingNotes = ({ assessmentId, noDataCard }) => {
  const [isRevealed, setIsRevealed] = useState(false);
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchNotes = async () => {
      if (assessmentId) {
        setIsLoading(true);
        const result = await API_GET(
          `${FOOD_SAFETY_API_BASE_URL}/assessment/lvr/oc-notes/${assessmentId}`,
        );
        if (isMounted) {
          setNotes(result.data);
          setIsLoading(false);
        }
      }
    };
    fetchNotes();

    return () => {
      isMounted = false;
    };
  }, [assessmentId]);

  const toggleIsRevealed = useCallback(() => {
    setIsRevealed(!isRevealed);
  }, [isRevealed]);

  if (noDataCard) {
    return <NoDataCard />;
  }
  return (
    <Card className="assessment-card oc-notes-card" style={{ height: '100%' }}>
      <CardContent className="assessment-card__content">
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <LvrWidgetTitle title="Operator Consulting Notes" />
          <Switch
            label="View"
            onChange={toggleIsRevealed}
            disabled={isLoading && assessmentId}
            data-testid="operator-notes-switch"
            defaultChecked={isRevealed}
            aria-label="View Operator Consulting Notes"
            className="oc-notes-card__switch"
          />
        </Row>
        <Row className="assessment-card__row oc-notes-card__row">
          {isLoading && assessmentId ? (
            <LoadingIndicator variant="inline" size="md" />
          ) : isRevealed ? (
            <Typography className="oc-notes-card__message">
              {notes !== ''
                ? notes
                : 'No OC notes were recorded in the LVR for the date selected.'}
            </Typography>
          ) : (
            <Typography className="oc-notes-card__message">
              {'-'.repeat(475)}.
            </Typography>
          )}
        </Row>
      </CardContent>
    </Card>
  );
};

export default ConsultingNotes;
