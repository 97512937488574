import queryString from 'query-string';
import { API_GET } from '../../../utils/api/API';

const domain = process.env.REACT_APP_SERVER_URL;

export const getSmartShopAmendments = async (
  locationNumber = null,
  round = null,
) => {
  if (!locationNumber || !round) return null;
  try {
    const queryParams = {
      program: 'SMARTSHOP',
      locations: [locationNumber],
      rounds: [round],
    };
    const url = `${domain}/assessment/amendments?${queryString.stringify(
      queryParams,
    )}`;
    const { data } = await API_GET(url);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getLbrData = async (assessmentId = null) => {
  if (!assessmentId) return null;
  try {
    const url = `${domain}/external/true-view/upload?${queryString.stringify({
      assessmentIds: [assessmentId],
    })}`;
    const { data } = await API_GET(url);

    return data;
  } catch (error) {
    console.log(error);
  }
};
