import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
  Button,
  Container,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
  List,
  ListItem,
} from 'cfa-react-components';
import { SuccessFilled } from '@cfa-icons/system';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import cloneDeep from 'lodash/cloneDeep';
import I18n from '../../../../i18n/utils';
import NavHeader from '../../../components/NavHeader/NavHeader';
import { API_GET, API_PUT } from '../../../utils/api/API';
import { syncResponseValidationStatus } from '../../../components/Assessment/utils/questionsUtils';
import useSelectedLocation from '../../../utils/hooks/useSelectedLocation';
import {
  useLVRStore,
  useLVRDispatch,
  actions,
} from '../../../components/Assessment/store/reducers';
import LicenseeVisitReportFormComponent from '../../../components/Assessment/LVR/LicenseeVisitReportForm';
import {
  getGeneralInformationValidationStatus,
  getCategoriesWithErrors,
} from './validationUtils';
import checkServerUpStatus from '../../../utils/checkServerUpStatus';
import {
  showAutoSaveSuccessNotification,
  showFailureNotification,
  showServerUpStatusNotification,
} from '../../../utils/Notification';
import {
  getAutoSaveAssessmentById,
  putAutoSaveAssessment,
  deleteAutoSaveAssessment,
} from '../../../components/Assessment/services/autoSaveApi';
import { parseUrlQueryString } from '../../../utils/parseUrlQueryString';
import useLvrAccess from '../../../utils/hooks/useLvrAccess';
import { getFlattenedQuestions } from '../../../components/Assessment/utils/dataUtils';

import './LicenseeVisitReportForm.scss';

const LicenseeVisitReportForm = () => {
  const routerLocation = useLocation();
  const history = useHistory();
  const { locationNumber, location } = useSelectedLocation();
  const { hasLvrFormAccess } = useLvrAccess();

  const lvrDispatch = useLVRDispatch();
  const lvrStore = useLVRStore();

  const [questionnaire, setQuestionnaire] = useState(null);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const autoSaveIntervalRef = useRef();

  const returnToLandingPage = () => {
    history.push(`/${locationNumber}/landing`);
  };

  const putAutoSaveData = useCallback(
    async (showNotification = false) => {
      try {
        // check connectivity and server status
        const isServerUp = await checkServerUpStatus();
        if (!isServerUp || !lvrStore.questionnaireId) return;

        const response = await putAutoSaveAssessment({
          locationNumber,
          questionnaireId: lvrStore.questionnaireId,
          form: lvrStore.form,
          saveData: lvrStore,
        });

        if (response && showNotification) {
          showAutoSaveSuccessNotification({
            message: 'Save successful.',
            showTitle: false,
          });
        }
      } catch (error) {
        console.log(error);
        showFailureNotification({
          message: 'Autosave failed.',
          showTitle: false,
        });
      }
    },
    [lvrStore, locationNumber],
  );

  const putAutoSaveDataDebounced = useMemo(
    () => debounce(putAutoSaveData, 300),
    [putAutoSaveData],
  );

  const validateForm = useCallback(
    (formData) => {
      let validated = true;

      const generalInformationValid =
        getGeneralInformationValidationStatus(lvrStore);

      const categoriesWithErrors = getCategoriesWithErrors({
        categories: questionnaire?.categories,
        responseStatuses: formData?.responseValidationStatuses,
      });

      if (!generalInformationValid || categoriesWithErrors?.length) {
        showFailureNotification({
          message: (
            <div data-testid="licensee-visit-report-form-failureNotificationMessageContainer">
              Required questions in the following categories need to be
              completed:
              <List
                data-testid="licensee-visit-report-form-failureNotificationMessageList"
                variant="ul"
              >
                {!generalInformationValid && (
                  <ListItem data-testid="licensee-visit-report-form-failureNotificationMessageGeneralInformationValidListItem">
                    General Information
                  </ListItem>
                )}
                {categoriesWithErrors.map((item) => (
                  <ListItem
                    data-testid="licensee-visit-report-form-failureNotificationMessageCategoriesWithErrorsListItem"
                    key={item}
                  >
                    {item}
                  </ListItem>
                ))}
              </List>
            </div>
          ),
          persistNotification: false,
        });

        validated = false;
      }

      lvrDispatch({
        type: actions.updateSubmissionFailed,
        data: true,
      });
      return validated;
    },
    [lvrDispatch, lvrStore, questionnaire],
  );

  const postAssessment = useCallback(async () => {
    // check connectivity and server status
    const isServerUp = await checkServerUpStatus();
    if (!isServerUp || isSubmitting) return;

    const path = `${process.env.REACT_APP_SERVER_URL}/assessment/`;
    try {
      setIsSubmitting(true);

      const lvrStoreClone = cloneDeep(lvrStore);

      if (!validateForm(lvrStoreClone)) return;

      // get all non-required response unique identifiers
      const nonRequiredUniqueIds = lvrStore?.questionnaireQuestions?.reduce(
        (acc, question) => {
          if (!question.questionAttributes?.includes('REQUIRED')) {
            acc.push({
              responseUniqueIdentifier: question.responseUniqueIdentifier,
            });
          }
          return acc;
        },
        [],
      );

      // Remove all non-required responses that are empty
      nonRequiredUniqueIds.forEach(({ responseUniqueIdentifier }) => {
        const responseData =
          lvrStoreClone.responses?.[responseUniqueIdentifier]?.responseData;
        const hasResponse =
          responseData?.choicesMade.length ||
          responseData?.attachmentS3Urls.length ||
          responseData?.response?.trim();

        if (!hasResponse) {
          lvrStoreClone.responses[responseUniqueIdentifier] = null;
        }
      });

      // remove all empty responses from the store
      const cleanResponses = Object.entries(lvrStoreClone.responses)
        .map(([_, response]) => response)
        .filter(Boolean);

      lvrStoreClone.responses = cleanResponses;

      delete lvrStoreClone.questionnaireQuestions;
      delete lvrStoreClone.responseValidationStatuses;

      const response = await API_PUT(path, lvrStoreClone);

      if (response.status === 200) {
        setShowSubmitModal(true);
        // cancel auto-save interval
        clearInterval(autoSaveIntervalRef.current);
        // auto-save updated store data to server as a backup in case
        // a form submission fails and requires re-submission
        await putAutoSaveData();
        // remove all auto-save data from server; this is a "soft" delete
        // on the DB, so the data can be recovered if needed
        deleteAutoSaveAssessment({ locationNumber });
      }
    } catch (err) {
      showFailureNotification({
        message: 'Submission failed. Please try again later.',
        persistNotification: false,
      });
      console.log('err', err);
    } finally {
      setIsSubmitting(false);
    }
  }, [isSubmitting, locationNumber, lvrStore, putAutoSaveData, validateForm]);

  const getQuestionnaire = useCallback(async () => {
    // check connectivity and server status
    const isServerUp = await checkServerUpStatus();
    if (!isServerUp) return;

    // return if we don't have the required supplementaryData for the request
    if (
      !lvrStore?.supplementaryData?.dayPart ||
      !lvrStore?.supplementaryData?.orderExperience
    ) {
      return;
    }

    const path = `${process.env.REACT_APP_SERVER_URL}/assessment/lvr/questionnaire?dayPart=${lvrStore?.supplementaryData?.dayPart}&orderChannel=${lvrStore?.supplementaryData?.orderExperience}`;
    try {
      const { data } = await API_GET(path);

      const transformedData = data?.categories.map((category) => ({
        ...category,
        questions: getFlattenedQuestions(category.questions),
      }));

      return {
        ...data,
        categories: transformedData,
      };
    } catch (err) {
      showServerUpStatusNotification({
        message:
          'Server is temporarily unable to service your request. Please try again later.',
      });
      console.log('err', err);
    }
  }, [
    lvrStore?.supplementaryData?.dayPart,
    lvrStore?.supplementaryData?.orderExperience,
  ]);

  const autoSaveId = useMemo(
    () => parseUrlQueryString(routerLocation?.search, 'autoSaveId'),
    [routerLocation?.search],
  );

  // Redirect to landing page if user does not have access to the form
  useEffect(() => {
    if (locationNumber && !hasLvrFormAccess) {
      history.push(`/${locationNumber}/landing`);
    }
  }, [hasLvrFormAccess, history, locationNumber]);

  // Delete auto-saved data from the server and replace history with the auto-saved questionnaire id
  useEffect(() => {
    if (
      !locationNumber ||
      lvrStore?.questionnaireId === null ||
      autoSaveId === lvrStore?.questionnaireId
    )
      return;

    const clearAutoSaveAndReplaceHistory = async () => {
      setFormLoaded(true);
      // remove all auto-save data from server
      await deleteAutoSaveAssessment({ locationNumber });

      // auto-save updated store data to server
      await putAutoSaveData();

      // replace history with auto-saved questionnaire id
      history.replace(
        `/${locationNumber}/licensee-visit-report-form?autoSaveId=${lvrStore.questionnaireId}`,
      );
    };
    clearAutoSaveAndReplaceHistory();
  }, [history, lvrStore?.questionnaireId, autoSaveId, locationNumber]);

  // Auto-save every 2 seconds
  useEffect(() => {
    if (!locationNumber || !questionnaire?.id) return;

    autoSaveIntervalRef.current = setInterval(putAutoSaveData, 2000);

    return () => clearInterval(autoSaveIntervalRef.current);
  }, [locationNumber, putAutoSaveData, questionnaire?.id]);

  // Get auto-saved data from the server and set it in the store on page load
  useEffect(() => {
    // return if we don't have the required supplementaryData for the request
    // or if the form is already loaded
    if (!locationNumber || formLoaded) return;

    // reset store if user started a new assessment
    if (!routerLocation?.search) {
      lvrDispatch({ type: actions.resetStore });
      return;
    }

    const getAutoSavedData = async () => {
      const { data } = await getAutoSaveAssessmentById({
        locationNumber,
        questionnaireId: autoSaveId,
      });

      if (data?.length) {
        const savedData = data.find(
          (item) => item.questionnaireId.toString() === autoSaveId,
        )?.saveData;

        const hasValidSaveData =
          savedData?.supplementaryData &&
          savedData?.uiFormStatus &&
          savedData?.responses &&
          savedData?.responseValidationStatuses;

        if (!hasValidSaveData) {
          showFailureNotification({
            message: 'Autosave failed to load.',
            showTitle: false,
          });
          return;
        }

        lvrDispatch({
          type: actions.updateSupplementaryData,
          data: savedData?.supplementaryData,
        });

        lvrDispatch({
          type: actions.dangerouslyUpdateStore,
          data: {
            questionnaireId: autoSaveId,
            uiFormStatus: savedData?.uiFormStatus,
            responses: savedData?.responses,
            responseValidationStatuses: savedData?.responseValidationStatuses,
          },
        });

        // only show notification if the form is not loaded
        if (!formLoaded) {
          showAutoSaveSuccessNotification({
            message: 'Autosave data loaded.',
          });
        }

        setFormLoaded(true);
      }
    };

    getAutoSavedData();
  }, [
    autoSaveId,
    locationNumber,
    lvrDispatch,
    formLoaded,
    routerLocation?.search,
  ]);

  // Get questionnaire data from the server and set it in the store
  useEffect(() => {
    const setQuestionnaireData = async () => {
      // get the questionnaire data from the server
      const transformedData = await getQuestionnaire();
      if (!transformedData) return;

      const allQuestions = transformedData.categories.flatMap(
        (data) => data.questions,
      );

      lvrDispatch({
        type: actions.dangerouslyUpdateStore,
        data: {
          form: transformedData.form,
          program: transformedData.program,
          provider: transformedData.provider,
          questionnaireId: transformedData.id.toString(),
          questionnaireQuestions: allQuestions,
        },
      });

      setQuestionnaire(transformedData);
    };
    setQuestionnaireData();
  }, [getQuestionnaire, lvrDispatch]);

  // Sync store with questionnaire when loaded or changed
  useEffect(() => {
    // return if quesitonnaire is not loaded
    if (!questionnaire) return;
    syncResponseValidationStatus({ store: lvrStore });
  }, [lvrStore, questionnaire]);

  // Update location number in store
  useEffect(() => {
    lvrDispatch({
      type: actions.updateLocationNumber,
      data: locationNumber,
    });
  }, [lvrDispatch, locationNumber]);

  // Cancel debounced auto-save on unmount
  useEffect(() => {
    return () => putAutoSaveDataDebounced.cancel();
  }, [putAutoSaveDataDebounced]);

  return (
    <>
      <NavHeader
        data-testid="licensee-visit-report-form-nav-header"
        buildLocationChangeRedirectUrl={(locNumber) =>
          `/${locNumber}/assessment`
        }
      />
      <Container data-testid="licensee-visit-report-form-container">
        <div
          data-testid="licensee-visit-report-form-app-assessment-licensee-visit-report-form-title-container"
          style={{ marginTop: '3em' }}
        >
          <Typography
            data-testid="licensee-visit-report-form-app-assessment-licensee-visit-report-form-title"
            variant="h2"
          >
            {I18n.t('APP_ASSESSMENT_LICENSEE_VISIT_REPORT_FORM_TITLE')} For
            Location Number {locationNumber}
          </Typography>
        </div>
        <LicenseeVisitReportFormComponent
          data-testid="licensee-visit-report-form-licensee-visit-report-form-component"
          questionnaire={questionnaire}
        />
        {questionnaire && (
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
            className="lvr-form__button-group"
            data-testid="licensee-visit-report-form-lvr-form-button-group"
          >
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => putAutoSaveDataDebounced(true)}
              className="lvr-form__button-group__button"
              data-testid="licensee-visit-report-form-lvr-form-button-group-button-save-report"
            >
              Save Report
            </Button>
            <Button
              color="secondary"
              onClick={postAssessment}
              className="lvr-form__button-group__button"
              data-testid="licensee-visit-report-form-lvr-form-button-group-button-submit"
            >
              Submit
            </Button>
          </div>
        )}
        <Modal
          data-testid="licensee-visit-report-form-modal"
          show={showSubmitModal}
          size="lg"
          onClose={returnToLandingPage}
          disableOverlayClick
        >
          <ModalHeader>
            <Icon
              data-testid="licensee-visit-report-form-modal-header"
              size="xl"
              icon={SuccessFilled}
              style={{ color: '#249E6B' }}
            />
          </ModalHeader>
          <ModalBody data-testid="licensee-visit-report-form-modal-body">
            <Typography
              data-testid="licensee-visit-report-form-licensee-visit-report-form-submitted-successfully"
              fontWeight="bold"
              variant="h2"
              gutterBottom
              color="default" // TODO need to revert the overwrite for the default color of the cfa design system in APP.scss, and then update app where black is needed instead of default
              style={{ color: '#5B6770' }}
            >
              Licensee Visit Report Form Submitted Successfully
            </Typography>
            {locationNumber && location?.locationName && (
              <Typography
                data-testid="licensee-visit-report-form-location-number-name"
                style={{ color: '#5B6770' }}
              >
                {locationNumber} : {location?.locationName}
              </Typography>
            )}
          </ModalBody>
          <ModalFooter data-testid="licensee-visit-report-form-modal-footer">
            <Button
              data-testid="licensee-visit-report-form-button-return-to-landing-page"
              color="secondary"
              onClick={returnToLandingPage}
            >
              Return to Landing Page
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};

export default LicenseeVisitReportForm;
