import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'cfa-react-components';
import 'react-notifications-component/dist/theme.css';
import './index.scss';
import App from './App';

// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <App />,
//   document.getElementById('root')
// );

const rootNode = document.getElementById('root');
ReactDOM.render(<App />, rootNode);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
