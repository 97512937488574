import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { LoadingIndicator } from 'cfa-react-components/dist/cjs';
import { routeToNewApp } from '../utils/redirectUtil';

// Component to support email notifications deep-linking links from Q1-2021.
// The email notification link changed in Q2-2020 to follow the standard expected by
// the main routes (`/${locationNumber}/report-dashboard/${round}`)
const EmailDeepLinkRedirect = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    searchParams.locationNumber
      ? history.replace(`/${searchParams.locationNumber}/report-dashboard`)
      : routeToNewApp();
  }, [location, history]);

  return (
    <LoadingIndicator
      data-testid="email-deep-link-redirect-loading-indicator"
      variant="page"
    />
  );
};

export default EmailDeepLinkRedirect;
