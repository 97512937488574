import { Typography } from 'cfa-react-components';
import { Col } from 'reactstrap';
import I18n from '../../../../../i18n/utils';
import FindingDetailsContainer from '../../../../components/SelectedFinding/FindingDetails/FindingDetailsContainer';
import { FoodSafetyLabel } from '../../../../components/common/FoodSafetyLabel/FoodSafetyLabel';
import AdditionalSupportCard from '../../../../components/SelectedFinding/AdditionalSupport/AdditionalSupportCard';
import { removeDashes } from '../../../../utils/FindingsUtil';
import './SelectedFinding.scss';

/**
 * The SelectedFinding page displays finding details and additional support
 * associated with one Finding that has been selected from the AllFindings page.
 */
const SelectedFinding = ({ finding }) => {
  return (
    <Col
      className="selected-finding container "
      data-testid="selected-findings-container-col"
    >
      {finding && (
        <>
          <div data-testid="selected-findings-container">
            <div
              className="finding-details-card__top-detail-container"
              data-testid="selected-findings-finding-details-card-top-detail-container"
            >
              <Typography
                className="force-cfa-gray"
                data-testid="selected-findings-force-cfa-gray-selected-findings-card-title"
                color="default"
                gutterBottom
                variant="h4"
              >
                {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_CARD_TITLE')}
              </Typography>
              <Typography
                data-testid="selected-findings-finding-question-safe-report-id-description"
                color="default"
                gutterBottom={false}
                variant="h2"
              >
                {`
                ${finding.questionSafeReportId}: 
                ${finding.description}
                `}
              </Typography>
              {finding.isRepeat && (
                <FoodSafetyLabel
                  data-testid="selected-findings-food-safety-label-finding-card-repeat"
                  title={`${I18n.t(
                    'APP_REPORT_DASHBOARD_FINDING_CARD_REPEAT_LABEL',
                  )} ${removeDashes(finding.repeatRounds)}`}
                  type="repeat"
                />
              )}
            </div>
          </div>
          <FindingDetailsContainer
            data-testid="selected-findings-finding-details-container"
            finding={finding}
          />
          <AdditionalSupportCard
            data-testid="selected-findings-additional-support-card"
            auditUUID={finding.auditUUID}
          />
        </>
      )}
    </Col>
  );
};

export default SelectedFinding;
