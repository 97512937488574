import { Icon, Typography } from 'cfa-react-components';
import { ErrorFilled } from '@cfa-icons/system';

const ErrorText = ({ error, gutterBottom, ...props }) =>
  error ? (
    <div {...props} style={{ display: 'flex' }}>
      <Icon icon={ErrorFilled} style={{ color: '#b6072f' }} />
      <Typography
        gutterBottom={gutterBottom}
        variant="caption1"
        style={{ paddingLeft: '0.5em', color: '#b6072f', fontWeight: 500 }}
      >
        {error}
      </Typography>
    </div>
  ) : null;

export default ErrorText;
