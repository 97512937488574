import isEmpty from 'lodash/isEmpty';

// Creates a lookup object for category names based on responseUniqueIdentifier
const createCategoryLookup = (categories) => {
  const extractLookupMap = (categoryName, questions, lookupMap) =>
    questions.reduce((acc, question) => {
      // add responseUniqueIdentifier / categoryName to lookup map if question is required
      if (question?.questionAttributes?.includes('REQUIRED')) {
        acc[question.responseUniqueIdentifier] = categoryName;
      }
      // recurse if question has subQuestions
      if (question?.questionData?.subQuestions) {
        extractLookupMap(categoryName, question.questionData.subQuestions, acc);
      }
      return acc;
    }, lookupMap);

  return categories.reduce((acc, { name, questions }) => {
    extractLookupMap(name, questions, acc);
    return acc;
  }, {});
};

// Gets an array of category names that have errors
export const getCategoriesWithErrors = ({ categories, responseStatuses }) => {
  const categoryLookup = createCategoryLookup(categories);

  if (!responseStatuses || isEmpty(responseStatuses)) return [];

  const categoriesWithErrors = Object.entries(responseStatuses)
    // filter out questions that have not been answered
    .filter(([, value]) => value === false)
    // get the category name for each question ID
    .map(([key]) => categoryLookup[key])
    // remove duplicates
    .filter((value, index, self) => self.indexOf(value) === index);

  return categoriesWithErrors;
};

// Returns a boolean indicating whether the General Information section is valid
export const getGeneralInformationValidationStatus = (store) => {
  const {
    round,
    inspectionTimestamp,
    supplementaryData: { dayPart, orderExperience, visitType },
  } = store;

  const valid =
    visitType && round && inspectionTimestamp && orderExperience && dayPart;

  return valid;
};
