// This File is for Util functions used in AllFindings.js and SelectedFindings.js

import {
  IMMEDIATE_RISK_SHORT,
  IMMEDIATE_RISK_LONG,
  HIGH_RISK_SHORT,
  HIGH_RISK_LONG,
  MEDIUM_RISK_SHORT,
  MEDIUM_RISK_LONG,
  LOW_RISK_SHORT,
  LOW_RISK_LONG,
  INFORMATIONAL_RISK_SHORT,
  INFORMATIONAL_RISK_LONG,
} from '../constants/constants';

export function lowercaseWithDashes(str) {
  return str
    .replace(/[^a-zA-Z ]/g, '')
    .toLowerCase()
    .replace(/\s+/g, '-');
}

export function uppercaseWithUnderscores(str) {
  return str
    .replace(/[^a-zA-Z ]/g, '')
    .toUpperCase()
    .replace(/\s+/g, '_');
}

export function removeDashes(str) {
  return str.replace(/-/g, ' ');
}

export function renderFindingColor(type) {
  return {
    [IMMEDIATE_RISK_LONG]: IMMEDIATE_RISK_SHORT,
    [HIGH_RISK_LONG]: HIGH_RISK_SHORT,
    [MEDIUM_RISK_LONG]: MEDIUM_RISK_SHORT,
    [LOW_RISK_LONG]: LOW_RISK_SHORT,
    [INFORMATIONAL_RISK_LONG]: INFORMATIONAL_RISK_SHORT,
  }[type];
}

export function renderParamRisk(type) {
  return {
    [IMMEDIATE_RISK_SHORT]: IMMEDIATE_RISK_LONG,
    [HIGH_RISK_SHORT]: HIGH_RISK_LONG,
    [MEDIUM_RISK_SHORT]: MEDIUM_RISK_LONG,
    [LOW_RISK_SHORT]: LOW_RISK_LONG,
    [INFORMATIONAL_RISK_SHORT]: INFORMATIONAL_RISK_LONG,
  }[type];
}
