import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { Table } from 'reactstrap';
import {
  Card,
  CardContent,
  Col,
  Row,
  LoadingIndicator,
  Typography,
} from 'cfa-react-components';
import I18n from '../../../../i18n/utils';
import { API_GET } from '../../../utils';
import { getAuditSummary } from '../../../actions';
import AuditSelector from '../../../components/AuditSelector/AuditSelector';
import AuditNotAvailable from '../AuditNotAvailable/AuditNotAvailable';
import AuditSummaryCard from './AuditSummaryCard/AuditSummaryCard';
import RequestedAppealsWidget from './RequestedAppealsWidget/RequestedAppealsWidget';
import HistoricalTrendWidget from './HistoricalTrendWidget/HistoricalTrendWidget';
import NeedHelpCard from './NeedHelpWidget/NeedHelpCard';

import './FoodSafetyReport.scss';

const FoodSafetyReport = () => {
  const {
    auditSummary,
    auditSummaryLoading,
    locationAuditsLoading,
    selectedAudit,
  } = useSelector((state) => state.audit);

  const { locationLoading, selectedLocation } = useSelector(
    (state) => state.location,
  );

  const dispatch = useDispatch();

  const { locationNumber, round } = useParams();
  const history = useHistory();
  const [requestedAppeals, setRequestedAppeals] = useState([]);
  const [loadingAppeals, setLoadingAppeals] = useState(false);

  const getRequestedAppeals = useCallback(async () => {
    setLoadingAppeals(true);
    const url = `${process.env.REACT_APP_SERVER_URL}/amendments?page=1&pageSize=100&rounds=${round}&locations=${locationNumber}`;
    try {
      const result = await API_GET(url);
      setRequestedAppeals(result.data.content);
      setLoadingAppeals(false);
    } catch (err) {
      console.error('Failed to retrieve requested appeals.', err);
      setLoadingAppeals(false);
    }
  }, [locationNumber, round]);

  useEffect(() => {
    if (!locationNumber || !round || auditSummaryLoading) {
      return;
    }

    if (
      auditSummary?.audit.locationNumber !== locationNumber ||
      auditSummary?.audit.round !== round
    ) {
      dispatch(getAuditSummary(locationNumber, round));
    }

    if (locationNumber && round) {
      getRequestedAppeals();
    }
  }, [
    dispatch,
    auditSummary,
    auditSummaryLoading,
    locationNumber,
    round,
    getRequestedAppeals,
  ]);

  const isLoading =
    auditSummaryLoading ||
    locationLoading ||
    locationAuditsLoading ||
    loadingAppeals;

  const goToFilteredFindings = (event) => {
    const riskLevel = event.currentTarget.getAttribute('value');
    history.push(
      `/${selectedLocation.locationNumber}/report-dashboard/${auditSummary.audit.round}/findings?riskLevel=${riskLevel}`,
    );
  };

  return (
    <Col
      className="ReportDashboard container"
      data-testid="food-safety-report-container"
    >
      {isLoading ? (
        <LoadingIndicator
          data-testid="food-safety-report-loading-indicator"
          variant="page"
        />
      ) : selectedLocation == null ? (
        <h5
          className="no-location-message"
          data-testid="food-safety-report-no-location-message"
        >
          {I18n.t('APP_REPORT_DASHBOARD_SELECT_LOCATION')}
        </h5>
      ) : (
        <>
          <Row id="header-row" data-testid="food-safety-report-header-row">
            <Col
              md="6"
              xs="12"
              data-testid="food-safety-report-header-row-column-1"
            >
              <Typography
                data-testid="food-safety-report-app-report-dashboard-title"
                variant="h3"
                align="left"
              >
                {I18n.t('APP_REPORT_DASHBOARD_TITLE')}
              </Typography>
            </Col>

            <Col
              lg={{ span: 2, offset: 4 }}
              md={{ span: 3, offset: 3 }}
              xs="6"
              data-testid="food-safety-report-header-row-column-2"
            >
              <AuditSelector
                data-testid="food-safety-report-audit-selector"
                loading={isLoading}
                buildRoundBasedUrl={(auditRound) =>
                  `/${selectedLocation.locationNumber}/report-dashboard/${auditRound}`
                }
              />
            </Col>
          </Row>

          {
            //
            // Food Safety Report UI display
            //
            selectedAudit && auditSummary ? (
              <>
                <Row
                  className="dashboard-row"
                  data-testid="food-safety-report-dashboard-row"
                >
                  <Col
                    xl="4"
                    lg="5"
                    md="6"
                    sm="12"
                    className="card-wrapper"
                    data-testid="food-safety-report-dashboard-row-column-1"
                  >
                    <AuditSummaryCard data-testid="food-safety-report-audit-summary-card" />
                  </Col>

                  <Col
                    xl="8"
                    lg="7"
                    md="6"
                    sm="12"
                    className="card-wrapper"
                    data-testid="food-safety-report-dashboard-row-column-2"
                  >
                    <HistoricalTrendWidget
                      data-testid="food-safety-report-historical-trend-widget"
                      performaceHistory={auditSummary}
                    />
                  </Col>
                </Row>
                {/* 
                ///
                ///  Restore Finding summary table 2023/03/30 jjir
                ///
                */}
                <Row
                  className="dashboard-row"
                  data-testid="food-safety-report-dashboard-row-2"
                >
                  {requestedAppeals?.length && (
                    <Col
                      xl="6"
                      lg="6"
                      md="6"
                      sm="12"
                      className="card-wrapper"
                      data-testid="food-safety-report-dashboard-row-card-wrapper"
                    >
                      <RequestedAppealsWidget
                        data-testid="food-safety-report-requested-appeals-widget"
                        appeals={requestedAppeals}
                      />
                    </Col>
                  )}
                  <Col
                    lg="6"
                    sm="12"
                    className="card-wrapper"
                    data-testid="food-safety-report-dashboard-row-card-wrapper-2"
                  >
                    <Card
                      className="dashboard-card findings-summary-card"
                      data-testid="food-safety-report-dashboard-card-findings-summary-card"
                    >
                      <CardContent data-testid="food-safety-report-dashboard-card-findings-summary-card-content">
                        <Typography
                          data-testid="food-safety-report-app-report-dashboard-findings-summary"
                          variant="h3"
                          align="left"
                        >
                          {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_SUMMARY')}
                        </Typography>
                        <Table
                          className="findings-summary-item-col"
                          data-testid="food-safety-report-findings-summary-item-col"
                          style={{ height: '100%' }}
                        >
                          <thead data-testid="food-safety-report-findings-summary-item-col-table-head">
                            <tr
                              className="findings-type-row"
                              data-testid="food-safety-report-findings-type-row"
                            >
                              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                              <th />
                              <th
                                className="findings-type"
                                data-testid="food-safety-report-findings-type-new"
                              >
                                {I18n.t('APP_REPORT_DASHBOARD_NEW')}
                              </th>
                              <th
                                className="findings-type"
                                data-testid="food-safety-report-findings-type-repeat"
                              >
                                {I18n.t('APP_REPORT_DASHBOARD_REPEAT')}
                              </th>
                              <th
                                className="findings-type"
                                data-testid="food-safety-report-findings-type-total"
                              >
                                {I18n.t('APP_REPORT_DASHBOARD_TOTAL')}
                              </th>
                            </tr>
                          </thead>
                          <tbody data-testid="food-safety-report-findings-summary-item-col-tbody">
                            {[
                              'Immediate Action',
                              'High',
                              'Medium',
                              'Low',
                              'Informational',
                            ].map((riskLevel) => {
                              const riskLevelShortName = riskLevel
                                .split(' ')[0]
                                .toLowerCase();

                              return (
                                <tr
                                  data-testid="food-safety-report-findings-summary-item-col-trow"
                                  key={riskLevelShortName}
                                >
                                  <th
                                    scope="row"
                                    className={`${riskLevelShortName} row-label`}
                                    data-testid="food-safety-report-row-label"
                                  >
                                    {I18n.t(
                                      `APP_REPORT_DASHBOARD_${riskLevelShortName.toUpperCase()}`,
                                    )}
                                  </th>
                                  <td
                                    data-testid="food-safety-report-go-to-filtered-findings-new"
                                    onClick={goToFilteredFindings}
                                    value={riskLevelShortName}
                                  >
                                    {auditSummary.findingsSummary[riskLevel]
                                      .newCount
                                      ? auditSummary.findingsSummary[riskLevel]
                                          .newCount
                                      : 0}
                                  </td>
                                  <td
                                    data-testid="food-safety-report-go-to-filtered-findings-repeat"
                                    onClick={goToFilteredFindings}
                                    value={riskLevelShortName}
                                  >
                                    {auditSummary.findingsSummary[riskLevel]
                                      .repeatCount
                                      ? auditSummary.findingsSummary[riskLevel]
                                          .repeatCount
                                      : 0}
                                  </td>
                                  <td
                                    className={`${riskLevelShortName} bold`}
                                    data-testid="food-safety-report-go-to-filtered-findings-total"
                                    onClick={goToFilteredFindings}
                                    value={riskLevelShortName}
                                  >
                                    {auditSummary.findingsSummary[riskLevel]
                                      .nonResolvedTotalCount
                                      ? auditSummary.findingsSummary[riskLevel]
                                          .nonResolvedTotalCount
                                      : 0}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardContent>
                    </Card>
                  </Col>
                  <Col>
                    <NeedHelpCard />
                  </Col>
                </Row>
              </>
            ) : (
              !auditSummary && (
                <AuditNotAvailable
                  data-testid="food-safety-report-audit-not-available"
                  type="report"
                />
              )
            )
          }
        </>
      )}
    </Col>
  );
};

export default withOktaAuth(withRouter(FoodSafetyReport));
