export const GET_STORE_LOCATIONS = 'GET_STORE_LOCATIONS';
export const GET_STORE_LOCATIONS_SUCCESS = 'GET_STORE_LOCATIONS_SUCCESS';
export const GET_STORE_LOCATIONS_FAILURE = 'GET_STORE_LOCATIONS_FAILURE';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_ASSIGNED_LOCATIONS = 'SET_ASSIGNED_LOCATIONS';
export const SET_LOCATION_COUNTRY_CODE = 'SET_LOCATION_COUNTRY_CODE';
export const SET_IS_LICENSEE_LOCATION = 'SET_IS_LICENSEE_LOCATION';
export const GET_LOCATION_FEATURE_PERMISSIONS =
  'GET_LOCATION_FEATURE_PERMISSIONS';
export const GET_LOCATION_FEATURE_PERMISSIONS_SUCCESS =
  'GET_LOCATION_FEATURE_PERMISSIONS_SUCCESS';
export const GET_LOCATION_FEATURE_PERMISSIONS_FAIL =
  'GET_LOCATION_FEATURE_PERMISSIONS_FAIL';
export const SET_SMARTSHOP_LOCATIONS = 'SET_SMARTSHOP_LOCATIONS';
