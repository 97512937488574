export const parseUrlQueryString = (queryString, parameterName) => {
  // Remove the starting "?" if it exists
  const cleanString = queryString.startsWith('?')
    ? queryString.slice(1)
    : queryString;

  // Split the query string into its components
  const params = new URLSearchParams(cleanString);
  return params.get(parameterName);
};
