import { sortByInspection } from '../../components/Assessment/PdfDownload/utils/dataUtils';
import { lowercaseWithDashes } from '../../utils/FindingsUtil';

const compareByLabel = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const addPropertiesToFinding = (findings = []) => {
  const allFindingIds = [];

  findings.forEach((finding) => {
    const questionId = finding.questionInternalId.split('~|~')[0] || '';
    finding.questionId = questionId;
    finding.orderChannel = finding.supplementaryData.orderChannel;
    finding.categoryBadge = `${lowercaseWithDashes(
      finding.questionSupplementaryData.category,
    )}-smart-shop`;
    finding.complianceBadge = `${finding.complianceStatus.toLowerCase()}-smart-shop`;
    allFindingIds.push({ label: questionId, value: questionId });
  });

  return allFindingIds.sort(compareByLabel);
};

export const prepLbrFiles = (data = [], assessments = []) => {
  const noResponseIds = data.filter((file) => !file.responseId);
  const filesToDisplay = noResponseIds.map((file) => {
    const currentAssessment = assessments.find(
      (assessment) => assessment.assessmentId === file.assessmentId,
    );
    const { date, inspectionTimestamp } = currentAssessment;
    // include inspection time stamp for sort in decending order.
    // label for cfa for dropdown display.
    return {
      ...file,
      inspectionTimestamp,
      label: `${date} - ${file.assessmentId}`,
    };
  });

  return filesToDisplay.sort(sortByInspection);
};
