import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import './FindingOccurrence.scss';

const OccurrenceTitleAndDescription = ({ title, description, ...props }) => (
  <div {...props}>
    <p className="finding-occurrence__text-lable">{title}</p>
    <p
      className="finding-occurrence__text-contenido"
      style={{ color: 'black', fontSize: 16 }}
    >
      {description}
    </p>
  </div>
);

const FindingOccurrence = ({
  issueDescription,
  zoneDescription,
  itemDescription,
  checked,
  index,
  onChange,
  occurrenceDataObj,
}) => (
  <div
    className={`finding-occurrence ${checked && 'finding-occurrence__active'}`}
    data-testid="finding-occurrence-container"
    onClick={onChange(occurrenceDataObj, {
      index,
      isChecked: checked,
    })}
  >
    <div
      className={`finding-occurrence__inner-container ${
        checked && 'finding-occurrence__inner-container__active'
      }`}
      data-testid="finding-occurrence-inner-container"
    >
      <div
        className="finding-occurrence__checkbox-container"
        data-testid="finding-occurrence-checkbox-container"
      >
        <Input
          className="finding-occurrence__checkbox"
          data-testid="finding-occurrence-checkbox-input"
          type="checkbox"
          checked={checked}
          value={occurrenceDataObj}
          onChange={onChange(occurrenceDataObj, {
            index,
            isChecked: checked,
          })}
        />
      </div>
      <OccurrenceTitleAndDescription
        data-testid="finding-occurrence-issue-occurrence-title-and-description"
        title="Issue"
        description={issueDescription}
      />
      <OccurrenceTitleAndDescription
        data-testid="finding-occurrence-zone-occurrence-title-and-description"
        title="Zone"
        description={zoneDescription}
      />
      <OccurrenceTitleAndDescription
        data-testid="finding-occurrence-item-occurrence-title-and-description"
        title="Item"
        description={itemDescription}
      />
    </div>
  </div>
);

FindingOccurrence.propTypes = {
  issueDescription: PropTypes.string,
  zoneDescription: PropTypes.string,
  itemDescription: PropTypes.string,
  checked: PropTypes.bool,
  index: PropTypes.number,
  onChange: PropTypes.func,
  occurrenceDataObj: PropTypes.object.isRequired,
};

export default FindingOccurrence;
