import {
  SEND_SELF_ASSESSMENT,
  SEND_SELF_ASSESSMENT_SUCCESS,
  SEND_SELF_ASSESSMENT_FAILURE,
  //
  GET_ALL_QUESTIONS,
  GET_ALL_QUESTIONS_SUCCESS,
  GET_ALL_QUESTIONS_FAILURE,
  //
  SET_SELECTED_SAFEQUESTION,
  //
  GET_SELECTED_SAFEQUESTION_DETAILS,
  GET_SELECTED_SAFEQUESTION_DETAILS_SUCCESS,
  GET_SELECTED_SAFEQUESTION_DETAILS_FAILURE,
  //
  UPDATE_SELECTED_SAFEQUESTION_PUT,
  UPDATE_SELECTED_SAFEQUESTION_SUCCESS,
  UPDATE_SELECTED_SAFEQUESTION_FAILURE,
  //
  GET_VENDOR_QUESTIONS,
  GET_VENDOR_QUESTIONS_SUCCESS,
  GET_VENDOR_QUESTIONS_FAILURE,
  //
  UPDATE_SELECTED_ROUND,
  //
  CLEAR_ADMIN_STORE,
} from '../actions';

const initialState = {
  loading: false,
  allQuestions: [],
  error: false,
  message: null,
  selectedQuestion: null,
  selectedQuestionDetails: null,
  vendorQuestions: {},
  selectedRound: '',
};

const doActions = (state = initialState, action) => {
  switch (action.type) {
    case SEND_SELF_ASSESSMENT:
      return {
        ...state,
        loading: true,
        error: false,
        message: null,
      };

    case SEND_SELF_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload,
      };

    case SEND_SELF_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: null,
      };

    case GET_ALL_QUESTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ALL_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allQuestions: action.payload,
        selectedQuestionDetails: null,
        error: null,
      };

    case GET_ALL_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        allQuestions: [],
        error: action.payload,
      };

    case SET_SELECTED_SAFEQUESTION:
      return {
        ...state,
        loading: false,
        selectedQuestion: action.payload,
      };

    case GET_SELECTED_SAFEQUESTION_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SELECTED_SAFEQUESTION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedQuestionDetails: action.payload,
        error: null,
      };

    case GET_SELECTED_SAFEQUESTION_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedQuestionDetails: null,
        error: action.payload,
      };

    case UPDATE_SELECTED_SAFEQUESTION_PUT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SELECTED_SAFEQUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case UPDATE_SELECTED_SAFEQUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case GET_VENDOR_QUESTIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_VENDOR_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorQuestions: action.payload,
      };

    case GET_VENDOR_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        vendorQuestions: null,
      };

    case UPDATE_SELECTED_ROUND:
      return {
        ...state,
        selectedRound: action.payload,
      };

    case CLEAR_ADMIN_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default doActions;
