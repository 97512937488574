import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { Icon, Button, Typography } from 'cfa-react-components';
import { CircleCrossFilled, SuccessFilled } from '@cfa-icons/system';
import { IconMessageDots, IconSend } from '@tabler/icons-react';
import { API_PUT, showFullTopSuccessNotification } from '../../utils';
import I18n from '../../../i18n/utils';
import { formatDateWithFullYear } from '../../utils/DateUtil';
import { claimsOneRole } from '../../utils/AuthUtils';
import {
  getApprovedAmendmentCount,
  postAmendmentReviewRequest,
} from '../../actions/AmendmentActions';
import AmendmentCommentsContainer from './AmendmentCommentsContainer';
import AmendmentBadges from './AmendmentBadges';
import CFASmallSpinner from '../Spinner/CFASmallSpinner';
import AmendmentApproveModal from './AmendmentApproveModal';
import AmendmentDenyModal from './AmendmentDenyModal';
import ModifyAppealModal from './ModifyAppealModal';
import { AMENDMENT_CARD_TYPE } from '../../utils/AmendmentUtil';
import LbrDownloadButton from './LbrDownloadButton';

import './AmendmentCard.scss';

const FOOD_SAFETY_API_BASE_URL = process.env.REACT_APP_SERVER_URL;

const AmendmentCard = ({
  cardType,
  amendment,
  loadingAmendmentsCallback,
  statusChangeCallback,
}) => {
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();

  const [showCommentBox, setOpenCloseCommentBox] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [isSafeAdmin, setIsSafeAdmin] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);

  const handleShowHideCommentBox = () =>
    setOpenCloseCommentBox(!showCommentBox);

  useEffect(() => {
    setIsSafeAdmin(
      claimsOneRole(authState?.accessToken?.claims, ['SAFE_ADMIN']),
    );
  }, [authState]);

  // Called when a user Approves or Denies an amendment through its respective modal
  const updateAmendmentBtn = async (updateStatus, updateReason) => {
    // Set Loading variables to true
    setSpinner(true);
    loadingAmendmentsCallback(true);

    // Hide the modal based on the status
    updateStatus === 'APPROVED'
      ? handleShowHideApproveModal()
      : handleShowHideDenyModal();

    // Build the URL and payload object for updating the amendment
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/update`;
    const payload = {
      status: updateStatus,
      requestId: amendment.id,
      updateReason,
    };

    // Attempt to update the amendment
    try {
      await API_PUT(url, payload);
      showFullTopSuccessNotification({
        message: I18n.t(
          `APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_${updateStatus}`,
        ),
      });
    } catch (error) {
      console.error(' PUT amendment STATUS error :', error);
    }

    // If the amendment was approved, update the approvedAmendmentCount in redux for the dashboard's btn
    if (updateStatus === 'APPROVED') {
      dispatch(
        getApprovedAmendmentCount(amendment.locationNumber, amendment.round),
      );
    }

    // invoke the callback function to refresh the amendmentsList at the parent level
    statusChangeCallback();
  };

  const handleModifyAppeal = async (payloadObj) => {
    // Set Loading variables to true
    loadingAmendmentsCallback(true);

    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/update`;
    const payload = {
      status: 'APPROVED',
      type: 'MODIFY',
      requestId: amendment.id,
      updateReason: payloadObj.updateReason,
      newQuestionId: payloadObj.newSelectedQuestionId,
    };
    // Attempt to update the amendment
    try {
      await API_PUT(url, payload);
      showFullTopSuccessNotification({
        message: I18n.t(`APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_MODIFY`),
      });
    } catch (error) {
      console.error(' PUT amendment STATUS error :', error);
    }
    // invoke the callback function to refresh the amendmentsList at the parent level
    statusChangeCallback();
  };

  const requestReviewBtn = () => {
    dispatch(postAmendmentReviewRequest(amendment.id));
  };

  const disabledActionBtn =
    useSelector((state) => state.amendments.fetchingAction) || false;

  const handleShowHideDenyModal = () => {
    setShowDenyModal(!showDenyModal);
  };

  const handleShowHideApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };

  const handleShowModifyAppealModal = () => {
    setShowModifyModal(!showModifyModal);
  };

  const getFriendlyAttachmentNameFromURL = (url) => {
    if (url != null) {
      const path = new URL(url).pathname;
      const fileName = path.split('/').at(-1);
      /* Regex to replace + with spaces */
      return decodeURI(fileName.replace(/[+]/g, ' '));
    }
    return 'Filename not found';
  };

  return (
    <>
      <div
        className="amendments-card__container"
        data-testid="amendment-card-container"
      >
        <div
          className="amendments-card__tag-header"
          data-testid="amendment-card-tag-header"
        >
          <LbrDownloadButton
            data-testid="amendment-card-lbr-download-button"
            location={amendment.locationNumber}
            round={amendment.round}
          />
          <AmendmentBadges
            data-testid="amendment-card-amendment-badges"
            cardType={cardType}
            round={amendment.round}
            requestStatus={amendment.amendmentStatus}
            statusTimestamp={amendment.lastModifiedTimestamp}
            isAdminRequested={amendment.isAdminRequested}
            isBulkAmendment={amendment.isBulkAmendment}
            amendmentType={amendment.amendmentType}
          />
          {amendment.requesterDisplayName && (
            <div
              className="amendments-card__tag-container"
              data-testid="amendment-card-submitted-by-tag-container"
            >
              <div
                className="amendments-card__tag-title"
                data-testid="amendment-card-submitted-by-tag-title"
              >
                {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_SUBMITTED_BY')}:
              </div>
              <div
                className="amendments-card__tag-data"
                data-testid="amendment-card-submitted-by-tag-data"
              >
                {amendment.requesterDisplayName}
              </div>
            </div>
          )}
          <div
            className="amendments-card__tag-container"
            data-testid="amendment-card-created-tag-container"
          >
            <p
              className="amendments-card__tag-title"
              data-testid="amendment-card-created-tag-title"
            >
              {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_CREATED')}:{' '}
            </p>
            <p
              className="amendments-card__tag-data"
              data-testid="amendment-card-created-tag-data"
            >
              {formatDateWithFullYear(amendment.requestedTimestamp)}
            </p>
          </div>
          <div
            className="amendments-card__tag-container"
            data-testid="amendment-card-visit-date-tag-container"
          >
            <p
              className="amendments-card__tag-title"
              data-testid="amendment-card-visit-date-tag-title"
            >
              {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_VISIT_DATE')}:{' '}
            </p>
            <p
              className="amendments-card__tag-data"
              data-testid="amendment-card-visit-date-tag-data"
            >
              {formatDateWithFullYear(
                amendment.originalAuditLocationLocalizedTimestamp,
              )}
            </p>
          </div>
        </div>

        <div
          className=" amendments-card__header-container"
          data-testid="amendment-card-question-safe-report-id-header-container"
        >
          <h2
            className="amendments-card__finding-title"
            data-testid="amendment-card-question-safe-report-id-finding-title"
          >
            {' '}
            {amendment.questionSafeReportId}: {amendment.findingDescription}
          </h2>
        </div>
        <div
          className=" amendments-card__header-container"
          data-testid="amendment-card-store-container"
        >
          <p
            className="amendments-card__store-title"
            data-testid="amendment-card-store-title"
          >
            {' '}
            {amendment.locationNumber} {amendment.locationName}
          </p>
        </div>
        <div
          className="amendments-card__details-info"
          data-testid="amendment-card-zone-item-issue-details-info"
        >
          {amendment.zone && (
            <div
              className="amendments-card__details-info-container"
              data-testid="amendment-card-zone-details-info-container"
            >
              <div
                className="amendments-card__details-info-title"
                data-testid="amendment-card-zone-details-info-title"
              >
                {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_ZONE')}:
              </div>
              <div
                className="amendments-card__details-info-data"
                data-testid="amendment-card-zone-details-info-data"
              >
                {amendment.zone}
              </div>
            </div>
          )}
          {amendment.item && (
            <div
              className="amendments-card__details-info-container"
              data-testid="amendment-card-item-details-info-container"
            >
              <div
                className="amendments-card__details-info-title"
                data-testid="amendment-card-item-details-info-title"
              >
                {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_ITEM')}:
              </div>
              <div
                className="amendments-card__details-info-data"
                data-testid="amendment-card-item-details-info-data"
              >
                {amendment.item}
              </div>
            </div>
          )}
          {amendment.issue && (
            <div
              className="amendments-card__details-info-container"
              data-testid="amendment-card-issue-details-info-container"
            >
              <div
                className="amendments-card__details-info-title"
                data-testid="amendment-card-issue-details-info-title"
              >
                {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_ISSUE')}:
              </div>
              <div
                className="amendments-card__details-info-data"
                data-testid="amendment-card-issue-details-info-data"
              >
                {amendment.issue}
              </div>
            </div>
          )}
        </div>
        {amendment.inspectorComments && (
          <div
            className="amendments-card__details-info"
            data-testid="amendment-card-auditors-notes-details-info"
          >
            <div
              className="amendments-card__details-info-container"
              data-testid="amendment-card-inspector-comments-details-info-container"
            >
              <div
                className="amendments-card__details-info-title"
                data-testid="amendment-card-inspector-comments-details-info-title"
              >
                {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_AUDITORS_NOTES')}:
              </div>
              <div
                className="amendments-card__details-info-data"
                data-testid="amendment-card-inspector-comments-details-info-data"
              >
                {amendment.inspectorComments}
              </div>
            </div>
          </div>
        )}
        {amendment.attachmentUrls && (
          <div
            className="amendments-card__details-info"
            data-testid="amendment-card-supporting-documents-details-info"
          >
            <div
              className="amendments-card__details-info-container"
              data-testid="amendment-card-supporting-documents-details-info-container"
            >
              <div
                className="amendments-card__details-info-title"
                data-testid="amendment-card-supporting-documents-details-info-title"
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_AMENDMENT_CARD_SUPPORTING_DOCUMENTS',
                )}
                :
              </div>
              <div
                className="amendments-card__details-attachment-container"
                data-testid="amendment-card-supporting-documents-details-attachment-container"
              >
                {amendment.attachmentUrls.length !== 0 &&
                  amendment.attachmentUrls.map((attachment) => (
                    <a
                      key={attachment}
                      href={attachment}
                      className="amendments-card__details-attachment-link"
                      data-testid="amendment-card-supporting-documents-details-attachment-link"
                    >
                      {getFriendlyAttachmentNameFromURL(attachment)}
                    </a>
                  ))}
              </div>
            </div>
          </div>
        )}
        {amendment.reasonForRequest && (
          <div
            className="amendments-card__details-info"
            data-testid="amendment-card-reason-for-review-details-info"
          >
            <div
              className="amendments-card__details-info-container"
              data-testid="amendment-card-reason-for-review-details-info-container"
            >
              <div
                className="amendments-card__details-info-title"
                data-testid="amendment-card-reason-for-review-details-info-title"
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_AMENDMENT_CARD_REASON_FOR_REVIEW',
                )}
                :
              </div>
              <div
                className="amendments-card__details-info-data"
                data-testid="amendment-card-reason-for-review-details-info-data"
              >
                {amendment.reasonForRequest}
              </div>
            </div>
          </div>
        )}
        {cardType === AMENDMENT_CARD_TYPE.OPERATOR &&
          amendment.decisionComment && (
            <div
              className="amendments-card__details-info"
              data-testid="amendment-card-admin-comments-details-info"
            >
              <div
                className="amendments-card__details-info-container"
                data-testid="amendment-card-admin-comments-details-info-container"
              >
                <div
                  className="amendments-card__details-info-title"
                  data-testid="amendment-card-admin-comments-details-info-title"
                >
                  {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_ADMIN_COMMENTS')}
                  :
                </div>
                <div
                  className="amendments-card__details-info-data"
                  data-testid="amendment-card-admin-comments-details-info-data"
                >
                  {amendment.decisionComment}
                </div>
              </div>
            </div>
          )}
        {cardType !== AMENDMENT_CARD_TYPE.OPERATOR && (
          <>
            <div
              className="amendments-card__buttons-divider"
              data-testid="amendment-card-buttons-divider"
            />
            <div
              className="amendments-card__comments-findings-btns-container"
              data-testid="amendment-card-comments-findings-buttons-container"
            >
              <div
                className="amendments-card__actions-buttons-container amendments-card__actions-buttons-container--comment-btns"
                data-testid="amendment-card-action-buttons-container-comment-buttons"
              >
                <div
                  className="amendments-card__flex-button"
                  data-testid="amendment-card-view-comments-flex-button"
                >
                  <Button
                    variant="text"
                    color="secondary"
                    className="amendments-card__open-comments-box-btn"
                    data-testid="amendment-card-view-coments-box-button"
                    onClick={handleShowHideCommentBox}
                    disabled={disabledActionBtn}
                    style={disabledActionBtn ? { opacity: 0.33 } : null}
                  >
                    <Icon
                      alt="Toggle comments button"
                      className="amendments-card__icon"
                      data-testid="amendment-card-view-comments-icon"
                      icon={IconMessageDots}
                      style={disabledActionBtn ? { opacity: 0.33 } : null}
                    />
                    <Typography
                      data-testid="amendment-card-view-comments-text"
                      variant="body1"
                    >
                      View Comments
                    </Typography>
                  </Button>
                </div>
                {cardType === AMENDMENT_CARD_TYPE.PENDING && isSafeAdmin && (
                  <div
                    className="amendments-card__flex-button"
                    data-testid="amendment-card-request-review-flex-button"
                  >
                    <Button
                      variant="text"
                      color="secondary"
                      className="amendments-card__open-comments-box-btn"
                      data-testid="amendment-card-request-review-box-button"
                      onClick={requestReviewBtn}
                      disabled={disabledActionBtn}
                      style={disabledActionBtn ? { opacity: 0.33 } : null}
                    >
                      <Icon
                        alt="Request review button"
                        icon={IconSend}
                        className="amendments-card__icon"
                        data-testid="amendment-card-request-review-icon"
                        style={disabledActionBtn ? { opacity: 0.33 } : null}
                      />
                      <Typography
                        data-testid="amendment-card-request-review-text"
                        variant="body1"
                      >
                        Request Review
                      </Typography>
                    </Button>
                  </div>
                )}
              </div>
              {cardType === AMENDMENT_CARD_TYPE.PENDING && isSafeAdmin && (
                // "Request Review" and Accept/Deny actions should be restricted to SAFE_ADMIN users only
                <div
                  className="amendments-card__actions-buttons-container"
                  data-testid="amendment-card-modify-actions-buttons-container"
                >
                  {showSpinner && disabledActionBtn && (
                    <div
                      data-testid="amendment-card-disabled-action-button-spinner-container"
                      style={{ marginLeft: '-3.5em' }}
                    >
                      <CFASmallSpinner data-testid="amendment-card-disabled-action-button-spinner" />
                    </div>
                  )}
                  <div
                    className="amendments-card__flex-button"
                    data-testid="amendment-card-modify-flex-button"
                  >
                    <Button
                      variant="text"
                      color="secondary"
                      size="sm"
                      className="amendments-card__modify-btn"
                      data-testid="amendment-card-modify-button"
                      onClick={handleShowModifyAppealModal}
                    >
                      <Typography
                        data-testid="amendment-card-modify-text"
                        variant="body1"
                        color="secondary"
                      >
                        Modify
                      </Typography>
                    </Button>
                  </div>

                  <ModifyAppealModal
                    data-testid="amendment-card-modify-appeal-modal"
                    isOpen={showModifyModal}
                    handleIsOpen={handleShowModifyAppealModal}
                    appealObj={amendment}
                    modifyAction={handleModifyAppeal}
                    round={amendment.round}
                  />
                  <div
                    className="amendments-card__flex-button"
                    data-testid="amendment-card-deny-flex-button"
                  >
                    <Button
                      data-testid="amendment-card-deny-button"
                      id="amendments-deny-modal__open"
                      variant="outlined"
                      size="md"
                      onClick={handleShowHideDenyModal}
                      disabled={disabledActionBtn}
                    >
                      <Icon
                        alt="Deny amendment button"
                        icon={CircleCrossFilled}
                        className="amendments-card__icon--deny"
                        data-testid="amendment-card-deny-button-icon"
                        style={disabledActionBtn ? { opacity: 0.33 } : null}
                      />
                      <Typography
                        variant="body1"
                        className="amendments-card__actions-btn-deny-text"
                        data-testid="amendment-card-deny-button-text"
                        style={disabledActionBtn ? { opacity: 0.33 } : null}
                      >
                        {I18n.t('APP_ADMIN_AMENDMENTS_DENY')}
                      </Typography>
                    </Button>
                  </div>
                  <div
                    className="amendments-card__flex-button"
                    data-testid="amendment-card-appprove-flex-button"
                  >
                    <Button
                      variant="outlined"
                      size="md"
                      className="amendments-card__actions-btn--approve"
                      data-testid="amendment-card-approve-button"
                      onClick={handleShowHideApproveModal}
                      disabled={disabledActionBtn}
                    >
                      <Icon
                        alt="Approve amendment button"
                        icon={SuccessFilled}
                        className="amendments-card__icon--approve"
                        data-testid="amendment-card-approve-button-icon"
                        style={disabledActionBtn ? { opacity: 0.33 } : null}
                      />
                      <Typography
                        variant="body1"
                        className="amendments-card__actions-btn-approve-text"
                        data-testid="amendment-card-approve-button-text"
                        style={disabledActionBtn ? { opacity: 0.33 } : null}
                      >
                        {I18n.t('APP_ADMIN_AMENDMENTS_ACCEPT')}
                      </Typography>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showCommentBox && cardType !== AMENDMENT_CARD_TYPE.OPERATOR && (
        <AmendmentCommentsContainer
          data-testid="amendment-card-amendment-comments-container"
          auditUuid={amendment.uuid}
          amendmentId={amendment.id.toString()}
          amendmentStatus={amendment.amendmentStatus}
          amendmentType={amendment.amendmentType}
          amendmentRequestDate={amendment.amendmentRequestDate}
          commentPayload={amendment.comment}
          disabledComment={disabledActionBtn}
          commentContend={amendment.comment}
          handleShowHideCommentBox={handleShowHideCommentBox}
          isHistoricTab={cardType === AMENDMENT_CARD_TYPE.HISTORICAL}
          isAdminRequested={amendment.isAdminRequested}
        />
      )}

      {showDenyModal && (
        <AmendmentDenyModal
          data-testid="amendment-card-amendment-deny-modal"
          cancelClicked={handleShowHideDenyModal}
          updateAmendment={updateAmendmentBtn}
          amendment={amendment}
        />
      )}

      {showApproveModal && (
        <AmendmentApproveModal
          data-testid="amendment-card-amendment-approve-modal"
          cancelClicked={handleShowHideApproveModal}
          updateAmendment={updateAmendmentBtn}
          amendment={amendment}
        />
      )}
    </>
  );
};

AmendmentCard.propTypes = {
  cardType: PropTypes.string.isRequired,
  amendment: PropTypes.shape({}),
};

export default AmendmentCard;
