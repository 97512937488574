import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  Typography,
  Icon,
} from 'cfa-react-components';
import { ErrorFilled } from '@cfa-icons/system';
import QuestionnaireCategory from './QuestionnaireCategory';
import { useLVRStore, useLVRDispatch, actions } from '../store/reducers';
import { getCategoriesWithErrors } from '../../../pages/Assessment/LicenseeVisitReportForm/validationUtils';

import './QuestionnaireContainer.scss';

/**
 * QuestionContainer is the top-level root of the dynamic Assessments Question pattern.
 * It will display an appropriate question type based on the given input
 */
const QuestionnaireContainer = ({ questionnaire, children }) => {
  const lvrDispatch = useLVRDispatch();
  const lvrStore = useLVRStore();

  const [openCategories, setOpenCategories] = useState(
    lvrStore?.uiFormStatus?.openCategories ?? ['General Information'],
  );
  const [categoriesWithErrors, setCategoriesWithErrors] = useState([]);

  useEffect(() => {
    if (lvrStore?.uiFormStatus?.openCategories) {
      setOpenCategories(lvrStore?.uiFormStatus?.openCategories);
    }
  }, [lvrStore?.uiFormStatus?.openCategories]);

  useEffect(() => {
    lvrDispatch({
      type: actions.updateUiFormStatus,
      data: { openCategories },
    });
  }, [openCategories, lvrDispatch]);

  useEffect(() => {
    if (!questionnaire) return;

    const erroneousCategories = getCategoriesWithErrors({
      categories: questionnaire?.categories,
      responseStatuses: lvrStore?.responseValidationStatuses,
    });
    setCategoriesWithErrors(erroneousCategories);
  }, [lvrStore?.responseValidationStatuses, questionnaire]);

  return (
    <Accordion
      className="questionnaire-container"
      data-testid="questionnaire-container-accordion"
      elevation={1}
      iconPosition="end"
      variant="surface"
      enableMultipleOpen
      defaultActiveItemKey="0"
      activeItemKey={openCategories}
      onChange={(incomingCategory) => setOpenCategories(incomingCategory)}
    >
      {children}
      {questionnaire?.categories.length
        ? questionnaire?.categories.map((category) => (
            <AccordionItem
              unmountOnExit={false}
              key={category.name}
              itemKey={category.name}
              className="questionnaire-category"
              data-testid="questionnaire-container-category-accordion-item"
              header={
                <>
                  <div
                    data-testid="questionnaire-container-category-container"
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      marginBottom: '1em',
                    }}
                  >
                    <Typography
                      data-testid="questionnaire-container-category-name"
                      color="default"
                      gutterBottom={false}
                      variant="h3"
                    >
                      {category.name}
                    </Typography>
                    {lvrStore?.submissionFailed &&
                      categoriesWithErrors.includes(category.name) && (
                        <Icon
                          data-testid="questionnaire-container-category-icon"
                          size="md"
                          icon={ErrorFilled}
                          style={{ marginLeft: '0.5em', color: '#b6072f' }}
                        />
                      )}
                  </div>
                  <Typography
                    className="questionnaire-header-description"
                    data-testid="questionnaire-container-header-description"
                    variant="body2"
                  >
                    {category.description}
                  </Typography>
                </>
              }
            >
              <QuestionnaireCategory
                data-testid="questionnaire-container-questionnaire-category"
                categoryData={category}
              />
            </AccordionItem>
          ))
        : null}
    </Accordion>
  );
};

QuestionnaireContainer.propTypes = {
  questionnaire: PropTypes.object,
  children: PropTypes.node,
};

export default QuestionnaireContainer;
