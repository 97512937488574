import { API_GET } from './API';

export const getEffectiveQuestions = async (selectedRound, selectedYear) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/question?round=${selectedRound}-${selectedYear}`;
  try {
    const result = await API_GET(url);
    return result.data;
  } catch (err) {
    console.error('Failed to retrieve effective questions.', err);
  }
};
