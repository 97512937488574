import {
  //
  SET_USER_TOKEN,
  //
  END_USER_SESSION,
} from '../actions';

const initialState = {
  userToken: null,
};

const doActions = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TOKEN:
      return { ...state, userToken: action.payload };
    case END_USER_SESSION:
      return { ...initialState };
    default:
      return state;
  }
};
export default doActions;
