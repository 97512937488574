import { useEffect, useState, Fragment } from 'react';
import {
  Typography,
  Button,
  Tooltip,
  LoadingIndicator,
} from 'cfa-react-components';
import { monthNames, dayNames } from '../../../constants/constants';
import './MonthCalendar.scss';
import ChartLegend from '../common/ChartLegend';

const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

const firstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

const mondaysOfMonth = (year = 0, month = 0, days = []) => {
  const mondays = [];
  days.forEach((day) => {
    const date = new Date(year, month, day);
    const weekDay = date.getDay();
    if (day === 1 && weekDay > 1) {
      while (date.getDay() !== 1) {
        date.setDate(date.getDate() - 1);
      }
      mondays.push(`${date.getMonth() + 1}/${date.getDate()}`);
    } else if (weekDay === 1) {
      mondays.push(`${month + 1}/${day}`);
    }
  });
  return mondays;
};

const CalendarDays = () => (
  <div className="weekrow" data-testid="month-calendar-weekrow">
    {dayNames.map((day) => (
      <span key={day} className="weekday" data-testid="month-calendar-weekday">
        {day}
      </span>
    ))}
  </div>
);

const Mondays = ({ date = new Date(), days = [] }) => (
  <div className="week-start-date-column">
    {mondaysOfMonth(date.getFullYear(), date.getMonth(), days).map((day, i) => (
      <Fragment
        data-testid="month-calendar-mondays-fragment"
        key={`${day}_${i}`}
      >
        {i === 0 ? (
          <span
            className="weekday"
            data-testid="month-calendar-mondays-weekday"
            style={{ visibility: 'hidden' }}
          >
            {day}
          </span>
        ) : null}
        <span
          className="weekday"
          data-testid="month-calendar-mondays-weekday-2"
        >
          {day}
        </span>
      </Fragment>
    ))}
  </div>
);

const CalendarBody = ({
  days,
  date = new Date(),
  dataMap,
  completedColor,
  incompleteColor,
}) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dayToBeginTheMonthFrom = firstDayOfMonth(year, month);
  const firstDayStyle = { gridColumnStart: dayToBeginTheMonthFrom };

  return days.map((day, i) => {
    const dayOfWeek = new Date(year, month, day, 0).getDay();
    const dateKey = new Date(year, month, day, 0).toISOString().split('T')[0];
    const isAssessmentComplete = dataMap[dateKey] != null;
    const dayBlockStyle = {
      background: isAssessmentComplete ? completedColor : incompleteColor,
    };
    return (
      dayOfWeek !== 0 && (
        <Tooltip
          data-testid="month-calendar-calendar-tooltip"
          key={day}
          content={day}
        >
          <div
            className="day"
            data-testid="month-calendar-calendar-day"
            style={i === 0 ? firstDayStyle : {}}
          >
            <span
              className={`day-block ${i === 0 ? 'first-day' : ''}`}
              data-testid="month-calendar-calendar-day-block"
              style={dayBlockStyle}
            />
          </div>
        </Tooltip>
      )
    );
  });
};

const MonthCalendar = ({
  isLoading = false,
  apiData,
  completedColor,
  incompleteColor,
}) => {
  const defaultDate = new Date();
  const [date, setDate] = useState(defaultDate);
  const [days, setDays] = useState([]);

  useEffect(() => {
    const year = date.getFullYear();
    const month = date.getMonth();
    setDays(Array.from({ length: daysInMonth(year, month) }, (_, v) => v + 1));
  }, [date]);

  const handleIncrement = () => {
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    const currentDate = new Date();
    if (
      nextMonth.getFullYear() < currentDate.getFullYear() ||
      (nextMonth.getFullYear() === currentDate.getFullYear() &&
        nextMonth.getMonth() <= currentDate.getMonth())
    ) {
      setDate(nextMonth);
    }
  };

  const handleDecrement = () =>
    setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));

  return (
    <div
      className="calendar-component-container"
      data-testid="month-calendar-calendar-component-container"
    >
      {isLoading ? (
        <LoadingIndicator
          data-testid="month-calendar-loading-indicator"
          variant="inline"
        />
      ) : (
        <>
          <ChartLegend
            keys={[
              { label: 'Completed', backgroundColor: completedColor },
              { label: 'Incomplete/Closed', backgroundColor: incompleteColor },
            ]}
            style={{ marginTop: '1rem' }}
          />
          <div className="calendar">
            <div className="week-start-date-container">
              <Mondays date={date} days={days} />
            </div>
            <div
              className="week-day-labels-container calendar-blocks"
              data-testid="month-calendar-week-day-labels-container"
            >
              <CalendarDays data-testid="month-calendar-calendar-days" />
              <CalendarBody
                data-testid="month-calendar-calendarBody"
                days={days}
                date={date}
                dataMap={apiData}
                completedColor={completedColor}
                incompleteColor={incompleteColor}
              />
            </div>
          </div>
          <div
            className="month-navigation"
            data-testid="month-calendar-month-navigation"
          >
            <Button
              data-testid="month-calendar-previous-month-button"
              color="secondary"
              size="sm"
              variant="text"
              onClick={handleDecrement}
            >
              <Typography
                className="left-button"
                data-testid="month-calendar-left-button"
                variant="h3"
              >
                &lt;
              </Typography>
            </Button>
            <div
              className="month-label-container"
              data-testid="month-calendar-month-label-container"
            >
              <Typography data-testid="month-calendar-month-label">
                {monthNames[date.getMonth()]} {date.getFullYear()}
              </Typography>
            </div>
            <Button
              data-testid="month-calendar-next-month-button"
              color="secondary"
              size="sm"
              variant="text"
              onClick={handleIncrement}
            >
              <Typography
                className="right-button"
                data-testid="month-calendar-right-button"
                variant="h3"
              >
                &gt;
              </Typography>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default MonthCalendar;
