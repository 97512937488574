import { Typography } from 'cfa-react-components';

const container = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '1.5em',
};

const item = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  gap: '.5rem',
};

const colorIcon = {
  width: '1rem',
  height: '1rem',
  display: 'inline-block',
  borderRadius: '0.625rem',
};

const ChartLegend = ({ keys = [], style = {}, ...props }) => (
  <div {...props} style={{ ...container, ...style }}>
    {keys.length > 0 &&
      keys.map(({ backgroundColor = '', label = '' }, i) => (
        <div key={`${label}_${i}`} style={item}>
          <span style={{ ...colorIcon, backgroundColor }} />
          <span>
            <Typography variant="caption1">{label}</Typography>
          </span>
        </div>
      ))}
  </div>
);

export default ChartLegend;
