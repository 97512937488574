import PropTypes from 'prop-types';
import RequestedAppealCard from './RequestedAppealCard';
import './RequestedAppealsWidget.scss';

const RequestedAppealsCardContainer = ({ appeals }) => {
  return (
    <div
      className="requested-appeals-widget__appeals-card-container"
      data-testid="requested-appeal-card-container-appeals-card-container"
    >
      <hr data-testid="requested-appeal-card-container-horizontal-rule" />
      {appeals.map((appeal, index) => (
        <div
          key={index}
          data-testid="requested-appeal-card-container-appeals-map-container"
        >
          <RequestedAppealCard
            appeal={appeal}
            data-testid="requested-appeal-card-container-requested-appeal-card"
          />
          {index !== appeals.length - 1 && <hr />}
        </div>
      ))}
      <hr data-testid="requested-appeal-card-container-horizontal-rule-2" />
    </div>
  );
};

RequestedAppealsCardContainer.propTypes = {
  appeals: PropTypes.array.isRequired,
};

export default RequestedAppealsCardContainer;
