import { useState } from 'react';
import './AmendmentApproveModal.scss';

function AmendmentApproveModal({ updateAmendment, cancelClicked, amendment }) {
  const [commentInput, setInputValue] = useState('');
  const onChangeHandler = (event) => {
    setInputValue(event.target.value);
  };
  const onDoneHandler = () => {
    updateAmendment('APPROVED', commentInput);
  };

  return (
    <div
      className="amendment-approve-modal__container"
      data-testid="amendment-approve-modal-container"
    >
      <div
        className="amendment-approve-modal"
        data-testid="amendment-approve-modal-sub-container"
      >
        <div
          className="amendment-approve-modal__title-container"
          data-testid="amendment-approve-modal-title-container"
        >
          <p
            className="amendment-approve-modal__title"
            data-testid="amendment-approve-modal-title"
          >
            Reason for Approving Appeal
          </p>
          <button
            type="button"
            className="amendment-approve-modal__cancel-button"
            data-testid="amendment-approve-modal-cancel-button"
            onClick={cancelClicked}
          >
            <img
              alt="Deny amendment button"
              data-testid="amendment-approve-modal-deny-amendment-button-img"
              src={require('../../assets/icons/X.svg').default}
              height={14}
              width={17}
            />
          </button>
        </div>
        <p
          className="amendment-approve-modal__subtitle"
          data-testid="amendment-approve-modal-approve-reason-subtitle"
        >
          Please provide a reason for approving this Appeal:
        </p>
        <div data-testid="amendment-approve-modal-finding-container">
          <p
            className="amendment-approve-modal__finding-title"
            data-testid="amendment-approve-modal-finding-title"
          >
            Finding {amendment.questionSafeReportId}:{' '}
            {amendment.findingDescription}
          </p>
          <p
            className="amendment-approve-modal__text"
            data-testid="amendment-approve-modal-location-name-num-text"
          >
            {amendment.locationNumber} {amendment.locationName}
          </p>
        </div>
        <div data-testid="amendment-approve-modal-zone-item-issue-container">
          {amendment.zone && (
            <div
              className="amendment-approve-modal__detail-container"
              data-testid="amendment-approve-modal-zone-detail-container"
            >
              <p
                className="amendment-approve-modal__detail-title"
                data-testid="amendment-approve-modal-zone-detail-title"
              >
                Zone:
              </p>
              <p
                className="amendment-approve-modal__detail-text"
                data-testid="amendment-approve-modal-zone-detail-text"
              >
                {amendment.zone}
              </p>
            </div>
          )}
          {amendment.item && (
            <div
              className="amendment-approve-modal__detail-container"
              data-testid="amendment-approve-modal-item-detail-container"
            >
              <p
                className="amendment-approve-modal__detail-title"
                data-testid="amendment-approve-modal-item-detail-title"
              >
                Item:
              </p>
              <p
                className="amendment-approve-modal__detail-text"
                data-testid="amendment-approve-modal-item-detail-text"
              >
                {amendment.item}
              </p>
            </div>
          )}
          {amendment.issue && (
            <div
              className="amendment-approve-modal__detail-container"
              data-testid="amendment-approve-modal-issue-detail-container"
            >
              <p
                className="amendment-approve-modal__detail-title"
                data-testid="amendment-approve-modal-issue-detail-title"
              >
                Issue:
              </p>
              <p
                className="amendment-approve-modal__detail-text"
                data-testid="amendment-approve-modal-issue-detail-text"
              >
                {amendment.issue}
              </p>
            </div>
          )}
        </div>
        <p
          className="amendment-approve-modal__subtitle"
          data-testid="amendment-approve-modal-reason-sent-subtitle"
        >
          This reason will be sent to the Operator via email.
        </p>
        <textarea
          onChange={onChangeHandler}
          type="text"
          name="reason-for-denial"
          id="reason-for-denial"
          rows="4"
          maxLength="500"
          placeholder="Write a reason or any additional details..."
          className="amendment-approve-modal__textbox"
          data-testid="amendment-approve-modal-reason-textbox"
        />
        <p
          className="amendment-approve-modal__charcount"
          data-testid="amendment-approve-modal-charcount"
        >
          {commentInput.length}/500 Characters
        </p>
        <div
          className="amendment-approve-modal__buttons-container"
          data-testid="amendment-approve-modal-buttons-container"
        >
          <button
            type="button"
            className="amendment-approve-modal__cancel-button"
            data-testid="amendment-approve-modal-cancel-button"
            onClick={cancelClicked}
          >
            CANCEL
          </button>
          <button
            type="button"
            className={
              commentInput.length === 0
                ? 'amendment-approve-modal__done-button-disabled'
                : 'amendment-approve-modal__done-button'
            }
            data-testid="amendment-approve-modal-done-button"
            onClick={onDoneHandler}
            disabled={commentInput.length === 0}
          >
            DONE
          </button>
        </div>
      </div>
    </div>
  );
}

AmendmentApproveModal.propTypes = {};

export default AmendmentApproveModal;
