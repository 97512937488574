import { useEffect, useState } from 'react';
import {
  RadioButton,
  RadioGroup,
  Typography,
  useMediaQuery,
} from 'cfa-react-components';
import { useLVRDispatch, useLVRStore } from '../../../store/reducers';
import { updateSupplementaryData } from '../../../utils/questionsUtils';

import '../../../Questions/MultipleChoice/MultipleChoiceQuestion.scss';

const GeneralInfomationRadioGroup = ({
  formKey,
  name,
  label,
  required,
  options,
}) => {
  const lvrDispatch = useLVRDispatch();
  const {
    submissionFailed,
    supplementaryData: { [formKey]: value },
  } = useLVRStore();

  const isMobile = useMediaQuery('max-width: 820px');

  const [errorText, setErrorText] = useState('');

  const onChange = (option) => {
    if (formKey === 'orderExperience') {
      updateSupplementaryData({
        dispatch: lvrDispatch,
        data: {
          orderExperience: option,
        },
      });
      setErrorText('');
    } else {
      updateSupplementaryData({
        dispatch: lvrDispatch,
        data: {
          [formKey]: option,
        },
      });
      setErrorText('');
    }
  };

  useEffect(() => {
    if (submissionFailed && !value) {
      setErrorText(`A value must be selected.`);
    }
  }, [submissionFailed, value]);

  return (
    <div
      className="multiple-choice-question"
      data-testid="general-information-radio-group-container"
    >
      {required && (
        <Typography
          data-testid="general-information-radio-group-required-text"
          color="primary"
          variant="caption2"
        >
          Required
        </Typography>
      )}
      <Typography
        className="multiple-choice-question__text"
        data-testid="general-information-radio-group-label-text"
        fontWeight="medium"
        color="default"
        gutterBottom
        variant="body1"
      >
        {label}
      </Typography>

      <RadioGroup
        className="multiple-choice-question__radio-group"
        data-testid="general-information-radio-group-radio-group"
        name={name}
        value={value || ''}
        errorText={errorText}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        orientation={isMobile ? 'vertical' : 'horizontal'}
      >
        {options.map((option) => (
          <RadioButton
            data-testid="general-information-radio-group-radio-button"
            key={option.label}
            label={option.label}
            value={option.value}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default GeneralInfomationRadioGroup;
