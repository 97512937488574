import { Icon, Typography } from 'cfa-react-components';
import { Celebrate } from '@cfa-icons/system';
import I18n from '../../../../i18n/utils';
import './LBRDownloadModal.scss';

const NoLBRData = () => (
  <div>
    <Typography gutterBottom>
      {I18n.t('APP_SMART_SHOP_LBR_NO_FILES_MSG')}
    </Typography>
    <div className="download-modal__no-lbr-reports">
      <Icon className="download-modal__icon" size="xl" icon={Celebrate} />
      <Typography
        style={{ marginLeft: '1rem', alignContent: 'center' }}
        variant="h4"
      >
        {I18n.t('APP_SMART_SHOP_LBR_NO_REPORTS_MSG')}
      </Typography>
    </div>
  </div>
);

export default NoLBRData;
