const espanol = {
  LANGUAGE_SELECTION_HEADING: 'Select a language',
  LANGUAGE_LOADING: 'Loading Translation...',
  LANGUAGE_ENGLISH: 'English',
  LANGUAGE_SPANISH: 'Español',
  // APP
  APP_NAME: 'Centro de Operaciones',
  APP_SHORT_NAME: 'Centro de Operaciones',
  APP_SIGN_IN: 'Ingresar',

  APP_COMING_SOON: 'Estará listo pronto',

  // <b></b> tags parse-able with the parse() plugin from react-html-parser

  APP_UNAUTHORIZED_MESSAGE: 'No autorizado para ver esta Aplicación',
  APP_UNDEFINED_LOCATION_MESSAGE: 'Por favor busque una ubicación',

  APP_NOTIFICATION_SUCCESS_TITLE: 'Éxito',
  APP_NOTIFICATION_ERROR_TITLE: 'Error',
  APP_NOTIFICATION_FORBIDDEN_TITLE: 'Prohibido',
  APP_API_DATA_FAILURE:
    '¡Ups! No pudimos obtener los datos del servidor, por favor trate de nuevo',
  APP_API_PERMISSIONS_FAILURE:
    '¡Ups! Parece que necesita permisos para acceder a este sitio. Comuníquese con su administrador.',

  // Nav Menu
  APP_SHORT_TITLE: 'Centro de Operaciones',
  APP_MENU_LANDING: 'Landing',
  APP_MENU_ADMIN: 'Admin',
  APP_MENU_SIGN_OUT: 'Salir',
  APP_PROFILE_TOOLTIP: 'Perfil',
  APP_SELECT_LOCATION: 'Seleccione ubicación',

  // Landing Page
  APP_LANDING_PAGE_TITLE: 'Centro de Operaciones',
  APP_LANDING_PAGE_WELCOME: 'Bienvenido',
  APP_LANDING_PAGE_SELECT_APP: 'Seleccionar una Aplicación',
  APP_LANDING_PAGE_SELECT_LOCATION:
    'Por favor seleccione una ubicación para continuar',
  APP_LANDING_PAGE_FOOD_SAFETY_CARD: 'Reporte de Seguridad de los Alimentos',
  APP_LANDING_PAGE_HEALTH_CARD: 'Inicio de la Evaluación de salud',
  APP_LANDING_PAGE_TABLEAU_DASHBOARD:
    'Tablero para Tableau de la Seguridad de los Alimentos',
  APP_LANDING_PAGE_BEST_PRACTICE_TOOL_KIT_TITLE: 'Kit de mejores prácticas',
  APP_LANDING_PAGE_403_FORBIDDEN:
    'No tienes acceso a este sitio. Comuníquese con un administrador.',

  // Admin Dashboard
  APP_ADMIN_DASHBOARD_TITLE: 'Admin Dashboard',
  APP_ADMIN_SELF_ASSESSMENT_TITLE: 'Self Assessment',
  APP_ADMIN_AMENDMENTS_TITLE: 'Amendments',
  APP_ADMIN_AMENDMENTS_DESCRIPTION:
    'Revise un historial de enmiendas de restaurantes a continuación y apruebe o rechace dichas solicitudes',
  APP_ADMIN_AMENDMENTS_ACCEPT: 'Appropar',
  APP_ADMIN_AMENDMENTS_DENY: 'Negar',
  APP_ADMIN_AMENDMENTS_REVIEW_REQUEST_NOTIFICATION:
    'Successfully requested review from vendor',
  APP_ADMIN_AMENDMENTS_EMPTY_MESSAGE: '¡No hay elementos para revisar!',
  APP_ADMIN_QUESTION_UPDATE_SUCCESS_NOTIFICATION:
    'Pregunta SAFE actualizada correctamente',
  APP_ADMIN_QUESTION_UPDATE_FAILURE_NOTIFICATION:
    'No se pudo actualizar la pregunta SAFE',
  APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_DENIED: 'Appeal Denied',
  APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_APPROVED: 'Appeal Approved',
  APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_MODIFY:
    'Appeal modify Successfully',

  // Health and Hygiene
  APP_HEALTH_AND_HYGIENE_TITLE: 'Salud e Higiene',
  APP_HEALTH_AND_HYGIENE_HEALTH_PROMPT: 'Inicio de la Evaluación de salud',
  APP_HEALTH_AND_HYGIENE_VENDOR_TRACING: 'Vendor Tracing',

  // Location Selector
  APP_LOCATION_SELECTOR_SELECT: 'Por favor seleccione un lugar para continuar',

  // Report Dashboard
  APP_REPORT_DASHBOARD_NO_AUDITS:
    'Parece que su informe aún no está listo.Vuelva a consultar después de recibir una notificación de que está listo',
  APP_REPORT_DASHBOARD_SELECT_LOCATION:
    'Por favor seleccione un lugar para continuar',
  APP_REPORT_DASHBOARD_TITLE: 'Reporte de Seguridad de los Alimentos',
  APP_REPORT_DASHBOARD_BACK_TO_HOME: 'Casa',
  APP_REPORT_DASHBOARD_PERFORMANCE: 'Desempeño',
  APP_REPORT_DASHBOARD_PERFORMANCE_LEARN:
    'Conozca más sobre los Niveles de Desempeño',
  APP_REPORT_DASHBOARD_NEEDING_IMPROVEMENT: 'Necesita mejoras',
  APP_REPORT_DASHBOARD_PERFORMANCE_LEVEL_LABEL: 'Nivel de Desempeño',
  APP_REPORT_DASHBOARD_TOTAL_FINDINGS_GRAPH_LABEL: 'Total hallazgos',
  APP_REPORT_DASHBOARD_IMMEDIATE_FINDINGS_GRAPH_LABEL: 'Inmediato hallazgos',
  APP_REPORT_DASHBOARD_HIGH_FINDINGS_GRAPH_LABEL: 'Alto hallazgoss',
  APP_REPORT_DASHBOARD_MEDIUM_FINDINGS_GRAPH_LABEL: 'Mediano hallazgos',
  APP_REPORT_DASHBOARD_LOW_FINDINGS_GRAPH_LABEL: 'Bajo hallazgos',
  // APP_REPORT_DASHBOARD_HISTORICAL_PERFORMANCE: 'Rendimiento Histórico',
  APP_REPORT_DASHBOARD_FINDINGS_SUMMARY: 'Resumen de hallazgos',
  APP_REPORT_DASHBOARD_FINDINGS_SUMMARY_EMPTY: 'No hay hallazgos que mostrar',
  APP_REPORT_DASHBOARD_FINDINGS_SEARCH_EMPTY:
    'No hay resultados de concuerden con la búsqueda',
  APP_REPORT_DASHBOARD_NEW: 'Nuevo',
  APP_REPORT_DASHBOARD_REPEAT: 'Repetir',
  APP_REPORT_DASHBOARD_TOTAL: 'Total',
  APP_REPORT_DASHBOARD_IMMEDIATE: 'Inmediato',
  APP_REPORT_DASHBOARD_HIGH: 'Alto',
  APP_REPORT_DASHBOARD_MEDIUM: 'Mediano',
  APP_REPORT_DASHBOARD_LOW: 'Bajo',
  APP_REPORT_DASHBOARD_INFORMATIONAL: 'Informativo',
  APP_REPORT_DASHBOARD_HISTORICAL_PERFORMANCE: 'Tendencias históricas',
  APP_REPORT_DASHBOARD_VIEW: 'Ver',
  APP_REPORT_DASHBOARD_LEAVE_BEHIND: 'Reporte Leave Behind',
  APP_REPORT_DASHBOARD_PERFECT_AUDIT:
    'No hubo hallazgos en esta auditoría. ¡Siga trabajando bien!',
  APP_REPORT_DASHBOARD_VIEW_PAST_FINDINGS: 'Ver hallazgos pasados',
  APP_REPORT_DASHBOARD_VIEW_FINDINGS: 'Ver hallazgos',
  APP_REPORT_DASHBOARD_PRINT_REPORT: 'Imprimir reporte',
  APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_NO_FINDINGS:
    'No hay hallazgos en el informe para comenzar la investigación.',
  APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_PASSED_DEADLINE:
    'Venció el %{deadlineDate} para presentar consultas.',
  APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_WITHIN_DEADLINE:
    'Las consultas se pueden enviar hasta el %{deadlineDate}.',
  APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_BUTTON_TEXT: 'Iniciar consulta',
  APP_REPORT_DASHBOARD_AMENDMENTS_APPROVED: 'Appeal Approved',
  APP_REPORT_DASHBOARD_APPROVED_APPEALS: 'Approved Appeals',

  APP_REPORT_DASHBOARD_PERFORMANCE_SCORE_NOT_AVAILABLE: `Se requieren dos cuartas partes de los datos para generar los Niveles de Desempeño`,

  // All Findings
  APP_REPORT_DASHBOARD_FINDINGS_NEW: 'Nuevos',
  APP_REPORT_DASHBOARD_FINDINGS_PAST: 'Pasados',
  APP_REPORT_DASHBOARD_FINDINGS_ALL: 'Todos los hallazgos',
  APP_REPORT_DASHBOARD_FINDINGS_AUDIT: 'Auditoría',
  APP_REPORT_DASHBOARD_DETAILS: 'Detalles',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_NAVIGATION:
    'Reporte de Seguridad de los Alimentos',

  // Historical Actions
  LINK_PATHWAY_PROCEDURES: 'Pathway Procedimientos Seguidos',
  LINK_BEST_PRACTICES: 'Mejores Prácticas Seguidas',
  LINK_CTE_FREE: 'Free CTE Request Abierto',
  LINK_CTE_ADDITIONAL: 'Additional/Safe CTE Abierto',
  LINK_CORRECTIVE_ACTION_PLANNING: 'Corrective Action Planning Abierto',
  LINK_ROOT_CAUSE_ANALYSIS: 'Root Cause Analysis Abierto',

  // ALL FINDINGS FILTER
  APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_PLACEHOLDER: 'Clasificado por',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_RISK_LEVEL: 'Nivel de riesgo',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_CATEGORY: 'Categoría',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_FINDING_ID: 'Buscar Id',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_CATEGORY_FILTER_PLACEHOLDER:
    'Filtrar por Categoría',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_RISK_LEVEL_FILTER_PLACEHOLDER:
    'Filtrar por Nivel de riesgo',
  APP_REPORT_DASHBORAD_ALL_FINDINGS_RESET: 'Limpiar filtros',
  APP_REPORT_DASHBORAD_ALL_FINDINGS_NO_RESULTS:
    'No hay resultados para esta selección de filtros',
  APP_REPORT_DASHBORAD_ALL_FINDINGS_NO_RESULTS_MSG:
    'Trate de usar filtros diferentes',

  // Findings Card
  APP_REPORT_DASHBOARD_FINDING_CARD_WHEN_LABEL: 'Cuando',
  APP_REPORT_DASHBOARD_FINDING_CARD_CATEGORY_LABEL: 'Categoría',
  APP_REPORT_DASHBOARD_FINDING_CARD_REPEAT_LABEL: 'REPETIR',

  // Selected Findings
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_SUBTITLE: 'Hallazgo',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_CARD_TITLE:
    'Los detalles del Hallazgo',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_RESOURCES:
    'Recursos para ayudar a determinar su plan de acción.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_PATHWAY: 'Procedimientos de Pathway',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_PATHWAY_DESCRIPTION:
    'Considere revisar los procedimientos de Pathway para los problemas que ha identificado.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_BEST_PRACTICES:
    'Enlaces de mejores prácticas',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_BEST_PRACTICES_DESCRIPTION:
    'Si ha seguido los procedimientos adecuados pero necesita medidas adicionales visite el kit de Herramientas de mejores prácticas.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL: 'Soporte adicional',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_OTHER:
    'Analice la causa raíz del hallazgo',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_OTHER_DESCRIPTION:
    'Si necesita medidas adicionales o asistencia sobre la causa raíz, utilice los siguientes recursos.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED: '¿Necesita ayuda?',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED_DESCRIPTION:
    'Si todavía está buscando ayuda, considere usar los siguientes recursos.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_ROOT_CAUSE:
    'Análisis de causa raíz',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_CORRECTIVE:
    'Planificación de Acciones Correctivas',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_FREE_CTE:
    'Solicitud gratuita de CTE',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_SAFE_CTE:
    'Caja Fuerte/CTE adicional',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_HISTORICAL_ACTIONS_TITLE:
    'Acciones Históricas',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_HISTORICAL_ACTIONS_DESCRIPTION:
    'Una descripción general de la línea de tiempo de este hallazgo y el plan de acción correctivo anterior.',

  // Findings Filter
  APP_REPORT_DASHBOARD_FINDINGS_SORT: 'Clasificado por:',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL: 'Nivel de Riesgo',
  APP_REPORT_DASHBOARD_FINDINGS_CUSTOM: 'Personalizar',
  APP_REPORT_DASHBOARD_FINDINGS_SHOW_PAST: 'Mostrar hallazgos pasados',
  APP_REPORT_DASHBOARD_FINDINGS_FILTER_RISK_LEVEL:
    'Filtrar por Nivel de Riesgo:',
  APP_REPORT_DASHBOARD_FINDINGS_FILTER_CATEGORY:
    'Filtrar por Seguridad Alimentaria 5:',
  APP_REPORT_DASHBOARD_FINDINGS_FILTER_ZONE: 'Filtrar por Zone:',
  APP_REPORT_DASHBOARD_FINDINGS_OCCURRENCES: 'Ocurrencias',

  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_IMMEDIATE: 'Inmediato',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_HIGH: 'Alto',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_MEDIUM: 'Mediano',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_LOW: 'Bajo',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_INFORMATIONAL: 'Informativo',

  APP_REPORT_DASHBOARD_FINDINGS_CROSS_CONTAMINATION: 'La contaminación cruzada',
  APP_REPORT_DASHBOARD_FINDINGS_HEALTH_HYGIENE: 'Salud e Higiene',
  APP_REPORT_DASHBOARD_FINDINGS_INFORMATIONAL_ONLY: 'Solo informativo',
  APP_REPORT_DASHBOARD_FINDINGS_PESTS: 'Plagas',
  APP_REPORT_DASHBOARD_FINDINGS_TIME_TEMPERATURE: 'Tiempo y temperatura',
  APP_REPORT_DASHBOARD_FINDINGS_CLEANING_SANITATION: 'Limpieza y desinfección',
  APP_REPORT_DASHBOARD_CLEAR_CATEGORY_FILTER: 'Borrar filtros',

  APP_REPORT_DASHBOARD_FINDINGS_ZONE_BOARDS: 'Boards',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_BREADING_TABLE: 'Mesa de empanizar',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_COOLER: 'Hielera',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_DINING_ROOM: 'Comedor',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_DRIVE_THRU: 'Drive Thru',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_FREEZER: 'Congelador',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_FRONT_COUNTER: 'Mostrador principal',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_SALAD_PREP: 'Preparación de ensaladas',

  APP_REPORT_DASHBOARD_SELECTED_FINDING_RESOURCES: 'Recursos',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_NO_RESOURCES: 'No Recursos',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_ERROR: `Estos detalles de hallazgos no se pueden ver dentro de la ubicación seleccionada y/o la auditoría seleccionada. Por favor intente de nuevo.`,

  APP_REPORT_DASHBOARD_SELECTED_FINDING_BEST_PRACTICE_LINKS:
    'Enlace de Buenas Practicas Recomendadas',

  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_STANDARD: 'Estándar',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_SUBQUESTION_ID: 'Numero de ID',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_QUARTER: 'Cuarta',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ZONE: 'Zona',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_TEMP: 'Temp',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ITEM: 'Artículo',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OPEN_COMMENTS:
    'Abrir comentarios',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ISSUE: 'Problema',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OTHER_INFORMATION:
    'Otra información',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OTHER_NOTES:
    'Notas del auditor',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_PHOTO: 'Foto',

  // Audit Amendments
  APP_REPORT_DASHBOARD_AUDIT_AMENDMENTS_TITLE: 'Enmiendas',
  APP_REPORT_DASHBOARD_AUDIT_AMENDMENTS_STATUS_AMMENDED: 'Cambiado',
  APP_REPORT_DASHBOARD_AUDIT_AMENDMENTS_EMPTY: 'No cambios',

  // PDF Export
  APP_PDF_EXPORT_FINDINGS_TITLE: 'Hallazgos',
  APP_PDF_EXPORT_NO_FINDINGS: 'No hallazgos en esta auditoría',

  // Performance Level Definitions
  APP_PERFORMANCE_LEVEL_CATEGORY_ELITE: 'Elite',
  APP_PERFORMANCE_LEVEL_CATEGORY_ELITE_DEFINITION:
    '¡Entre los mejores de la cadena! Demostración obvia de una cultura de seguridad de los alimentos a través de sistemas y procesos robustos para asegurar el cumplimiento consistente de los principios de seguridad de los alimentos. Riesgo mínimo para un brote potencial de una enfermedad transmitida por los alimentos. Continúe poniendo énfasis en el mantenimiento de las prácticas de seguridad de los alimentos.',
  APP_PERFORMANCE_LEVEL_CATEGORY_GOOD: 'Bueno',
  APP_PERFORMANCE_LEVEL_CATEGORY_GOOD_DEFINITION:
    'Muestra un entendimiento y ejecución minuciosos de las prácticas de seguridad de los alimentos. Riesgo bajo para un brote potencial de una enfermedad transmitida por los alimentos. Enfóquese consistentemente en la implementación de sistemas y procesos de seguridad de los alimentos para continuar bajando el riesgo en su Restaurante.',
  APP_PERFORMANCE_LEVEL_CATEGORY_FAIR: 'Satisfactorio',
  APP_PERFORMANCE_LEVEL_CATEGORY_FAIR_DEFINITION:
    'Demuestra un conocimiento y/o una ejecución de los sistemas y procesos de seguridad de los alimentos pasables, pero incompletos o inconsistentes. Riesgo moderado para un brote de una enfermedad transmitida por los alimentos comparado con otros Restaurantes dentro de la cadena. Enfóquese en fortalecer su entendimiento de los principios de seguridad de los alimentos en su Restaurante.',
  APP_PERFORMANCE_LEVEL_CATEGORY_UNSATISFACTORY: 'No satisfactorio',
  APP_PERFORMANCE_LEVEL_CATEGORY_UNSATISFACTORY_DEFINITION:
    'Demuestra una oportunidad para mejorar los sistemas y procesos de seguridad de los alimentos. Riesgo superior al promedio para un brote de una enfermedad transmitida por los alimentos comparado con otros Restaurantes dentro de la cadena. Pida ayuda para corregir las oportunidades encontradas.',
  APP_PERFORMANCE_LEVEL_CATEGORY_SUBSTANDARD: 'Subestándar',
  APP_PERFORMANCE_LEVEL_CATEGORY_SUBSTANDARD_DEFINITION:
    'Demuestra una necesidad para tomar una acción e implementar los sistemas y procesos de seguridad de los alimentos que cumplan con los estándares de Chick-fil-A. Riesgo elevado para un brote de una enfermedad transmitida por los alimentos comparado con otros Restaurantes dentro de la cadena. Pida ayuda a su Consultor de Negocios para corregir las oportunidades encontradas.',

  // Audit Summary Card
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_TOTAL_FINDINGS: 'Hallazgos Total',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_TOP_FOCUS_AREA:
    'Táreas de enfoque superior',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_NO_IMPROVEMENTS:
    '¡No hay áreas que necesiten mejoras para esta auditoría!',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_GOOD_JOB: 'Buen Trabajo',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_VIEW_FINDINGS: 'Ver Hallazgos',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_START_INQUIRY: 'Inciar Consulta',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_PRINT_FS_REPORT: 'Imprimir Reporte',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_LEAVE_BEHIND_REPORT:
    'Reporte Leave Behind',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_INQUIRY_DEADLINE:
    'Fecha límite de consulta',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_PREVIOUS_ROUND: 'Hallazgo Anterior',

  // Requested Appeals
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS: 'Apelaciones Solicitadas',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_VIEW_APPEALS: 'Ver Apelaciones',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FINDINGS: 'Hallazgos',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_STATUS: 'Estatus',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FILTERS_BADGE: 'Insignia',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FILTERS_FINDING_ID: 'Buscar Id',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_APPROVED: 'Aprobado',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_UPHELD: 'Confirmado',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_MODIFIED: 'Modificado',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REQUESTS: 'Solicitudes',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REMOVED: 'Quito',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_IN_REVIEW: 'En Revisión',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_NO_FILTERED_RESULTS:
    'No se solicitaron apelaciones para los filtros aplicados',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_NO_RESULTS:
    'No se solicitaron apelaciones en',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_CREATED: 'Creado',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_SUBMITTED_BY: 'Presentado por',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_VISIT_DATE: 'Fecha de Visita',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_REASON_FOR_REVIEW: 'Motivo de Revisión',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_SUPPORTING_DOCUMENTS:
    'Documentos de Respaldo',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_ADMIN_COMMENTS:
    'Comentarios de Administrador',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_AUDITORS_NOTES: 'Notas del Auditor',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_ZONE: 'Zona',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_ITEM: 'Artículo',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_ISSUE: 'Problema',

  // Filters
  APP_REPORT_DASHBOARD_FILTERS: 'Filtrar',
  APP_REPORT_DASHBOARD_FILTERS_SORT_BY: 'Ordenar por',
  APP_REPORT_DASHBOARD_FILTERS_ROUND: 'Ronda',
  APP_REPORT_DASHBOARD_FILTERS_FINDINGS: 'Hallazgos',
  APP_REPORT_DASHBOARD_FILTERS_FILTER_BY: 'Filtrado por',
  APP_REPORT_DASHBOARD_FILTERS_FINDING_ID: 'Identificación de Hallazgo',
  APP_REPORT_DASHBOARD_FILTERS_CATEGORY: 'Categoría',
  APP_REPORT_DASHBOARD_FILTERS_PRIORITY: 'Prioridad',
  APP_REPORT_DASHBOARD_FILTERS_APPLY: 'Solicitar',
  APP_REPORT_DASHBOARD_FILTERS_RESET: 'Reiniciar',

  // Assessment
  APP_ASSESSMENT_LANDING_PAGE_TITLE: 'Licensee Visit Report',
  APP_ASSESSMENT_LANDING_PAGE_SUB_TITLE:
    'Please submit the report within 24 hours of your visit to provide timely and actionable feedback to the restaurant leaders and partners.',
  APP_ASSESSMENT_LANDING_START_REPORT: 'Start LVR',
  APP_ASSESSMENT_LICENSEE_VISIT_REPORT_FORM_TITLE: 'Licensee Visit Report',

  // Best Practices Toolkit for Need Help Widget
  APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_TITLE:
    'Kit de mejores prácticas',
  APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_DESCRIPTION:
    'El Toolkit de mejores prácticas ayuda a los Restaurantes a comprender por qué es probable que ocurra un problema (causa raíz) y los conecta con una biblioteca de consejos de expertos y mejores prácticas revisadas y que son utilizadas por Restaurantes de alto rendimiento.',
  APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_LINK_CAPTION:
    'Explore el Toolkit aquí',
};

export default espanol;
