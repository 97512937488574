import { LoadingIndicator, Typography } from 'cfa-react-components';
import './ChartContainer.scss';

const ChartContainer = ({
  style = {},
  children = null,
  isLoading = true,
  isEmptyChart = false,
  emptyChartMessage = 'No Assessment Data Available',
  ...props
}) => (
  <div className="chart-container" {...props} style={style}>
    {isLoading ? (
      <LoadingIndicator variant="inline" />
    ) : isEmptyChart ? (
      <Typography variant="h4" style={{ alignSelf: 'center' }}>
        {emptyChartMessage}
      </Typography>
    ) : (
      children
    )}
  </div>
);

export default ChartContainer;
