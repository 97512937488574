import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Typography } from 'cfa-react-components';
import FreeResponseQuestion from './FreeResponse/FreeResponseQuestion';
import MultipleChoiceQuestionContainer from './MultipleChoice/MultipleChoiceQuestionContainer';
import { useLVRDispatch, useLVRStore, actions } from '../store/reducers';
import { getAttachmentDisplayName } from '../../FileUploader/common/dataUtils';

/**
 * QuestionContainer is the top-level root of the dynamic Assessments Question pattern.
 * It will display an appropriate question type based on the given input props.
 */
const QuestionContainer = ({ question }) => {
  const { type, responseUniqueIdentifier } = question;

  const lvrDispatch = useLVRDispatch();
  const lvrStore = useLVRStore();

  const questionAttributes = question?.questionAttributes;
  const isRequired = questionAttributes?.includes('REQUIRED');

  const questionTypeComponents = {
    FreeResponse: FreeResponseQuestion,
    MultipleChoice: MultipleChoiceQuestionContainer,
  };

  const QuestionToRender = questionTypeComponents[type];

  const responseStatuses = useMemo(
    () => lvrStore?.responseValidationStatuses?.[responseUniqueIdentifier],
    [responseUniqueIdentifier, lvrStore?.responseValidationStatuses],
  );

  useEffect(() => {
    if (responseStatuses) return;

    if (!isRequired) {
      lvrDispatch({
        type: actions.updateResponseValidationStatuses,
        data: { [responseUniqueIdentifier]: true },
      });
      return;
    }

    lvrDispatch({
      type: actions.updateResponseValidationStatuses,
      data: { [responseUniqueIdentifier]: false },
    });
  }, [responseUniqueIdentifier, isRequired, lvrDispatch, responseStatuses]);

  const attachmentUrls = useMemo(
    () =>
      lvrStore.responses?.[responseUniqueIdentifier]?.responseData
        ?.attachmentS3Urls || [],
    [lvrStore.responses, responseUniqueIdentifier],
  );

  return (
    <>
      {isRequired && (
        <Typography
          data-testid="question-container-required-text"
          color="primary"
          variant="caption2"
        >
          Required
        </Typography>
      )}
      <QuestionToRender
        data-testid="question-container-question-to-render"
        question={question}
      />
      {attachmentUrls.map((attachment) => (
        <Typography
          data-testid="question-container-get-attachment-display-name"
          key={attachment}
          color="secondary"
        >
          {getAttachmentDisplayName(attachment)}
        </Typography>
      ))}
    </>
  );
};

QuestionContainer.propTypes = {
  question: PropTypes.object,
};

export default QuestionContainer;
