import PropTypes from 'prop-types';
import QuestionContainer from '../Questions/QuestionContainer';

/**
 * QuestionContainer is the top-level root of the dynamic Assessments Question pattern.
 * It will display an appropriate question type based on the given input props.
 */
const QuestionnaireCategory = ({ categoryData }) => {
  const sortedQuestions = categoryData.questions.filter(
    (question) => question?.rootQuestion,
  );

  if (!sortedQuestions) return null;

  return sortedQuestions.map((question) => (
    <QuestionContainer
      data-testid="questionnaire-category-question-container"
      key={question.responseUniqueIdentifier}
      question={question}
    />
  ));
};

QuestionnaireCategory.propTypes = {
  categoryData: PropTypes.object,
};

export default QuestionnaireCategory;
