import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { AMENDMENT_CARD_TYPE } from '../../utils/AmendmentUtil';
import { formatDateMonthsAndFullYear } from '../../utils/DateUtil';
import I18n from '../../../i18n/utils';
import './AmendmentBadges.scss';

function AmendmentBadges({
  cardType,
  round,
  requestStatus,
  statusTimestamp,
  findingsBadge,
  addedFinding,
  isAdminRequested,
  isBulkAmendment,
  amendmentType,
}) {
  if (findingsBadge) {
    return (
      <div data-testid="amendment-badges-findings-badge-container">
        <Badge
          className="amendment-badges__tag amendment-badges__findings"
          data-testid="amendment-badges-findings-badge"
        >
          {addedFinding ? 'ADD FINDING' : 'REMOVED FINDING'}
        </Badge>
      </div>
    );
  }

  const getBadgeLabel = () => {
    if (amendmentType === 'MODIFICATION') {
      return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_MODIFIED')}`;
    }
    if (
      isAdminRequested &&
      isBulkAmendment &&
      cardType !== AMENDMENT_CARD_TYPE.OPERATOR
    ) {
      return 'BULK REMOVED';
    }
    if (isAdminRequested) {
      return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REMOVED')}`;
    }
    if (requestStatus === 'DENIED') {
      return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_UPHELD')}`;
    }
    if (requestStatus === 'PENDING') {
      return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_IN_REVIEW')}`;
    }
    return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_APPROVED')}`;
  };

  return (
    <div
      className="amendment-badges__container"
      data-testid="amendment-badges-container"
    >
      <Badge
        className="amendment-badges__tag"
        data-testid="amendment-badges-round-badge"
      >
        {round}
      </Badge>
      {cardType !== AMENDMENT_CARD_TYPE.PENDING && (
        <Badge
          className={`amendment-badges__tag amendment-badges__${requestStatus.toLowerCase()}`}
          data-testid="amendment-badges-status-badge"
        >
          {getBadgeLabel()}
          {statusTimestamp &&
            requestStatus !== 'PENDING' &&
            ` - ${formatDateMonthsAndFullYear(statusTimestamp)}`}
        </Badge>
      )}
    </div>
  );
}

AmendmentBadges.propTypes = {
  cardType: PropTypes.string,
  round: PropTypes.string,
  requestStatus: PropTypes.string,
  statusTimestamp: PropTypes.string,
  findingsBadge: PropTypes.bool,
  addedFinding: PropTypes.bool,
  isAdminRequested: PropTypes.bool,
  isBulkAmendment: PropTypes.bool,
  amendmentType: PropTypes.string,
};

export default AmendmentBadges;
