import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  LoadingIndicator,
  Typography,
} from 'cfa-react-components';
import dayjs from 'dayjs';
import I18n from '../../../../../i18n/utils';
import { API_GET, formatDateMonthsAndFullYear } from '../../../../utils';
import { INFORMATIONAL_RISK_SHORT } from '../../../../constants/constants';
import { FoodSafetyLabel } from '../../../../components/common/FoodSafetyLabel/FoodSafetyLabel';
import './PastFindings.scss';
import { flattenAnswers } from '../helpers';

const domain = process.env.REACT_APP_SERVER_URL;

const PastFindings = ({ findingId = '', findingDate }) => {
  const { assessmentId } = useParams();

  const [pastFindings, setPastFindings] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        setIsFetching(true);
        const params = findingDate
          ? `?sinceDate=${dayjs(findingDate)
              .subtract(3, 'month')
              .format('YYYY-MM-DD')}`
          : '';
        const path = `${domain}/assessment/response/${findingId}/history${params}`;
        const { data } = await API_GET(path);

        if (isMounted) {
          setPastFindings(
            data.map((f) => ({
              ...f,
              responseData: {
                ...f?.responseData,
                flattenAnswers: flattenAnswers(f?.responseData),
              },
            })),
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) setIsFetching(false);
      }
    };
    getData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId]);
  return (
    <Card className="past-findings">
      <CardContent>
        <Typography variant="h3" gutterBottom>
          {`${I18n.t('APP_PAST_FINDINGS_PAST')} ${I18n.t(
            'APP_LVR_FINDING_DETAILS_RESPONSES_LABEL',
          )}`}
        </Typography>
        {isFetching ? (
          <LoadingIndicator variant="page" />
        ) : pastFindings.length === 0 ? (
          <Typography
            disabled
            variant="h3"
            gutterBottom
            className="past-findings__no-data-msg"
          >
            {I18n.t('APP_PAST_FINDINGS_NO_DATA')}
          </Typography>
        ) : (
          <div>
            <div className="past-findings__label-container">
              <Typography gutterBottom className="past-findings__label">
                {I18n.t('APP_REPORT_DASHBOARD_FINDING_CARD_WHEN_LABEL')}
              </Typography>
              <Typography gutterBottom className="past-findings__label">
                {I18n.t('APP_PAST_FINDINGS_RESPONSE')}
              </Typography>
            </div>
            <div>
              {pastFindings.map((pastFinding) => (
                <div
                  key={pastFinding.id}
                  className="past-findings__values-container"
                >
                  <div className="past-findings__badge-container">
                    <FoodSafetyLabel
                      title={formatDateMonthsAndFullYear(
                        pastFinding.createdTimestamp,
                      )}
                      type={INFORMATIONAL_RISK_SHORT}
                      margin
                    />
                  </div>
                  {pastFinding?.responseData?.flattenAnswers?.length > 0 && (
                    <ul>
                      {pastFinding?.responseData?.flattenAnswers?.map(
                        (answer, i) => (
                          <li key={i}>{answer}</li>
                        ),
                      )}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PastFindings;
