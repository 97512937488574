import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography } from 'cfa-react-components';
import { API_GET } from '../../../../../utils/api/API';
import QIVChart from '../../../../../components/charts/QIVChart/QIVChart';
import MissingDataToolTip from '../../../../../components/common/MissingDataToolTip/MissingDataToolTip';

const compareRound = (pointer = '', current = '') => {
  // Split each element into [quarter, year]
  const [quarterA, yearA] = pointer.split('-');
  const [quarterB, yearB] = current.split('-');

  // Compare years first
  if (yearA !== yearB) {
    return yearB - yearA;
  }

  // If years are the same, compare quarters
  return quarterB.localeCompare(quarterA);
};

const QIVCard = ({ round }) => {
  const { locationNumber } = useParams();
  const [isMissingData, setIsMissingData] = useState(false);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!locationNumber || !round) return;

    const path = `${process.env.REACT_APP_SERVER_URL}/qiv/historical-data/${locationNumber}?amount=100`;
    const getData = async () => {
      try {
        setIsMissingData(false);
        setChartDataLoading(true);
        const { data } = await API_GET(path);

        const assessmentList = data.assessments;

        // Find the index of the specified round
        let startIndex = assessmentList.findIndex(
          (item) => item.round === round,
        );

        // missing assessment condition
        if (startIndex === -1) {
          for (let i = 0; i < assessmentList.length; i++) {
            const current = assessmentList[i];
            if (compareRound(round, current.round) === -1) {
              // update pointer
              startIndex = i;
              // insert missing assessment
              assessmentList.splice(i, 0, {
                round,
                overallScore: null,
              });
              setIsMissingData(true);
              break;
            }
          }
        }

        // Filter the array from the found index (or use an empty array if not found)
        // Then map and round overallScore for each filtered assessment
        const processedData = assessmentList
          .slice(startIndex)
          .map((item) => ({
            x: item.round,
            y: item.overallScore
              ? Math.round(item.overallScore * 10) / 10
              : null,
          }))
          .slice(0, 5)
          .reverse();
        setChartData(processedData);
      } catch (error) {
        console.error(error);
      } finally {
        setChartDataLoading(false);
      }
    };
    getData();
  }, [locationNumber, round]);

  return (
    <Card data-testid="qiv-card-card">
      <CardContent data-testid="qiv-card-card-content">
        <Typography data-testid="qiv-card-title" variant="h4" gutterBottom>
          Quality Improvement Visit Historical Data
        </Typography>
        <div>
          <QIVChart
            data-testid="qiv-card-qiv-chart"
            chartData={chartData}
            isLoading={chartDataLoading}
          />
          {isMissingData && <MissingDataToolTip />}
        </div>
      </CardContent>
    </Card>
  );
};

export default QIVCard;
