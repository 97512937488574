import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import cloneDeep from 'lodash/cloneDeep';
import { Typography } from 'cfa-react-components';
import I18n from '../../../i18n/utils';

import './PerformanceHistoryChart.scss';
import PerformanceLevelLegend from '../common/PerformanceLevel/PerformanceLevelLegend';
import { customSymbol } from '../common/PerformanceLevel/CustomPointSymbol';
import { baseTheme } from '../../constants/constants';

const theme = cloneDeep(baseTheme);

export default function PerformanceHistoryChart({
  performaceHistory,
  isMobileView,
}) {
  const historyRecordData = performaceHistory.performanceHistory
    .filter((histoyRecord) => histoyRecord.performanceLevel)
    .map((record) => ({
      x: quarterRoundFormatting(record.round),
      y: record.performanceLevel,
    }))
    .splice(-5);

  const performanceHistoryData = [
    {
      id: '0',
      // Passing null as a parameter to generate "empty" data point (row / x value) to match UI desing.
      data: [{ x: null, y: null }],
    },
    {
      id: '1',
      data: [...historyRecordData],
    },
    {
      id: '2',
      // TODO: Find a better solution to generate "empty" data point (row / x value) to match UI desing.
      data: [{ x: undefined, y: null }],
    },
  ];

  return performanceHistoryData[1].data.length >= 2 ? (
    <div
      className="performance-level-graph__container"
      data-testid="performance-history-chart-container"
    >
      <Typography
        className="performance-level-graph__left-axis-title"
        data-testid="performance-history-chart-left-axis-title"
        fontWeight="bold"
      >
        {I18n.t('APP_REPORT_DASHBOARD_PERFORMANCE_LEVEL_LABEL')}
      </Typography>

      <PerformanceLevelLegend data-testid="performance-history-chart-performance-level-legend" />

      <div
        id="performance-history-chart"
        className="chart-container"
        data-testid="performance-history-chart-chart-container"
      >
        <ResponsiveLine
          data-testid="performance-history-chart-responsive-line"
          type="linear"
          curve="linear"
          data={performanceHistoryData}
          pointSize={12}
          margin={{
            top: 18,
            right: 25,
            bottom: 50,
            left: 50,
          }}
          yScale={{
            type: 'linear',
            stacked: false,
            min: 10,
            max: 0,
          }}
          ticksValues
          padding={10.6}
          axisLeft={null}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: (axisBottomValue) => axisBottomValue ?? '',
            orient: 'bottom',
            tickSize: isMobileView ? 0 : 3,
            tickPadding: isMobileView ? 5 : 15,
            tickRotation: isMobileView ? -53 : 0,
            legendOffset: 36,
            legendPosition: 'middle',
            pointBorderWidth: 0,
          }}
          animate
          enablePointLabel={false}
          colors={[
            '#FFFFFF',
            '#003E59',
            '#3EB1C8',
            '#006B9A',
            '#994878',
            '#5D3754',
          ]}
          pointBorderColor={{
            from: 'color',
          }}
          pointSymbol={(value) => {
            return customSymbol(value);
          }}
          pointBorderWidth={0}
          enableArea={false}
          useMesh={false}
          enableGridY
          enableGridX
          theme={theme}
        />
      </div>
    </div>
  ) : (
    <h4
      className="coming-soon"
      data-testid="performance-history-chart-comingSoon"
    >
      -- {I18n.t('APP_COMING_SOON')} --
    </h4>
  );
}

PerformanceHistoryChart.prototype = {
  isMobileView: PropTypes.bool,
};

function quarterRoundFormatting(str) {
  return `${str.substring(0, 3)}${str.substring(str.length - 2)}`;
}
