import {
  GET_VENDOR_TRACING,
  GET_VENDOR_TRACING_SUCCESS,
  GET_VENDOR_TRACING_FAILURE,
  //
  GET_VENDOR_TRACING_ITEM,
  GET_VENDOR_TRACING_ITEM_SUCCESS,
  GET_VENDOR_TRACING_ITEM_FAILURE,
} from '../actions';

const defaultArrayValue = [];

const initialState = {
  loading: false,
  vendorTracings: defaultArrayValue,
  selectedVendorTracingItem: null,
  error: null,
};

const doActions = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDOR_TRACING:
      return { ...state, loading: true, error: null };
    case GET_VENDOR_TRACING_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorTracings: action.payload,
        error: null,
      };
    case GET_VENDOR_TRACING_FAILURE:
      return {
        ...state,
        loading: false,
        vendorTracings: defaultArrayValue,
        error: action.payload,
      };
    //
    case GET_VENDOR_TRACING_ITEM:
      return { ...state, loading: true, error: null };
    case GET_VENDOR_TRACING_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedVendorTracingItem: action.payload,
        error: null,
      };
    case GET_VENDOR_TRACING_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        selectedVendorTracingItem: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default doActions;
