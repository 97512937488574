import { Card, CardContent, Typography } from 'cfa-react-components';
import { FoodSafetyLabel } from '../../../../components/common/FoodSafetyLabel/FoodSafetyLabel';
import I18n from '../../../../../i18n/utils';
import './SelectedFinding.scss';

const SelectedFinding = ({ finding = {}, round = '' }) => {
  const response = finding?.responseData?.response;
  const categoryTitle =
    finding.questionSupplementaryData.category.toUpperCase();
  const orderChannelTitle =
    finding.questionSupplementaryData.order_channel &&
    finding.questionSupplementaryData.order_channel.toUpperCase();
  const complianceStatus = finding?.complianceStatus;
  const issueTextList = finding?.supplementaryData?.pre_defined_comment || [];
  const photos = finding?.responseData?.attachmentS3Urls || [];
  const hasPhotos = photos.length > 0;

  return (
    <div className="selected-finding">
      <Typography
        variant="h3"
        gutterBottom
        className="selected-finding__finding-details-label"
      >
        {`${I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_CARD_TITLE')}`}
      </Typography>
      <Typography variant="h2" gutterBottom>
        {`${finding.questionId}: ${finding.questionText}`}
      </Typography>
      <Card className="selected-finding__content">
        <CardContent className="selected-finding__card-content">
          <Typography fontWeight="bold" variant="h3" gutterBottom>
            {`${I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_CARD_TITLE')}`}
          </Typography>
          <div className="selected-finding__card-container">
            <div className="selected-finding__badge-container">
              <Typography
                fontWeight="bold"
                className="selected-finding__badge-label"
              >
                {I18n.t('APP_REPORT_DASHBOARD_FINDING_CARD_WHEN_LABEL')}
              </Typography>
              <FoodSafetyLabel title={round} type="round-smart-shop" margin />
            </div>
            <div className="selected-finding__badge-container">
              <Typography
                fontWeight="bold"
                className="selected-finding__badge-label"
              >
                {I18n.t('APP_SMART_SHOP_SALES_CHANNEL')}
              </Typography>
              <FoodSafetyLabel
                title={orderChannelTitle || 'n/a'}
                type="order-channel-smart-shop"
                margin
              />
            </div>
            <div className="selected-finding__badge-container">
              <Typography
                fontWeight="bold"
                className="selected-finding__badge-label"
              >
                {I18n.t('APP_REPORT_DASHBOARD_FILTERS_CATEGORY')}
              </Typography>
              <FoodSafetyLabel
                title={categoryTitle}
                type={finding.categoryBadge}
                margin
              />
            </div>
            <div className="selected-finding__badge-container">
              <Typography
                fontWeight="bold"
                className="selected-finding__badge-label"
              >
                {I18n.t('APP_LVR_FINDING_DETAILS_COMPLIANCE_LABEL')}
              </Typography>
              <FoodSafetyLabel
                title={complianceStatus}
                type={finding.complianceBadge}
                margin
              />
            </div>
          </div>
          <div className="selected-finding__answer-container">
            <div>
              <Typography gutterBottom fontWeight="bold" variant="h4">
                {I18n.t('APP_TOUCHPOINT')}
              </Typography>
              <Typography>
                {finding.questionSupplementaryData.touchpoint}
              </Typography>
            </div>
            <div className="selected-finding__response-container">
              <Typography gutterBottom fontWeight="bold" variant="h4">
                {I18n.t('APP_PAST_FINDINGS_RESPONSE')}
              </Typography>
              <Typography>{response}</Typography>
            </div>
          </div>
          {hasPhotos && (
            <div className="selected-finding__photo-container">
              <Typography gutterBottom fontWeight="bold" variant="h4">
                {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_PHOTO')}
              </Typography>
              <div>
                {photos.map((imageUrl = '', i) => (
                  <img
                    src={imageUrl}
                    key={i}
                    alt="attachment-url"
                    className="selected-finding__image"
                  />
                ))}
              </div>
            </div>
          )}
          {issueTextList.length > 0 && (
            <div className="selected-finding__issue-text-container">
              <Typography gutterBottom fontWeight="bold" variant="h4">
                {I18n.t('APP_ISSUE_TEXT')}
              </Typography>
              <ul className="selected-finding__issue-text-list">
                {issueTextList.map((issue, i) => (
                  <li key={i}>{issue}</li>
                ))}
              </ul>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SelectedFinding;
