import { Card, CardContent, Typography } from 'cfa-react-components';
import { useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import FAASChart from '../../../../../components/charts/FAASChart/FAASChart';
import { API_GET, formatDateMonthsAndFullYear } from '../../../../../utils';
import LvrWidgetTitle from '../../LicenseeVisitReportWidgetTitle';

const parseMins = (mins = '00:00') => {
  if (!mins.includes(':')) return parseInt(mins, 10);
  const nums = mins.split(':');
  const roundedNumber = (
    parseInt(nums[0], 10) +
    parseInt(nums[1], 10) / 60
  ).toFixed(2);
  return parseFloat(roundedNumber);
};

const formatChartData = (assessments = []) => {
  return assessments
    .reverse()
    .map(
      ({
        assessmentDate = '',
        waitTimeForFood = '00:00',
        waitTimeForService = '00:00',
      }) => {
        const date = new Date(assessmentDate);
        const formatedDate = formatDateMonthsAndFullYear(date);
        const time = dayjs(date).format('h:mm A');
        return {
          waitTimeForFood,
          waitTimeForService,
          'In line': parseMins(waitTimeForService),
          'To receive food': parseMins(waitTimeForFood),
          assessmentDate: `${time} ${formatedDate}`,
        };
      },
    );
};

const FAASCard = ({ assessmentId = null, numberOfAssessmentsToShow = 6 }) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!assessmentId) {
      setIsLoading(false);
      return;
    }
    let isMounted = true;
    setIsLoading(true);
    setChartData([]);
    const fetchServiceData = async () => {
      try {
        const domain = process.env.REACT_APP_SERVER_URL;
        const params = `numberOfAssessmentsToShow=${numberOfAssessmentsToShow}`;
        const path = `${domain}/assessment/lvr/fast-accurate-service/${assessmentId}?${params}`;
        const { data = [] } = await API_GET(path);
        if (isMounted) {
          setChartData(formatChartData(data));
        }
      } catch (e) {
        console.error('FAAS chart - error fetching data:', e.message);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    fetchServiceData();
    return () => {
      isMounted = false;
    };
  }, [assessmentId, numberOfAssessmentsToShow]);

  return (
    <Card data-testid="faas-card-container" style={{ overflow: 'visible' }}>
      <CardContent data-testid="faas-card-content">
        <LvrWidgetTitle
          title="Fast & Accurate Service"
          data-testid="faas-card-label"
        />
        <FAASChart
          data-testid="faas-card-faas-chart"
          isLoading={isLoading}
          chartData={chartData}
        />
      </CardContent>
    </Card>
  );
};

export default FAASCard;
