import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Icon, Tooltip, Typography } from 'cfa-react-components';
import { InfoOutline } from '@cfa-icons/system';
import {
  initializeResponse,
  updateResponseData,
} from '../../utils/questionsUtils';
import ImageUploadModal from './ImageUploadModal';
import FreeResponseTextarea from './FreeResponseTextarea';
import { actions, useLVRDispatch, useLVRStore } from '../../store/reducers';
import {
  SPECIAL_VALIDATION_QUESTIONS,
  NON_REQUIRED_SPECIAL_VALIDATION_QUESTIONS,
  validateSpecialQuestionResponse,
} from '../../utils/validationUtils';

import '../MultipleChoice/MultipleChoiceQuestion.scss';

const FreeResponseQuestion = ({ question }) => {
  const { internalId, responseUniqueIdentifier, questionData } = question;
  const { questionText, toolTip } = questionData;

  const lvrStore = useLVRStore();
  const lvrDispatch = useLVRDispatch();

  const existingResponse = useMemo(
    () => lvrStore.responses?.[responseUniqueIdentifier]?.responseData,
    [lvrStore.responses, responseUniqueIdentifier],
  );

  const [errorText, setErrorText] = useState('');

  const isNumericQuestion =
    question.questionData.unitType.toLowerCase() === 'numeric' ||
    question.questionData.unitType.toLowerCase() === 'integer';

  const validateResponse = (value) => {
    const response =
      value ||
      lvrStore.responses?.[responseUniqueIdentifier]?.responseData?.response;

    if (
      !question?.questionAttributes?.includes('REQUIRED') &&
      !NON_REQUIRED_SPECIAL_VALIDATION_QUESTIONS.includes(internalId)
    ) {
      lvrDispatch({
        type: actions.updateResponseValidationStatuses,
        data: { [responseUniqueIdentifier]: true },
      });
      setErrorText('');
      return;
    }

    if (question?.questionAttributes?.includes('REQUIRED')) {
      if (!response) {
        lvrDispatch({
          type: actions.updateResponseValidationStatuses,
          data: { [responseUniqueIdentifier]: false },
        });

        setErrorText(
          `Response cannot be blank.${
            isNumericQuestion ? ' Response may only include numbers.' : ''
          }`,
        );
        return;
      }
    }

    if (response && /^[\s\r\n]*$/.test(response)) {
      lvrDispatch({
        type: actions.updateResponseValidationStatuses,
        data: { [responseUniqueIdentifier]: false },
      });

      setErrorText(
        `Response cannot be blank.${
          isNumericQuestion ? ' Response may only include numbers.' : ''
        }`,
      );
      return;
    }

    const isSpecialValidationQuestion =
      SPECIAL_VALIDATION_QUESTIONS.includes(internalId);

    if (isSpecialValidationQuestion || isNumericQuestion) {
      const { isValid = true, errorMessage } = validateSpecialQuestionResponse(
        internalId,
        response,
      );

      if (!isValid) {
        lvrDispatch({
          type: actions.updateResponseValidationStatuses,
          data: { [responseUniqueIdentifier]: false },
        });

        setErrorText(errorMessage);
        return;
      }
    }

    lvrDispatch({
      type: actions.updateResponseValidationStatuses,
      data: { [responseUniqueIdentifier]: true },
    });

    setErrorText('');
  };

  const putResponseValue = (value) => {
    if (value === undefined) return;

    let responseValue = value;
    if (isNumericQuestion) responseValue = value.trim();

    updateResponseData({
      store: lvrStore,
      dispatch: lvrDispatch,
      type: 'text',
      question,
      data: responseValue,
    });

    validateResponse(responseValue);
  };

  useEffect(() => {
    // Break out if they've not attempted submission yet for a happier form.
    if (lvrStore.submissionFailed) {
      validateResponse();
    }
  }, [question?.questionAttributes, lvrStore.submissionFailed]);

  useEffect(() => {
    if (
      existingResponse?.response ||
      existingResponse?.attachmentS3Urls.length
    ) {
      return;
    }
    initializeResponse({ dispatch: lvrDispatch, question });
  }, []);

  return (
    <div
      className="multiple-choice-question"
      data-testid="free-response-question-container"
    >
      <Typography
        className="multiple-choice-question__text"
        data-testid="free-response-question-text"
        fontWeight="medium"
        color="default"
        variant="body1"
      >
        {questionText}
        {toolTip && (
          <Tooltip
            data-testid="free-response-question-tooltip"
            content={toolTip}
            showOnElementEvents={['hover']}
          >
            <span
              className="multiple-choice-question__tooltip"
              data-testid="free-response-question-tooltip-span"
            >
              <Icon
                data-testid="free-response-question-tooltip-icon"
                icon={InfoOutline}
              />
            </span>
          </Tooltip>
        )}
      </Typography>
      <FreeResponseTextarea
        data-testid="free-response-question-free-response-textarea"
        value={existingResponse?.response || ''}
        helperText={question?.questionData?.unitName}
        errorText={errorText}
        onChange={(e) => putResponseValue(e.target.value)}
      />
      {question?.questionAttributes?.includes('ATTACHMENTS') && (
        <ImageUploadModal
          data-testid="free-response-question-image-upload-modal"
          question={question}
        />
      )}
    </div>
  );
};

FreeResponseQuestion.propTypes = {
  question: PropTypes.object,
};

export default FreeResponseQuestion;
