import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip, Icon } from 'cfa-react-components';
import { InfoOutline } from '@cfa-icons/system';
import Radio from './Radio/RadioComponent';
import Checkbox from './Checkbox/CheckboxComponent';
import Dropdown from './Dropdown/DropdownComponent';
import Multiselect from './Multiselect/MultiselectComponent';
import SubQuestionContainers from '../SubQuestionContainer';

import './MultipleChoiceQuestion.scss';

/**
 * QuestionContainer is the top-level root of the dynamic Assessments Question pattern.
 * It will display an appropriate question type based on the given input props.
 */
const MultipleChoiceQuestionContainer = ({ question }) => {
  const { style, questionText, toolTip, choices } =
    question?.questionData || {};

  const isCheckboxType = ['Checkbox', 'ModernBoxes'].includes(style);

  const checkBoxComponent = useMemo(
    () => (isCheckboxType && choices?.length > 5 ? Multiselect : Checkbox),
    [isCheckboxType, choices],
  );

  const Questions = useMemo(() => {
    const questionTypeComponents = {
      Checkbox: checkBoxComponent,
      ModernBoxes: checkBoxComponent,
      Radio,
      Dropdown,
    };
    const QuestionToRender = questionTypeComponents[style];

    return (
      <>
        <QuestionToRender question={question} />
        <SubQuestionContainers question={question} />
      </>
    );
  }, [checkBoxComponent, question, style]);

  return (
    <div className="multiple-choice-question">
      <Typography
        className="multiple-choice-question__text"
        fontWeight="medium"
        color="default"
        gutterBottom={!['ModernBoxes', 'Checkbox', 'Dropdown'].includes(style)}
        variant="body1"
      >
        {questionText}
        {toolTip && (
          <Tooltip content={toolTip} showOnElementEvents={['hover']}>
            <span className="multiple-choice-question__tooltip">
              <Icon icon={InfoOutline} />
            </span>
          </Tooltip>
        )}
      </Typography>
      {Questions}
    </div>
  );
};

MultipleChoiceQuestionContainer.propTypes = {
  question: PropTypes.object,
};

export default MultipleChoiceQuestionContainer;
