import { useSelector } from 'react-redux';
import FoodSafetyReport from './FoodSafetyReport/FoodSafetyReport';
import AllFindings from './AllFindings/AllFindings';
import RequestedAppeals from './RequestedAppeals/RequestedAppeals';
import TabLayout from '../../components/Layout/TabLayout/TabLayout';
import I18n from '../../../i18n/utils';

const FOOD_SAFETY_REPORTS_TABS = {
  FOODSAFETYREPORT: {
    label: 'Food Safety Report',
    basePathBuilder: (path) => path,
    component: (
      <FoodSafetyReport data-testid="report-dashboard-food-safety-report-tab" />
    ),
  },
  ALLFINDINGS: {
    label: 'All Findings',
    basePathBuilder: (path) => `${path}/findings`,
    component: <AllFindings data-testid="report-dashboard-all-findings-tab" />,
    optPathParam: 'id', // optional path param to append to the tab's base path
  },
  REQUESTEDAPPEALS: {
    label: 'Requested Appeals',
    basePathBuilder: (path) => `${path}/appeals`,
    component: (
      <RequestedAppeals data-testid="report-dashboard-reqested-appeals-tab" />
    ),
  },
};

const ReportDashboard = () => {
  const selectedAudit = useSelector((state) => state.audit.selectedAudit);

  return (
    <TabLayout
      title={I18n.t('APP_LANDING_PAGE_FOOD_SAFETY_CARD')}
      content={FOOD_SAFETY_REPORTS_TABS}
      baseUrl="/report-dashboard"
      disabled={!selectedAudit}
      data-testid="report-dashboard-tab-layout"
    />
  );
};

export default ReportDashboard;
