import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent } from 'cfa-react-components';
import { API_GET, formatReverseDate } from '../../../../../utils';
import MonthCalendar from '../../../../../components/charts/MonthCalendar/MonthCalendar';
import './ERQACard.scss';

const ERQACard = ({ completedColor, incompleteColor }) => {
  const { locationNumber } = useParams();
  const [apiData, setApiData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // Flag to track mount status

    const fetchAssessmentCalendarData = async () => {
      const path = `${process.env.REACT_APP_SERVER_URL}/assessment/self-assessment?program=ERQA&locationNumber=${locationNumber}`;
      try {
        const { data } = await API_GET(path);
        const dataMap = data.reduce((map, assessment) => {
          const auditDate = assessment.localizedAuditStartDate
            ? assessment.localizedAuditStartDate
            : assessment.oldAuditStartDate;
          let dateKey;

          if (auditDate.length > 10) {
            // Date includes time
            const date = new Date(auditDate);
            dateKey = formatReverseDate(date);
          } else {
            // Date without time
            dateKey = auditDate;
          }
          if (!map[dateKey]) {
            map[dateKey] = [];
          }
          map[dateKey].push(assessment);
          return map;
        }, {});

        if (isMounted) {
          setApiData(dataMap);
        }
      } catch (e) {
        console.error('Fetching error:', e.message);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchAssessmentCalendarData();

    return () => {
      isMounted = false; // Set flag to false when component unmounts
    };
  }, [locationNumber]);

  return (
    <Card className="erqa-card" data-testid="erqa-card-erqa-card">
      <CardContent
        className="erqa-card-content"
        data-testid="erqa-card-erqa-card-content"
      >
        <Typography data-testid="erqa-card-title" align="left" variant="h4">
          eRQA
        </Typography>
        <Typography
          data-testid="erqa-card-description"
          align="left"
          variant="caption1"
        >
          eRQA for the previous 4 weeks. Reminder - if the location is closed
          and an eRQA is not completed, it will still show as missing on this
          report.
        </Typography>
        <MonthCalendar
          data-testid="erqa-card-month-calendar"
          completedColor={completedColor}
          incompleteColor={incompleteColor}
          isLoading={isLoading}
          apiData={apiData}
        />
      </CardContent>
    </Card>
  );
};
// Define default props
ERQACard.defaultProps = {
  completedColor: '#3EB1C8', // default color for completedColor
  incompleteColor: '#5B6770', // default color for incompleteColor
};

// Define prop types
ERQACard.propTypes = {
  completedColor: PropTypes.string, // completedColor is an optional string
  incompleteColor: PropTypes.string, // incompleteColor is an optional string
};
export default ERQACard;
