import { Typography } from 'cfa-react-components';

const renderPhotos = (findings = {}) => {
  let hasPhotos = false;
  const photos = findings.map(({ parentFinding, childrenFindings }) => {
    if (parentFinding?.responseData?.attachmentS3Urls?.length > 0) {
      hasPhotos = true;
    }
    return (
      <>
        {parentFinding?.responseData?.attachmentS3Urls?.map((imageUrl = '') => (
          <img
            src={imageUrl}
            key={parentFinding.id}
            alt="attachment-url"
            style={{ maxHeight: '30rem', maxWidth: '45rem' }}
          />
        ))}
        {childrenFindings.length > 0 && renderPhotos(childrenFindings)}
      </>
    );
  });

  return hasPhotos && photos;
};

const FindingsPhotos = ({ finding = {}, childrenFindings = [] }) => {
  const hasPhotos = finding?.responseData?.attachmentS3Urls?.length > 0;
  const additionalPhotos = renderPhotos(childrenFindings);
  return (
    (hasPhotos || additionalPhotos) && (
      <div
        key={`${finding.id}-photos-container`}
        className="selected-finding__answer-container"
      >
        <Typography
          key={`${finding.id}-photo-title`}
          className="selected-finding__badge-label"
        >
          Photo
        </Typography>
        {finding?.responseData?.attachmentS3Urls?.map((imageUrl = '') => (
          <img
            src={imageUrl}
            key={finding.id}
            alt="attachment-url"
            style={{ maxHeight: '30rem' }}
          />
        ))}
        {additionalPhotos}
      </div>
    )
  );
};

export default FindingsPhotos;
