export const IMAGE_FILE_TYPES = [
  '.png',
  '.jpeg',
  '.jpg',
  '.bmp',
  '.tiff',
  '.wmf',
  '.gif',
  '.heic',
];

export const DOCUMENT_FILE_TYPES = [
  '.png',
  '.jpeg',
  '.jpg',
  '.pdf',
  '.doc',
  '.docx',
  '.eml',
  '.heic',
  '.heif',
  '.heifs',
  '.heics',
];
