import PropTypes from 'prop-types';
import { Row, Col, Label, Input } from 'reactstrap';
import I18n from '../../../i18n/utils';
import './QuestionsFilter.scss';
import {
  IMMEDIATE_RISK_LONG,
  HIGH_RISK_LONG,
  MEDIUM_RISK_LONG,
  LOW_RISK_LONG,
  INFORMATIONAL_RISK_LONG,
  //
  CLEANING_SANITATION,
  CROSS_CONTAMINATION,
  HEALTH_HYGIENE,
  PESTS,
  TIME_TEMPERATURE,
} from '../../constants/constants';

const QuestionsFilter = ({ updateFilterLabels }) => {
  const triggerQuestionFilter = (label) => {
    return () => updateFilterLabels(label);
  };

  return (
    <Col
      className="QuestionsFilter container"
      data-testid="questions-filter-container"
    >
      <Row className="filter-list" data-testid="questions-filter-filter-list">
        <Col
          className="container"
          data-testid="questions-filter-filter-list-col-container"
        >
          <h2
            className="sub-header filter-by"
            data-testid="questions-filter-sub-header-filter-by-risk-level"
          >
            {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_FILTER_RISK_LEVEL')}
          </h2>
          <div
            className="riskFilters"
            data-testid="questions-filter-risk-filters"
          >
            <Row data-testid="questions-filter-risk-level-immediate-row">
              <Label
                data-testid="questions-filter-risk-level-immediate-label"
                check
              >
                <Input
                  data-testid="questions-filter-risk-level-immediate-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(IMMEDIATE_RISK_LONG)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_IMMEDIATE')}
              </Label>
            </Row>
            <Row data-testid="questions-filter-risk-level-high-row">
              <Label data-testid="questions-filter-risk-level-high-label" check>
                <Input
                  data-testid="questions-filter-risk-level-high-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(HIGH_RISK_LONG)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_HIGH')}
              </Label>
            </Row>
            <Row data-testid="questions-filter-risk-level-medium-row">
              <Label
                data-testid="questions-filter-risk-level-medium-label"
                check
              >
                <Input
                  data-testid="questions-filter-risk-level-medium-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(MEDIUM_RISK_LONG)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_MEDIUM')}
              </Label>
            </Row>
            <Row data-testid="questions-filter-risk-level-low-row">
              <Label data-testid="questions-filter-risk-level-low-label" check>
                <Input
                  data-testid="questions-filter-risk-level-low-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(LOW_RISK_LONG)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_LOW')}
              </Label>
            </Row>
            <Row data-testid="questions-filter-risk-level-informational-row">
              <Label
                data-testid="questions-filter-risk-level-informational-label"
                check
              >
                <Input
                  data-testid="questions-filter-risk-level-informational-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(INFORMATIONAL_RISK_LONG)}
                />
                {I18n.t(
                  'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_INFORMATIONAL',
                )}
              </Label>
            </Row>
          </div>
          <h2
            className="sub-header filter-by"
            data-testid="questions-filter-sub-header-filter-by-category"
          >
            {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_FILTER_CATEGORY')}
          </h2>
          <div
            className="categoryFilters"
            data-testid="questions-filter-category-filters"
          >
            <Row data-testid="questions-filter-cleaning-sanitation-row">
              <Label
                data-testid="questions-filter-cleaning-sanitation-label"
                check
              >
                <Input
                  data-testid="questions-filter-cleaning-sanitation-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(CLEANING_SANITATION)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_CLEANING_SANITATION')}
              </Label>
            </Row>
            <Row data-testid="questions-filter-cross-contamination-row">
              <Label
                data-testid="questions-filter-cross-contamination-label"
                check
              >
                <Input
                  data-testid="questions-filter-cross-contamination-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(CROSS_CONTAMINATION)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_CROSS_CONTAMINATION')}
              </Label>
            </Row>
            <Row data-testid="questions-filter-health-hygiene-row">
              <Label data-testid="questions-filter-health-hygiene-label" check>
                <Input
                  data-testid="questions-filter-health-hygiene-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(HEALTH_HYGIENE)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_HEALTH_HYGIENE')}
              </Label>
            </Row>
            <Row data-testid="questions-filter-pests-row">
              <Label data-testid="questions-filter-pests-label" check>
                <Input
                  data-testid="questions-filter-pests-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(PESTS)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_PESTS')}
              </Label>
            </Row>
            <Row data-testid="questions-filter-time-temperature-row">
              <Label
                data-testid="questions-filter-time-temperature-label"
                check
              >
                <Input
                  data-testid="questions-filter-time-temperature-input"
                  type="checkbox"
                  onClick={triggerQuestionFilter(TIME_TEMPERATURE)}
                />
                {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_TIME_TEMPERATURE')}
              </Label>
            </Row>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default QuestionsFilter;

QuestionsFilter.propTypes = {
  updateFilterLabels: PropTypes.func.isRequired,
};
