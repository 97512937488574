import { API_GET } from './api/API';
import { showServerUpStatusNotification } from './Notification';

const checkServerUpStatus = async () => {
  try {
    const response = await API_GET(
      `${process.env.REACT_APP_SERVER_URL}/health-check`,
    );
    if (response?.status !== 200) {
      showServerUpStatusNotification({
        message:
          'Server is temporarily unable to service your request. Please try again later.',
      });
      return false;
    }
    return true;
  } catch (error) {
    showServerUpStatusNotification({
      message:
        'Connection to API lost or not possible. Check your connection and try again.',
    });
    console.error('error', error);
    return false;
  }
};

export default checkServerUpStatus;
