import { ResponsiveLine } from '@nivo/line';
import cloneDeep from 'lodash/cloneDeep';
import { baseTheme } from '../../../constants/constants';
import ChartContainer from '../common/ChartContainer';

import './QIVChart.scss';

const theme = cloneDeep(baseTheme);

const percentageFormat = (v) => {
  if (!v) return '';
  return `${v}%`;
};

const QIVChart = ({ chartData, isLoading }) => {
  // find the minimum y value in the chart data
  const minY = chartData.reduce(
    (min, p) => (p.y && p.y < min ? p.y : min),
    chartData[0]?.y,
  );
  // round the minimum y value down to the nearest 10
  const yScaleMin = (Math.floor(minY / 10) * 10).toString();

  return (
    <ChartContainer
      data-testid="qiv-chart-chart-container"
      isLoading={isLoading}
      isEmptyChart={chartData.length === 0}
    >
      <ResponsiveLine
        data-testid="qiv-chart-responsive-line"
        type="linear"
        curve="linear"
        data={[
          { id: '0', data: [{ x: '', y: '' }] },
          { id: '1', data: chartData },
        ]}
        margin={{
          top: 30,
          right: 40,
          bottom: 50,
          left: 80,
        }}
        yScale={{
          type: 'linear',
          min: yScaleMin,
          max: '100',
        }}
        yFormat={percentageFormat}
        enablePointLabel
        pointLabelYOffset={-15}
        lineWidth={2}
        axisLeft={{
          format: percentageFormat,
          tickPadding: 10,
          tickSize: 0,
          tickValues: 5,
          legend: 'Performance Level',
          legendOffset: -65,
          legendPosition: 'middle',
        }}
        axisBottom={{
          tickRotation: window.innerWidth <= 991 ? -40 : 0,
          tickPadding: 10,
          tickFontSize: 10,
          orient: 'bottom',
          legendOffset: 36,
          legendPosition: 'middle',
          tickSize: 0,
        }}
        animate
        colors={['#003E59']}
        theme={theme}
        pointSize={8}
      />
    </ChartContainer>
  );
};

export default QIVChart;
