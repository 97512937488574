import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { Celebrate } from '@cfa-icons/system';
import { Typography, Icon } from 'cfa-react-components';

import './AuditNotAvailable.scss';

const AuditNotAvailable = ({ type }) => (
  <div
    className="no-audits-container"
    data-testid="audit-not-available-no-audits-container"
  >
    <Icon
      icon={Celebrate}
      className="no-audits-icon"
      data-testid="audit-not-available-no-audits-icon"
    />
    <Typography
      variant="h4"
      className="no-audits-title"
      data-testid="audit-not-available-no-audits-title"
    >
      Coming Soon!
    </Typography>
    <Typography
      data-testid="audit-not-available-audit-no-audit-report"
      variant="h4"
      fontWeight="regular"
      color="#5b6770"
    >
      {type === 'report'
        ? 'The performance will be calculated based on at least 2 rounds of audit.'
        : I18n.t('APP_REPORT_DASHBOARD_NO_AUDITS')}
    </Typography>
  </div>
);

AuditNotAvailable.propTypes = {
  type: PropTypes.string,
};

export default AuditNotAvailable;
