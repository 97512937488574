import { useEffect, useState } from 'react';
import { Typography, DatePicker } from 'cfa-react-components';
import { disablePastDates } from '../../../../../utils';
import { useLVRStore, useLVRDispatch } from '../../../store/reducers';
import ErrorText from '../../../Questions/ErrorText';
import { updateSupplementaryData } from '../../../utils/questionsUtils';
import { isValidDate } from '../../../utils/dataUtils';

import '../GeneralInformationForm.scss';

const GeneralinformationDatePicker = () => {
  const lvrDispatch = useLVRDispatch();
  const {
    submissionFailed,
    supplementaryData: { visitDate },
  } = useLVRStore();

  const [value, setValue] = useState(visitDate);
  const [errorText, setErrorText] = useState('');

  // Update local state when store changes
  // so that epoch time is not displayed on load
  useEffect(() => {
    if (visitDate) setValue(visitDate);
  }, [visitDate]);

  const onChange = (date) => {
    updateSupplementaryData({
      dispatch: lvrDispatch,
      data: { visitDate: date },
    });
    setErrorText('');
  };

  useEffect(() => {
    if (submissionFailed && !visitDate) {
      setErrorText('Response cannot be blank.');
    }
  }, [submissionFailed, visitDate]);

  return (
    <>
      <div
        className="lvr-gen-info__input-picker"
        data-testid="general-information-date-picker-container"
      >
        <Typography
          data-testid="general-information-date-picker-required-text"
          color="primary"
          variant="caption2"
        >
          Required
        </Typography>
        <DatePicker
          data-testid="general-information-date-picker-date-picker"
          locale="en-US"
          label="Visit Date"
          value={
            value !== null && isValidDate(new Date(value))
              ? new Date(value)
              : null
          }
          onChange={onChange}
          fullWidth
          disabledDates={disablePastDates}
        />
      </div>
      <ErrorText
        data-testid="general-information-date-picker-error-text"
        error={errorText}
        gutterBottom
      />
    </>
  );
};

export default GeneralinformationDatePicker;
