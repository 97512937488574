import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Drawer,
  IconButton,
  Icon,
} from 'cfa-react-components';
import { ChevronRight, Close } from '@cfa-icons/system';
import { renderFindingColor } from '../../utils/FindingsUtil';

import './ListCard.scss';

const ListCard = ({
  cardId,
  cardContent,
  drawerContent,
  onDrawerOpened,
  onDrawerClosed,
  riskLevel,
  compact,
  ...cardProps
}) => {
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const openDrawer = () => {
    if (drawerContent) {
      setIsOpen(true);
      if (onDrawerOpened) onDrawerOpened();
    }
  };

  const closeDrawer = () => {
    if (onDrawerClosed) onDrawerClosed();
    setIsOpen(false);
  };

  const riskLevelClass = useMemo(
    () => `risk-level-accent__${renderFindingColor(riskLevel)}`,
    [riskLevel],
  );

  useEffect(() => {
    if (params?.id === cardId) setIsOpen(true);
  }, [params, cardId]);

  return (
    <>
      <Card
        onClick={drawerContent && openDrawer}
        style={{ cursor: drawerContent && 'pointer' }}
        className={`list-card ${compact ? 'compact' : ''}`}
        data-testid="list-card-list-card-compact"
        {...cardProps}
      >
        <div
          className={`risk-level-accent ${riskLevel ? riskLevelClass : ''}`}
          data-testid="list-card-risk-level-accent"
        />
        <CardContent
          data-testid="list-card-card-content"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {cardContent}
          {drawerContent && (
            <div
              className="list-card__right-arrow"
              data-testid="list-card-right-arrow"
            >
              <Icon data-testid="list-card-icon" icon={ChevronRight} />
            </div>
          )}
        </CardContent>
      </Card>

      <Drawer
        id={`${cardId}_drawer`}
        data-testid="list-card-drawer"
        size="lg"
        content={
          <>
            <IconButton
              size="xs"
              color="default"
              onClick={closeDrawer}
              aria-label="close"
              className="list-card__close-button"
              data-testid="list-card-close-button"
            >
              <Icon data-testid="list-card-close-button-icon" icon={Close} />
            </IconButton>
            {drawerContent}
          </>
        }
        open={isOpen}
        onClose={closeDrawer}
      />
    </>
  );
};

ListCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardContent: PropTypes.node.isRequired,
  onDrawerOpened: PropTypes.func,
  onDrawerClosed: PropTypes.func,
  compact: PropTypes.bool,
};

export default ListCard;
