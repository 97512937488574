import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from 'cfa-react-components';
import PreviousVisitFindingsChart from '../../../../../components/charts/PreviousVisitFindingsChart/PreviousVisitFindingsChart';
import { API_GET } from '../../../../../utils';
import LvrWidgetTitle from '../../LicenseeVisitReportWidgetTitle';

const PreviousVisitFindingsCard = () => {
  const { locationNumber } = useParams();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!locationNumber) return;

    const path = `${process.env.REACT_APP_SERVER_URL}/assessment/lvr/previous-visits-summary/${locationNumber}`;
    const fetchAssessmentCalendarData = async () => {
      try {
        setIsLoading(true);
        const { data } = await API_GET(path);

        const transformedData = transformAPIData(data);
        setApiData(transformedData);
      } catch (e) {
        console.error('Fetching error:', e.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAssessmentCalendarData();
  }, [locationNumber]);

  const transformAPIData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    const transformedData = data
      .filter((item) => item.round) // Ensure 'round' field exists
      .map((item) => {
        // Convert the date to a more readable format
        const formattedDate = new Date(item.round).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });

        const entry = {
          x: formattedDate,
          y: item.totalFindings || 0,
        };

        // Add categories
        if (
          item.categoryFindingOccurrences &&
          typeof item.categoryFindingOccurrences === 'object'
        ) {
          Object.keys(item.categoryFindingOccurrences).forEach((category) => {
            entry[category] = item.categoryFindingOccurrences[category];
          });
        }

        return entry;
      })
      // Sort by date
      .sort((a, b) => new Date(a.x) - new Date(b.x));

    // Insert a blank data point at the beginning of the array
    const blankDataPoint = { x: '', y: null };
    transformedData.unshift(blankDataPoint);

    return [
      {
        id: 'findings',
        data: transformedData,
      },
    ];
  };

  return (
    <Card
      className="previous-visit-findings-card"
      style={{ overflow: 'visible', height: '100%' }}
    >
      <CardContent className="previous-visit-finding-card-content">
        <LvrWidgetTitle title="Previous Visit Findings" />
        <PreviousVisitFindingsChart apiData={apiData} isLoading={isLoading} />
      </CardContent>
    </Card>
  );
};

export default PreviousVisitFindingsCard;
