import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FindingOccurrence from './FindingOccurrence';
import './FindingOccurrenceContainer.scss';

export default function FindingOccurrenceContainer({
  findingOccurrences,
  handleFindingOccurrence,
}) {
  const [itemIndexObj, setItemIdex] = useState({
    index: null,
    isChecked: false,
  });

  const handleOnChange = (occurrenceObj, itemObj) => {
    return () => {
      setItemIdex({
        index: itemObj.index,
        isChecked: !itemObj.isChecked,
      });
      handleFindingOccurrence(occurrenceObj);
    };
  };

  useEffect(() => {
    setItemIdex({ index: null, isChecked: false });
  }, [findingOccurrences]);

  return (
    <>
      <h5
        className="finding-occurrence__title"
        data-testid="finding-occurrence-container-title"
      >
        Select the Zone, Item, and Issue
      </h5>
      <p
        className="finding-occurrence__sub-title"
        data-testid="finding-occurrence-container-sub-title"
      >
        Choose one option
      </p>

      <div
        className="finding-occurrence__container"
        data-testid="finding-occurrence-container-container"
      >
        {findingOccurrences.map((occurrence, i) => {
          const checkLogic = !!(
            itemIndexObj.index === i && itemIndexObj.isChecked !== false
          );
          return (
            <FindingOccurrence
              data-testid="finding-occurrence-container-finding-occurrence"
              key={occurrence.findingUUID}
              issueDescription={occurrence.details.issue}
              zoneDescription={occurrence.details.zone}
              itemDescription={occurrence.details.item}
              findingUUID={occurrence.findingUUID}
              checked={checkLogic}
              index={i}
              onChange={handleOnChange}
              // Due to change on the form ACs this was added later 2022-05-19
              occurrenceDataObj={occurrence}
            />
          );
        })}
      </div>
    </>
  );
}

FindingOccurrenceContainer.propTypes = {
  findingOccurrences: PropTypes.array.isRequired,
  handleFindingOccurrence: PropTypes.func,
};
