import { MessageBlock } from 'cfa-react-components';
import { Store } from 'react-notifications-component';

export const isWhitespaceString = (str) => !str.replace(/\s/g, '').length;

export const FORMSTEPS = {
  SELECT_FINDING: 'SELECT_FINDING',
  ORG_QUESTION: 'ORG_QUESTION',
  ORG_LINK: 'ORG_LINK',
  QUESTION_AND_APPEAL: 'QUESTION_AND_APPEAL',
  ASK_QUESTION: 'ASK_QUESTION',
  REQUEST_APPEAL: 'REQUEST_APPEAL',
};

export const pushNotification = (
  message = '',
  failed = false,
  isMobile = false,
) =>
  Store.addNotification({
    width: isMobile ? 600 : 0,
    container: 'top-center',
    dismiss: { duration: 7000 },
    content: (
      <MessageBlock
        title={message}
        onClose={() => {}}
        style={{ width: isMobile ? '50rem' : '' }}
        severity={failed ? 'error ' : 'success'}
        data-testid="smart-shop-notification-message"
      />
    ),
  });
