import { Typography } from 'cfa-react-components';

const LvrWidgetTitle = ({
  title,
  marginBottom = '1.5rem',
  paddingLeft = '1rem',
  ...props
}) => {
  return (
    <Typography
      {...props}
      variant="h4"
      gutterBottom
      style={{
        marginTop: '.6rem',
        marginBottom,
        paddingBottom: '.5rem',
        paddingLeft,
      }}
    >
      {title}
    </Typography>
  );
};

export default LvrWidgetTitle;
