import { useEffect, useState, useMemo, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { API_GET } from '../../utils/api/API';
import { checkAcceptableFileTypes, setFormData } from './common/dataUtils';
import Uploader from './components/Uploader';
import { showFailureNotification } from '../../utils/Notification';

import './FileUploader.scss';

const FileUploader = ({
  config,
  initialData,
  setStore,
  isUploading,
  setIsUploading,
  children,
}) => {
  const [s3HeaderData, setS3headerData] = useState({});
  const [fileData, setFileData] = useState({});
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (initialData) setFileList(initialData);
  }, [initialData]);

  useEffect(() => {
    const getS3Configs = async () => {
      try {
        const { data } = await API_GET(config.s3Policy);
        setS3headerData(data);
      } catch (err) {
        console.error('Fail to get S3 configs', err);
      }
    };
    getS3Configs();
  }, [config.s3Policy]);

  useEffect(() => {
    if (fileList.length === 0) return;

    const fileItems = fileList.map((fileItem) => {
      if (fileItem.fileName === fileData.fileName) {
        return {
          ...fileItem,
          isLoading: false,
          isError: fileData.isError,
        };
      }
      return fileItem;
    });

    putResponseValue(fileItems);
  }, [fileData]);

  const putResponseValue = (fileItems) => {
    if (!fileItems) return;

    setFileList(fileItems);

    if (setStore) {
      // filter out failed uploads
      const filePaths = fileItems
        .filter((item) => !item.isError)
        .map((fileItem) => fileItem.filePath);

      setStore(filePaths);
    }
  };

  const submitFiles = useCallback(
    async (file, fileName) => {
      const formData = setFormData({
        key: `${config.s3DirPath}/${fileName}`,
        config: { ...s3HeaderData },
        file,
        fileName,
      });

      try {
        setIsUploading(true);
        const response = await fetch(s3HeaderData.s3TargetUrl, {
          method: 'POST',
          body: formData,
          redirect: 'follow',
        });
        if (`${response.status}`[0] !== '2') {
          setFileData({ fileName, isError: true });
        } else {
          setFileData({ fileName, isSuccess: true });
        }
      } catch (error) {
        setFileData({ fileName, isError: true });
        console.log(' error', error);
      } finally {
        setIsUploading(false);
      }
    },
    [config.s3DirPath, s3HeaderData, setIsUploading],
  );

  const onSelect = useCallback(
    async (e) => {
      e.preventDefault();
      const file = e.target.files;

      // Handle/catch when user close file browser window with out selecting a file
      if (!file[0]?.name) {
        showFailureNotification({
          message: 'File name missing',
          showTitle: false,
        });
        return;
      }

      if (!checkAcceptableFileTypes(file[0].name, config.accept)) {
        showFailureNotification({
          message: 'File type not valid',
          showTitle: false,
        });
        return;
      }

      if (file[0]?.size === 0) {
        showFailureNotification({ message: 'File is empty', showTitle: false });
        return;
      }

      const transformedFileName = file[0].name.replace(/\s+/g, '_');
      const uniqueFileName = `${uuid()}.${transformedFileName}`;

      submitFiles(file[0], uniqueFileName);

      setFileList((prevState) => [
        ...prevState,
        {
          displayName: transformedFileName,
          fileName: uniqueFileName,
          isLoading: true,
          filePath: `${config.s3DirPath}/${uniqueFileName}`,
        },
      ]);

      // Reset file input so that onChange event
      // will be triggered when user select the same file again
      e.target.value = '';
    },
    [submitFiles, config.accept, config.s3DirPath],
  );

  const onDelete = useCallback(
    (fileName) => {
      const filteredFileItems = fileList.filter(
        (fileItem) => fileItem.fileName !== fileName,
      );
      putResponseValue(filteredFileItems);
    },
    [fileList],
  );

  return useMemo(
    () => (
      <Uploader
        data-testid="file-uploader-uploader"
        fileList={fileList}
        onSelect={onSelect}
        onDelete={onDelete}
        accept={config.accept}
        isUploading={isUploading}
      >
        {children}
      </Uploader>
    ),
    [fileList, onSelect, onDelete, config.accept, isUploading],
  );
};

export default FileUploader;
