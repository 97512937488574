const guid = 'cfa-guid';

export const getToken = () =>
  JSON.parse(localStorage.getItem('okta-token-storage'));

export const getIdTokenClaims = () => {
  const token = getToken();
  return {
    cfaGuid: token?.idToken?.claims?.[`${guid}`],
    assessorName: token?.idToken?.claims?.name,
  };
};

export default getIdTokenClaims;
