/* eslint-disable react/destructuring-assignment */
// TODO: review disabled eslint rule
import { Component } from 'react';
import { Row, Col, Label, Input } from 'reactstrap';
import Switch from 'react-switch';
import I18n from '../../../i18n/utils';
import './FindingsFilter.scss';
import {
  IMMEDIATE_RISK_LONG,
  HIGH_RISK_LONG,
  MEDIUM_RISK_LONG,
  LOW_RISK_LONG,
  INFORMATIONAL_RISK_LONG,
  //
  CLEANING_SANITATION,
  CROSS_CONTAMINATION,
  HEALTH_HYGIENE,
  PESTS,
  TIME_TEMPERATURE,
  //
  BOARDS,
  BOARDS_LONG,
  BREADING_TABLE,
  BREADING_TABLE_LONG,
  COOLER,
  COOLER_LONG,
  DINING_ROOM,
  DINING_ROOM_LONG,
  DRIVE_THRU,
  DRIVE_THRU_LONG,
  FREEZER,
  FREEZER_LONG,
  FRONT_COUNTER,
  FRONT_COUNTER_LONG,
  SALAD_PREP,
  SALAD_PREP_LONG,
} from '../../constants/constants';

class FindingsFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customFilterCounts: {
        [IMMEDIATE_RISK_LONG]: 0,
        [HIGH_RISK_LONG]: 0,
        [MEDIUM_RISK_LONG]: 0,
        [LOW_RISK_LONG]: 0,
        [INFORMATIONAL_RISK_LONG]: 0,
        //
        [CLEANING_SANITATION]: 0,
        [CROSS_CONTAMINATION]: 0,
        [HEALTH_HYGIENE]: 0,
        [PESTS]: 0,
        [TIME_TEMPERATURE]: 0,
        //
        [BOARDS]: 0,
        [BREADING_TABLE]: 0,
        [COOLER]: 0,
        [DINING_ROOM]: 0,
        [DRIVE_THRU]: 0,
        [FREEZER]: 0,
        [FRONT_COUNTER]: 0,
        [SALAD_PREP]: 0,
      },
    };

    this.toggleCustomFilter = this.toggleCustomFilter.bind(this);
    this.updateFilterLabels = this.updateFilterLabels.bind(this);
    this.toggleResolved = this.toggleResolved.bind(this);
    this.clearFilterLabels = this.clearFilterLabels.bind(this);
    this.labelHighlighted = this.labelHighlighted.bind(this);
  }

  componentDidMount() {
    // Generate Custom Category counts on load, as well as reseting filters
    this.generateCategoryCount();
  }

  // Return string for css class of highlighted filter label
  labelHighlighted(label) {
    const highlighted = this.props.chipLabels.includes(label);
    return highlighted ? 'label-is-selected' : '';
  }

  generateCategoryCount() {
    const findings = this.props.selectedAuditFindings;
    const { customFilterCounts } = this.state;

    if (findings != null) {
      findings.forEach((finding) => {
        // Set Severity Counts
        const severityName = finding.riskLevel;
        customFilterCounts[severityName]++;

        // Set Category Counts
        const categoryName = finding.category;
        customFilterCounts[categoryName]++;

        // Set Zone Counts
        if (finding.findingHistory) {
          if (finding.findingHistory[0].details) {
            const zoneName = this.renderZoneString(
              finding.findingHistory[0].zone,
            );
            customFilterCounts[zoneName]++;
          }
        }
      });
    }

    this.setState({ customFilterCounts });
  }

  toggleCustomFilter(bool) {
    this.props.toggleCustomFilter(bool);
  }

  updateFilterLabels(label, type) {
    this.props.updateFilterLabels({ label, type });
  }

  toggleResolved() {
    this.props.toggleResolved();
  }

  clearFilterLabels() {
    this.props.clearFilterLabels();
  }

  renderZoneString(type) {
    return {
      [BOARDS_LONG]: BOARDS,
      [BREADING_TABLE_LONG]: BREADING_TABLE,
      [COOLER_LONG]: COOLER,
      [DINING_ROOM_LONG]: DINING_ROOM,
      [DRIVE_THRU_LONG]: DRIVE_THRU,
      [FREEZER_LONG]: FREEZER,
      [FRONT_COUNTER_LONG]: FRONT_COUNTER,
      [SALAD_PREP_LONG]: SALAD_PREP,
    }[type];
  }

  render() {
    return (
      <Col
        className="FindingsFilter container"
        data-testid="findings-filter-container"
      >
        {
          // Show the clear filters link if any custom selection is present
          this.props.showResolved || this.props.chipLabels.length ? (
            <Row data-testid="findings-filter-clear-category-filter-row">
              <img
                alt="close"
                className="close-icon"
                data-testid="findings-filter-close-icon"
                src={require('../../assets/icons/close-outline.svg').default}
                height={16}
                width={16}
              />
              <div
                className="filter-link clear-filters"
                data-testid="findings-filter-filter-link-clear-filters"
                onClick={this.clearFilterLabels}
              >
                {I18n.t('APP_REPORT_DASHBOARD_CLEAR_CATEGORY_FILTER')}
                &nbsp;
              </div>
            </Row>
          ) : (
            <div />
          )
        }
        <h3 className="sub-header" data-testid="findings-filter-sub-header">
          {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_SORT')}
        </h3>
        <Col
          className="radio-container"
          data-testid="findings-filter-radio-container"
        >
          <Row
            className="radio-item"
            data-testid="findings-filter-risk-level-radio-item"
          >
            <Label data-testid="findings-filter-risk-level-label" check>
              <Input
                data-testid="findings-filter-risk-level-input"
                type="radio"
                name="radio1"
                onChange={() => this.props.toggleCustomFilter(false)}
                checked={!this.props.filterByCustom}
              />
              {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL')}
            </Label>
          </Row>
          <Row
            className="radio-item"
            data-testid="findings-filter-custom-radio-item"
          >
            <Label data-testid="findings-filter-custom-label" check>
              <Input
                data-testid="findings-filter-custom-input"
                type="radio"
                name="radio2"
                onChange={() => this.props.toggleCustomFilter(true)}
                checked={this.props.filterByCustom}
              />
              {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_CUSTOM')}
            </Label>
          </Row>
          <Row
            className="resolved-switch-container container"
            data-testid="findings-filter-resolved-switch-container"
          >
            <Switch
              data-testid="findings-filter-resolved-switch"
              onChange={this.toggleResolved}
              checked={this.props.showResolved}
              height={12}
              width={30}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#aee1ea"
              onHandleColor="#3eb1c8"
              offHandleColor="#3eb1c8"
              defaultChecked={false}
              handleDiameter={18}
            />
            <span data-testid="findings-filter-show-past">
              {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_SHOW_PAST')}
            </span>
          </Row>
          {this.props.filterByCustom && (
            <>
              {/* Severity */}
              <Row
                className="filter-list"
                data-testid="findings-filter-severity-filter-list"
              >
                <Col
                  className="container"
                  data-testid="findings-filter-severity-container-col"
                >
                  <h3
                    className="sub-header filter-by"
                    data-testid="findings-filter-severity-sub-header-filter-by"
                  >
                    {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_FILTER_RISK_LEVEL')}
                  </h3>
                  <div
                    className={`${
                      this.props.filterByCustom ? '' : 'dimmed-view'
                    }`}
                    data-testid="findings-filter-severity-dimmed-view"
                  >
                    <Row data-testid="findings-filter-severity-risk-level-immediate-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          IMMEDIATE_RISK_LONG,
                          'riskLevel',
                        )}`}
                        data-testid="findings-filter-severity-risk-level-immediate"
                        onClick={() =>
                          this.updateFilterLabels(
                            IMMEDIATE_RISK_LONG,
                            'riskLevel',
                          )
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_IMMEDIATE',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-severity-risk-level-immediate-long">
                          ({this.state.customFilterCounts[IMMEDIATE_RISK_LONG]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-severity-risk-level-high-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          HIGH_RISK_LONG,
                          'riskLevel',
                        )}`}
                        data-testid="findings-filter-severity-risk-level-high"
                        value="Cross Contamination"
                        onClick={() =>
                          this.updateFilterLabels(HIGH_RISK_LONG, 'riskLevel')
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_HIGH',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-severity-risk-level-high-long">
                          ({this.state.customFilterCounts[HIGH_RISK_LONG]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-severity-risk-level-medium-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          MEDIUM_RISK_LONG,
                          'riskLevel',
                        )}`}
                        data-testid="findings-filter-severity-risk-level-medium"
                        value="Health & Hygiene"
                        onClick={() =>
                          this.updateFilterLabels(MEDIUM_RISK_LONG, 'riskLevel')
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_MEDIUM',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-severity-risk-level-medium-long">
                          ({this.state.customFilterCounts[MEDIUM_RISK_LONG]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-severity-risk-level-low-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          LOW_RISK_LONG,
                          'riskLevel',
                        )}`}
                        data-testid="findings-filter-severity-risk-level-low"
                        value="Pests"
                        onClick={() =>
                          this.updateFilterLabels(LOW_RISK_LONG, 'riskLevel')
                        }
                      >
                        {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_LOW')}
                        &nbsp;
                        <span data-testid="findings-filter-severity-risk-level-low-long">
                          ({this.state.customFilterCounts[LOW_RISK_LONG]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-severity-risk-level-informational-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          INFORMATIONAL_RISK_LONG,
                          'riskLevel',
                        )}`}
                        data-testid="findings-filter-severity-risk-level-informational"
                        value="Time & Temperature"
                        onClick={() =>
                          this.updateFilterLabels(
                            INFORMATIONAL_RISK_LONG,
                            'riskLevel',
                          )
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_INFORMATIONAL',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-severity-risk-level-informational-long">
                          (
                          {
                            this.state.customFilterCounts[
                              INFORMATIONAL_RISK_LONG
                            ]
                          }
                          )
                        </span>
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
              {/* Category */}
              <Row
                className="filter-list"
                data-testid="findings-filter-category-filter-list-row"
              >
                <Col
                  className="container"
                  data-testid="findings-filter-category-container-col"
                >
                  <h3
                    className="sub-header filter-by"
                    data-testid="findings-filter-category-sub-header-filter-by"
                  >
                    {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_FILTER_CATEGORY')}
                  </h3>
                  <div
                    className={`${
                      this.props.filterByCustom ? '' : 'dimmed-view'
                    }`}
                    data-testid="findings-filter-category-dimmed-view"
                  >
                    <Row data-testid="findings-filter-category-cleaning-sanitation-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          CLEANING_SANITATION,
                          'category',
                        )}`}
                        data-testid="findings-filter-category-cleaning-sanitation"
                        onClick={() =>
                          this.updateFilterLabels(
                            CLEANING_SANITATION,
                            'category',
                          )
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_CLEANING_SANITATION',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-category-cleaning-sanitation-span">
                          ({this.state.customFilterCounts[CLEANING_SANITATION]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-category-cross-contamination-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          CROSS_CONTAMINATION,
                          'category',
                        )}`}
                        data-testid="findings-filter-category-cross-contamination"
                        value="Cross Contamination"
                        onClick={() =>
                          this.updateFilterLabels(
                            CROSS_CONTAMINATION,
                            'category',
                          )
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_CROSS_CONTAMINATION',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-category-cross-contamination-span">
                          ({this.state.customFilterCounts[CROSS_CONTAMINATION]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-category-health-hygiene-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          HEALTH_HYGIENE,
                          'category',
                        )}`}
                        data-testid="findings-filter-category-health-hygiene"
                        value="Health & Hygiene"
                        onClick={() =>
                          this.updateFilterLabels(HEALTH_HYGIENE, 'category')
                        }
                      >
                        {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_HEALTH_HYGIENE')}
                        &nbsp;
                        <span data-testid="findings-filter-category-health-hygiene-span">
                          ({this.state.customFilterCounts[HEALTH_HYGIENE]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-category-pests-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          PESTS,
                          'category',
                        )}`}
                        data-testid="findings-filter-category-pests"
                        value="Pests"
                        onClick={() =>
                          this.updateFilterLabels(PESTS, 'category')
                        }
                      >
                        {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_PESTS')}
                        &nbsp;
                        <span data-testid="findings-filter-category-pests-span">
                          ({this.state.customFilterCounts[PESTS]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-category-time-temperature-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          TIME_TEMPERATURE,
                          'category',
                        )}`}
                        data-testid="findings-filter-category-time-temperature"
                        value="Time & Temperature"
                        onClick={() =>
                          this.updateFilterLabels(TIME_TEMPERATURE, 'category')
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_TIME_TEMPERATURE',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-category-time-temperature-span">
                          ({this.state.customFilterCounts[TIME_TEMPERATURE]})
                        </span>
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
              {/* Zone */}
              <Row
                className="filter-list"
                data-testid="findings-filter-zone-filter-list-row"
              >
                <Col
                  className="container"
                  data-testid="findings-filter-zone-container-col"
                >
                  <h3
                    className="sub-header filter-by"
                    data-testid="findings-filter-zone-sub-header-filter-by"
                  >
                    {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_FILTER_ZONE')}
                  </h3>
                  <div
                    className={`${
                      this.props.filterByCustom ? '' : 'dimmed-view'
                    }`}
                    data-testid="findings-filter-zone-dimmed-view"
                  >
                    <Row data-testid="findings-filter-zone-boards-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          BOARDS_LONG,
                          'zone',
                        )}`}
                        data-testid="findings-filter-zone-boards"
                        onClick={() =>
                          this.updateFilterLabels(BOARDS_LONG, 'zone')
                        }
                      >
                        {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_ZONE_BOARDS')}
                        &nbsp;
                        <span data-testid="findings-filter-zone-boards-span">
                          ({this.state.customFilterCounts[BOARDS]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-zone-breading-table-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          BREADING_TABLE_LONG,
                          'zone',
                        )}`}
                        data-testid="findings-filter-zone-breading-table"
                        onClick={() =>
                          this.updateFilterLabels(BREADING_TABLE_LONG, 'zone')
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_ZONE_BREADING_TABLE',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-zone-breading-table-span">
                          ({this.state.customFilterCounts[BREADING_TABLE]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-zone-cooler-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          COOLER_LONG,
                          'zone',
                        )}`}
                        data-testid="findings-filter-zone-cooler"
                        onClick={() =>
                          this.updateFilterLabels(COOLER_LONG, 'zone')
                        }
                      >
                        {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_ZONE_COOLER')}
                        &nbsp;
                        <span data-testid="findings-filter-zone-cooler-span">
                          ({this.state.customFilterCounts[COOLER]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-zone-dining-room-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          DINING_ROOM_LONG,
                          'zone',
                        )}`}
                        data-testid="findings-filter-zone-dining-room"
                        onClick={() =>
                          this.updateFilterLabels(DINING_ROOM_LONG, 'zone')
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_ZONE_DINING_ROOM',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-zone-dining-room-span">
                          ({this.state.customFilterCounts[DINING_ROOM]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-zone-drive-thru-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          DRIVE_THRU_LONG,
                          'zone',
                        )}`}
                        data-testid="findings-filter-zone-drive-thru"
                        onClick={() =>
                          this.updateFilterLabels(DRIVE_THRU_LONG, 'zone')
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_ZONE_DRIVE_THRU',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-zone-drive-thru-span">
                          ({this.state.customFilterCounts[DRIVE_THRU]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-zone-freezer-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          FREEZER_LONG,
                          'zone',
                        )}`}
                        data-testid="findings-filter-zone-freezer"
                        onClick={() =>
                          this.updateFilterLabels(FREEZER_LONG, 'zone')
                        }
                      >
                        {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_ZONE_FREEZER')}
                        &nbsp;
                        <span data-testid="findings-filter-zone-freezer-span">
                          ({this.state.customFilterCounts[FREEZER]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-zone-front-counter-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          FRONT_COUNTER_LONG,
                          'zone',
                        )}`}
                        data-testid="findings-filter-zone-front-counter"
                        onClick={() =>
                          this.updateFilterLabels(FRONT_COUNTER_LONG, 'zone')
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_ZONE_FRONT_COUNTER',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-zone-front-counter-span">
                          ({this.state.customFilterCounts[FRONT_COUNTER]})
                        </span>
                      </div>
                    </Row>
                    <Row data-testid="findings-filter-zone-salad-prep-row">
                      <div
                        className={`filter-link ${this.labelHighlighted(
                          SALAD_PREP_LONG,
                          'zone',
                        )}`}
                        data-testid="findings-filter-zone-salad-prep"
                        onClick={() =>
                          this.updateFilterLabels(SALAD_PREP_LONG, 'zone')
                        }
                      >
                        {I18n.t(
                          'APP_REPORT_DASHBOARD_FINDINGS_ZONE_SALAD_PREP',
                        )}
                        &nbsp;
                        <span data-testid="findings-filter-zone-salad-prep-span">
                          ({this.state.customFilterCounts[SALAD_PREP]})
                        </span>
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Col>
    );
  }
}

export default FindingsFilter;
