import I18n from '../../i18n/utils';

// Risk Level Constants for UI
export const IMMEDIATE_RISK_SHORT = 'immediate';
export const IMMEDIATE_RISK_LONG = 'Immediate Action';
//
export const HIGH_RISK_SHORT = 'high';
export const HIGH_RISK_LONG = 'High';
//
export const MEDIUM_RISK_SHORT = 'medium';
export const MEDIUM_RISK_LONG = 'Medium';
//
export const LOW_RISK_SHORT = 'low';
export const LOW_RISK_LONG = 'Low';
//
export const INFORMATIONAL_RISK_SHORT = 'informational';
export const INFORMATIONAL_RISK_LONG = 'Informational';
//
export const RISK_LEVELS_LONG = [
  IMMEDIATE_RISK_LONG,
  HIGH_RISK_LONG,
  MEDIUM_RISK_LONG,
  LOW_RISK_LONG,
  INFORMATIONAL_RISK_LONG,
];
export const RISK_LEVELS_SHORT = [
  IMMEDIATE_RISK_SHORT,
  HIGH_RISK_SHORT,
  MEDIUM_RISK_SHORT,
  LOW_RISK_SHORT,
  INFORMATIONAL_RISK_SHORT,
];

// Category Constants for UI
export const CLEANING_SANITATION = 'Cleaning & Sanitation';
export const CROSS_CONTAMINATION = 'Cross Contamination';
export const HEALTH_HYGIENE = 'Health & Hygiene';
export const PESTS = 'Pests';
export const TIME_TEMPERATURE = 'Time & Temperature';
export const CATEGORIES = [
  CLEANING_SANITATION,
  CROSS_CONTAMINATION,
  HEALTH_HYGIENE,
  PESTS,
  TIME_TEMPERATURE,
];

export const LVR_STAFF = 'Staff';
export const LVR_GREAT_FOOD = 'Great Food';
export const LVR_CLEAN_SAFE = 'Clean & Safe Environment';
export const LVR_OPERATOR_CONSULTING_NOTES = 'Operator Consulting Notes';
export const LVR_GUEST_EXPERIENCE = 'Guest Experience';

export const LVR_SORT_OPTIONS = [
  {
    id: 1,
    value: 'highRiskLevel',
    label: 'Highest Risk',
  },
  {
    id: 2,
    value: 'lowRiskLevel',
    label: 'Lowest Risk',
  },
];

// Zone Constants for UI
export const BOARDS = 'Boards';
export const BOARDS_LONG = 'Boards/Cook-line/Warming Units';
//
export const BREADING_TABLE = 'Breading Table';
export const BREADING_TABLE_LONG = 'Breading Table/Thaw Cabinet';
//
export const COOLER = 'Cooler';
export const COOLER_LONG = 'Walk-in Cooler and Freezer';
//
export const DINING_ROOM = 'Dining Room';
export const DINING_ROOM_LONG = 'Restrooms/Dining';
//
export const DRIVE_THRU = 'Drive Thru';
export const DRIVE_THRU_LONG = 'Front Counter/Drive-Thru';
//
export const FREEZER = 'Freezer';
export const FREEZER_LONG = 'Walk-in Cooler and Freezer';
//
export const FRONT_COUNTER = 'Front Counter';
export const FRONT_COUNTER_LONG = 'Front Counter/Drive-Thru';
//
export const SALAD_PREP = 'Salad Prep';
export const SALAD_PREP_LONG = 'Produce/Salad/Prep';

// Performance Level Definitions
export const renderPerformanceCategory = (score) => {
  return {
    1: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_ELITE'),
      definition: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_ELITE_DEFINITION'),
      color: '#2c663b',
    },
    2: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_GOOD'),
      definition: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_GOOD_DEFINITION'),
      color: '#4d9758',
    },
    3: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_GOOD'),
      definition: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_GOOD_DEFINITION'),
      color: '#86c06f',
    },
    4: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_GOOD'),
      definition: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_GOOD_DEFINITION'),
      color: '#bfde82',
    },
    5: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_FAIR'),
      definition: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_FAIR_DEFINITION'),
      color: '#ecf3a9',
    },
    6: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_FAIR'),
      definition: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_FAIR_DEFINITION'),
      color: '#fceda9',
    },
    7: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_FAIR'),
      definition: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_FAIR_DEFINITION'),
      color: '#f4c07c',
    },
    8: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_UNSATISFACTORY'),
      definition: I18n.t(
        'APP_PERFORMANCE_LEVEL_CATEGORY_UNSATISFACTORY_DEFINITION',
      ),
      color: '#e58156',
    },
    9: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_UNSATISFACTORY'),
      definition: I18n.t(
        'APP_PERFORMANCE_LEVEL_CATEGORY_UNSATISFACTORY_DEFINITION',
      ),
      color: '#c74537',
    },
    10: {
      category: I18n.t('APP_PERFORMANCE_LEVEL_CATEGORY_SUBSTANDARD'),
      definition: I18n.t(
        'APP_PERFORMANCE_LEVEL_CATEGORY_SUBSTANDARD_DEFINITION',
      ),
      color: '#972921',
    },
  }[score];
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const baseTheme = {
  fontSize: '1rem',
  fontFamily: 'Apercu-regular',
  axis: {
    legend: {
      text: {
        fontSize: '1rem',
        fontFamily: 'Apercu-regular',
        fontWeight: '700',
      },
    },
    ticks: {
      text: {
        fontSize: '.75rem',
        fontFamily: 'Apercu-regular',
      },
    },
  },
};

// Potential Long values for filters

// Records Review
// Breading Table / Thaw Cabinet
// Compartment Sink / Dish Machine / Ice Machine
// Boards / Cook - line / Warming Units
// In restaurants participating in facial hair test
// Ice machine
// Mop Sink / Chemical Storage
// Compartment Sink / Dish Machine
// Produce / Salad / Prep
// Test Kitchen / Holding Cabinet
// Restrooms / Dining
// Dry Storage
// Walk -in Cooler and Freezer
// Exterior - Cage, Dumpster, Parking Lot
// Exterior - Cage
// Front Counter / Drive - Thru
// Ice Machine

// Records Review
// Breading Table / Thaw Cabinet
// Compartment Sink / Dish Machine / Ice Machine
// Boards / Cook - line / Warming Units
// In restaurants participating in facial hair test
// Ice machine
// Mop Sink / Chemical Storage
// Compartment Sink / Dish Machine
// Produce / Salad / Prep
// Test Kitchen / Holding Cabinet
// Restrooms / Dining
// Dry Storage
// Walk -in Cooler and Freezer
// Front Counter / Drive - Thru
// Ice Machine
