import { API_GET, API_PUT, API_DEL } from '../../../utils/api/API';

export const getAutoSaveAssessments = async ({ locationNumber }) => {
  try {
    const path = `${process.env.REACT_APP_SERVER_URL}/assessment/autosave?locationNumber=${locationNumber}&program=LVR`;
    const response = await API_GET(path);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAutoSaveAssessmentById = async ({
  locationNumber,
  questionnaireId,
}) => {
  try {
    const path = `${process.env.REACT_APP_SERVER_URL}/assessment/autosave?locationNumber=${locationNumber}&questionnaireId=${questionnaireId}&program=LVR`;
    const response = await API_GET(path);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const putAutoSaveAssessment = async ({
  locationNumber,
  questionnaireId = 0,
  form = '',
  saveData,
}) => {
  try {
    const path = `${process.env.REACT_APP_SERVER_URL}/assessment/autosave`;
    const response = await API_PUT(path, {
      form,
      locationNumber,
      questionnaireId,
      program: 'LVR',
      saveData: {
        empty: false,
        ...saveData,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAutoSaveAssessment = async ({
  locationNumber,
  questionnaireId,
}) => {
  try {
    const path = `${
      process.env.REACT_APP_SERVER_URL
    }/assessment/autosave?program=LVR&locationNumber=${locationNumber}${
      questionnaireId ? `&questionnaireId=${questionnaireId}` : ''
    }`;
    const response = await API_DEL(path);
    return response;
  } catch (error) {
    console.log(error);
  }
};
