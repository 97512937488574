import { useMemo } from 'react';
import { LoadingIndicator } from 'cfa-react-components';
import useSmartShopFindings from '../../utils/hooks/useSmartShopFindings';
import SelectedFinding from '../../pages/SmartShop/AllFindings/SelectedFinding/SelectedFinding';
import { formatDateNoDay } from '../../utils';

const SelectedAppeal = ({ appeal }) => {
  const { findings, isLoadingFindings } = useSmartShopFindings();
  const finding = useMemo(() => {
    if (isLoadingFindings) {
      return null;
    }
    return findings.find(({ questionInternalId }) => {
      return questionInternalId === appeal.questionInternalId;
    });
  }, [findings, isLoadingFindings, appeal.questionInternalId]);
  if (!finding) {
    return (
      <LoadingIndicator
        size="md"
        variant="page"
        style={{ margin: '6rem auto' }}
      />
    );
  }
  return (
    <SelectedFinding
      finding={finding}
      round={formatDateNoDay(appeal.responseInspectionTimestamp)}
    />
  );
};

export default SelectedAppeal;
