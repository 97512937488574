import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Dropdown,
  LoadingIndicator,
} from 'cfa-react-components';
import { useParams } from 'react-router-dom';
import QIVCard from './components/QIVCard/QIVCard';
import HistoricalPerformanceCard from './components/HistoricalPerformanceCard/HistoricalPerformanceCard';
import { API_GET } from '../../../utils/api/API';
import SDCCard from './components/SDCCard/SDCCard';
import ERQACard from './components/ERQACard/ERQACard';

const AssessmentData = () => {
  const { locationNumber } = useParams();
  const [roundsLoading, setRoundsLoading] = useState(true);
  const [rounds, setRounds] = useState([]);
  const [selectedRound, setSelectedRound] = useState('');

  // TODO: find a better way to get rounds for a location
  useEffect(() => {
    if (!locationNumber) return;

    const fetchData = async () => {
      try {
        setRoundsLoading(true);

        // Fetch available rounds from the qiv historical-data endpoint
        const qivPath = `${process.env.REACT_APP_SERVER_URL}/qiv/historical-data/${locationNumber}?amount=100`;
        const { data: historicalData } = await API_GET(qivPath);
        const qivRounds = historicalData.assessments.map((item) => item.round);

        // Fetch available rounds from the audit by-location endpoint
        const byLocationPath = `${process.env.REACT_APP_SERVER_URL}/audit/by-location/${locationNumber}`;
        const { data: auditData } = await API_GET(byLocationPath);
        const auditRounds = auditData.map((item) => item.round);

        // Combine and deduplicate the rounds
        const combinedRounds = [...new Set([...auditRounds, ...qivRounds])];
        // Sort rounds by year and quarter
        combinedRounds.sort((a, b) => {
          // Split each element into [quarter, year]
          const [quarterA, yearA] = a.split('-');
          const [quarterB, yearB] = b.split('-');

          // Compare years first
          if (yearA !== yearB) {
            return yearB - yearA;
          }

          // If years are the same, compare quarters
          return quarterB.localeCompare(quarterA);
        });

        setRounds(combinedRounds);
      } catch (error) {
        console.error(error);
      } finally {
        setRoundsLoading(false);
      }
    };
    fetchData();
  }, [locationNumber]);

  if (roundsLoading)
    return (
      <LoadingIndicator
        data-testid="assessment-data-loading-indicator"
        variant="page"
      />
    );

  return (
    <>
      <Row
        data-testid="assessment-data-header-row"
        style={{ alignItems: 'center', marginBottom: '2rem' }}
      >
        <Col data-testid="assessment-data-header-col" md="6" xs="12">
          <Typography data-testid="assessment-data-header" variant="h3">
            Assessment Data
          </Typography>
        </Col>
        <Col
          data-testid="assessment-data-dropdown-col"
          lg={{ span: 2, offset: 4 }}
          md={{ span: 3, offset: 3 }}
          xs="12"
        >
          <Dropdown
            data-testid="assessment-data-round-dropdown"
            compact
            label="Round"
            value={selectedRound || rounds?.[0]}
            onChange={(value) => setSelectedRound(value)}
            options={rounds}
            disabled={roundsLoading || !rounds?.length}
            style={{ marginTop: '-1.5rem' }}
          />
        </Col>
      </Row>
      <Row
        style={{ marginBottom: '1rem' }}
        data-testid="assessment-data-widget-row"
      >
        <Col
          data-testid="assessment-data-historical-performance-card-col"
          md="6"
          xs="12"
          style={{ marginBottom: '1rem' }}
        >
          <HistoricalPerformanceCard
            data-testid="assessment-data-historical-performance-card"
            round={selectedRound || rounds?.[0]}
            rounds={rounds}
            roundsLoading={roundsLoading}
          />
        </Col>
        <Col
          data-testid="assessment-data-qiv-card-col"
          md="6"
          xs="12"
          style={{ marginBottom: '1rem' }}
        >
          <QIVCard
            data-testid="assessment-data-qiv-card"
            round={selectedRound || rounds?.[0]}
          />
        </Col>
      </Row>
      <Row data-testid="assessment-data-widget-row-2">
        <Col
          data-testid="assessment-data-sdc-card-col"
          md="6"
          xs="12"
          style={{ marginBottom: '1rem' }}
        >
          <SDCCard data-testid="assessment-data-sdc-card" />
        </Col>
        <Col
          data-testid="assessment-data-erqa-card-col"
          md="6"
          xs="12"
          style={{ marginBottom: '1rem' }}
        >
          <ERQACard data-testid="assessment-data-erqa-card" />
        </Col>
      </Row>
    </>
  );
};

export default AssessmentData;
