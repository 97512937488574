import PropTypes from 'prop-types';

import FindingDetailsCard from './FindingDetailsCard';

/**
 * FindingDetailsContainer is a component that returns a list of FindingDetailsCard components
 * based on the findingHistory array in selectedFindingDetails.
 * This gives a place for logic needed at the list level to be implemented.
 *
 * selectedFindingDetails: an object holding data associated with the currently selected finding.
 * This includes findingHistory, an array of finding detail objects associated with the selected finding.
 */
const FindingDetailsContainer = ({ finding }) => {
  if (!finding?.findingHistory) return null;
  return finding.findingHistory.map((detail, index) => (
    <FindingDetailsCard
      data-testid="finding-details-container-finding-details-card"
      detail={detail}
      auditUUID={finding.auditUUID}
      questionUUID={finding.questionUUID}
      riskLevel={finding.riskLevel}
      category={
        finding.category.name ? finding.category.name : finding.category
      }
      bestPracticeLinks={finding.bestPracticeLinks}
      findingHistoryIndex={index}
      key={index}
    />
  ));
};

FindingDetailsContainer.propTypes = {
  finding: PropTypes.object,
};

export default FindingDetailsContainer;
