import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Card,
  CardContent,
  Row,
  Col,
  Button,
  LoadingIndicator,
  Dropdown,
} from 'cfa-react-components';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { API_GET, API_POST } from '../../../../../utils';
import I18n from '../../../../../../i18n/utils';

import { pushNotification } from '../../../../SmartShop/InquiryForm/utils';
import LvrWidgetTitle from '../../LicenseeVisitReportWidgetTitle';
import {
  generateDropdownOptions,
  getMonthName,
  formatSalesNumber,
} from './RestaurantSalesHelpers';
import './RestaurantSalesCard.scss';

const domain = process.env.REACT_APP_SERVER_URL;

const RestaurantSalesCard = () => {
  const { locationNumber } = useParams();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    const options = generateDropdownOptions();
    setSelectedDate(options[0]);
    setDropdownOptions(options);
  }, []);

  useEffect(() => {
    if (!locationNumber || !selectedDate) {
      setApiData({});
      setIsLoading(false);
      return;
    }

    const fetchDataForMonth = async (year, monthNum) => {
      setIsLoading(true);
      const queryParams = {
        locationNumber,
        monthNum,
        year,
      };
      const path = `${domain}/licensee/licensee-sales-monthly-totals?${queryString.stringify(
        queryParams,
      )}`;
      try {
        const { data } = await API_GET(path);
        return data;
      } catch (e) {
        console.error('Fetching error:', e.message);
        return null;
      }
    };

    const fetchLatestUpdateDate = async (year, monthNum) => {
      const queryParams = {
        locationNumber,
        monthNum,
        year,
      };
      const path = `${domain}/licensee/licensee-sales-daily/last-update-for-month?${queryString.stringify(
        queryParams,
      )}`;
      try {
        const { data } = await API_GET(path);
        return data;
      } catch (e) {
        console.error('Fetching error:', e.message);
        return null;
      }
    };

    const fetchAndSetData = async () => {
      if (selectedDate) {
        const currentYearData =
          (await fetchDataForMonth(selectedDate.year, selectedDate.month)) ||
          null;
        const previousYearData =
          (await fetchDataForMonth(
            selectedDate.year - 1,
            selectedDate.month,
          )) || null;
        const recentDailySalesData =
          (await fetchLatestUpdateDate(
            selectedDate.year,
            selectedDate.month,
          )) || null;

        setIsLoading(false);
        setApiData({ currentYearData, previousYearData, recentDailySalesData });
      }
    };

    fetchAndSetData();
  }, [locationNumber, selectedDate]);

  let previousDate = '';
  if (selectedDate) {
    previousDate = `${getMonthName(selectedDate.month)}, ${
      selectedDate.year - 1
    }`;
  }
  const { currentYearData, previousYearData, recentDailySalesData } = apiData;

  const salesData = {
    currentSales: currentYearData?.reportingPeriodTotal
      ? formatSalesNumber(currentYearData?.reportingPeriodTotal)
      : 'Waiting for update',
    currentSalesDate: selectedDate?.name,
    previousSales: previousYearData?.reportingPeriodTotal
      ? formatSalesNumber(previousYearData?.reportingPeriodTotal)
      : 'Waiting for update',
    previousSalesDate: previousDate,
  };

  let currentSalesVariant = 'h2';
  let waitingOnCurrrentSales = false;
  if (salesData.currentSales === 'Waiting for update') {
    currentSalesVariant = 'h3';
    waitingOnCurrrentSales = true;
  }

  let lastUpdateDate;
  const reportingPeriodMonth = getMonthName(
    recentDailySalesData?.reportingPeriodMonth,
  );
  const reportingPeriodDay = recentDailySalesData?.reportingPeriodDay;
  const reportingPeriodYear = recentDailySalesData?.reportingPeriodYear;

  let noUpdates = false;
  if (reportingPeriodMonth && reportingPeriodDay && reportingPeriodYear) {
    lastUpdateDate = `on ${reportingPeriodMonth} ${reportingPeriodDay}, ${reportingPeriodYear}`;
  } else {
    // Ensure there is a valid month from selectedDate, or default to the current month using dayjs
    const currentMonthName = selectedDate
      ? getMonthName(selectedDate.month)
      : getMonthName(dayjs().month() + 1);
    lastUpdateDate = `There are no updates to the sales data for ${currentMonthName}`;
    noUpdates = true;
  }

  const handleSendEmail = async () => {
    const emailEndpoint = `${process.env.REACT_APP_SERVER_URL}/assessment/lvr/licensee-sales-reminder-email/`;
    const queryParams = `?locationNumber=${locationNumber}`;
    const response = await API_POST(emailEndpoint + queryParams, {});
    if (response.status === 200) {
      pushNotification('Email sent successfully');
    } else {
      pushNotification('Email failed to send', true);
    }
  };

  return (
    <Card className="restaurant-sales-card__card-container">
      <CardContent className="restaurant-sales-card__card-content-container">
        {isLoading ? (
          <LoadingIndicator
            variant="inline"
            size="lg"
            className="restaurant-sales-card__loading-indicator"
          />
        ) : (
          <>
            <div className="restaurant-sales-card__title-container">
              <LvrWidgetTitle title="Restaurant Sales" marginBottom="0rem" />
              <Dropdown
                className="restaurant-sales-card__dropdown"
                label="Select Month"
                value={selectedDate}
                options={dropdownOptions}
                renderOption={(option) => `${option.name}`}
                getOptionId={(option) => option.id}
                getOptionText={(option) => option.name}
                onChange={(newDate) => setSelectedDate(newDate)}
              />
            </div>
            <Row>
              <Col className="restaurant-sales-card__sales-column">
                <div className="restaurant-sales-card__current-total-sales-container">
                  <Typography
                    className="restaurant-sales-card__current-sales-number"
                    variant={currentSalesVariant}
                    color="secondary"
                  >
                    {salesData.currentSales}
                  </Typography>
                  <Typography className="restaurant-sales-card__total-sales-in">
                    {I18n.t('APP_LVR_RESTAURANT_SALES_TOTAL_SALES')}
                    <span className="restaurant-sales-card__sales-span">
                      {salesData.currentSalesDate}
                    </span>
                  </Typography>
                </div>
                <div className="restaurant-sales-card__gray-divider" />
                <div className="restaurant-sales-card__previous-total-sales-container">
                  <Typography
                    className="restaurant-sales-card__previous-sales-number"
                    variant="h3"
                    color="secondary"
                    style={{
                      marginBottom: waitingOnCurrrentSales ? '0' : '0.5rem',
                    }}
                  >
                    {salesData.previousSales}
                  </Typography>
                  <Typography className="restaurant-sales-card__last-year-in">
                    {I18n.t('APP_LVR_RESTAURANT_SALES_LAST_YEAR')}
                    <span className="restaurant-sales-card__sales-span">
                      {salesData.previousSalesDate}
                    </span>
                  </Typography>
                </div>
              </Col>
              <Col className="restaurant-sales-card__update-column">
                {noUpdates ? (
                  <Typography className="restaurant-sales-card__latest-update">
                    {lastUpdateDate}.{' '}
                    {I18n.t('APP_LVR_RESTAURANT_SALES_LICENSEE_REMIND')}
                  </Typography>
                ) : (
                  <Typography className="restaurant-sales-card__latest-update">
                    {I18n.t('APP_LVR_RESTAURANT_SALES_LATEST_UPDATE')}{' '}
                    {lastUpdateDate}.{' '}
                    {I18n.t('APP_LVR_RESTAURANT_SALES_LICENSEE_REMIND')}
                  </Typography>
                )}
                <Button
                  color="secondary"
                  variant="outlined"
                  size="sm"
                  onClick={handleSendEmail}
                  className="restaurant-sales-card__send-a-link-button"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {I18n.t('APP_LVR_RESTAURANT_SALES_SEND_LINK')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default RestaurantSalesCard;
