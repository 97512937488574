export const GET_ALL_AUDITS = 'GET_ALL_AUDITS';
export const GET_ALL_AUDITS_SUCCESS = 'GET_ALL_AUDITS_SUCCESS';
export const GET_ALL_AUDITS_FAILURE = 'GET_ALL_AUDITS_FAILURE';
export const GET_ALL_AUDITS_SUCCESS_EMPTY = 'GET_ALL_AUDITS_SUCCESS_EMPTY';
//
export const GET_AUDIT_SUMMARY = 'GET_AUDIT_SUMMARY';
export const GET_AUDIT_SUMMARY_SUCCESS = 'GET_AUDIT_SUMMARY_SUCCESS';
export const GET_AUDIT_SUMMARY_FAILURE = 'GET_AUDIT_SUMMARY_FAILURE';
//
export const SET_SELECTED_AUDIT = 'SET_SELECTED_AUDIT';
//
export const GET_EFFECTIVE_AUDIT = 'GET_EFFECTIVE_AUDIT';
export const GET_EFFECTIVE_AUDIT_SUCCESS = 'GET_EFFECTIVE_AUDIT_SUCCESS';
export const GET_EFFECTIVE_AUDIT_FAILURE = 'GET_EFFECTIVE_AUDIT_FAILURE';
//
export const GET_LEAVE_BEHIND_REPORT_PDF = 'GET_LEAVE_BEHIND_REPORT_PDF';
export const GET_LEAVE_BEHIND_REPORT_PDF_SUCCESS =
  'GET_LEAVE_BEHIND_REPORT_PDF_SUCCESS';
export const GET_LEAVE_BEHIND_REPORT_PDF_FAILURE =
  'GET_LEAVE_BEHIND_REPORT_PDF_FAILURE';
//
export const GET_SELECTED_AUDIT_FINDINGS = 'GET_SELECTED_AUDIT_FINDINGS';
export const GET_SELECTED_AUDIT_FINDINGS_SUCCESS =
  'GET_SELECTED_AUDIT_FINDINGS_SUCCESS';
export const GET_SELECTED_AUDIT_FINDINGS_FAILURE =
  'GET_SELECTED_AUDIT_FINDINGS_FAILURE';
export const CLEAR_SELECTED_AUDIT_FINDINGS = 'CLEAR_SELECTED_AUDIT_FINDINGS';
//
export const SET_SELECTED_FINDING = 'SET_SELECTED_FINDING';
//
export const GET_SELECTED_FINDING_DETAILS = 'GET_SELECTED_FINDING_DETAILS';
export const GET_SELECTED_FINDING_DETAILS_SUCCESS =
  'GET_SELECTED_FINDING_DETAILS_SUCCESS';
export const GET_SELECTED_FINDING_DETAILS_FAILURE =
  'GET_SELECTED_FINDING_DETAILS_FAILURE';
//
export const SET_SELECTED_LOCATION_AMENDMENTS =
  'SET_SELECTED_LOCATION_AMENDMENT';
//
export const CLEAR_AUDIT_SELECTION = 'CLEAR_AUDIT_SELECTION';
//
export const GET_HISTORICAL_ACTIONS = 'GET_HISTORICAL_ACTIONS';
export const GET_HISTORICAL_ACTIONS_SUCCESS = 'GET_HISTORICAL_ACTIONS_SUCCESS';
export const GET_HISTORICAL_ACTIONS_FAILURE = 'GET_HISTORICAL_ACTIONS_FAILURE';
//
export const GET_SELECTED_HISTORICAL_ACTIONS =
  'GET_SELECTED_HISTORICAL_ACTIONS';
export const GET_SELECTED_HISTORICAL_ACTIONS_SUCCESS =
  'GET_SELECTED_HISTORICAL_ACTIONS_SUCCESS';
export const GET_SELECTED_HISTORICAL_ACTIONS_FAILURE =
  'GET_SELECTED_HISTORICAL_ACTIONS_FAILURE';
//
export const PUT_HISTORICAL_ACTION = 'PUT_HISTORICAL_ACTION';
export const PUT_HISTORICAL_ACTION_SUCCESS = 'PUT_HISTORICAL_ACTION_SUCCESS';
export const PUT_HISTORICAL_ACTION_FAILURE = 'PUT_HISTORICAL_ACTION_FAILURE';
//
export const GET_FINDINGS = 'GET_FINDINGS';
export const GET_FINDINGS_SUCCESS = 'GET_FINDINGS_SUCCESS';
export const GET_FINDINGS_FAIL = 'GET_FINDINGS_FAIL';

export const GET_FINDINGS_SUMMARY = 'GET_FINDINGS_SUMMARY';
export const GET_FINDINGS_SUMMARY_SUCCESS = 'GET_FINDINGS_SUMMARY_SUCCESS';
export const GET_FINDINGS_SUMMARY_FAILURE = 'GET_FINDINGS_SUMMARY_FAILURE';
