import { Card, CardContent, Icon, Typography } from 'cfa-react-components';
import { Calendar } from '@cfa-icons/system';
import LvrWidgetTitle from '../../LicenseeVisitReportWidgetTitle';

import './Visits.scss';
import I18n from '../../../../../../i18n/utils';

const VisitsCard = ({ content }) => {
  const {
    conversationsThisMonth = 0,
    visitsThisMonth = 0,
    lastConversation,
    lastReport,
  } = content;
  return (
    <Card
      className="assessment-card visits-card"
      data-testid="visits-card-assessment-card"
      style={{
        height: '100%',
      }}
    >
      <CardContent
        className="visits-card__card-container"
        data-testid="visits-card-assessment-card-content"
      >
        <LvrWidgetTitle
          title="Visits"
          paddingLeft="0"
          data-testid="visits-card-assessment-card-header"
        />
        <div data-testid="visits-card-assessment-card-row">
          <div className="visits-card__container">
            <div
              className="visits-card__last-visit-container"
              data-testid="visits-card-last-visit-container"
              style={{ marginBottom: '3rem' }}
            >
              <Icon
                icon={Calendar}
                size="xl"
                className="visits-card__calendar"
                data-testid="visits-card-calendar-icon"
              />

              <div className="visits-card__general-date-info">
                <div
                  className="visits-card__last-visit-subtitle-container"
                  data-testid="visits-card-last-visit-subtitle-container"
                >
                  <Typography data-testid="visits-card-last-visit-subtitle">
                    {I18n.t('APP_LAST_VISIT')}:
                  </Typography>
                  <Typography
                    data-testid="visits-card-last-visit"
                    fontWeight="bold"
                  >
                    {lastReport?.date || 'None'}
                  </Typography>
                </div>
                <div
                  className="visits-card__last-visit-subtitle-container"
                  data-testid="visits-card-last-visit-subtitle-container"
                >
                  <Typography data-testid="visits-card-last-visit-subtitle">
                    {I18n.t('APP_LAST_CONVERSATION')}:
                  </Typography>
                  <Typography
                    data-testid="visits-card-last-visit"
                    fontWeight="bold"
                  >
                    {lastConversation?.date || 'None'}
                  </Typography>
                </div>
              </div>
            </div>

            <div
              className="visits-card__subtitle-container"
              data-testid="visits-card-visits-completed-subtitle-container"
            >
              <Typography
                className="visits-card__subtitle"
                data-testid="visits-card-visits-completed-subtitle"
                style={{
                  marginBottom: '0.15em',
                }}
              >
                {I18n.t('APP_TOTAL_VISITS_THIS_MONTH')}
              </Typography>
              <div className="visits-card__monthly-data-container">
                <Typography data-testid="visits-card-visits-completed">
                  {I18n.t('APP_VISITS')}:
                </Typography>
                <Typography fontWeight="bold">{visitsThisMonth}</Typography>
              </div>
              <div className="visits-card__monthly-data-container">
                <Typography data-testid="visits-card-visits-completed">
                  {I18n.t('APP_CONVERSATIONS')}:
                </Typography>
                <Typography fontWeight="bold">
                  {conversationsThisMonth}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default VisitsCard;
