import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '../../../i18n/utils';
import { getCurrentTime } from '../../utils';
import {
  postAmendmentComment,
  getAmendmentComments,
} from '../../actions/AmendmentActions';
import AmendmentCommentCard from './AmendmentCommentCard';
import './AmendmentCommentsContainer.scss';

function AmendmentCommentsContainer({
  amendmentId,
  amendmentStatus,
  amendmentType,
  disabledComment,
  handleShowHideCommentBox,
  commentPayload,
  isHistoricTab,
  isAdminRequested,
}) {
  const dispatch = useDispatch();
  const [commentInput, setInputValue] = useState('');
  const [canEditComment, setEditingComent] = useState(false);

  const amendmentComments = useSelector(
    (state) => state.amendments.amendmentComments,
  );

  const userToken = useSelector((state) => state.auth.userToken);

  // Componet DidMount
  useEffect(() => {
    dispatch(getAmendmentComments(amendmentId));

    commentPayload?.comments.length > 0 &&
      setInputValue(commentPayload?.comments);
  }, []);

  const onChangeHandler = (event) => {
    setInputValue(event.target.value);
  };
  const submitComment = (newComment) => {
    dispatch(
      postAmendmentComment({
        amendmentRequestId: amendmentId,
        comment: newComment,
        commentTimestamp: getCurrentTime(),
        username: userToken.name,
      }),
    );

    setInputValue('');

    // setEditingComent(false);
    // setInputValue('');
  };

  const editComment = () => {
    setEditingComent(!canEditComment);
  };

  // If there are comments OR we are NOT in the historic tab, show the comments container.
  const showComments =
    amendmentComments[amendmentId]?.length !== 0 || !isHistoricTab;

  return (
    <>
      {showComments === true && (
        <div
          className="amendment-comment-container"
          data-testid="amendment-comments-container-show-comments-container"
        >
          <div
            className="amendment-comment__header-container"
            data-testid="amendment-comments-container-header-container"
          >
            Comment History
            <button
              type="button"
              className="amendment-comment__close-btn position-relative"
              data-testid="amendment-comments-container-close-button-position-relative"
              disabled={disabledComment}
              onClick={handleShowHideCommentBox}
            >
              ✕
            </button>
          </div>
          <div
            className="amendment-comment-container__comments"
            data-testid="amendment-comments-container-comments"
          >
            {amendmentId &&
              amendmentComments[amendmentId] &&
              amendmentComments[amendmentId].length !== 0 &&
              amendmentComments[amendmentId].map((comment, index) => {
                return (
                  <AmendmentCommentCard
                    data-testid="amendment-comments-container-amendment-comment-card"
                    key={comment.id}
                    comment={comment}
                    status={amendmentStatus}
                    isStatusComment={
                      index === amendmentComments[amendmentId].length - 1
                    }
                    isAdminRequested={isAdminRequested}
                    isModified={amendmentType === 'MODIFICATION'}
                  />
                );
              })}
          </div>
          {isHistoricTab === false && (
            <>
              <div data-testid="amendment-comments-container-comment-header-container">
                {commentPayload?.comments === undefined && (
                  <p
                    className="amendment-comment__header-title"
                    data-testid="amendment-comments-container-header-title"
                  >
                    {I18n.t('APP_ADMIN_AMENDMENTS_ADD_COMMENT_HEADER')}
                  </p>
                )}
              </div>

              <div
                className="amendment-comment__input-container"
                data-testid="amendment-comments-container-input-container"
              >
                <textarea
                  className="amendment-comment__comment_input"
                  data-testid="amendment-comments-container-comment-input"
                  value={commentInput}
                  onChange={onChangeHandler}
                  placeholder="Add a comment..."
                  rows="3"
                  type="text"
                  draggable="false"
                  readOnly={
                    canEditComment ? false : commentPayload !== undefined
                  }
                />
                {(commentPayload?.comments === undefined ||
                  canEditComment === true) && (
                  <button
                    type="button"
                    className={`amendment-comment__submit-btn ${
                      disabledComment ||
                      (!commentInput.length &&
                        'amendment-comment__submit-btn--disabled')
                    }`}
                    data-testid="amendment-comments-container-submit-button"
                    disabled={disabledComment || !commentInput.length}
                    onClick={() => {
                      submitComment(commentInput);
                    }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {showComments === false && (
        <div
          className="amendment-comment-container"
          data-testid="amendment-comments-container-no-show-comments-container"
        >
          <div
            className="amendment-comment__header-container"
            data-testid="amendment-comments-container-no-show-header-container"
          >
            No Comments
            <button
              type="button"
              className="amendment-comment__close-btn position-relative"
              data-testid="amendment-comments-container-no-show-close-button-position-relative"
              disabled={disabledComment}
              onClick={handleShowHideCommentBox}
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </>
  );
}

AmendmentCommentsContainer.propTypes = {
  amendmentId: PropTypes.string.isRequired,
  amendmentStatus: PropTypes.string.isRequired,
  amendmentType: PropTypes.string.isRequired,
  disabledComment: PropTypes.bool.isRequired,
  handleShowHideCommentBox: PropTypes.func.isRequired,
  commentPayload: PropTypes.object,
  isHistoricTab: PropTypes.bool.isRequired,
  isAdminRequested: PropTypes.bool.isRequired,
};

export default AmendmentCommentsContainer;
