import { Typography, Card } from 'cfa-react-components';
import { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { API_GET } from '../../../../utils';

export function ScoreInsights({ activeScore, audit }) {
  const [scoreParameters, setScoreParameters] = useState({});
  const [violationMultiplier, setViolationMultipler] = useState({});
  const [binSpread, setBinSpread] = useState(0);
  const [findingPenalty, setFindingPenalty] = useState(null);

  useEffect(() => {
    fetchSafeScoreParameters();
  }, []);

  const fetchSafeScoreParameters = async () => {
    const parameterData = (
      await API_GET(
        `${process.env.REACT_APP_SERVER_URL}/model/parameters/${activeScore.modelParametersId}`,
      )
    ).data;
    const violationDataset = parameterData.violationHistoryMultiplier;
    const Spread =
      (parameterData.performanceLevelUpperBound -
        parameterData.performanceLevelLowerBound) /
      (parameterData.performanceLevelBinCount - 2);
    const penaltyData = await API_GET(
      `${process.env.REACT_APP_SERVER_URL}/safe-score/${audit.id}/finding-penalty`,
    );
    setScoreParameters(parameterData);
    setViolationMultipler(violationDataset);
    setBinSpread(Spread);
    setFindingPenalty(penaltyData.data);
  };

  return (
    <Card className="card-display">
      <Typography fontWeight="medium" variant="h3">
        Risk Level Specific Parameters Table
      </Typography>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>Risk Level</th>
            <th>Base Penalty</th>
            <th>Violation Count Threshold</th>
            <th>Violation Count Multiplier</th>
          </tr>
        </thead>
        {scoreParameters && (
          <tbody>
            <tr>
              <th>Immediate</th>
              <td>{scoreParameters.immediateFindingBaseScore}</td>
              <td>{scoreParameters.immediateViolationCountThreshold}</td>
              <td>{scoreParameters.immediateViolationCountMultiplier}</td>
            </tr>
            <tr>
              <th>High</th>
              <td>{scoreParameters.highFindingBaseScore}</td>
              <td>{scoreParameters.highViolationCountThreshold}</td>
              <td>{scoreParameters.highViolationCountMultiplier}</td>
            </tr>
            <tr>
              <th>Medium</th>
              <td>{scoreParameters.mediumFindingBaseScore}</td>
              <td>{scoreParameters.mediumViolationCountThreshold}</td>
              <td>{scoreParameters.mediumViolationCountMultiplier}</td>
            </tr>
            <tr>
              <th>Low</th>
              <td>{scoreParameters.lowFindingBaseScore}</td>
              <td>{scoreParameters.lowViolationCountThreshold}</td>
              <td>{scoreParameters.lowViolationCountMultiplier}</td>
            </tr>
            <tr>
              <th>Informational</th>
              <td>{scoreParameters.informationFindingBaseScore}</td>
              <td>N/A</td>
              <td>N/A</td>
            </tr>
          </tbody>
        )}
      </Table>
      <Typography fontWeight="medium" variant="h3">
        Historical Assesment Data Period Table
      </Typography>
      <Table size="sm">
        <thead>
          <tr>
            <th>Assesment</th>
            <th>
              Historical Time Period{'\n'}
              (in months)
            </th>
            <th>Historical Max Inspections</th>
            <th>Finding Match Multiplier</th>
          </tr>
        </thead>
        {scoreParameters && (
          <tbody>
            <tr>
              <th>HDI</th>
              <td>{scoreParameters.hdiDateIntervalInMonths}</td>
              <td>{scoreParameters.hdiMaxInspections}</td>
              <td>{scoreParameters.hdiMatchesSafeMultiplier}</td>
            </tr>
            <tr>
              <th>Safe</th>
              <td>{scoreParameters.safeDateIntervalInMonths}</td>
              <td>{scoreParameters.safeMaxInspections}</td>
              <td>Refer to Violation History Multiplier Table</td>
            </tr>
          </tbody>
        )}
      </Table>
      <Typography fontWeight="medium" variant="h3">
        Violation History Multiplier Table
      </Typography>
      <Table size="sm">
        <thead>
          <tr>
            <th>Repeat Sequence</th>
            <th>Multiplier</th>
            <th>Repeat Sequence</th>
            <th>Multiplier</th>
          </tr>
        </thead>
        {violationMultiplier && (
          <tbody>
            <tr>
              <td>0000</td>
              <td>{violationMultiplier['0000']}</td>
              <td>0001</td>
              <td>{violationMultiplier['0001']}</td>
            </tr>
            <tr>
              <td>1000</td>
              <td>{violationMultiplier['1000']}</td>
              <td>1001</td>
              <td>{violationMultiplier['1001']}</td>
            </tr>
            <tr>
              <td>0100</td>
              <td>{violationMultiplier['0100']}</td>
              <td>0101</td>
              <td>{violationMultiplier['0101']}</td>
            </tr>
            <tr>
              <td>1100</td>
              <td>{violationMultiplier['1100']}</td>
              <td>1101</td>
              <td>{violationMultiplier['1101']}</td>
            </tr>
            <tr>
              <td>0010</td>
              <td>{violationMultiplier['0010']}</td>
              <td>00111</td>
              <td>{violationMultiplier['0011']}</td>
            </tr>
            <tr>
              <td>1010</td>
              <td>{violationMultiplier['1010']}</td>
              <td>1011</td>
              <td>{violationMultiplier['1011']}</td>
            </tr>
            <tr>
              <td>0110</td>
              <td>{violationMultiplier['0110']}</td>
              <td>0111</td>
              <td>{violationMultiplier['0111']}</td>
            </tr>
            <tr>
              <td>1110</td>
              <td>{violationMultiplier['1110']}</td>
              <td>1111</td>
              <td>{violationMultiplier['1111']}</td>
            </tr>
          </tbody>
        )}
      </Table>
      <Typography fontWeight="medium" variant="h3">
        Performance Level Bin Table
      </Typography>
      <Table size="sm">
        <thead>
          <tr>
            <th>Performance Level</th>
            <th>Lower Bound (Exclusive)</th>
            <th>Upper Bound (Inclusive)</th>
          </tr>
        </thead>
        {scoreParameters && (
          <tbody>
            <tr>
              <td>1</td>
              <td>{0}</td>
              <td>{binSpread}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>{binSpread}</td>
              <td>{binSpread * 2}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>{binSpread * 2}</td>
              <td>{binSpread * 3}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>{binSpread * 3}</td>
              <td>{binSpread * 4}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>{binSpread * 4}</td>
              <td>{binSpread * 5}</td>
            </tr>
            <tr>
              <td>6</td>
              <td>{binSpread * 5}</td>
              <td>{binSpread * 6}</td>
            </tr>
            <tr>
              <td>7</td>
              <td>{binSpread * 6}</td>
              <td>{binSpread * 7}</td>
            </tr>
            <tr>
              <td>8</td>
              <td>{binSpread * 7}</td>
              <td>{binSpread * 8}</td>
            </tr>
            <tr>
              <td>9</td>
              <td>{binSpread * 8}</td>
              <td>{binSpread * 9}</td>
            </tr>
            <tr>
              <td>10</td>
              <td>{binSpread * 9}</td>
              <td>∞</td>
            </tr>
          </tbody>
        )}
      </Table>
      <Typography fontWeight="medium" variant="h3">
        Finding Penalty Table
      </Typography>
      <Table size="sm">
        <thead>
          <tr>
            <th>Question ID</th>
            <th>Penalty</th>
            <th>Risk Level</th>
            <th>Repeat Sequence</th>
            <th>HDI Multiplier Applied</th>
          </tr>
        </thead>
        <tbody>
          {findingPenalty?.map((penalty) => (
            <tr key={penalty.id}>
              <td>{penalty.questionSafeReportId}</td>
              <td>{penalty.penalty}</td>
              <td>{penalty.riskLevel}</td>
              <td>{penalty.repeatRounds}</td>
              <td>{penalty.hdiMultiplierApplied?.toString().toUpperCase()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
