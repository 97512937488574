import {
  SET_USER_TOKEN,
  //
  END_USER_SESSION,
} from '.';
import { setAuthCookie, clearAuthCookie } from '../utils';
import { claimsOneRole } from '../utils/AuthUtils';
import { routeToNewApp } from '../utils/redirectUtil';

export const setUserSession = (accessToken, idToken) => {
  return async (dispatch, getState) => {
    const userToken = {
      name: idToken.claims.name,
      email: idToken.claims.email,
      userType: accessToken.claims.userType,
      smartShopAdmin:
        claimsOneRole(accessToken?.claims, ['SMART_SHOP']) &&
        !claimsOneRole(accessToken?.claims, ['ADMIN']),
      isStaff: idToken.claims['cfa-locations'].includes('00000'),
    };

    const state = getState();
    const location = state.location.selectedLocation;
    if (location) {
      const { appFeaturePermissions } = location;
      userToken.healthPrompt =
        !!appFeaturePermissions?.includes('HEALTH_PROMPT') &&
        !userToken.smartShopAdmin;
      userToken.reportDashboard =
        !!appFeaturePermissions.includes('FOOD_SAFETY_REPORT') &&
        !userToken.smartShopAdmin;
    }

    dispatch(setUserToken(userToken));

    // Set Auth Cookie for TMHP webview
    setAuthCookie(accessToken.accessToken);
  };
};

// Update User Token with Permissions
export const updateUserPermissions = (appFeaturePermissions) => {
  return (dispatch, getState) => {
    const { userToken } = getState().auth;
    if (!userToken) return;

    // Clear Redux user token so state updates globally
    dispatch(setUserToken(null));

    // Set App Features
    userToken.healthPrompt =
      !!appFeaturePermissions?.includes('HEALTH_PROMPT') &&
      !userToken.smartShopAdmin;
    userToken.reportDashboard =
      !!appFeaturePermissions.includes('FOOD_SAFETY_REPORT') &&
      !userToken.smartShopAdmin;

    // Dispatch to Redux
    dispatch(setUserToken(userToken));
  };
};

const setUserToken = (userToken) => {
  return (dispatch) => {
    dispatch({ type: SET_USER_TOKEN, payload: userToken });
  };
};

export const endUserSession = (redirectHome = true) => {
  return (dispatch) => {
    dispatch({ type: END_USER_SESSION });
    clearAuthCookie();
    if (redirectHome) {
      routeToNewApp();
    }
  };
};
