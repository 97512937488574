/* eslint-disable */
// TODO: review disabled eslint rules
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import './AddSafeQuestion.scss';
import {
  Card,
  Button,
  Col,
  Row,
  InputGroup,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Select from 'react-select';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  getSelectedQuestionDetails,
  updateSelectedQuestion,
  getVendorQuestions,
} from '../../../actions';
import CFASpinner from '../../../components/Spinner/CFASpinner';
import NavHeader from '../../../components/NavHeader/NavHeader';
import { getFormRoundOptions } from '../../../utils';
import { claimsOneRole } from '../../../utils/AuthUtils';
import { routeToNewApp } from '../../../utils/redirectUtil';

class AddSafeQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: props.loading,
      form: {
        questionUuid: '',
        safeReportId: '',
        text: '',
        standard: '',
        textTranslations: {
          spanishText: '',
        },
        riskLevel: '',
        category: '',
        effectiveStartRound: '',
        effectiveEndRound: null,
        hdiQuestionMappings: [],
        sdcQuestionMappings: [],
      },
      submitDisabled: true,
      textDeleted: false,
      standardDeleted: false,
      esDeleted: false,
      hdiMappingsDeleted: false,
      effectiveStartRoundDeleted: false,
      effectiveEndRoundDeleted: false,
      sdcMappingsDeleted: false,
      selectedQuestionDetails: {
        questionUuid: '',
        safeReportId: '',
        text: '',
        standard: '',
        textTranslations: {
          spanishText: '',
          frenchCanadian: '',
        },
        riskLevel: '',
        riskLevelOpen: false,
        category: {
          uuid: '',
          name: '',
        },
        categoryOpen: false,
        effectiveStartRound: '',
        effectiveEndRound: null,
        viewableOnReport: false,
        hdiQuestionMappings: [],
        sdcQuestionMappings: [],
      },
      error: null,
      roundValidity: {
        roundsInvalid: false,
        errorMessage: '',
      },
      roundOptions: getFormRoundOptions(),
      roundOptionValues: getFormRoundOptions().map((round) => round.value),
    };

    this.effectiveStartRoundChange = this.effectiveStartRoundChange.bind(this);
    this.effectiveEndRoundChange = this.effectiveEndRoundChange.bind(this);
    this.hdiQuestionsChange = this.hdiQuestionsChange.bind(this);
    this.sdcQuestionsChange = this.sdcQuestionsChange.bind(this);
    this.validateRounds = this.validateRounds.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleViewableCheckbox = this.handleViewableCheckbox.bind(this);
  }

  componentDidMount() {
    window.scrollTo(500, 0);
    if (!claimsOneRole(this.props.authState.accessToken.claims, ['SAFE_ADMIN']))
      routeToNewApp();
    const { safeReportId } = this.props.match.params;
    this.props.getSelectedQuestionDetails(safeReportId);
    this.props.getVendorQuestions();

    if (this.state.selectedQuestionDetails) {
      this.setState({
        form: { ...this.state.form, form: this.state.selectedQuestionDetails },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.form.questionUuid && this.state.selectedQuestionDetails) {
      this.setState({
        form: {
          ...this.state.form,
          questionUuid: this.state.selectedQuestionDetails.questionUuid,
        },
      });
    }

    if (!this.state.form.safeReportId && this.state.selectedQuestionDetails) {
      this.setState({
        form: {
          ...this.state.form,
          safeReportId: this.state.selectedQuestionDetails.safeReportId,
        },
      });
    }

    if (
      !this.state.form.text &&
      this.state.textDeleted !== true &&
      this.state.selectedQuestionDetails
    ) {
      this.setState({
        form: {
          ...this.state.form,
          text: this.state.selectedQuestionDetails.text,
        },
      });
    }

    if (
      !this.state.form.standard &&
      this.state.standardDeleted !== true &&
      this.state.selectedQuestionDetails
    ) {
      this.setState({
        form: {
          ...this.state.form,
          standard: this.state.selectedQuestionDetails.standard,
        },
      });
    }

    if (!this.state.form.category && this.state.selectedQuestionDetails) {
      this.setState({
        form: {
          ...this.state.form,
          category: this.state.selectedQuestionDetails.category.name,
        },
      });
    }

    if (!this.state.form.riskLevel && this.state.selectedQuestionDetails) {
      this.setState({
        form: {
          ...this.state.form,
          riskLevel: this.state.selectedQuestionDetails.riskLevel,
          viewableOnReport: this.state.selectedQuestionDetails.viewableOnReport,
        },
      });
    }

    if (
      !this.state.form.effectiveStartRound &&
      this.state.selectedQuestionDetails.effectiveStartRound &&
      this.state.effectiveStartRoundDeleted !== true
    ) {
      this.setState({
        form: {
          ...this.state.form,
          effectiveStartRound:
            this.state.selectedQuestionDetails.effectiveStartRound,
        },
      });
    }

    if (
      !this.state.form.effectiveEndRound &&
      this.state.selectedQuestionDetails.effectiveEndRound &&
      this.state.effectiveEndRoundDeleted !== true
    ) {
      this.setState({
        form: {
          ...this.state.form,
          effectiveEndRound:
            this.state.selectedQuestionDetails.effectiveEndRound,
        },
      });
    }

    if (
      !this.state.form.textTranslations &&
      this.state.selectedQuestionDetails?.textTranslations
    ) {
      const txtTrans = JSON.parse(
        JSON.stringify(this.state.selectedQuestionDetails.textTranslations),
      );
      this.setState({
        form: { ...this.state.form, textTranslations: txtTrans },
      });
    }

    if (
      this.state.form.hdiQuestionMappings &&
      this.state.form.hdiQuestionMappings.length === 0 &&
      this.state.hdiMappingsDeleted !== true &&
      this.state.selectedQuestionDetails.hdiQuestionMappings
    ) {
      this.setState({
        form: {
          ...this.state.form,
          hdiQuestionMappings:
            this.state.selectedQuestionDetails.hdiQuestionMappings,
        },
      });
    }

    if (
      this.state.form.sdcQuestionMappings &&
      this.state.form.sdcQuestionMappings.length === 0 &&
      this.state.sdcMappingsDeleted !== true &&
      this.state.selectedQuestionDetails.sdcQuestionMappings
    ) {
      this.setState({
        form: {
          ...this.state.form,
          sdcQuestionMappings:
            this.state.selectedQuestionDetails.sdcQuestionMappings,
        },
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const update = {};

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    // Update Error
    if (props.error !== state.error) {
      update.error = props.error;
    }

    if (props.selectedQuestionDetails !== state.selectedQuestionDetails) {
      update.selectedQuestionDetails = props.selectedQuestionDetails;
    }

    return update;
  }

  getTextField = () => {
    if (!this.state.selectedQuestionDetails) {
      return null;
    }
    if (this.state.textDeleted) {
      return this.state.form.text;
    }
    if (this.state.form.text) {
      return this.state.form.text;
    }
    return this.state.selectedQuestionDetails.text;
  };

  getStandardField = () => {
    if (!this.state.selectedQuestionDetails) {
      return null;
    }
    if (this.state.standardDeleted) {
      return this.state.form.standard;
    }
    if (this.state.form.standard) {
      return this.state.form.standard;
    }
    return this.state.selectedQuestionDetails.standard;
  };

  getSpanishText = () => {
    if (!this.state.selectedQuestionDetails) {
      return null;
    }

    if (this.state.esDeleted) {
      return '';
    }

    if (
      this.state.form.textTranslations &&
      this.state.form.textTranslations.spanishText
    ) {
      return this.state.form.textTranslations.spanishText;
    }
    if (
      this.state.selectedQuestionDetails.textTranslations &&
      this.state.selectedQuestionDetails.textTranslations.spanishText
    ) {
      return this.state.selectedQuestionDetails.textTranslations.spanishText;
    }
    return '';
  };

  // getHdiMappings = () => {
  //   if (!this.state.selectedQuestionDetails) {
  //     return null;
  //   }
  //   if (this.state.hdiMappingsDeleted) {
  //     return this.state.form.hdiQuestionMappings;
  //   }
  //   if (
  //     this.state.form.hdiQuestionMappings &&
  //     this.state.form.hdiQuestionMappings.length > 0
  //   ) {
  //     return this.state.form.hdiQuestionMappings;
  //   }
  //   return this.state.selectedQuestionDetails.hdiQuestionMappings;
  // };

  // getSdcMappings = () => {
  //   if (!this.state.selectedQuestionDetails) {
  //     return null;
  //   }
  //   if (this.state.sdcMappingsDeleted) {
  //     return this.state.form.sdcQuestionMappings;
  //   }
  //   if (
  //     this.state.form.sdcQuestionMappings &&
  //     this.state.form.sdcQuestionMappings.length > 0
  //   ) {
  //     return this.state.form.sdcQuestionMappings;
  //   }
  //   return this.state.selectedQuestionDetails.sdcQuestionMappings;
  // };

  effectiveStartRoundChange = (effectiveStartRound) => {
    this.setState({
      form: {
        ...this.state.form,
        effectiveStartRound: effectiveStartRound.value,
      },
      submitDisabled: false,
      effectiveStartRoundDeleted: effectiveStartRound.label === '-',
      roundValidity: this.validateRounds(
        effectiveStartRound.value,
        this.state.form.effectiveEndRound,
      ),
    });
  };

  effectiveEndRoundChange = (effectiveEndRound) => {
    this.setState({
      form: {
        ...this.state.form,
        effectiveEndRound: effectiveEndRound.value,
      },
      submitDisabled: false,
      effectiveEndRoundDeleted: effectiveEndRound.label === '-',
      roundValidity: this.validateRounds(
        this.state.form.effectiveStartRound,
        effectiveEndRound.value,
      ),
    });
  };

  hdiQuestionsChange = (hdiQuestions) => {
    this.setState({
      form: {
        ...this.state.form,
        hdiQuestionMappings: hdiQuestions,
      },
      submitDisabled: false,
      hdiMappingsDeleted: hdiQuestions.length === 0,
    });
  };

  sdcQuestionsChange = (sdcQuestions) => {
    this.setState({
      form: {
        ...this.state.form,
        sdcQuestionMappings: sdcQuestions,
      },
      submitDisabled: false,
      sdcMappingsDeleted: sdcQuestions.length === 0,
    });
  };

  handleViewableCheckbox = (viewableOnReport) => {
    this.setState({
      form: {
        ...this.state.form,
        viewableOnReport: !this.state.form.viewableOnReport,
      },
      submitDisabled: false,
    });
  };

  handleChangeInput = (event) => {
    this.setState({ submitDisabled: false });

    if (event == null) return;
    const { target } = event;
    const { name } = target;

    // Parse value from event. If event.target is undefined, set value to event (for location)
    const value = event.target != null ? event.target.value : event;

    // check for when user deletes all characters in 'Text' input field
    if (name === 'text' && value === '') {
      this.setState({ textDeleted: true });
      this.setState({ form: { ...this.state.form, text: '' } });
    }

    // check for when user deletes all characters in 'Standard' input field
    if (name === 'standard' && value === '') {
      this.setState({ standardDeleted: true });
      this.setState({ form: { ...this.state.form, standard: '' } });
    }

    // check for when user deletes all characters in 'spanishText input field
    if (name === 'spanishText' && value === '') {
      this.setState({ esDeleted: true });
      this.setState({ form: { ...this.state.form, spanishText: '' } });
    } else if (name === 'spanishText' && value !== '') {
      this.setState({ esDeleted: false });
    }

    // check for when SDCQuestions are deleted
    if (name === 'sdcQuestions' && value === '') {
      this.setState({ sdcMappingsDeleted: true });
      this.setState({ form: { ...this.state.form, sdcQuestionMappings: [] } });
    } else if (name === 'sdcQuestions' && value === '') {
      this.setState({ sdcMappingsDeleted: false });
    }

    // Get a snapshot of the current form state
    const currentForm = this.state.form;

    let form = {};
    if (name === 'spanishText') {
      const { textTranslations } = { ...this.state.form };
      const currentState = textTranslations;
      const { name, value } = event.target;
      currentState[name] = value;

      form = {
        ...currentForm,
        textTranslations: currentState,
      };
      this.setState({ textTranslations: currentState });
    } else if (name === 'sdcQuestions') {
      const { value } = event.target;
      const sdcWithNoSpaces = value.replace(' ', '');
      const sdcArray = sdcWithNoSpaces.split(',');

      form = {
        ...currentForm,
        sdcQuestionMappings: [...sdcArray],
      };
    } else {
      // Do this when the target name is not = 'spanishText'
      form = {
        ...currentForm,
        [name]: value,
      };
    }
    this.setState({ form });
  };

  validateRounds(startRound, endRound) {
    // If Effective Start Round is null, then Effective End Round must also be null
    if (startRound === null && endRound) {
      return {
        roundsInvalid: true,
        errorMessage:
          'Effective End Round cannot exist if Effective Start Round does not exist',
      };
    }

    // A non-null Effective Start Round value must be lesser than or equal to the Effective End Round value
    if (
      this.state.roundOptionValues.indexOf(startRound) >
      this.state.roundOptionValues.indexOf(endRound)
    ) {
      return {
        roundsInvalid: true,
        errorMessage:
          'Effective Start Round cannot come after Effective End Round',
      };
    }

    // A non-null (i.e. saved in the database) Effective Start Round cannot be cleared out
    if (
      this.props.selectedQuestionDetails.effectiveStartRound &&
      startRound === null
    ) {
      return {
        roundsInvalid: true,
        errorMessage: 'Effective Start Round cannot be cleared out',
      };
    }

    return { roundsInvalid: false, errorMessage: '' };
  }

  submitForm() {
    this.props.updateSelectedQuestion(this.state.form);
  }

  render() {
    return (
      <>
        <NavHeader />
        <Col className="SelfAssessment container ">
          <Row className="container">
            <a
              onClick={this.props.history.goBack}
              className="desktop-back-container"
            >
              <img
                alt=""
                src={require('../../../assets/icons/left-arrow.svg').default}
                height={15}
                width={15}
                className="desktop-back-arrow"
              />
              <span className="desktop-back-link">Manage Questions</span>
            </a>
          </Row>
          <Row>
            <Col className="container">
              <Card className="add-safe-question-card container">
                {this.state.selectedQuestionDetails && (
                  <>
                    <FormGroup className="safe-question-form">
                      <Row className="container">
                        <Row className="input-row">
                          <h3>Edit Question</h3>
                        </Row>
                        <Row className="input-row">
                          <Label className="input-label">Safe Report Id</Label>
                          <InputGroup>
                            <Input
                              className="safereport-id"
                              id="safeReportId"
                              name="safeReportId"
                              type="text"
                              maxLength="55"
                              disabled
                              value={
                                this.state.form.safeReportId
                                  ? this.state.form.safeReportId
                                  : this.state.selectedQuestionDetails
                                      .safeReportId
                              }
                              onChange={this.handleChangeInput}
                            />
                          </InputGroup>
                        </Row>
                        <Row className="input-row">
                          <Label className="input-label">Risk Level</Label>
                          <InputGroup>
                            <Input
                              className="safereport-id"
                              id="riskLevel"
                              name="riskLevel"
                              type="text"
                              maxLength="55"
                              disabled={false}
                              value={
                                this.state.form.riskLevel
                                  ? this.state.form.riskLevel
                                  : this.state.selectedQuestionDetails.riskLevel
                              }
                              onChange={this.handleChangeInput}
                            />
                          </InputGroup>
                        </Row>
                        <Row className="input-row">
                          <Label className="input-label">Category</Label>
                          <InputGroup>
                            <Input
                              className="safereport-id"
                              id="category"
                              name="category"
                              type="text"
                              maxLength="55"
                              disabled={false}
                              value={
                                this.state.form.category
                                  ? this.state.form.category
                                  : this.state.selectedQuestionDetails.category
                                      .name
                              }
                              onChange={this.handleChangeInput}
                            />
                          </InputGroup>
                        </Row>
                        <Row className="input-row">
                          <Label className="input-label">Standard</Label>
                          <InputGroup>
                            <Input
                              id="standard"
                              name="standard"
                              type="textarea"
                              disabled
                              value={this.getStandardField()}
                              onChange={this.handleChangeInput}
                            />
                          </InputGroup>
                        </Row>
                        <Row className="input-row">
                          <Label className="input-label">
                            Report Messaging
                          </Label>
                          <InputGroup>
                            <Input
                              id="text"
                              name="text"
                              type="textarea"
                              value={this.getTextField()}
                              onChange={this.handleChangeInput}
                            />
                          </InputGroup>
                        </Row>
                        <Row className="input-row">
                          <Label className="input-label">
                            Report Messaging (Espanol)
                          </Label>
                          <InputGroup>
                            <Input
                              id="spanishText"
                              name="spanishText"
                              type="textarea"
                              value={this.getSpanishText()}
                              onChange={this.handleChangeInput}
                            />
                          </InputGroup>
                        </Row>
                        <Row className="dropdown-label-row">
                          <Label className="effective-start-date">
                            EFFECTIVE START ROUND
                          </Label>
                          <Label className="effective-end-date">
                            EFFECTIVE END ROUND
                          </Label>
                        </Row>
                        <Row className="input-row">
                          <Select
                            options={this.state.roundOptions}
                            defaultValue={{
                              label:
                                this.state.selectedQuestionDetails
                                  .effectiveStartRound,
                              value:
                                this.state.selectedQuestionDetails
                                  .effectiveStartRound,
                            }}
                            onChange={this.effectiveStartRoundChange}
                            className="quarter-select-container quarter-dropdown add-safe-question-form__start-round"
                            isClearable={false}
                            isRtl={false}
                            isSearchable={false}
                            name="startRound"
                          />
                          <Select
                            options={this.state.roundOptions}
                            defaultValue={{
                              label:
                                this.state.selectedQuestionDetails
                                  .effectiveEndRound,
                              value:
                                this.state.selectedQuestionDetails
                                  .effectiveEndRound,
                            }}
                            onChange={this.effectiveEndRoundChange}
                            className="quarter-select-container quarter-dropdown add-safe-question-form__end-round"
                            isClearable={false}
                            isRtl={false}
                            isSearchable={false}
                            name="startRound"
                          />
                          {this.state.form.riskLevel === 'Informational' && (
                            <div>
                              <input
                                className="question-management-viewable-on-report-checkbox"
                                type="checkbox"
                                id="viewableOnReport"
                                name="viewableOnReport"
                                defaultValue={{
                                  label:
                                    this.state.selectedQuestionDetails
                                      .viewableOnReport,
                                  value:
                                    this.state.selectedQuestionDetails
                                      .viewableOnReport,
                                }}
                                onClick={this.handleViewableCheckbox}
                                checked={this.state.form.viewableOnReport}
                              />
                              <label className="question-management-viewable-on-report-label">
                                Viewable on Report
                              </label>
                            </div>
                          )}
                          <Row>
                            {this.state.roundValidity.roundsInvalid && (
                              <p className="add-safe-question-form__round-error">
                                {' '}
                                {this.state.roundValidity.errorMessage}{' '}
                              </p>
                            )}
                          </Row>
                        </Row>
                        <Row className="dropdown-label-row">
                          <Label className="input-label">
                            {' '}
                            MAPPED HEALTH DEPT.
                          </Label>
                        </Row>
                        <Row className="input-row">
                          <Typeahead
                            className="add-safe-question-form__typeahead"
                            id="basic-typeahead-multiple"
                            labelKey="hdiQuestionMappings"
                            multiple
                            onChange={this.hdiQuestionsChange}
                            options={this.props.vendorQuestions.hdiQuestions}
                            placeholder="Select HDI Question ID(s) ..."
                            selected={this.state.form.hdiQuestionMappings}
                          />
                        </Row>
                        <Row className="dropdown-label-row">
                          <Label className="input-label">
                            SAFE DAILY CRITICAL QUESTIONS
                          </Label>
                        </Row>
                        <Row className="input-row">
                          <Typeahead
                            className="add-safe-question-form__typeahead"
                            id="basic-typeahead-multiple"
                            labelKey="hdiQuestionMappings"
                            multiple
                            onChange={this.sdcQuestionsChange}
                            options={
                              this.props.vendorQuestions
                                ? this.props.vendorQuestions.sdcQuestions
                                : []
                            }
                            placeholder="Select SDC Question ID(s) ..."
                            selected={this.state.form.sdcQuestionMappings}
                          />
                        </Row>
                      </Row>
                    </FormGroup>
                    {!this.state.loading ? (
                      <>
                        {this.state.error && (
                          <h4 className="error">
                            There was an error while submitting the form. Please
                            try again.
                          </h4>
                        )}
                        <Button
                          disabled={
                            this.state.submitDisabled ||
                            this.state.roundValidity.roundsInvalid
                          }
                          className="submit-button"
                          block
                          onClick={this.submitForm}
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <CFASpinner />
                    )}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, selectedQuestionDetails, vendorQuestions } =
    state.admin;
  return { loading, error, selectedQuestionDetails, vendorQuestions };
};

export default withOktaAuth(
  withRouter(
    connect(mapStateToProps, {
      getSelectedQuestionDetails,
      updateSelectedQuestion,
      getVendorQuestions,
    })(AddSafeQuestion),
  ),
);

/*
//Legacy code saved 10/26

-- This was used when risk level/ category level were mutable

 riskLvlToggle = () => {
 this.setState({
      riskLevelOpen: !this.state.riskLevelOpen
    })
  }

  riskLvlChangeValue = (e) => {
    this.setState({ form: {...this.state.form, riskLevel: e.currentTarget.textContent}})
    this.setState({submitDisabled: false})
  }

  categoryToggle = () => {
    this.setState({
      categoryOpen: !this.state.categoryOpen
    })
  }

  categoryChangeValue = (e) => {
    this.setState({ form: {...this.state.form, category: e.currentTarget.textContent}})
    this.setState({submitDisabled: false})
  }

  effectiveStartDateChange = (effectiveStartDate) => {
    this.setState({form: {...this.state.form, effectiveStartDate: effectiveStartDate}})
    this.setState({submitDisabled: false})
  }

  -- in the render:

   <Row className="input-row">
                          <Col className="risk-column">
                          <>
                          <ButtonDropdown name="riskLevel" id="riskLevel" className="risk-level-btn-dropdown" isOpen={this.state.riskLevelOpen} toggle={this.riskLvlToggle}>
                            <DropdownToggle className="risk-dropdown-toggle" caret size="sm" color="$white-gray">
                              {
                                 this.state.form.riskLevel
                                      ? this.state.form.riskLevel
                                      : this.state.selectedQuestionDetails.riskLevel
                              }
                            </DropdownToggle>
                              <DropdownMenu className="risk-level-dropdown-menu">
                                <DropdownItem id="ddImmediateAction" value="Immediate Action" onClick={this.riskLvlChangeValue}>Immediate Action</DropdownItem>
                                <DropdownItem id="ddHighRisk" value="High" onClick={this.riskLvlChangeValue}>High</DropdownItem>
                                <DropdownItem id="ddMediumRisk" value="Medium Risk" onClick={this.riskLvlChangeValue}>Medium</DropdownItem>
                                <DropdownItem id="ddLowRisk" value="Low Risk" onClick={this.riskLvlChangeValue}>Low</DropdownItem>
                                <DropdownItem id="ddInformational" value="Informational" onClick={this.riskLvlChangeValue}>Informational</DropdownItem>
                              </DropdownMenu>
                          </ButtonDropdown>
                          </>
                          </Col>
                          <Col className="category-column">
                          <>
                          <ButtonDropdown name="category" id="category" className="category-btn-dropdown" isOpen={this.state.categoryOpen} toggle={this.categoryToggle}>
                            <DropdownToggle className="category-dropdown-toggle" caret size="sm" color="$white-gray">
                              {
                                 this.state.form.category
                                      ? this.state.form.category
                                      : this.state.selectedQuestionDetails.category.name
                              }
                            </DropdownToggle>
                              <DropdownMenu className="category-dropdown-menu">
                                <DropdownItem id="ddCleaningSanitation" style={{paddingLeft: '0px'}} value="Cleaning &amp; Sanitation" onClick={this.categoryChangeValue}>Cleaning &amp; Sanitation</DropdownItem>
                                <DropdownItem id="ddCrossContamination" style={{paddingLeft: '0px'}} value="Cross Contamination" onClick={this.categoryChangeValue}>Cross Contamination</DropdownItem>
                                <DropdownItem id="ddHealthHygiene" style={{paddingLeft: '0px'}} value="Hygiene" onClick={this.categoryChangeValue}>Health &amp; Hygiene</DropdownItem>
                                <DropdownItem id="ddPests" style={{paddingLeft: '0px'}} value="Pests" onClick={this.categoryChangeValue}>Pests</DropdownItem>
                                <DropdownItem id="ddTemperature" style={{paddingLeft: '0px'}} value="Time &amp; Temperature" onClick={this.categoryChangeValue}>Time &amp; Temperature</DropdownItem>
                              </DropdownMenu>
                          </ButtonDropdown>
                          </>
                          </Col>
                        </Row>
                        <Row className="dropdown-label-row">
                            <Label className="effective-start-date">EFFECTIVE START DATE</Label>
                            <Label className="effective-end-date">EFFECTIVE END DATE</Label>
                        </Row>
                        <Row className="input-row">
                          <Input className="eff-start-date-picker"
                            name="effectiveStartDate"
                            type="date"
                            size="sm"
                            value={
                                  this.state.form.effectiveStartDate
                                    ? this.state.form.effectiveStartDate
                                    : this.state.selectedQuestionDetails.effectiveStartDate
                                    }
                            onChange={this.handleChangeInput}/>
                          <InputGroup className="eff-end-date-group">
                            <Input className="eff-end-date-picker"
                              name="effectiveEndDate"
                              type="date"
                              size="sm"
                              value={this.state.form.effectiveEndDate
                                      ? this.state.form.effectiveEndDate
                                      : this.state.selectedQuestionDetails.effectiveEndDate}
                              onChange={this.handleChangeInput}/>
                          </InputGroup>

 */
