import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { trackEvent } from '../../utils';

import './PathwayCard.scss';

export default function PathwayCard({ pathwayList, ...props }) {
  const { userToken } = useSelector((state) => state.auth);

  return (
    <div className="pathway-card__container" {...props}>
      <p className="finding-details-card__links-label">
        <img
          alt="pathway"
          className="finding-details-card__links-icon"
          src={require(`../../assets/icons/pathway-links.svg`).default}
        />
        Pathway Procedures
      </p>
      <p>
        Consider reviewing Pathway procedures for the problems you've
        identified.
      </p>

      <div className="pathway-card__links-container">
        {pathwayList?.map((link, i) => (
          <a
            href={link.url}
            onClick={() =>
              trackEvent('pathway-link-clicked', userToken.userRole)
            }
            key={`${i}-${link.url}`}
            target="_blank"
            rel="noopener noreferrer"
            className="finding-details-card__links-url fsd__links-url"
            style={{ display: 'inline-block' }}
          >
            {link.linkStr}
          </a>
        ))}
      </div>
    </div>
  );
}

PathwayCard.propTypes = {
  pathwayList: PropTypes.array,
};
