import PropTypes from 'prop-types';

import './CancelNextButtons.scss';

function CancelNextButtons({ handleCancel, handleNext, isDisabled, ...props }) {
  return (
    <div className="cancel-next-button__container" {...props}>
      <button
        type="button"
        className="request-amendment-form__cancel-btn"
        onClick={handleCancel}
      >
        Cancel
      </button>
      <button
        type="button"
        className={`request-amendment-form__submit-btn cancel-next-button__next-btn ${
          isDisabled && 'request-amendment-form__submit-btn--disabled'
        }`}
        onClick={handleNext}
        disabled={isDisabled}
      >
        Next
      </button>
    </div>
  );
}

CancelNextButtons.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default CancelNextButtons;
