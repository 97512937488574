// eslint - disable import /first
import axios from 'axios';
import handleError from './APIError';
import { getAPILanguageLocaleString } from '../../../i18n/utils';
import { oktaAuthClient } from '../AuthUtils';

// Init Axios Instance
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (res) => {
    return res;
  },
  (error) => {
    // Send Error to handler
    const handledError = handleError(error);
    return Promise.reject(handledError);
  },
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    // Send Error to handler
    const handledError = handleError(error.response);
    return Promise.reject(handledError);
  },
);

const getConfig = async () => {
  // Get latest accessToken
  const { accessToken } = await oktaAuthClient.tokenManager.get('accessToken');

  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

const addPathLanguageSuffix = (path) => {
  const locale = getAPILanguageLocaleString();
  if (locale === 'EN') return path;
  return path.indexOf('?') !== -1
    ? `${path}&language=${locale}`
    : `${path}?language=${locale}`;
};

export const API_GET = async (path) => {
  return axiosInstance.get(addPathLanguageSuffix(path), await getConfig());
};

export const API_GET_FILE = async (path) => {
  const config = await getConfig();
  config.responseType = 'blob';
  config.headers.Accept = 'application/octet-stream';
  return axiosInstance.get(addPathLanguageSuffix(path), config);
};

export const API_POST = async (path, body) => {
  return axiosInstance.post(
    addPathLanguageSuffix(path),
    body,
    await getConfig(),
  );
};

export const API_PATCH = async (path, body) => {
  return axiosInstance.patch(path, body, await getConfig());
};

export const API_PUT = async (path, body) => {
  return axiosInstance.put(path, body, await getConfig());
};

export const API_DEL = async (path) => {
  return axiosInstance.delete(path, await getConfig());
};
