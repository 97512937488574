import queryString from 'query-string';
import { useEffect, useState, useCallback } from 'react';
import { API_GET } from '../api/API';
import { formatReverseDate } from '../DateUtil';
import { uniqueByKey } from '../filters';

const domain = process.env.REACT_APP_SERVER_URL;

const splitFindingProperties = (finding) => {
  const [questionInternalId, description] = finding.split(':');
  return {
    description,
    questionInternalId,
    label: finding,
  };
};

const uniqueByInternalId = (findings = []) => {
  const mappedFindings = findings.map(splitFindingProperties);
  return uniqueByKey(mappedFindings, 'questionInternalId');
};

const useSmartShopAdminFindings = (
  startDate = null,
  endDate = null,
  program = 'SMARTSHOP',
  provider = 'ECOSURE',
  page = 1,
  pageSize = 1000,
) => {
  const [findings, setFindings] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const getFindings = useCallback(
    async (start, end) => {
      try {
        const filterEndDate = formatReverseDate(end);
        const filterStartDate = formatReverseDate(start);
        const queryParams = {
          program,
          provider,
        };
        if (startDate) {
          queryParams.filterStartDate = filterStartDate;
        }
        if (endDate) {
          queryParams.filterEndDate = filterEndDate;
        }
        const url = `${domain}/assessment/response/standards-in-period?${queryString.stringify(
          { page, pageSize, program, provider, filterEndDate, filterStartDate },
        )}`;

        const { data: findingsData } = await API_GET(url);
        if (findingsData) {
          return uniqueByInternalId(findingsData);
        }
      } catch (error) {
        console.error(error);
      }
      return false;
    },
    [program, provider, startDate, endDate, page, pageSize],
  );

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      setIsFetching(true);
      const result = await getFindings(startDate, endDate);
      if (isMounted && result) {
        setFindings(result);
        setIsFetching(false);
      }
    };
    if (endDate && startDate) {
      getData();
    }
    return () => {
      isMounted = false;
    };
  }, [getFindings, endDate, startDate]);

  return { findings, isFetching, getFindings };
};

export default useSmartShopAdminFindings;
