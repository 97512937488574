import './SafeAuditAdmin.scss';

import I18n from 'i18n-js';
import { Table } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  Button,
  Container,
  Dropdown,
  LoadingIndicator,
  Modal,
  Row,
  Col,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import {
  API_GET,
  formatReverseDate,
  showSuccessNotification,
} from '../../../utils';
import { claimsOneRole } from '../../../utils/AuthUtils';
import MobileNestedHeader from '../../../components/NavHeader/MobileNestedHeader/MobileNestedHeader';
import NavHeader from '../../../components/NavHeader/NavHeader';
import { ManageSafeScoreModal } from './modals/ManageSafeScoreModal';
import { DeleteAuditModal } from './modals/DeleteAuditModal';
import { routeToNewApp } from '../../../utils/redirectUtil';

export default function SafeAuditAdmin() {
  const { authState } = useOktaAuth();

  const availableLocations = useSelector(
    (state) => state.location.availableLocations,
  );

  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationAudits, setLocationAudits] = useState(null);
  const [locationAuditsLoading, setLocationAuditsLoading] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showSafeScoreModal, setShowSafeScoreModal] = useState(false);

  useEffect(() => {
    if (!claimsOneRole(authState.accessToken.claims, ['SAFE_ADMIN'])) {
      routeToNewApp();
    }
  }, []);

  useEffect(() => {
    if (availableLocations) {
      setLocationOptions(
        availableLocations.map((location) => location.locationNumber),
      );
    }
  }, [availableLocations]);

  useEffect(() => {
    if (selectedLocation) {
      fetchLocationAudits();
    }
  }, [selectedLocation]);

  const fetchLocationAudits = async () => {
    setLocationAuditsLoading(true);
    const { data } = await API_GET(
      `${process.env.REACT_APP_SERVER_URL}/audit/by-location/${selectedLocation}`,
    );
    setLocationAudits(data);
    setLocationAuditsLoading(false);
  };

  const handleDeleteButtonClick = (audit) => {
    setSelectedAudit(audit);
    setShowDeleteModal(true);
  };

  const onDeleteSuccess = () => {
    showSuccessNotification({
      message: `Successfully deleted ${selectedAudit.round} audit for location ${selectedAudit.locationNumber} (${selectedAudit.externalId})`,
    });

    fetchLocationAudits();

    setShowDeleteModal(false);
    setSelectedAudit(null);
  };

  const handleSafeScoreButtonClick = (audit) => {
    setSelectedAudit(audit);
    setShowSafeScoreModal(true);
  };

  return (
    <div className="safe-audit-admin-container">
      <div className="d-none d-sm-block">
        <NavHeader />
      </div>
      <div className="d-block d-sm-none">
        <MobileNestedHeader
          title={I18n.t('APP_ADMIN_AMENDMENTS_CONTAINER_TITLE')}
          navigateBackRoute={false}
        />
      </div>
      <Container>
        <Row>
          <Col>
            <Dropdown
              label="Select a Location"
              placeholder="Location"
              value={selectedLocation}
              onChange={setSelectedLocation}
              options={locationOptions}
              disabled={locationAuditsLoading}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            {locationAuditsLoading ? (
              <div className="grid-loading-indicator">
                <LoadingIndicator size="lg" variant="page" />
              </div>
            ) : (
              locationAudits && (
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Round</th>
                      <th>Inspection Date</th>
                      <th>EcoSure Audit ID</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationAudits.map((audit) => (
                      <tr key={audit.externalId}>
                        <td>{audit.round}</td>
                        <td>
                          {formatReverseDate(
                            audit.locationLocalizedInspectionTimestamp,
                          )}
                        </td>
                        <td>{audit.externalId}</td>
                        <td>
                          <Container>
                            <Row>
                              <Col md="auto">
                                <Button
                                  color="primary"
                                  size="sm"
                                  variant="destructive"
                                  onClick={() => handleDeleteButtonClick(audit)}
                                >
                                  Delete
                                </Button>
                              </Col>
                              <Col md="auto">
                                <Button
                                  color="secondary"
                                  size="sm"
                                  onClick={() =>
                                    handleSafeScoreButtonClick(audit)
                                  }
                                >
                                  SAFE Score
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
            )}
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={showSafeScoreModal}
        disableOverlayClick
        onClose={() => setShowSafeScoreModal(false)}
      >
        <ManageSafeScoreModal
          audit={selectedAudit}
          onClose={() => setShowSafeScoreModal(false)}
        />
      </Modal>

      <Modal
        size="md"
        show={showDeleteModal}
        disableOverlayClick
        onClose={() => setShowDeleteModal(false)}
      >
        <DeleteAuditModal
          audit={selectedAudit}
          onSuccess={onDeleteSuccess}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
    </div>
  );
}
