const customPointSymbol = ({ color, borderWidth, borderColor, datum }) => (
  <g data-testid="custom-point-symbol-graphic">
    <circle
      data-testid="custom-point-symbol-circle"
      r={15}
      style={{ marginLeft: 60, textAlign: 'center' }}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
    />
    <text
      data-testid="custom-point-symbol-text"
      fontSize="14"
      fontFamily="Verdana"
      x={Math.abs(datum.y).toString().length === 2 ? -6 : -4}
      y="5"
      fill="#ffffff"
    >
      {`${datum.y}`}
    </text>
  </g>
);

export default customPointSymbol;
