import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

export default function SubmitButtons({
  handleFormSubmit,
  handleCancelBtn,
  disabledBtn,
  submitButtonText,
}) {
  return (
    <Row
      className="request-amendment-form__submit-btn-container"
      data-testid="submit-buttons-submit-button-container-row"
    >
      <button
        type="button"
        className="request-amendment-form__cancel-btn"
        data-testid="submit-buttons-cancel-button"
        onClick={handleCancelBtn}
      >
        Cancel
      </button>
      <button
        type="button"
        className={`request-amendment-form__submit-btn ${
          disabledBtn && 'request-amendment-form__submit-btn--disabled'
        }`}
        data-testid="submit-buttons-submit-button"
        onClick={handleFormSubmit}
        disabled={disabledBtn}
      >
        {submitButtonText}
      </button>
    </Row>
  );
}

SubmitButtons.propTypes = {
  handleFormSubmit: PropTypes.func,
  handleCancelBtn: PropTypes.func,
  disabledBtn: PropTypes.bool,
  submitButtonText: PropTypes.string,
};
