import { Card, CardContent, Typography } from 'cfa-react-components';

import { FoodSafetyLabel } from '../../../../components/common/FoodSafetyLabel/FoodSafetyLabel';
import {
  lowercaseWithDashes,
  renderFindingColor,
  uppercaseWithUnderscores,
} from '../../../../utils/FindingsUtil';
import { INFORMATIONAL_RISK_SHORT } from '../../../../constants/constants';
import I18n from '../../../../../i18n/utils';
import { formatDateMonthsAndFullYear } from '../../../../utils';
import './SelectedFinding.scss';
import FindingsQuestionChain from '../FindingsQuestionChain/FindingsQuestionChain';
import FindingsPhotos from '../FindingsPhotos/FindingsPhotos';
import PastFindings from '../PastFindings/PastFindings';

const SelectedFinding = ({ finding = {}, childrenFindings = [] }) => (
  <div className="selected-finding">
    <Typography
      variant="h3"
      gutterBottom
      className="selected-finding__finding-details-label"
    >
      {`${I18n.t('APP_LVR_FINDING_DETAILS_RESPONSE_LABEL')} ${I18n.t(
        'APP_REPORT_DASHBOARD_DETAILS',
      )}`}
    </Typography>
    <Typography variant="h2" gutterBottom>
      {finding.questionText}
    </Typography>
    <Card className="selected-finding__content">
      <CardContent>
        <Typography variant="h3" gutterBottom>
          {`${I18n.t('APP_LVR_FINDING_DETAILS_RESPONSE_LABEL')} ${I18n.t(
            'APP_REPORT_DASHBOARD_DETAILS',
          )}`}
        </Typography>
        <div className="selected-finding__card-container">
          <div className="selected-finding__badge-container">
            <Typography className="selected-finding__badge-label">
              {I18n.t('APP_REPORT_DASHBOARD_FINDING_CARD_WHEN_LABEL')}
            </Typography>
            <FoodSafetyLabel
              title={formatDateMonthsAndFullYear(finding.createdTimestamp)}
              type={INFORMATIONAL_RISK_SHORT}
              margin
            />
          </div>
          <div className="selected-finding__badge-container">
            <Typography className="selected-finding__badge-label">
              {I18n.t('APP_REPORT_DASHBOARD_FINDING_CARD_CATEGORY_LABEL')}
            </Typography>
            <FoodSafetyLabel
              title={I18n.t(
                `APP_LVR_FINDING_${uppercaseWithUnderscores(
                  finding.categoryName,
                )}`,
              ).toUpperCase()}
              type={lowercaseWithDashes(finding.categoryName)}
              margin
            />
          </div>
          <div className="selected-finding__badge-container">
            <Typography className="selected-finding__badge-label">
              {I18n.t('APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_RISK_LEVEL')}
            </Typography>
            <FoodSafetyLabel
              title={I18n.t(
                `APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_${renderFindingColor(
                  finding?.riskLevel,
                )?.toUpperCase()}`,
              )}
              type={lowercaseWithDashes(renderFindingColor(finding?.riskLevel))}
              margin
            />
          </div>
          <div className="selected-finding__badge-container">
            <Typography className="selected-finding__badge-label">
              {I18n.t('APP_LVR_FINDING_DETAILS_COMPLIANCE_LABEL')}
            </Typography>
            <FoodSafetyLabel
              title={finding.complianceStatus}
              type={INFORMATIONAL_RISK_SHORT}
              margin
            />
          </div>
        </div>
        <div className="selected-finding__answer-container">
          <Typography className="selected-finding__badge-label">
            {I18n.t('APP_LVR_FINDING_DETAILS_RESPONSE_LABEL')}
          </Typography>
          <div>
            {finding?.responseData?.flattenAnswers?.length > 0 && (
              <ul>
                {finding?.responseData?.flattenAnswers?.map((answer, i) => (
                  <li key={i}>{answer}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {childrenFindings.length > 0 && (
          <FindingsQuestionChain childrenFindings={childrenFindings} />
        )}
        <FindingsPhotos finding={finding} childrenFindings={childrenFindings} />
      </CardContent>
    </Card>
    <PastFindings
      findingId={finding.id}
      findingDate={finding.createdTimestamp}
    />
  </div>
);

export default SelectedFinding;
