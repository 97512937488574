import PropTypes from 'prop-types';
import PathwayCard from '../../Pathway/PathwayCard';

import './ReviewTheProcedures.scss';

export default function ReviewTheProcedures({
  findingTitle,
  pathwayURL,
  handleProceed,
}) {
  return (
    <div data-testid="review-the-procedures-container">
      <p
        className="pathway-question__finding-title"
        data-testid="review-the-procedures-finding-title"
      >
        {findingTitle}
      </p>
      {pathwayURL.length === 0 ? (
        <p
          className="pathway-card__question-text"
          data-testid="review-the-procedures-no-assigned-question-text"
        >
          No assinged procedures
        </p>
      ) : (
        <>
          <p
            className="pathway-card__question-text"
            data-testid="review-the-procedures-question-text"
          >
            Review the Procedures
          </p>
          <div
            className="pathway-question__button-container"
            data-testid="review-the-procedures-button-container"
          >
            <PathwayCard
              data-testid="review-the-procedures-pathway-card"
              pathwayList={pathwayURL}
            />
          </div>
        </>
      )}
      <div
        className="review-procedures__btn-container"
        data-testid="review-the-procedures-proceed-button-container"
      >
        <button
          type="button"
          className="request-amendment-form__submit-btn cancel-next-button__next-btn review-procedures__"
          data-testid="review-the-procedures-proceed-button"
          onClick={handleProceed}
        >
          PROCEED
        </button>
      </div>
    </div>
  );
}

ReviewTheProcedures.propTypes = {
  findingTitle: PropTypes.string,
  pathwayURL: PropTypes.array.isRequired,
  handleProceed: PropTypes.func.isRequired,
};
