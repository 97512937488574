import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addTenDays, isWithinTendays } from '../DateUtil';
import { getAuditSummary } from '../../actions';

const useAuditSummary = () => {
  const dispatch = useDispatch();
  const { locationNumber, round } = useParams();
  const { auditSummary, auditSummaryLoading } = useSelector(
    (state) => state.audit,
  );

  useEffect(() => {
    if (!locationNumber || !round || auditSummaryLoading) return;

    if (
      auditSummary?.audit.locationNumber !== locationNumber ||
      auditSummary?.audit.round !== round
    ) {
      dispatch(getAuditSummary(locationNumber, round));
    }
  }, [dispatch, auditSummary, auditSummaryLoading, locationNumber, round]);

  const audit = auditSummary?.audit;

  const inquiryDeadlineDate = addTenDays(
    audit?.inspectionTimestamp,
  ).formatedDate;
  const canSubmitInquiries = audit
    ? isWithinTendays(audit?.inspectionTimestamp) && !audit?.isPerfectAudit
    : false;
  const isPerfectAudit = audit?.isPerfectAudit;

  return {
    auditSummary,
    auditSummaryLoading,
    inquiryDeadlineDate,
    canSubmitInquiries,
    isPerfectAudit,
  };
};

export default useAuditSummary;
