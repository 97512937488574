import { useEffect, useState } from 'react';
import {
  Dropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Icon,
  Typography,
  LoadingIndicator,
} from 'cfa-react-components';
import { IconDownload } from '@tabler/icons-react';
import queryString from 'query-string';
import { API_GET } from '../../../utils';
import './LBRDownloadModal.scss';
import I18n from '../../../../i18n/utils';
import NoLBRData from './NoLBRData';
import { prepLbrFiles } from '../helpers';

const domain = process.env.REACT_APP_SERVER_URL;

const LBRDownloadModal = ({ assessments = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [files, setFiles] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const handleNewTab = () => {
    if (!selectedValue) return;
    window.open(selectedValue.uploadedFileSignedUrl, '_blank');
    setIsOpen(false);
  };

  const resetFileState = () => {
    setFiles([]);
    setSelectedValue(null);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchFiles = async () => {
      try {
        resetFileState();
        setIsFetching(true);

        const assessmentIds = assessments
          .map((assessment) => assessment.assessmentId)
          .join();

        const path = `${domain}/external/true-view/upload?${queryString.stringify(
          {
            assessmentIds,
          },
        )}`;
        const { data } = await API_GET(path);

        if (data && data.length > 0 && isMounted) {
          setFiles(prepLbrFiles(data, assessments));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsFetching(false);
      }
    };
    if (isOpen && assessments?.length !== 0) {
      fetchFiles();
    } else {
      resetFileState();
      setIsFetching(false);
    }

    return () => {
      isMounted = false;
    };
  }, [isOpen, assessments]);

  useEffect(() => {
    // Set the default selected value to the first file if files are available
    if (files.length > 0 && !selectedValue) {
      setSelectedValue(files[0]);
    }
  }, [files, selectedValue]);

  return (
    <div>
      <Button
        size="md"
        color="secondary"
        variant="outlined"
        onClick={() => setIsOpen(true)}
        data-testid="lbr-download-button"
      >
        <Icon className="download-button__icon" icon={IconDownload} />
        {I18n.t('APP_LBR_DOWNLOAD_PDF')}
      </Button>
      <Modal show={isOpen} size="lg" onClose={() => setIsOpen(false)}>
        <ModalHeader className="download-modal__header">
          {I18n.t('APP_LBR_DOWNLOAD_PDF')}
        </ModalHeader>
        <ModalBody className="download-modal__body">
          {isFetching ? (
            <LoadingIndicator variant="page" />
          ) : files.length > 0 ? (
            <>
              <Typography gutterBottom>
                {I18n.t('APP_SMART_SHOP_LBR_MSG')}
              </Typography>
              <Dropdown
                fullWidth
                options={files}
                value={selectedValue}
                renderOption={(option) => option.label}
                label={I18n.t('APP_LBR_DROPDOWN_LABEL')}
                getOptionId={(option) => option.createdTimestamp}
                onChange={(newValue) => setSelectedValue(newValue)}
              />
            </>
          ) : (
            <NoLBRData />
          )}
        </ModalBody>
        <ModalFooter className="download-modal__footer">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setIsOpen(false)}
            data-testid="lbr-modal-cancel-button"
          >
            {I18n.t('APP_NEXT_CANCEL')}
          </Button>
          {selectedValue && (
            <Button
              color="secondary"
              disabled={!selectedValue}
              onClick={handleNewTab}
              data-testid="lbr-modal-download-button"
            >
              {I18n.t('APP_DOWNLOAD')}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LBRDownloadModal;
