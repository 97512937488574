import { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from 'cfa-react-components';
import { Form } from 'reactstrap';
import {
  getAllAudits,
  setSelectedAudit,
  setSelectedAuditByRound,
} from '../../actions';

import './AuditSelector.scss';

const AuditSelector = ({ loading, buildRoundBasedUrl }) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { availableLocations } = useSelector((state) => state.location);
  const {
    allAudits,
    selectedAudit,
    hasFoodSafetyAudits: hasAudits,
    loading: auditLoading,
  } = useSelector((state) => state.audit);

  const [auditSelection, setAuditSelection] = useState(null);

  const isLoading = loading || auditLoading;

  const options = useMemo(
    () => allAudits.map(({ round }) => round),
    [allAudits],
  );

  const hasAccessToLocation = useCallback(
    (locationNumber) =>
      availableLocations
        ?.map((location) => location.locationNumber)
        .includes(locationNumber),
    [availableLocations],
  );

  // helper func to determine if the given `locationNumber` corresponds to the list of `allAudits` in the global
  // redux store
  const locationParamMatchesAllAudits = useCallback(
    (locationNumber) => {
      return allAudits
        .map((audit) => audit.locationNumber)
        .includes(locationNumber);
    },
    [allAudits],
  );

  const auditExistsByRound = useCallback(
    (auditRound) => allAudits.map((audit) => audit.round).includes(auditRound),
    [allAudits],
  );

  // if a `round` param doesn't exists and if a `selectedAudit` exists...replace the current URL
  // to reflect the round of the currently selected audit.
  useEffect(() => {
    if (!hasAccessToLocation(params.locationNumber)) return;

    // if endpoint call is successful but location payload is empty, due to no Food Safety Audits (use-case: New locations may fit this)
    if (hasAudits === false) return;

    // check to see if the locationNumber from the URL corresponds to audits we currently have in redux. If not, then
    // we need to get audits for the given location
    if (!locationParamMatchesAllAudits(params.locationNumber) && !isLoading) {
      dispatch(getAllAudits(params.locationNumber, params.round)); // include `params.round` to pre-select audit
      return; // early return to avoid further processing
    }

    // at this point, we know that we have audits for the location specified in the URL
    if (params.round && auditExistsByRound(params.round)) {
      // if `params.round` exists AND it exists in the `allAudits` array, then set that audit as the selected audit
      dispatch(setSelectedAuditByRound(params.round));
    } else if (selectedAudit && buildRoundBasedUrl) {
      // otherwise (i.e. if `params.round` doesn't exist OR it doesn't match an existent audits) if a `selectedAudit`
      // exists in the global redux store AND a function for building out a new URL has been provided as a prop, replace
      // the current URL with one that reflects the currently selected audit round
      history.replace(buildRoundBasedUrl(selectedAudit.round));
    }
  }, [
    auditExistsByRound,
    buildRoundBasedUrl,
    dispatch,
    hasAccessToLocation,
    hasAudits,
    history,
    isLoading,
    locationParamMatchesAllAudits,
    params?.locationNumber,
    params?.round,
    selectedAudit,
  ]);

  useEffect(() => {
    if (auditSelection) {
      if (auditSelection.round !== selectedAudit.round) {
        history.push(buildRoundBasedUrl(auditSelection.round));
      }
      dispatch(setSelectedAudit(auditSelection));
    }
  }, [
    auditSelection,
    buildRoundBasedUrl,
    dispatch,
    history,
    selectedAudit?.round,
  ]);

  const handleAuditSelection = (audit) => {
    const selection = allAudits.find((a) => a.round === audit);
    setAuditSelection(selection);
  };

  return (
    <Form className="audit-selector-form" data-testid="audit-selector-form">
      <Dropdown
        data-testid="audit-selector-dropdown"
        value={selectedAudit?.round}
        options={options}
        onChange={handleAuditSelection}
        disabled={isLoading || !allAudits.length || !selectedAudit}
        placeholder={
          selectedAudit && allAudits ? 'Select Audit' : 'No Audits Available'
        }
        compact
      />
    </Form>
  );
};

AuditSelector.propTypes = {
  loading: PropTypes.bool,
  buildRoundBasedUrl: PropTypes.func,
};

export default AuditSelector;
