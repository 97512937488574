import PropTypes from 'prop-types';
import Select from 'react-select';

import './SelectAuditOccurrence.scss';

const SelectAuditOccurrence = ({
  dropdownOptions,
  onChangeSelectFindingOccurrence,
}) => (
  <div
    className="select-audit-occurance__container"
    data-testid="select-audit-occurrence-container"
  >
    <div
      className="select-audit-occurance__title-container"
      data-testid="select-audit-occurrence-title-container"
    >
      <h4
        className="select-audit-occurance__title"
        data-testid="select-audit-occurrence-welcome-title"
      >
        Welcome!
      </h4>
      <p
        className="select-audit-occurance__sub-title"
        data-testid="select-audit-occurrence-welcome-subtitle"
      >
        Welcome to the SAFE Inquiry Form. There are two paths available on this
        form:
      </p>
      <h5
        className="select-audit-occurance__sub-heading"
        data-testid="select-audit-occurrence-ask-a-question-sub-heading"
      >
        Ask a question about a specific finding
      </h5>
      <p
        className="select-audit-occurance__sub-title"
        data-testid="select-audit-occurrence-send-a-message-subtitle"
      >
        Send a message to your Food Safety Consultant to help better understand
        a procedure or the “why” behind a finding.
      </p>
      <h5
        className="select-audit-occurance__sub-heading"
        data-testid="select-audit-occurrence-appeal-specific-finding-sub-heading"
      >
        Appeal a specific finding
      </h5>
      <p
        className="select-audit-occurance__sub-title"
        data-testid="select-audit-occurrence-submit-information-sub-title"
      >
        Submit information that was not available at the time of the audit (e.x.
        a ServSafe certificate that was not posted, an existing work order for
        facilities issues) or clarify a finding that was improperly cited (e.x.
        an approved produce brand marked unapproved).
      </p>
      <p
        className="select-audit-occurance__sub-title"
        data-testid="select-audit-occurrence-10-days-sub-title"
      >
        Appeals must be submitted within 10 days of your SAFE visit. Once
        submitted, a member of the Food Safety Team will review the information
        provided and a decision will be made to either uphold the finding or
        approve an amendment.
      </p>
      <div
        className="select-audit-occurance__division-line"
        data-testid="select-audit-occurrence-division-line"
      />
    </div>
    <div data-testid="select-audit-occurrence-finding-container">
      <h4
        className="select-audit-occurance__title"
        data-testid="select-audit-occurrence-finding-title"
      >
        Start Request by Choosing a Finding
      </h4>
      <p
        className="select-audit-occurance__sub-title"
        data-testid="select-audit-occurrence-search-question-id-sub-title"
      >
        Search using the question ID or keyword from the finding.
      </p>
      <Select
        className="select-audit-occurance"
        data-testid="select-audit-occurrence-select"
        options={dropdownOptions}
        onChange={onChangeSelectFindingOccurrence}
        isSearchable
        placeholder="Type a question ID or keyword..."
      />
    </div>
  </div>
);

SelectAuditOccurrence.propTypes = {
  dropdownOptions: PropTypes.array,
  onChangeSelectFindingOccurrence: PropTypes.func,
};

export default SelectAuditOccurrence;
