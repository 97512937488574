import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveLine } from '@nivo/line';
import { LoadingIndicator, Typography } from 'cfa-react-components';
import cloneDeep from 'lodash/cloneDeep';
import { baseTheme } from '../../constants/constants';
import I18n from '../../../i18n/utils';

import { getAuditSummaryDataGraph } from '../../actions/AuditActions';

import './TotalFindingsChart.scss';

const theme = cloneDeep(baseTheme);

export default function TotalFindingsChart({ isMobileView }) {
  const dispatch = useDispatch();
  const { loadingAuditSummaryGraph, auditSummaryGraphData } = useSelector(
    (state) => state.audit,
  );
  const graphData = parseToAllFiningGraph(auditSummaryGraphData);
  const largestAuditNumber = Math.max(...graphData.map((audit) => audit.y));
  const allFindingsSummaryData = [
    {
      id: '00',
      // Note: Passing null as a parameter to generate "empty" data point (row / x value) to match UI desing.
      // jjir 2022/12/13
      data: [{ x: null, y: null }],
    },
    {
      id: '01',
      data: graphData,
    },
  ];

  // Component Did Mount
  useEffect(() => {
    if (auditSummaryGraphData.length === 0) {
      dispatch(getAuditSummaryDataGraph());
    }
  }, []);

  const customSymbol = ({ color, borderWidth, borderColor, datum }) => {
    return (
      <g data-testid="total-findings-chart-customSymbolGraphic">
        <circle
          data-testid="total-findings-chart-customSymbolCircle"
          r={15}
          style={{ marginLeft: 60 }}
          strokeWidth={borderWidth}
          stroke={borderColor}
          fill={color}
        />
        <text
          data-testid="total-findings-chart-customSymbolText"
          fontSize="14"
          fontFamily="Verdana"
          x={Math.abs(datum.y).toString().length === 2 ? -6 : -4}
          y="5"
          fill="#ffffff"
        >
          {`${datum.y}`}
        </text>
      </g>
    );
  };

  return (
    <div data-testid="total-findings-chart-parent-container">
      {loadingAuditSummaryGraph === true && (
        <LoadingIndicator
          data-testid="total-findings-chart-loading-indicator"
          variant="inline"
        />
      )}

      {loadingAuditSummaryGraph === false &&
        allFindingsSummaryData[1].data.length > 1 && (
          <div
            className="total-findings-chart__container"
            data-testid="total-findings-chart-container"
          >
            <Typography
              className="total-findings-chart__left-axis-title"
              data-testid="total-findings-chart-left-axis-title"
              fontWeight="bold"
            >
              {I18n.t('APP_REPORT_DASHBOARD_TOTAL_FINDINGS_GRAPH_LABEL')}
            </Typography>
            <div
              id="performance-history-char"
              className="total-findings-chart__graph-container "
              data-testid="total-findings-chart-graph-container"
            >
              <ResponsiveLine
                data-testid="total-findings-chart-responsive-line"
                type="linear"
                curve="linear"
                data={allFindingsSummaryData}
                margin={{
                  top: 18,
                  right: 25,
                  bottom: 50,
                  left: 50,
                }}
                yScale={{
                  type: 'linear',
                  stacked: false,
                  max: largestAuditNumber + 1,
                }}
                padding={10.6}
                colors={[
                  '#FFFFFF',
                  '#003E59',
                  '#3EB1C8',
                  '#006B9A',
                  '#994878',
                  '#5D3754',
                ]}
                pointBorderColor={{ from: 'color' }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  format: (axisBottomValue) => axisBottomValue ?? '',
                  orient: 'bottom',
                  tickSize: isMobileView ? 0 : 3,
                  tickPadding: isMobileView ? 5 : 15,
                  tickRotation: isMobileView ? -53 : 0,
                  legendOffset: 36,
                  legendPosition: 'middle',
                  pointBorderWidth: 0,
                }}
                axisLeft={{
                  format: (axisValue) =>
                    Math.floor(axisValue) === axisValue ? axisValue : '',
                  tickSize: 0,
                }}
                animate
                enablePointLabel={false}
                pointBorderWidth={0}
                enableArea={false}
                useMesh={false}
                enableGridY
                enableGridX
                theme={theme}
                pointSymbol={(value) => {
                  return customSymbol(value);
                }}
              />
            </div>
          </div>
        )}

      {loadingAuditSummaryGraph === false &&
        allFindingsSummaryData[1].data.length <= 1 && <h1>Comming Soon</h1>}
    </div>
  );
}

TotalFindingsChart.prototype = {
  isMobileView: PropTypes.bool,
};

// Helper funcitons
function parseToAllFiningGraph([...graphData]) {
  return graphData
    .map((data) => {
      const totalScore = Object.values(data).reduce((acc, item) => {
        if (
          typeof item === 'object' &&
          item !== null &&
          Object.keys(data).includes(
            'Immediate Action',
            'High',
            'Medium',
            'Low',
            'Informational',
          )
        ) {
          return acc + item.nonResolvedTotalCount;
        }
        return acc;
      }, 0);
      return {
        y: totalScore,
        x: data.round,
        allFiningsdData: data,
      };
    })
    .reverse();
}
