import './TooltipContainer.scss';

const TooltipContainer = ({
  offset = 0,
  carrotOffset = 50,
  spaceBetweenPoint = 0,
  contentPaddingLeft,
  children,
}) => (
  <div
    className="pointing-tooltip"
    style={{
      left: `${offset}%`,
      marginBottom: `${spaceBetweenPoint}%`,
      paddingLeft: `${contentPaddingLeft}%`,
    }}
  >
    {children}
    <div
      className="pointing-tooltip-carrot"
      style={{ left: `${carrotOffset}%` }}
    />
  </div>
);

export default TooltipContainer;
