import PropTypes from 'prop-types';

import './FindingOccurrence.scss';

const OccurrenceTitleAndDescription = ({ title, description }) => (
  <div>
    <p className="finding-occurrence__text-lable">{title}</p>
    <p
      className="finding-occurrence__text-contenido"
      style={{ color: 'black', fontSize: 16 }}
    >
      {description}
    </p>
  </div>
);

const DisplayFindingOccurrence = ({
  issueDescription,
  zoneDescription,
  itemDescription,
  checked,
}) => (
  <div
    style={{ border: '1px solid #8e969c', padding: '0.51em' }}
    className={`finding-occurrence ${checked && 'finding-occurrence__active'}`}
    data-testid="display-finding-occurrence-container"
  >
    <div
      className={`finding-occurrence__inner-container ${
        checked && 'finding-occurrence__inner-container__active'
      }`}
      data-testid="display-finding-occurrence-inner-container"
    >
      <div
        className="finding-occurrence__checkbox-container"
        data-testid="display-finding-occurrence-checkbox-container"
      >
        <input
          className="finding-occurrence__checkbox"
          data-testid="display-finding-occurrence-checkbox-input"
          type="checkbox"
          defaultChecked
          disabled
        />
      </div>
      <OccurrenceTitleAndDescription
        data-testid="display-finding-occurrence-issue-occurrence-title-and-description"
        title="Issue"
        description={issueDescription}
      />
      <OccurrenceTitleAndDescription
        data-testid="display-finding-occurrence-zone-occurrence-title-and-description"
        title="Zone"
        description={zoneDescription}
      />
      <OccurrenceTitleAndDescription
        data-testid="display-finding-occurrence-item-occurrence-title-and-description"
        title="Item"
        description={itemDescription}
      />
    </div>
  </div>
);

DisplayFindingOccurrence.propTypes = {
  issueDescription: PropTypes.string,
  zoneDescription: PropTypes.string,
  itemDescription: PropTypes.string,
  checked: PropTypes.bool,
};

export default DisplayFindingOccurrence;
