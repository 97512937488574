import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Dropdown, Typography } from 'cfa-react-components';
import I18n from '../../../../../i18n/utils';

import PerformanceHistoryChart from '../../../../components/charts/PerformanceHistoryChart';
import TotalFindingsChart from '../../../../components/charts/TotalFindingsChart';
import RiskLevelBarChart from '../../../../components/charts/RiskLevelBarChart';

import '../../../../components/charts/PerformanceHistoryChart.scss';
import './HistoricalTrendWidget.scss';

function HistoricalTrendWidget({ performaceHistory }) {
  const dropDownOptions = [
    {
      id: 'op-01',
      label: I18n.t('APP_REPORT_DASHBOARD_PERFORMANCE_LEVEL_LABEL'),
      value: 'performance-level',
    },
    {
      id: 'op-02',
      label: I18n.t('APP_REPORT_DASHBOARD_TOTAL_FINDINGS_GRAPH_LABEL'),
      value: 'total-findings',
    },
    {
      id: 'op-03',
      label: I18n.t('APP_REPORT_DASHBOARD_IMMEDIATE_FINDINGS_GRAPH_LABEL'),
      value: 'immediate-risk-level-bar-chart',
    },
    {
      id: 'op-04',
      label: I18n.t('APP_REPORT_DASHBOARD_HIGH_FINDINGS_GRAPH_LABEL'),
      value: 'high-risk-level-bar-chart',
    },
    {
      id: 'op-05',
      label: I18n.t('APP_REPORT_DASHBOARD_MEDIUM_FINDINGS_GRAPH_LABEL'),
      value: 'medium-risk-level-bar-chart',
    },
    {
      id: 'op-06',
      label: I18n.t('APP_REPORT_DASHBOARD_LOW_FINDINGS_GRAPH_LABEL'),
      value: 'low-risk-level-bar-chart',
    },
  ];
  const [selectedOptionVelue, setSelectedOptionValue] = useState(
    dropDownOptions[0],
  );
  const isMobileView = window.innerWidth <= 820;

  return (
    <Card
      id="historical-trends-card"
      data-testid="historical-trend-widget-historical-trends-card"
    >
      <CardContent data-testid="historical-trend-widget-historical-trends-card-content">
        <div
          className="historical-trend-widget__title-container"
          data-testid="historical-trend-widget-title-container"
        >
          <Typography
            variant="h3"
            align="left"
            className="historical-trend-widget__title-text"
            data-testid="historical-trend-widget-title-text"
          >
            {I18n.t('APP_REPORT_DASHBOARD_HISTORICAL_PERFORMANCE')}
          </Typography>

          <Dropdown
            className="historical-trend-widget__dropdown-menu"
            data-testid="historical-trend-widget-dropdown-menu"
            options={dropDownOptions}
            renderOption={(option) => option.label}
            value={selectedOptionVelue}
            onChange={setSelectedOptionValue}
          />
        </div>

        {/* Performance History Lines graph */}
        {selectedOptionVelue.value === 'performance-level' && (
          <PerformanceHistoryChart
            data-testid="historical-trend-widget-performance-history-chart"
            performaceHistory={performaceHistory}
            isMobileView={isMobileView}
          />
        )}

        {/* Total Findings Lines Chart */}
        {selectedOptionVelue.value === 'total-findings' && (
          <TotalFindingsChart
            data-testid="historical-trend-widget-total-findings-chart"
            isMobileView={isMobileView}
          />
        )}

        {/* Immediate Findings bar graph */}
        {selectedOptionVelue.value === 'immediate-risk-level-bar-chart' && (
          <RiskLevelBarChart
            data-testid="historical-trend-widget-risk-level-bar-chart-immediate-findings"
            colors={['#84001D', 'rgba(182, 7, 47, 0.3)']}
            levelTitle="immediateFindings"
            isMobileView={isMobileView}
          />
        )}

        {/* High Findings bar graph */}
        {selectedOptionVelue.value === 'high-risk-level-bar-chart' && (
          <RiskLevelBarChart
            data-testid="historical-trend-widget-risk-level-bar-chart-high-findings"
            colors={['#DD0031', 'rgba(221, 0, 49, 0.3)']}
            levelTitle="highFindings"
            isMobileView={isMobileView}
          />
        )}

        {/* Medium Findings bar graph */}
        {selectedOptionVelue.value === 'medium-risk-level-bar-chart' && (
          <RiskLevelBarChart
            data-testid="historical-trend-widget-risk-level-bar-chart-medium-findings"
            colors={['#E35205', 'rgba(250, 147, 112, 0.5)']}
            levelTitle="mediumFindings"
            isMobileView={isMobileView}
          />
        )}

        {/* Low Findings bar graph  */}
        {selectedOptionVelue.value === 'low-risk-level-bar-chart' && (
          <RiskLevelBarChart
            data-testid="historical-trend-widget-risk-level-bar-chart-low-findings"
            colors={['#FFB549', 'rgba(245, 225, 164, 0.6)']}
            levelTitle="lowFindings"
            isMobileView={isMobileView}
          />
        )}
      </CardContent>
    </Card>
  );
}

HistoricalTrendWidget.propTypes = {
  performaceHistory: PropTypes.shape({
    performanceHistory: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default HistoricalTrendWidget;
