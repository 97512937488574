import PropTypes from 'prop-types';
import { Typography } from 'cfa-react-components';

import './ReasonForReview.scss';

export default function ReasonForReviewContainer({
  handleReviewTextInput,
  disabledReviewTextInput,
  reasonForReviewTextValue,
}) {
  return (
    <div
      className="reason-for-review__container"
      data-testid="reason-for-review-container-container"
    >
      <Typography
        data-testid="reason-for-review-container-reason-for-review-text"
        variant="h4"
      >
        Reason for review
      </Typography>
      <p
        className="reason-for-review__sub-title"
        data-testid="reason-for-review-container-sub-title"
      >
        Provide additional details to describe the reason for this appeal
        request.
      </p>
      <textarea
        className="reason-for-review__input-box"
        data-testid="reason-for-review-container-input-box"
        onChange={handleReviewTextInput}
        value={reasonForReviewTextValue}
        disabled={disabledReviewTextInput}
      />
    </div>
  );
}

ReasonForReviewContainer.propTypes = {
  handleReviewTextInput: PropTypes.func,
  disabledReviewTextInput: PropTypes.bool,
  reasonForReviewTextValue: PropTypes.string,
};
