import { Col, Typography } from 'cfa-react-components';

const StaffingCardEntry = ({ title, name = '--', ...props }) => (
  <Col className="staffing-card__col" {...props}>
    <Typography fontWeight="bold" className="staffing-card__title">
      {title}
    </Typography>
    <Typography className="staffing-card__name">{name}</Typography>
  </Col>
);

export default StaffingCardEntry;
