/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
// TODO: review disabled eslint rules
import { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { Row, Col, UncontrolledTooltip, Card, Badge } from 'reactstrap';
import FlatList from 'flatlist-react';
import Select from 'react-select';
import I18n from '../../../../i18n/utils';

import {
  setSelectedQuestion,
  getSelectedRoundQuestions,
  clearAssessmentPrompt,
} from '../../../actions';
import QuestionsFilter from '../../../components/QuestionsFilter/QuestionsFilter';
import NavHeader from '../../../components/NavHeader/NavHeader';
import CFASpinner from '../../../components/Spinner/CFASpinner';
import { BackArrow } from '../../../components/common';
import { getRoundOptions } from '../../../utils';

import './SafeQuestion.scss';
import {
  IMMEDIATE_RISK_LONG,
  HIGH_RISK_LONG,
  MEDIUM_RISK_LONG,
  LOW_RISK_LONG,
  INFORMATIONAL_RISK_LONG,
  //
  CLEANING_SANITATION,
  CROSS_CONTAMINATION,
  HEALTH_HYGIENE,
  PESTS,
  TIME_TEMPERATURE,
} from '../../../constants/constants';

const MAX_LENGTH = 110;
const riskLabels = [
  IMMEDIATE_RISK_LONG,
  HIGH_RISK_LONG,
  MEDIUM_RISK_LONG,
  LOW_RISK_LONG,
  INFORMATIONAL_RISK_LONG,
];
const categoryLabels = [
  CLEANING_SANITATION,
  CROSS_CONTAMINATION,
  HEALTH_HYGIENE,
  PESTS,
  TIME_TEMPERATURE,
];

class SafeQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      filterByCustom: false,
      filterLabels: [],
      filteredQuestions: [],
      searchValue: '',
      roundOptions: getRoundOptions(),
    };

    this.renderQuestion = this.renderQuestion.bind(this);
    this.sortQuestions = this.sortQuestions.bind(this);
    this.filterSelectedQuestions = this.filterSelectedQuestions.bind(this);
    this.handleSearchInputValue = this.handleSearchInputValue.bind(this);
  }

  componentDidMount() {
    if (!this.props.selectedRound) {
      this.props.getSelectedRoundQuestions(
        this.state.roundOptions[this.state.roundOptions.length - 3],
      );
    } else {
      this.props.getSelectedRoundQuestions(this.props.selectedRound);
    }
  }

  handleSearchInputValue(event) {
    this.setState({
      searchValue: event.target.value,
    });
  }

  getCategoryNameButton = (question) => {
    switch (question.category.name) {
      case 'Cleaning & Sanitation':
        return (
          <Badge className="category-name-cleaning-sanitation" disabled>
            {question.category.name}
          </Badge>
        );
      case 'Cross Contamination':
        return (
          <Badge className="category-name-cross-contamination" disabled>
            {question.category.name}
          </Badge>
        );
      case 'Health & Hygiene':
        return (
          <Badge className="category-name-health-hygiene" disabled>
            {question.category.name}
          </Badge>
        );
      case 'Pests':
        return (
          <Badge className="category-name-pests" disabled>
            {question.category.name}
          </Badge>
        );
      case 'Time & Temperature':
        return (
          <Badge className="category-name-pests" disabled>
            {question.category.name}
          </Badge>
        );
      default:
        return null;
    }
  };

  getRiskLevelButton = (question) => {
    switch (question.riskLevel) {
      case 'Immediate Action':
        return (
          <Badge className="risk-level-immediate-action" disabled>
            {question.riskLevel}
          </Badge>
        );
      case 'High':
        return (
          <Badge className="risk-level-high" disabled>
            {question.riskLevel}
          </Badge>
        );
      case 'Medium':
        return (
          <Badge className="risk-level-medium" disabled>
            {question.riskLevel}
          </Badge>
        );
      case 'Low':
        return (
          <Badge className="risk-level-low" disabled>
            {question.riskLevel}
          </Badge>
        );
      case 'Informational':
        return (
          <Badge className="risk-level-informational" disabled>
            {question.riskLevel}
          </Badge>
        );
      default:
        return null;
    }
  };

  // this is called before Render method, it returns an object that updates to the state
  static getDerivedStateFromProps(props, state) {
    const update = {};

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    return update;
  }

  sortQuestions(a, b) {
    if (riskLabels.indexOf(a.riskLevel) < riskLabels.indexOf(b.riskLevel)) {
      return -1;
    }

    if (riskLabels.indexOf(a.riskLevel) > riskLabels.indexOf(b.riskLevel)) {
      return 1;
    }

    if (a.category.name > b.category.name) {
      return -1;
    }

    return 1;
  }

  // Triggered by updateFilterLabels, method to change the questions that appear on the filter
  filterSelectedQuestions(labelStr) {
    const questions = this.props.allQuestions;
    const filterOptions = this.state.filterLabels.includes(labelStr)
      ? this.state.filterLabels.filter((label) => label !== labelStr)
      : [labelStr, ...this.state.filterLabels];

    // Separate currentFilterLabels to risks and FS5 Categories
    const riskFilters = filterOptions.filter((x) => riskLabels.includes(x));
    const categoryFilters = filterOptions.filter((x) =>
      categoryLabels.includes(x),
    );

    // If there are filters, filter risks and then categories. Finally, sort the questions by risks then categories
    const newFilteredQuestions = questions
      .filter((x) =>
        riskFilters.length ? riskFilters.includes(x.riskLevel) : true,
      )
      .filter((x) =>
        categoryFilters.length
          ? categoryFilters.includes(x.category.name)
          : true,
      )
      .sort(this.sortQuestions);

    // Update state.filteredQuestions
    this.setState({
      filteredQuestions: newFilteredQuestions,
      filterByCustom: !!(riskFilters.length || categoryFilters.length),
      filterLabels: filterOptions,
    });
  }

  renderQuestion(question) {
    if (
      question.text
        .toLowerCase()
        .indexOf(this.state.searchValue.toLowerCase()) !== -1 ||
      question.safeReportId
        .toLowerCase()
        .indexOf(this.state.searchValue.toLowerCase()) !== -1
    ) {
      return (
        <Row className="finding-item container" key={question.questionUuid}>
          <Col>
            <p className="question-message">
              <span className="question-report-id">
                {question.safeReportId}:
              </span>
              {question.text !== undefined &&
              question.text.length < MAX_LENGTH ? (
                <b>{question.text}</b>
              ) : (
                <>
                  <span id={`tooltip-${question.questionUuid}`}>
                    <b>{`${question.text.substring(0, MAX_LENGTH)}...`}</b>
                  </span>
                  <UncontrolledTooltip
                    placement="right-end"
                    target={`tooltip-${question.questionUuid}`}
                  >
                    {`${question.text.toUpperCase()}`}
                  </UncontrolledTooltip>
                </>
              )}
            </p>

            {this.getRiskLevelButton(question)}
            {this.getCategoryNameButton(question)}
            <Link
              className="view-question"
              key={question.questionUuid}
              to={`/admin/safe-question/${question.safeReportId}`}
            >
              VIEW
            </Link>
          </Col>
        </Row>
      );
    }
    return <div key={question.questionUuid} />;
  }

  render() {
    return (
      <>
        <NavHeader />
        <Col className="SafeQuestion container">
          <Row className="d-none d-sm-block container">
            <div onClick={this.props.clearAssessmentPrompt}>
              <BackArrow
                title={I18n.t('APP_SAFE_QUESTION_BACK')}
                path="/admin"
              />
            </div>
          </Row>
          <Row className="desktop-sub-header">
            <Col xs="6" sm="8">
              <h2 className="d-none d-sm-block">
                {I18n.t('APP_SAFE_QUESTION_TITLE')}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col className="container">
              <input
                className="question-search-box"
                type="text"
                placeholder="Search for a question"
                onChange={this.handleSearchInputValue}
                value={this.state.searchValue}
              />
            </Col>
          </Row>
          <Row>
            <p className="safe-question__quarter-selector-prompt">
              Select an Effective Round:
            </p>
          </Row>
          <Row>
            <Select
              options={this.state.roundOptions}
              defaultValue={
                this.props.selectedRound
                  ? this.props.selectedRound
                  : this.state.roundOptions[this.state.roundOptions.length - 3]
              }
              onChange={this.props.getSelectedRoundQuestions}
              className="safe-question__quarter-selector quarter-select-container quarter-dropdown"
              isClearable={false}
              isRtl={false}
              isSearchable={false}
              name="startRound"
            />
          </Row>
          <Row className="both-containers">
            <Col className="container">
              <Row className="question-card-container">
                {this.state.loading ? (
                  <CFASpinner />
                ) : (
                  <FlatList
                    list={
                      this.state.filterByCustom
                        ? this.state.filteredQuestions
                        : this.props.allQuestions?.sort(this.sortQuestions)
                    }
                    renderItem={this.renderQuestion}
                    renderWhenEmpty={() => (
                      <p className="empty-search">
                        {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_SEARCH_EMPTY')}
                      </p>
                    )}
                  />
                )}
              </Row>
            </Col>
            <Col sm="4" className="sort-options-col d-none d-sm-block">
              <Card
                style={{ marginTop: '1.45rem', borderRadius: '6px' }}
                className="question-filter-container"
              >
                <QuestionsFilter
                  updateFilterLabels={this.filterSelectedQuestions}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, allQuestions, selectedQuestion, selectedRound } =
    state.admin;
  return {
    loading,
    allQuestions,
    selectedQuestion,
    selectedRound,
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    getSelectedRoundQuestions,
    setSelectedQuestion,
    clearAssessmentPrompt,
  })(SafeQuestion),
);

SafeQuestion.propTypes = {
  allQuestions: PropTypes.array,
  getSelectedRoundQuestions: PropTypes.func,
  // selectedQuestion: PropTypes.object,
  // setSelectedQuestion: PropTypes.func,
  clearAssessmentPrompt: PropTypes.func,
};
