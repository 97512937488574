import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import queryString from 'query-string';
import ReactPaginate from 'react-paginate';
import { Button, LoadingIndicator } from 'cfa-react-components';
import { API_GET } from '../../../utils';
import AmendmentCardContainer from '../../../components/Ammendments/AmendmentCardContainer';
import { getStoreLocations } from '../../../actions';
import FilterBoxContainer from '../../../components/common/FilterBox/FilterBoxContainer';
import {
  sortFilterOptions,
  categoryFilterOptions,
  riskLevelFilterOptions,
  AMENDMENT_CARD_TYPE,
} from '../../../utils/AmendmentUtil';
import './AmendmentContainer.scss';

export default function PendingAmendments() {
  const [amendments, setAmendments] = useState({ totalCount: 0 });
  const [isLoadingAmendments, setIsLoadingAmendments] = useState(true);
  const availableLocations = useSelector(
    (state) => state.location.availableLocations,
  );
  const [roundSelectionOptions, setRoundSelectionOptions] = useState(null);
  const [questionSelectionOptions, setQuestionSelectionOptions] = useState([]);
  const [locationSelectionOptions, setLocationSelectionOptions] = useState([]);
  const [amendmentCountData, setAmendmentCountData] = useState([]);
  const [selectedRound, setSelectedRound] = useState('');
  const [selectedSort, setSelectedSort] = useState('');
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const dispatch = useDispatch();

  // Holds filters that have been applied
  const [appliedFilters, setAppliedFilters] = useState({});

  // This is the "static" filter object for the Pending appeals page. It supplies the FilterBoxContainer.
  const appealFilters = [
    {
      filterName: 'selectedQuestionId',
      initialValue: null,
      filterLabel: 'Finding ID',
      filterType: 'dropdown',
      filterOptions: questionSelectionOptions,
    },
    {
      filterName: 'selectedLocation',
      initialValue: null,
      filterLabel: 'Store Number',
      filterType: 'dropdown',
      filterOptions: locationSelectionOptions,
    },
    {
      filterName: 'selectedCategory',
      initialValue: null,
      filterLabel: 'Category',
      filterType: 'dropdown',
      filterOptions: categoryFilterOptions,
    },
    {
      filterName: 'selectedPriority',
      initialValue: null,
      filterLabel: 'Priority',
      filterType: 'dropdown',
      filterOptions: riskLevelFilterOptions,
    },
  ];

  // Build the url based on appliedFilters/currentPageNumber/selectedRound/selectedSort and call the endpoint.
  const retrieveAmendments = async () => {
    // Display the loading symbol
    setIsLoadingAmendments(true);

    // Build url to retrieve the amendments list
    let url = `${process.env.REACT_APP_SERVER_URL}/amendments?page=${
      currentPageNumber + 1
    }&pageSize=10&statuses=PENDING`;

    // Set Round filter, if any
    if (selectedRound) url += `&rounds=${selectedRound.value}`;

    // Set Location filter, if any
    if (appliedFilters.selectedLocation)
      url += `&locations=${appliedFilters.selectedLocation.value}`;

    // Set Question IDs filter, if any
    if (appliedFilters.selectedQuestionId)
      url += `&questionIds=${appliedFilters.selectedQuestionId.value}`;

    // Set Priority filter, if any
    if (appliedFilters.selectedPriority)
      url += `&priorities=${appliedFilters.selectedPriority.value}`;

    // Set Category filter, if any
    if (appliedFilters.selectedCategory)
      url += `&${queryString.stringify({
        categories: appliedFilters.selectedCategory.value,
      })}`;

    // Set Sort Field, if any
    if (selectedSort) url += `&sortField=${selectedSort.value}`;

    try {
      const result = await API_GET(url);
      setAmendments(result.data);
    } catch (err) {
      console.error('Failed to retrieve filtered amendments.', err);
    } finally {
      setIsLoadingAmendments(false);
    }
  };

  // get the possible round selections for amendments
  const getRoundSelectionOptions = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/amendments/count`;

    try {
      const result = await API_GET(url);

      // Format round data into label and value key pairs.
      const formattedResultRounds = result.data.map((option) => {
        return { value: option.round, label: option.round };
      });

      setRoundSelectionOptions(formattedResultRounds);
      setAmendmentCountData(result.data);
    } catch (err) {
      console.error('Failed to retrieve appeal round selection options.', err);
    }
  };

  // On round selection, retrieve the questions asked that round and supply the finding dropdown with them.
  const setEffectiveQuestionsFromRoundSelection = () => {
    // If a round is selected, we need to grab ONLY its questions.
    // Otherwise, grab ALL questions
    const rawQuestionIds = selectedRound
      ? amendmentCountData
          .filter((object) => object.round === selectedRound.value)
          .map((roundData) => roundData.effectiveQuestionIds)
          .flat()
      : amendmentCountData
          .map((roundData) => roundData.effectiveQuestionIds)
          .flat();

    // We then merge the question IDs & sort them.
    const distinctQuestionIds = [...new Set(rawQuestionIds)].sort();
    // And finally the format question data into label and value key pairs.
    const questionIdOptions = distinctQuestionIds.map((questionId) => {
      return { value: questionId, label: questionId };
    });
    setQuestionSelectionOptions(questionIdOptions);
  };

  const setAppliedFiltersHelper = (filterObject) => {
    setAppliedFilters(filterObject);
    setCurrentPageNumber(0);
  };

  // if the amendment count data changes we need to re-grab the questions from that round.
  useEffect(() => {
    setEffectiveQuestionsFromRoundSelection();
  }, [amendmentCountData]);

  // If the user selects a new round then immediately fetch new data and re-grab the questions from that round.
  useEffect(() => {
    if (currentPageNumber !== 0) setCurrentPageNumber(0);
    else retrieveAmendments();
    setEffectiveQuestionsFromRoundSelection();
  }, [selectedRound]);

  // if the user changes the page, the selected sort, or applies filters just re-fetch the amendments
  useEffect(() => {
    retrieveAmendments();
  }, [currentPageNumber, selectedSort, appliedFilters]);

  // If the availableLocations changes then we need to repopulate the location options
  useEffect(() => {
    setLocationSelectionOptions(
      availableLocations?.map((l) => {
        return {
          label: l.location.locationNumber,
          value: l.location.locationNumber,
        };
      }),
    );
  }, [availableLocations]);

  // On mount
  useEffect(() => {
    // Grab round options to supply the round dropdown
    getRoundSelectionOptions();
    // If redux doesn't have locations, grab them
    if (!locationSelectionOptions || !availableLocations) {
      dispatch(getStoreLocations('00000'));
      // Return so we don't get a null ref error below. The above useEffect handles this case
      return;
    }
    setLocationSelectionOptions(
      availableLocations.map((l) => {
        return {
          label: l.location.locationNumber,
          value: l.location.locationNumber,
        };
      }),
    );
  }, []);

  return (
    <div className="amendments__content-container">
      <div className="amendments-subtitle-container">
        <h2 className="amendments-subtitle">Pending Appeals</h2>
        <div className="amendments__filter-container">
          <Select
            className="amendments-filters__selector"
            options={sortFilterOptions}
            value={selectedSort}
            onChange={setSelectedSort}
            isClearable
            isRtl={false}
            isSearchable={false}
            name="sortSelector"
            placeholder="Sort by..."
          />
          <Select
            options={roundSelectionOptions}
            value={selectedRound}
            onChange={setSelectedRound}
            className="amendments-filters__selector"
            isClearable
            isRtl={false}
            isSearchable
            name="roundSelector"
          />
          <Button
            className="amendments-filters__filter-button"
            color="secondary"
            variant="outlined"
            size="lg"
            onClick={() => setShowFilterBox(!showFilterBox)}
          >
            Filters
          </Button>
        </div>
      </div>
      {!isLoadingAmendments && (
        <p className="amendments-count">
          {amendments.totalCount} Requests | {amendments.uniqueLocationCount}{' '}
          Unique Locations
        </p>
      )}
      <FilterBoxContainer
        filterArray={appealFilters}
        hideFilterBoxCallback={() => setShowFilterBox(false)}
        applyFiltersCallback={setAppliedFiltersHelper}
        showFilterBox={showFilterBox}
      />
      {isLoadingAmendments ? (
        <LoadingIndicator
          size="md"
          variant="page"
          style={{ margin: '6rem auto' }}
        />
      ) : amendments && amendments.content ? (
        <AmendmentCardContainer
          cardType={AMENDMENT_CARD_TYPE.PENDING}
          amendmentList={amendments.content}
          loadingAmendmentsCallback={setIsLoadingAmendments}
          statusChangeCallback={retrieveAmendments}
        />
      ) : (
        <h3 className="amendment-container__no-amendments-title">
          No Results Found
          <div>Try applying different filters.</div>
        </h3>
      )}
      <ReactPaginate
        pageCount={Math.ceil(amendments.totalCount / 10)}
        onPageChange={({ selected: pageNumber }) => {
          setCurrentPageNumber(pageNumber);
        }}
        forcePage={currentPageNumber}
        renderOnZeroPageCount={false}
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        subContainerClassName="pages pagination"
        breakLinkClassName=" page-link  amendments-pagination__page-link-a amendments-pagination--ellipsis-text"
        breakClassName="  amendments-pagination__page-item-li"
        containerClassName="pagination amendments-pagination__container"
        pageClassName="page-it amendments-pagination__page-item-li"
        pageLinkClassName="page-link  amendments-pagination__page-link-a"
        previousClassName="page-ite amendments-pagination__page-item-li"
        previousLinkClassName="page-link amendments-pagination__page-link-a"
        nextClassName="amendments-pagination__page-item-li"
        nextLinkClassName="page-link amendments-pagination__page-link-a"
        activeLinkClassName="active amendments-pagination--active"
      />
    </div>
  );
}
