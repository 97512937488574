import {
  Button,
  LoadingIndicator,
  MessageBlock,
  MessageBlockContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from 'cfa-react-components';
import { useState } from 'react';
import { API_DEL } from '../../../../utils';

export function DeleteAuditModal({ audit, onSuccess, onCancel }) {
  const [deleteActionPending, setDeleteActionPending] = useState(false);

  const handleDeleteConfirmationButtonClick = async () => {
    setDeleteActionPending(true);

    await API_DEL(
      `${process.env.REACT_APP_SERVER_URL}/audit/delete/${audit.externalId}`,
    );

    onSuccess();
  };

  return (
    <div className="delete-audit-modal-container">
      <ModalHeader>Delete audit {audit?.externalId}</ModalHeader>

      <ModalBody>
        <Typography color="default" align="left">
          Are you sure you want to delete the <strong>{audit?.round}</strong>{' '}
          audit for location <strong>{audit?.locationNumber}</strong>?
        </Typography>
        <br />
        <MessageBlock severity="warning">
          <MessageBlockContent>
            This action is irreversible and permanently deletes all data
            associated with this audit.
          </MessageBlockContent>
        </MessageBlock>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          disabled={deleteActionPending}
        >
          Cancel
        </Button>

        <Button
          variant="destructive"
          onClick={handleDeleteConfirmationButtonClick}
          disabled={deleteActionPending}
        >
          Delete {audit?.externalId}
          {deleteActionPending && (
            <LoadingIndicator size="sm" variant="inline" />
          )}
        </Button>
      </ModalFooter>
    </div>
  );
}
