import { useEffect, useState, useRef } from 'react';
import { Typography, TimePicker } from 'cfa-react-components';
import { useLVRStore, useLVRDispatch } from '../../../store/reducers';
import ErrorText from '../../../Questions/ErrorText';
import { updateSupplementaryData } from '../../../utils/questionsUtils';
import { isValidDate } from '../../../utils/dataUtils';

import '../GeneralInformationForm.scss';

const GeneralInformationTimePicker = () => {
  const lvrDispatch = useLVRDispatch();
  const {
    submissionFailed,
    supplementaryData: { visitTime },
  } = useLVRStore();

  const pickerRef = useRef(null);
  const [errorText, setErrorText] = useState('');

  const onChange = (option) => {
    updateSupplementaryData({
      dispatch: lvrDispatch,
      data: { visitTime: option },
    });
    setErrorText('');
  };

  useEffect(() => {
    // Use the pickerRef to get the value of the TimePicker component input
    // to determine if the user has entered a value or not. Otherwise, use the
    // component's error state management messaging.
    if (!pickerRef?.current?.value && submissionFailed && !visitTime) {
      setErrorText('Response cannot be blank.');
    }
  }, [submissionFailed, visitTime, pickerRef?.current?.value]);

  return (
    <>
      <div
        className="lvr-gen-info__input-picker"
        data-testid="general-information-time-picker-container"
      >
        <Typography
          data-testid="general-information-time-picker-required-text"
          color="primary"
          variant="caption2"
        >
          Required
        </Typography>
        <TimePicker
          data-testid="general-information-time-picker-time-picker"
          ref={pickerRef}
          label="Visit Time"
          value={
            visitTime && isValidDate(new Date(visitTime))
              ? new Date(visitTime)
              : ''
          }
          onChange={onChange}
          step={15}
          fullWidth
        />
      </div>
      <ErrorText
        data-testid="general-information-time-picker-error-text"
        error={errorText}
        gutterBottoms
      />
    </>
  );
};

export default GeneralInformationTimePicker;
