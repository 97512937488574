import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getStoreLocations } from '../../actions';

const useSelectedLocation = () => {
  const { locationNumber } = useParams();
  const selectedLocation = useSelector(
    (state) => state.location.selectedLocation,
  );

  const dispatch = useDispatch();
  // Get location information if NOT in redux store
  useEffect(() => {
    if (!selectedLocation) dispatch(getStoreLocations(locationNumber));
  }, [dispatch, locationNumber, selectedLocation]);

  return { ...selectedLocation };
};

export default useSelectedLocation;
