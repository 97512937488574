import PropTypes from 'prop-types';

import './FSModal.scss';

export default function FSModal({ isOpen, children, ...props }) {
  if (isOpen !== true) return null;
  return (
    <div className="fs-modal" {...props}>
      <div className="fs-modal__container">{children}</div>
    </div>
  );
}

FSModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
