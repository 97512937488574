import PropTypes from 'prop-types';
import { formatDateAbbreviatedMonthDayYear } from '../../utils/DateUtil';
import './AmendmentCommentCard.scss';

function AmendmentCommentCard({
  comment,
  status,
  isStatusComment,
  isAdminRequested,
  isModified,
}) {
  const getApprovalBadge = () => {
    if (isModified === true) return 'Finding Modified';
    if (isAdminRequested === true) return 'Finding Removed';
    return 'Reqeust Approved';
  };

  return (
    <>
      {(isStatusComment === false || status === 'PENDING') && (
        <div
          className="amendment-comment-card__container"
          data-testid="amendment-comment-card-container"
        >
          <div
            className="amendment-comment-card__top-info-container"
            data-testid="amendment-comment-card-top-info-container"
          >
            <div
              className="amendment-comment-card__username"
              data-testid="amendment-comment-card-username"
            >
              {comment.commenterDisplayName}
            </div>
            <div
              className="amendment-comment-card__time"
              data-testid="amendment-comment-card-time"
            >
              {formatDateAbbreviatedMonthDayYear(comment.lastModifiedTimestamp)}
            </div>
          </div>
          <div
            className="amendment-comment-card__comment"
            data-testid="amendment-comment-card-comment"
          >
            {comment.comment}
          </div>
        </div>
      )}
      {isStatusComment === true && status === 'APPROVED' && (
        <div
          className="amendment-comment-card__container"
          data-testid="amendment-comment-card-approved-comment-container"
        >
          <div
            className="amendment-comment-card__top-info-container"
            data-testid="amendment-comment-card-approved-top-info-container"
          >
            <div
              className="amendment-comment-card__approval"
              data-testid="amendment-comment-card-approval"
            >
              {getApprovalBadge()}
            </div>
            <div
              className="amendment-comment-card__time"
              data-testid="amendment-comment-card-approved-time"
            >
              {formatDateAbbreviatedMonthDayYear(comment.lastModifiedTimestamp)}
            </div>
          </div>
          <div
            className="amendment-comment-card__username"
            data-testid="amendment-comment-card-approved-username"
          >
            {comment.commenterDisplayName}
          </div>
          <div
            className="amendment-comment-card__comment-container"
            data-testid="amendment-comment-card-approved-comment-container-sub"
          >
            <div
              className="amendment-comment-card__comment"
              data-testid="amendment-comment-card-approved-comment"
            >
              {comment.comment}
            </div>
          </div>
        </div>
      )}
      {isStatusComment === true && status === 'DENIED' && (
        <div
          className="amendment-comment-card__container"
          data-testid="amendment-comment-card-denied-container"
        >
          <div
            className="amendment-comment-card__top-info-container"
            data-testid="amendment-comment-card-denied-top-info-container"
          >
            <div
              className="amendment-comment-card__denial"
              data-testid="amendment-comment-card-denial"
            >
              Request Denied
            </div>
            <div
              className="amendment-comment-card__time"
              data-testid="amendment-comment-card-denied-time"
            >
              {formatDateAbbreviatedMonthDayYear(comment.lastModifiedTimestamp)}
            </div>
          </div>
          <div
            className="amendment-comment-card__username"
            data-testid="amendment-comment-card-denied-username"
          >
            {comment.commenterDisplayName}
          </div>
          <div
            className="amendment-comment-card__comment-container"
            data-testid="amendment-comment-card-denied-comment-container"
          >
            <div
              className="amendments-comment-card__comment"
              data-testid="amendment-comment-card-denied-comment"
            >
              {comment.comment}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

AmendmentCommentCard.propTypes = {
  comment: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  isStatusComment: PropTypes.bool,
  isAdminRequested: PropTypes.bool,
  isModified: PropTypes.bool,
};

export default AmendmentCommentCard;
