import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Typography } from 'cfa-react-components';
import I18n from '../../../../i18n/utils';
import { putHistoricalAction } from '../../../actions/AuditActions';
import './AdditionalSupportCard.scss';

// Global Variables holding the URLs to be used by the AdditionalSupprotCard component.
const ROOT_CAUSE_ANALYSIS_URL =
  'https://portal.cfahome.com/en/food-and-delivery/food-safety-and-quality/food-safety-best-practices-toolkit/root-cause-analysis-best-practices/';
const CORRECTIVE_ACTION_PLANNING_URL =
  'https://portal.cfahome.com/globalassets/documents/staff-operator-team-member/safe-evaluation-corrective-action-template.pdf?v=1D758AEFFCE8F80';
const CTE_FREE_URL =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=ElHrwUZ5nUy8V0AEDP46kejN85qPfD5MvtKylBuF20RUOFdYOElOTTJVWEc0UlVCUzY3Q1kwMzFYTC4u';
const CTE_ADDITIONAL_URL =
  'https://forms.office.com/pages/responsepage.aspx?id=ElHrwUZ5nUy8V0AEDP46kejN85qPfD5MvtKylBuF20RUQTlUODMzR1c2VVpYVk5GR1RHMUNYS0FRNy4u';

/**
 * AdditionalSupportCard is a component that returns a card containing
 * links to additional support for findings tied to the audit.
 *
 * auditUUID: a string holding the UUID of the currently selected Audit used for creating historical actions.
 */
function AdditionalSupportCard({ auditUUID }) {
  // A local name for the useDispatch function fom react-redux.
  const dispatch = useDispatch();

  // Calls a dispatch to redux to put a Historical Action Object in the database.
  const addHistoricalAction = (auditID, questionUUID, type, content) => {
    return () => {
      dispatch(putHistoricalAction(auditID, questionUUID, type, content));
    };
  };

  // Returns the html for the AdditionalSupportCard component.
  return (
    <div
      className="additional-support-card"
      data-testid="additional-support-card-additional-support-card"
    >
      <Typography
        data-testid="additional-support-card-title"
        color="default"
        gutterBottom={false}
        variant="h3"
      >
        {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL')}
      </Typography>
      <div
        className="additional-support-card__element-container"
        data-testid="additional-support-card-element-container"
      >
        <div
          className="additional-support-card__element"
          data-testid="additional-support-card-element"
        >
          <Typography
            data-testid="additional-support-card-other"
            color="default"
            gutterBottom={false}
            variant="h4"
          >
            <img
              alt="support"
              className="additional-support-card__element-links-icon"
              data-testid="additional-support-card-other-element-links-icon"
              src={require(`../../../assets/icons/support.svg`).default}
            />
            {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_OTHER')}
          </Typography>
          <Typography
            className="force-cfa-gray"
            data-testid="additional-support-card-other-description"
            color="default"
            gutterBottom={false}
            variant="body2"
          >
            {I18n.t(
              'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_OTHER_DESCRIPTION',
            )}
          </Typography>
          <ul
            className="additional-support-card__element-list"
            data-testid="additional-support-card-root-cause-element-list"
          >
            <li
              className="additional-support-card__element-list-item"
              data-testid="additional-support-card-root-cause-element-list-item"
            >
              <a
                href={ROOT_CAUSE_ANALYSIS_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="additional-support-card__element-url"
                data-testid="additional-support-card-root-cause-element-url"
                onClick={addHistoricalAction(
                  auditUUID,
                  null,
                  'LINK_ROOT_CAUSE_ANALYSIS',
                  `{ "name": "Root Cause Analysis", "url": "${ROOT_CAUSE_ANALYSIS_URL}"}`,
                )}
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_ROOT_CAUSE',
                )}
              </a>
            </li>
            <li
              className="additional-support-card__element-list-item"
              data-testid="additional-support-card-corrective-action-element-list-item"
            >
              <a
                href={CORRECTIVE_ACTION_PLANNING_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="additional-support-card__element-url"
                data-testid="additional-support-card-corrective-action-element-url"
                onClick={addHistoricalAction(
                  auditUUID,
                  null,
                  'LINK_CORRECTIVE_ACTION_PLANNING',
                  `{"name": "Corrective Action Planning","url": "${CORRECTIVE_ACTION_PLANNING_URL}"}`,
                )}
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_CORRECTIVE',
                )}
              </a>
            </li>
          </ul>
        </div>
        <div
          className="additional-support-card__element"
          data-testid="additional-support-card-element-2"
        >
          <Typography
            data-testid="additional-support-card-need"
            color="default"
            gutterBottom={false}
            variant="h4"
          >
            <img
              alt="support"
              className="additional-support-card__element-links-icon"
              data-testid="additional-support-card-need-element-links-icon"
              src={require(`../../../assets/icons/help.svg`).default}
            />
            {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED')}
          </Typography>
          <Typography
            className="force-cfa-gray"
            data-testid="additional-support-card-need-description"
            color="default"
            gutterBottom={false}
            variant="body2"
          >
            {I18n.t(
              'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED_DESCRIPTION',
            )}
          </Typography>
          <ul
            className="additional-support-card__element-list"
            data-testid="additional-support-card-free-cte-element-list"
          >
            <li
              className="additional-support-card__element-list-item"
              data-testid="additional-support-card-free-cte-element-list-item"
            >
              <a
                href={CTE_FREE_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="additional-support-card__element-url"
                data-testid="additional-support-card-free-cte-element-url"
                onClick={addHistoricalAction(
                  auditUUID,
                  null,
                  'LINK_CTE_FREE',
                  `{"name": "Corrective Action Planning","url": "${CORRECTIVE_ACTION_PLANNING_URL}"}`,
                )}
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_FREE_CTE',
                )}
              </a>
            </li>
            <li
              className="additional-support-card__element-list-item"
              data-testid="additional-support-card-safe-cte-element-list-item"
            >
              <a
                href={CTE_ADDITIONAL_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="additional-support-card__element-url"
                data-testid="additional-support-card-safe-cte-element-url"
                onClick={addHistoricalAction(
                  auditUUID,
                  null,
                  'LINK_CTE_ADDITIONAL',
                  `{"name": "Additional SAFE/CTE","url": "${CTE_ADDITIONAL_URL}"}`,
                )}
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_SAFE_CTE',
                )}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

AdditionalSupportCard.propTypes = {
  auditUUID: PropTypes.string,
};

export default AdditionalSupportCard;
