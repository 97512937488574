import PropTypes from 'prop-types';
import { IconButton, Typography } from 'cfa-react-components';
import { Col } from 'reactstrap';
import I18n from 'i18n-js';
import {
  lowercaseWithDashes,
  removeDashes,
  renderFindingColor,
  uppercaseWithUnderscores,
} from '../../../../utils/FindingsUtil';
import { FoodSafetyLabel } from '../../../../components/common/FoodSafetyLabel/FoodSafetyLabel';

import './FindingOverview.scss';

const FindingOverview = ({ finding }) => (
  <div
    className="finding-overview-container"
    data-testid="finding-overview-container"
  >
    <div data-testid="finding-overview-container-sub">
      <div
        className="finding-card__label-container"
        data-testid="finding-overview-label-container"
      >
        <FoodSafetyLabel
          data-testid="finding-overview-food-safety-label-audit-round"
          title={removeDashes(finding.auditRound)}
          type="round"
          margin
        />
        {finding.isRepeat && (
          <FoodSafetyLabel
            data-testid="finding-overview-food-safety-label-card-repeat"
            title={I18n.t('APP_REPORT_DASHBOARD_FINDING_CARD_REPEAT_LABEL')}
            type="round"
            margin
          />
        )}
        <FoodSafetyLabel
          data-testid="finding-overview-food-safety-label-risk-level"
          title={I18n.t(
            `APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_${renderFindingColor(
              finding.riskLevel,
            ).toUpperCase()}`,
          )}
          type={lowercaseWithDashes(renderFindingColor(finding.riskLevel))}
          margin
        />
        <FoodSafetyLabel
          data-testid="finding-overview-food-safety-label-category-name"
          title={I18n.t(
            `APP_REPORT_DASHBOARD_FINDINGS_${uppercaseWithUnderscores(
              finding.category.name,
            )}`,
          ).toUpperCase()}
          type={lowercaseWithDashes(finding.category.name)}
          margin
        />
      </div>
      <div
        className="finding-card__title-container"
        data-testid="finding-overview-title-container"
      >
        <Typography
          data-testid="finding-overview-question-safe-report-id"
          as=""
          color="default"
          variant="h3"
          gutterBottom
        >
          {finding.questionSafeReportId}: {finding.description}
        </Typography>
      </div>
      <div
        className="finding-card__details-container"
        data-testid="finding-overview-details-container"
      >
        <Typography
          data-testid="finding-overview-location-num-name"
          as=""
          color="default"
          variant="h4"
        >
          {finding.locationNum} {finding.locationName}
        </Typography>
        <Typography
          className="finding-card__detail"
          data-testid="finding-overview-card-detail"
          as=""
          color="default"
        >
          <b data-testid="finding-overview-card-detail-bring-attention">
            {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_OCCURRENCES')}
          </b>
          : {finding.findingHistory?.length}
        </Typography>
      </div>
    </div>
    <Col
      className="finding-card__finding-forward-arrow col-1"
      data-testid="finding-overview-forward-arrow-col-1"
    >
      <IconButton
        data-testid="finding-overview-icon-button-chevron-right"
        icon="chevronRight"
        color="tertiary"
        size="sm"
      />
    </Col>
  </div>
);

FindingOverview.propTypes = {
  finding: PropTypes.object.isRequired,
};

export default FindingOverview;
