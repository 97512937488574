import { useMemo } from 'react';
import { Card, CardContent, Row, Typography } from 'cfa-react-components';
import StaffingCardEntry from './StaffingCardEntry';
import SendReminder from './SendReminder';
import LvrWidgetTitle from '../../LicenseeVisitReportWidgetTitle';

import './Staffing.scss';

const StaffingCard = ({ content, locationNumber }) => {
  const {
    cfaLeaderName,
    retailManagerName,
    foodServiceDirectorName,
    additionalGroupContactName,
  } = content;

  return useMemo(
    () => (
      <Card data-testid="staffing-card-assessment-card">
        <CardContent data-testid="staffing-card-assessment-card-content">
          <LvrWidgetTitle
            title="Location Leadership"
            marginBottom="0"
            data-testid="staffing-card-assessment-card-header"
          />
          <Row
            data-testid="staffing-card-leader-row"
            style={{
              paddingLeft: '1rem',
            }}
          >
            <StaffingCardEntry
              data-testid="staffing-card-dedicated-chickfila-leader-staffing-card-entry"
              title="Dedicated Chick-fil-A Leader:"
              name={cfaLeaderName}
            />
            <StaffingCardEntry
              data-testid="staffing-card-multi-concept-leader-staffing-card-entry"
              title="Multi-concept Leader:"
              name={retailManagerName}
            />
          </Row>
          <Row
            data-testid="staffing-card-leaderRow2"
            style={{
              paddingLeft: '1rem',
            }}
          >
            <StaffingCardEntry
              data-testid="staffing-card-additional-chickfila-leader-staffing-card-entry"
              title="Additional Chick-fil-A Leader:"
              name={additionalGroupContactName}
            />
            <StaffingCardEntry
              data-testid="staffing-card-highest-level-leader-staffing-card-entry"
              title="Highest Level Leader:"
              name={foodServiceDirectorName}
            />
          </Row>
          <SendReminder locationNumber={locationNumber} />
        </CardContent>
      </Card>
    ),
    [
      additionalGroupContactName,
      cfaLeaderName,
      foodServiceDirectorName,
      retailManagerName,
    ],
  );
};

export default StaffingCard;
