import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputGroup,
  Container,
  Row,
  Col,
  DropdownItem,
  NavItem,
  UncontrolledTooltip,
  UncontrolledPopover,
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import './LocationSelector.scss';

import { useHistory, useParams } from 'react-router';
import {
  getStoreLocations,
  setSelectedLocation,
  setSelectedLocationByLocationNumber,
} from '../../actions';
import I18n from '../../../i18n/utils';

const LocationSelector = ({ buildLocationChangeRedirectUrl, closePopover }) => {
  /*
   * variables fetched from the global redux store
   */
  const availableLocations = useSelector(
    (state) => state.location.availableLocations,
  );
  const loading = useSelector((state) => state.location.loading);
  const selectedLocation = useSelector(
    (state) => state.location.selectedLocation,
  );

  /*
   * Redux's dispatch method that is used to "push" global state changes through redux
   */
  const dispatch = useDispatch();

  /*
   * Local state variables
   */
  const [settingLocation, setSettingLocation] = useState(false);

  /*
   * react-router related hooks
   */
  const history = useHistory();
  const params = useParams();

  // listen for `locationNumber` URL parameter changes. When this param changes (e.g. browser navigation such as "back")
  // modify the `selectedLocation` global state object in redux
  useEffect(() => {
    // if the `availableLocations` array doesn't exist in the global redux store...we need to initiate the action to get
    // all session accessible locations from the backend
    if (!availableLocations) {
      dispatch(getStoreLocations(params.locationNumber)); // pass in params.locationsNumber to auto-select a location
      return; // early return to avoid further processing
    }

    // at this point...we know that the `availableLocations` array exists in the global redux state

    const hasAccessToLocation = (locationNumber) =>
      availableLocations
        ?.map((location) => location.locationNumber)
        .includes(locationNumber);

    if (params.locationNumber && hasAccessToLocation(params.locationNumber)) {
      // if params.locationNumber exists in the URL AND the current user session has access to that location...go ahead
      // and set that locations as the `selectedLocation` in the global redux store
      dispatch(setSelectedLocationByLocationNumber(params.locationNumber));
    } else if (selectedLocation && buildLocationChangeRedirectUrl) {
      // otherwise (i.e. `params.locationNumber` doesn't exist OR the user session doesn't have access to it), go ahead
      // and replace the current URL to include the locationNumber of the selected location assuming that one exists AND
      // that a function to build out a URL has been passed in as a prop
      history.replace(
        buildLocationChangeRedirectUrl(selectedLocation.locationNumber),
      );
    }
  }, [
    availableLocations,
    params.locationNumber,
    selectedLocation,
    history,
    dispatch,
    buildLocationChangeRedirectUrl,
  ]);

  function setLocation(selectedLoc) {
    // Typeahead calls this method twice when a location is selected [bug]
    // This is a workaround
    if (settingLocation) return;

    setSettingLocation(true);
    dispatch(setSelectedLocation(selectedLoc)).then(() => {
      setSettingLocation(false);
    });

    if (buildLocationChangeRedirectUrl) {
      // determine if we need to call history replace, or history.push
      const updateUrlFunc = !params.locationNumber
        ? history.replace
        : history.push;

      updateUrlFunc(buildLocationChangeRedirectUrl(selectedLoc.locationNumber));
    }

    closePopover();
  }

  function filterByCallback(option, props) {
    return (
      option.location.locationName
        .toLowerCase()
        .indexOf(props.text.toLowerCase()) !== -1 ||
      option.location.locationNumber
        .toLowerCase()
        .indexOf(props.text.toLowerCase()) !== -1 ||
      (option.location.operatorName &&
        option.location.operatorName
          .toLowerCase()
          .indexOf(props.text.toLowerCase()) !== -1)
    );
  }

  function renderMenuItemChildren(option) {
    return (
      <>
        <Row
          className="location-list-item"
          data-testid="location-selector-location-list-item-row"
          onClick={setLocation.bind(this, option)}
        >
          <Col
            className="location-item-avatar"
            data-testid="location-selector-location-item-avatar-col"
          >
            <img
              alt=""
              className="location-item-avatar"
              data-testid="location-selector-location-item-avatar"
              src={require('../../assets/icons/store.svg').default}
            />
          </Col>
          <Col
            className="location-item-details"
            data-testid="location-selector-location-item-details-col"
          >
            <h3
              className="location-store-name"
              data-testid="location-selector-location-store-name"
            >
              {option.location.locationName}
            </h3>
            <h4
              className="location-store-number"
              data-testid="location-selector-location-store-number"
            >
              {option.location.locationNumber}
            </h4>
            <p
              className="location-store-address"
              data-testid="location-selector-location-store-address"
            >
              {option.location.address}&nbsp;
              {option.location.city},&nbsp;
              {option.location.state}&nbsp;
              {option.location.zip}
            </p>
            <p
              className="location-operator-name"
              data-testid="location-selector-location-operator-name"
            >
              {option.location.operatorName}
            </p>
          </Col>
        </Row>
        <DropdownItem data-testid="location-selector-dropdown-item" divider />
      </>
    );
  }

  return (
    <>
      {/* Selected Location Data */}
      {selectedLocation?.location && (
        <NavItem
          className="selected-location-container"
          data-testid="location-selector-selected-location-container"
        >
          <Col data-testid="location-selector-selected-location-col">
            {selectedLocation && (
              <Row
                className="selected-location-name"
                data-testid="location-selector-selected-location-name-row"
              >
                {selectedLocation.location.locationName}
              </Row>
            )}
            <Row
              className="selected-location-number"
              data-testid="location-selector-selected-location-number-row"
            >
              {selectedLocation.locationNumber}
            </Row>
          </Col>
        </NavItem>
      )}
      <img
        alt="location"
        id="location-popover"
        className="location-selector-icon"
        data-testid="location-selector-location-selector-icon"
        width="23"
        height="23"
        // eslint-disable-next-line import/no-dynamic-require
        src={require(
          `../../assets/icons/${
            selectedLocation ? 'location-selected' : 'location'
          }.svg`,
        )}
      />
      <UncontrolledTooltip
        data-testid="location-selector-uncontrolled-tooltip"
        placement="bottom-end"
        target="location-popover"
      >
        {I18n.t('APP_SELECT_LOCATION')}
      </UncontrolledTooltip>
      <UncontrolledPopover
        data-testid="location-selector-uncontrolled-popover"
        placement="bottom-end"
        trigger="legacy"
        target="location-popover"
      >
        <Container
          className="location-selector"
          data-testid="location-selector-location-selector-container"
        >
          <h3
            className="location-selector-title"
            data-testid="location-selector-location-selector-title"
          >
            {I18n.t('APP_LOCATION_SELECTOR_SELECT')}
          </h3>
          {availableLocations?.length ? (
            <Row
              className="input-row"
              data-testid="location-selector-input-row"
            >
              <InputGroup data-testid="location-selector-input-group">
                <Typeahead
                  disabled={loading}
                  className="store-location-input"
                  data-testid="location-selector-store-location-input"
                  labelKey={(location) => location.locationNumber}
                  filterBy={filterByCallback}
                  autoFocus
                  id="filtering-example"
                  placeholder="Search by Store Name or Number"
                  maxLength="5"
                  paginate
                  onChange={(selected) => {
                    setLocation(selected[0]);
                  }}
                  emptyLabel="No results found"
                  type="text"
                  name="location_number"
                  options={availableLocations}
                  renderMenuItemChildren={renderMenuItemChildren}
                />
              </InputGroup>
            </Row>
          ) : (
            <p
              className="location-loading-text"
              data-testid="location-selector-loading-locations-text"
            >
              Loading Locations...
            </p>
          )}
        </Container>
      </UncontrolledPopover>
    </>
  );
};

export default LocationSelector;
