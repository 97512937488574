import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { Button, Typography, LoadingIndicator } from 'cfa-react-components';
import { API_GET } from '../../../utils';
import I18n from '../../../../i18n/utils';
import AmendmentCardContainer from '../../../components/Ammendments/AmendmentCardContainer';
import { AMENDMENT_CARD_TYPE } from '../../../utils/AmendmentUtil';
import FilterBoxContainer from '../../../components/common/FilterBox/FilterBoxContainer';
import RoundSelector from '../../../components/FoodSafetyDashboard/RoundSelector';

import './RequestedAppeals.scss';

const RequestedAppeals = () => {
  const { locationNumber, round } = useParams();
  const selectedAuditFindings = useSelector(
    (state) => state.audit.selectedAuditFindings,
  );
  const [amendments, setAmendments] = useState({});
  const [isLoadingAmendments, setIsLoadingAmendments] = useState(false);
  const [questionSelectionOptions, setQuestionSelectionOptions] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});

  const requestedAppealsBadgeFilterOptions = [
    {
      label: `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_IN_REVIEW')}`,
      value: '&statuses=PENDING',
    },
    {
      label: `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_APPROVED')}`,
      value: '&statuses=APPROVED&isAdmin=false&isModified=false',
    },
    {
      label: `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_UPHELD')}`,
      value: '&statuses=DENIED',
    },
    {
      label: `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REMOVED')}`,
      value: '&isAdmin=true',
    },
    {
      label: `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_MODIFIED')}`,
      value: '&isModified=true',
    },
  ];

  // This is the "static" filter object for the Requested appeals page. It supplies the FilterBoxContainer.
  const appealFilters = [
    {
      filterName: 'selectedQuestionId',
      initialValue: null,
      filterLabel: `${I18n.t(
        'APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FILTERS_FINDING_ID',
      )}`,
      filterType: 'dropdown',
      filterOptions: questionSelectionOptions,
    },
    {
      filterName: 'selectedBadge',
      initialValue: null,
      filterLabel: `${I18n.t(
        'APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FILTERS_BADGE',
      )}`,
      filterType: 'dropdown',
      filterOptions: requestedAppealsBadgeFilterOptions,
    },
  ];

  // function to retrieve amendments based on the appliedFilters
  const retrieveAmendments = async () => {
    setIsLoadingAmendments(true);
    let url = `${process.env.REACT_APP_SERVER_URL}/amendments?page=1&pageSize=100&rounds=${round}&locations=${locationNumber}`;
    if (appliedFilters.selectedBadge) url += appliedFilters.selectedBadge.value;
    if (appliedFilters.selectedQuestionId)
      url += `&questionIds=${appliedFilters.selectedQuestionId.value}`;
    try {
      const result = await API_GET(url);
      setAmendments(result.data);
    } catch (err) {
      console.error('Failed to retrieve filtered amendments.', err);
    }
    setIsLoadingAmendments(false);
  };

  // Component Did Mount Effect that initializes the filteredAuditFindings list.
  useEffect(() => {
    window.scrollTo(0, 0);
    retrieveAmendments();
  }, []);

  useEffect(() => {
    const rawQuestionIds = selectedAuditFindings.map(
      (finding) => finding.questionSafeReportId,
    );
    setQuestionSelectionOptions(
      [...new Set(rawQuestionIds)].sort().map((questionId) => {
        return { value: questionId, label: questionId };
      }),
    );
  }, [selectedAuditFindings]);

  // if the user changes the round or applies filters fetch the amendments
  useEffect(() => {
    retrieveAmendments();
  }, [appliedFilters, round]);

  return (
    <>
      <div
        className="requested-appeals__header-container"
        data-testid="requested-appeals-header-container"
      >
        <div
          className="requested-appeals__title-container"
          data-testid="requested-appeals-title-container"
        >
          <Typography
            data-testid="requested-appeals-app-report-dashboard-requested-appeals"
            as=""
            color="default"
            gutterBottom={false}
            variant="h3"
          >
            {I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS')}
          </Typography>
          {isLoadingAmendments === false && (
            <p
              className="requested-appeals__request-count"
              data-testid="requested-appeals-request-count"
            >
              {amendments.content?.length > 0
                ? amendments.content?.length
                : 'No'}{' '}
              {I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REQUESTS')}
            </p>
          )}
        </div>
        <div
          className="requested-appeals__filters-container"
          data-testid="requested-appeals-filters-container"
        >
          <div
            className="all-findings__round-selector-container"
            data-testid="requested-appeals-round-selector-container"
          >
            <RoundSelector
              data-testid="requested-appeals-round-selector"
              loading={isLoadingAmendments}
              buildRoundBasedUrl={(auditRound) =>
                `/${locationNumber}/report-dashboard/${auditRound}/appeals`
              }
            />
          </div>
          <Button
            data-testid="requested-appeals-filter-button"
            className="requested-appeals__filter-button"
            color="secondary"
            variant="outlined"
            size="lg"
            disabled={isLoadingAmendments}
            onClick={() => setShowFilterBox(!showFilterBox)}
          >
            {I18n.t('APP_REPORT_DASHBOARD_FILTERS')}
          </Button>
        </div>
      </div>
      <FilterBoxContainer
        data-testid="requested-appeals-filter-box-container"
        filterArray={appealFilters}
        hideFilterBoxCallback={() => setShowFilterBox(false)}
        applyFiltersCallback={setAppliedFilters}
        showFilterBox={showFilterBox}
      />
      {isLoadingAmendments === false && amendments.content?.length > 0 && (
        <AmendmentCardContainer
          data-testid="requested-appeals-amendment-card-container"
          cardType={AMENDMENT_CARD_TYPE.OPERATOR}
          amendmentList={amendments.content}
          loadingAmendmentsCallback={setIsLoadingAmendments}
          statusChangeCallback={retrieveAmendments}
        />
      )}
      {isLoadingAmendments === true && (
        <div
          className="requested-appeals__no-results-container"
          data-testid="requested-appeals-no-results-container"
        >
          <LoadingIndicator
            data-testid="requested-appeals-no-results-container-loading-indicator"
            variant="page"
          />
        </div>
      )}
      {isLoadingAmendments === false && !amendments.content && (
        <div
          className="requested-appeals__no-results-container"
          data-testid="requested-appeals-no-results-container-2"
        >
          <Typography
            data-testid="requested-appeals-applied-filters-title"
            as=""
            color="default"
            gutterBottom
            variant="h3"
          >
            {appliedFilters.filtersApplied
              ? `${I18n.t(
                  'APP_REPORT_DASHBOARD_REQUESTED_APPEALS_NO_FILTERED_RESULTS',
                )}`
              : `${I18n.t(
                  'APP_REPORT_DASHBOARD_REQUESTED_APPEALS_NO_RESULTS',
                )} ${round}`}
          </Typography>
        </div>
      )}
    </>
  );
};

export default withOktaAuth(withRouter(RequestedAppeals));
