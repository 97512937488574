import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import cloneDeep from 'lodash/cloneDeep';
import PerformanceLevelLegend from './components/PerfomanceLevelLegend/PerformanceLevelLegend';
import customPointSymbol from './components/CustomPointSymbol/CustomPointSymbol';
import ChartContainer from '../common/ChartContainer';
import { baseTheme } from '../../../constants/constants';

import './HistoricalPerformanceChart.scss';

const theme = cloneDeep(baseTheme);

const HistoricalPerformanceChart = ({ chartData, isLoading }) => (
  <ChartContainer
    data-testid="historical-performance-chart-chart-container"
    isLoading={isLoading}
    isEmptyChart={chartData.length === 0}
  >
    <div
      className="historical-performance-chart"
      data-testid="historical-performance-chart-container"
    >
      <PerformanceLevelLegend data-testid="historical-performance-chart-performance-level-legend" />
      <ResponsiveLine
        data-testid="historical-performance-chart-responsive-line"
        type="linear"
        curve="linear"
        data={[
          { id: '0', data: [{ x: '', y: null }] },
          { id: '1', data: chartData },
        ]}
        pointSize={12}
        margin={{
          top: 30,
          right: 40,
          bottom: 50,
          left: 80,
        }}
        yScale={{
          type: 'linear',
          stacked: false,
          min: 10,
          max: 0,
        }}
        ticksValues
        axisLeft={{
          format: () => '',
          tickPadding: 10,
          tickSize: 0,
          tickValues: 5,
          legend: 'Performance Level',
          legendOffset: -65,
          legendPosition: 'middle',
        }}
        axisBottom={{
          tickPadding: 10,
          tickFontSize: 10,
          tickSize: 0,
          tickRotation: window.innerWidth <= 991 ? -40 : 0,
          orient: 'bottom',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        animate
        enablePointLabel={false}
        colors={['#003E59']}
        theme={theme}
        pointSymbol={(v) => customPointSymbol(v)}
        pointBorderWidth={0}
        enableArea={false}
        useMesh={false}
        enableGridY
        enableGridX
      />
    </div>
  </ChartContainer>
);

HistoricalPerformanceChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
};

export default HistoricalPerformanceChart;
