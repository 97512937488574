import { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Multiselect,
  Typography,
  Tooltip,
  Icon,
  useMediaQuery,
} from 'cfa-react-components';
import { InfoOutline } from '@cfa-icons/system';
import { useLVRDispatch, useLVRStore, actions } from '../../../store/reducers';
import {
  initializeResponse,
  updateResponseData,
  getIdsToClearFromStore,
} from '../../../utils/questionsUtils';

import '../MultipleChoiceQuestion.scss';
import './MultiselectComponent.scss';

const MultiselectComponent = ({ question }) => {
  const { responseUniqueIdentifier, questionData } = question;

  const lvrDispatch = useLVRDispatch();
  const lvrStore = useLVRStore();

  const [selectedValues, setSelectedValues] = useState([]);
  const [errorText, setErrorText] = useState(null);
  const [selectionMade, setSelectionMade] = useState(false);

  const isMobile = useMediaQuery('(max-width: 820px)');

  const existingResponse = useMemo(
    () => lvrStore.responses?.[responseUniqueIdentifier]?.responseData,
    [lvrStore.responses, responseUniqueIdentifier],
  );

  const toggleSelectedCheckbox = (response) => {
    setSelectionMade(true);
    const exclusiveChoice = response?.filter((c) => c?.exclusive);

    setSelectedValues((prevSelectedValues) => {
      // If exclusive choice was previously selected, remove it from the list.
      if (prevSelectedValues.some((c) => c?.exclusive)) {
        return response.filter((c) => !c?.exclusive);
      }
      // If exclusive choice is selected, remove all other choices.
      if (exclusiveChoice?.length) {
        return exclusiveChoice;
      }
      // Otherwise, add the choice to the list.
      return response;
    });
  };

  const validateResponse = useCallback(() => {
    if (question?.questionAttributes?.includes('REQUIRED')) {
      if (selectedValues.length === 0) {
        lvrDispatch({
          type: actions.updateResponseValidationStatuses,
          data: { [responseUniqueIdentifier]: false },
        });

        setErrorText(`Response cannot be blank.`);
        return;
      }
    }

    lvrDispatch({
      type: actions.updateResponseValidationStatuses,
      data: { [responseUniqueIdentifier]: true },
    });

    setErrorText(null);
  }, [
    responseUniqueIdentifier,
    lvrDispatch,
    question?.questionAttributes,
    selectedValues,
  ]);

  const putResponseValue = useCallback(() => {
    const choices = questionData?.choices || [];
    const rootIdsToClearFromStore = choices
      .flatMap((c) =>
        !selectedValues.some((value) => c.id === value.id)
          ? c.responseUniqueIdentifiers
          : [],
      )
      .filter(Boolean);

    const idsToClearFromStore = getIdsToClearFromStore({
      store: lvrStore,
      rootIds: rootIdsToClearFromStore,
      exclusionIds: selectedValues
        .flatMap((value) => value?.responseUniqueIdentifiers)
        .filter(Boolean),
    });

    idsToClearFromStore.forEach((id) => {
      lvrDispatch({
        type: actions.updateResponses,
        data: { [id]: null },
      });

      lvrDispatch({
        type: actions.updateResponseValidationStatuses,
        data: { [id]: null },
      });
    });

    updateResponseData({
      dispatch: lvrDispatch,
      question,
      data: selectedValues,
    });

    validateResponse();
  }, [
    lvrDispatch,
    question,
    questionData?.choices,
    selectedValues,
    validateResponse,
  ]);

  useEffect(() => {
    if (existingResponse?.choicesMade) {
      setSelectedValues(existingResponse?.choicesMade);
    } else {
      initializeResponse({ dispatch: lvrDispatch, question });
    }
  }, []);

  useEffect(() => {
    if (!selectionMade) return;
    putResponseValue();
  }, [putResponseValue, selectionMade, selectedValues]);

  useEffect(() => {
    // Break out if they've not attempted submission yet for a happier form.
    if (lvrStore.submissionFailed) {
      validateResponse();
    }
  }, [
    question?.questionAttributes,
    lvrStore?.submissionFailed,
    validateResponse,
  ]);

  return (
    <form
      className="multiple-choice-question__form"
      data-testid="multiselect-component-multiple-choice-question-form"
    >
      <Multiselect
        data-testid="multiselect-component-multiselect"
        limitTags={isMobile ? 2 : 4}
        fullWidth
        hideSelectAll
        showCheckboxes
        value={selectedValues}
        errorText={errorText}
        onChange={(e) => toggleSelectedCheckbox(e)}
        options={question.questionData.choices}
        getOptionId={(option) => option?.id}
        getOptionText={(option) => option?.text}
        renderOption={(option) => {
          return (
            <div
              className="multiple-choice-question__multi-select-item"
              data-testid="multiselect-component-multiselect-item"
            >
              <Typography
                data-testid="multiselect-component-option-text"
                variant="body1"
              >
                {option?.text}
              </Typography>
              {option?.toolTip && (
                <Tooltip
                  data-testid="multiselect-component-option-tooltip"
                  content={option?.toolTip}
                  showOnElementEvents={['hover']}
                >
                  <span
                    className="multiple-choice-question__tooltip"
                    data-testid="multiselect-component-tooltip-span"
                  >
                    <Icon
                      data-testid="multiselect-component-tooltip-icon"
                      icon={InfoOutline}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          );
        }}
      />
    </form>
  );
};

MultiselectComponent.propTypes = {
  question: PropTypes.object,
};

export default MultiselectComponent;
