import { Component, createRef } from 'react';
import lottie from 'lottie-web';
import LoadingData from '../../assets/animations/CFASpinner.json';

class CFASpinner extends Component {
  constructor() {
    super();
    this.loadingRef = createRef();
  }

  componentDidMount() {
    lottie.loadAnimation({
      container: this.loadingRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: LoadingData,
    });
  }

  componentWillUnmount() {
    lottie.destroy();
  }

  render() {
    return (
      <div
        ref={this.loadingRef}
        className="bounceIn"
        data-testid="cfa-spinner-bounce-in"
        style={{
          width: '70px',
          margin: 'auto',
        }}
      />
    );
  }
}

export default CFASpinner;
