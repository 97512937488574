import { Icon } from 'cfa-react-components';
import { SuccessFilled, ErrorFilled } from '@cfa-icons/system';

import '../FileUploader.scss';

const StatusIcon = ({ isLoading, isError }) => {
  if (isLoading || isLoading === undefined) return null;
  return (
    <div
      className="file-uploader__file-item__flex-container file-uploader__file-item__status-container"
      data-testid="status-icon-container"
    >
      {isError ? (
        <>
          <Icon
            icon={ErrorFilled}
            color="#b6072f"
            className="file-uploader__file-item__status-icon"
            data-testid="status-icon-fail-icon"
          />
          <div data-testid="status-icon-fail-text">Fail</div>
        </>
      ) : (
        <>
          <Icon
            icon={SuccessFilled}
            color="#249E6B"
            className="file-uploader__file-item__status-icon"
            data-testid="status-icon-success-status-icon"
          />
          <div data-testid="status-icon-done-text">Done</div>
        </>
      )}
    </div>
  );
};

export default StatusIcon;
