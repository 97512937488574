import { useState } from 'react';
import PropTypes from 'prop-types';

import CancelNextButtons from './CancelNextButtons';

import './PathwayQuestion.scss';

export default function PathwayQuestion({
  findingTitle,
  handleCancel,
  handleNext,
}) {
  const [answerState, setAnswerYes] = useState({
    yes: false,
    no: false,
    stepIndex: 0,
  });

  const handleYesBtn = () => {
    setAnswerYes({
      yes: !answerState.yes,
      answer: 'YES',
      no: false,
    });
  };

  const handleNoBtn = () => {
    setAnswerYes({
      no: !answerState.no,
      answer: 'NO',
      yes: false,
    });
  };

  return (
    <div data-testid="pathway-question-container">
      <p
        className="pathway-question__finding-title"
        data-testid="pathway-question-finding-title"
      >
        {findingTitle}
      </p>
      <p
        className="pathway-question__question-text"
        data-testid="pathway-question-question-text"
      >
        Pathway provides step-by-step processes to achieve Chick-fil-A
        requirements. Have you reviewed the Pathway procedures for this finding?
      </p>

      <div
        className="pathway-question__button-container"
        data-testid="pathway-question-button-container"
      >
        <button
          type="button"
          className={`pathway-question__button ${
            answerState.yes ? 'pathway-question__button--active' : ''
          }`}
          data-testid="pathway-question-yes-button"
          onClick={handleYesBtn}
        >
          Yes
        </button>

        <button
          type="button"
          className={`pathway-question__button ${
            answerState.no && 'pathway-question__button--active'
          }`}
          data-testid="pathway-question-no-button"
          onClick={handleNoBtn}
        >
          No
        </button>
      </div>

      <CancelNextButtons
        data-testid="pathway-question-cancel-next-buttons"
        handleCancel={handleCancel}
        handleNext={handleNext('PATHWAY_QUESTION', answerState.answer)}
        isDisabled={answerState.yes === false && answerState.no === false}
      />
    </div>
  );
}

PathwayQuestion.propTypes = {
  findingTitle: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};
