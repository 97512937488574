import { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'cfa-react-components';
import {
  initializeResponse,
  updateResponseData,
  getIdsToClearFromStore,
} from '../../../utils/questionsUtils';
import { useLVRDispatch, useLVRStore, actions } from '../../../store/reducers';

import './DropdownComponent.scss';

const MultipleChoiceDropdownQuestion = ({ question }) => {
  const { responseUniqueIdentifier, questionData } = question;

  const lvrDispatch = useLVRDispatch();
  const lvrStore = useLVRStore();

  const [value, setValue] = useState('');
  const [errorText, setErrorText] = useState(null);

  const existingResponse = useMemo(
    () => lvrStore.responses?.[responseUniqueIdentifier]?.responseData,
    [lvrStore.responses, responseUniqueIdentifier],
  );

  const validateResponse = useCallback(() => {
    if (question?.questionAttributes?.includes('REQUIRED')) {
      if (value === '') {
        lvrDispatch({
          type: actions.updateResponseValidationStatuses,
          data: { [responseUniqueIdentifier]: false },
        });

        setErrorText(`A value must be selected.`);
        return;
      }
    }

    lvrDispatch({
      type: actions.updateResponseValidationStatuses,
      data: { [responseUniqueIdentifier]: true },
    });

    setErrorText(null);
  }, [
    responseUniqueIdentifier,
    lvrDispatch,
    question?.questionAttributes,
    value,
  ]);

  const putResponseValue = useCallback(() => {
    if (!value) return;

    const choiceMade = questionData.choices.find((c) => c.id === value.id);
    const rootIdsToClearFromStore = questionData.choices
      .flatMap((c) => (c.id !== value.id ? c.responseUniqueIdentifiers : []))
      .filter(Boolean);

    const idsToClearFromStore = getIdsToClearFromStore({
      store: lvrStore,
      rootIds: rootIdsToClearFromStore,
      exclusionIds: choiceMade?.responseUniqueIdentifiers,
    });

    idsToClearFromStore.forEach((id) => {
      lvrDispatch({
        type: actions.updateResponses,
        data: { [id]: null },
      });

      lvrDispatch({
        type: actions.updateResponseValidationStatuses,
        data: { [id]: null },
      });
    });

    updateResponseData({
      dispatch: lvrDispatch,
      question,
      data: [choiceMade],
    });

    validateResponse();
  }, [lvrDispatch, question, questionData?.choices, validateResponse, value]);

  useEffect(() => {
    if (existingResponse?.choicesMade?.[0]) {
      setValue(existingResponse?.choicesMade[0]);
    } else {
      initializeResponse({ dispatch: lvrDispatch, question });
    }
  }, []);

  useEffect(() => {
    putResponseValue();
  }, [putResponseValue, value]);

  useEffect(() => {
    // Break out if they've not attempted submission yet for a happier form.
    if (lvrStore.submissionFailed) {
      validateResponse();
    }
  }, [
    question?.questionAttributes,
    lvrStore?.submissionFailed,
    validateResponse,
  ]);

  return (
    <Dropdown
      className="multiple-choice-dropdown"
      data-testid="dropdown-component-dropdown"
      options={questionData.choices}
      value={value}
      onChange={(e) => setValue(e)}
      renderOption={(option) => option.text}
      getOptionId={(option) => option.id}
      getOptionText={(option) => option.text}
      fullWidth
      errorText={errorText}
    />
  );
};

MultipleChoiceDropdownQuestion.propTypes = {
  question: PropTypes.object,
};

export default MultipleChoiceDropdownQuestion;
