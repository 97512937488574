import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { LoadingIndicator, Typography } from 'cfa-react-components';
import { useOktaAuth } from '@okta/okta-react';

import { claimsOneRole, useIsDevTeam } from '../../utils/AuthUtils';
import NavHeader from '../../components/NavHeader/NavHeader';
import I18n from '../../../i18n/utils';
import useSmartShopAccess from '../../utils/hooks/useSmartShopAccess';

import './Landing.scss';
import BetaBadge from '../../components/common/BetaBadge/BetaBadge';
import { trackEvent } from '../../utils';
import { routeToNewApp } from '../../utils/redirectUtil';

const Landing = () => {
  routeToNewApp();
  const { locationNumber } = useParams();
  const hasSmartShopAccess = useSmartShopAccess();
  const hasDevTeamAccess = useIsDevTeam();
  const isLicenseeLocation = useSelector(
    (state) => state.location.isLicenseeLocation,
  );
  const availableLocations = useSelector(
    (state) => state.location.availableLocations,
  );
  const locationsLoading = useSelector((state) => state.location.loading);
  const userToken = useSelector((state) => state.auth.userToken);

  const addLocationNumberToUrl = useCallback(
    (urlSubstring) => `/${locationNumber}${urlSubstring}`,
    [locationNumber],
  );

  const healthPromptDomain = process.env.REACT_APP_HEALTH_PROMPT_URL;

  const { authState } = useOktaAuth();

  const claimsRequiredRole = (requiredRoles) =>
    claimsOneRole(authState?.accessToken?.claims, requiredRoles);

  return useMemo(
    () => (
      <>
        <NavHeader
          data-testid="landing-nav-header"
          buildLocationChangeRedirectUrl={(locNumber) =>
            `/${locNumber}/landing`
          }
        />
        <Container className="landing" data-testid="landing-landing-container">
          {!userToken || locationsLoading ? (
            <LoadingIndicator
              data-testid="landing-loading-indicator"
              variant="page"
            />
          ) : !availableLocations || availableLocations.length === 0 ? (
            <Row data-testid="landing-403-error-row">
              <Col>
                <h1 className="403-error" data-testid="landing-403-error">
                  403 Forbidden
                </h1>
                <p data-testid="landing-403-error-message">
                  You do not have access to this site. Please contact an
                  administrator.
                </p>
              </Col>
            </Row>
          ) : (
            <>
              <Row data-testid="landing-greeting-row">
                <Col data-testid="landing-greeting-col">
                  <h1
                    className="landing-greeting"
                    data-testid="landing-greeting"
                  >
                    {I18n.t('APP_LANDING_PAGE_WELCOME')}, {userToken.name}
                  </h1>
                </Col>
              </Row>
              <Row data-testid="landing-food-safety-row">
                {claimsRequiredRole(['PROGRAM_SAFE']) && (
                  <Col data-testid="landing-food-safety-col">
                    <Link
                      to={addLocationNumberToUrl('/report-dashboard')}
                      className="container landing__link"
                      data-testid="landing-food-safety-container-landing-link"
                    >
                      <Card
                        className="app-card"
                        data-testid="landing-food-safety-app-card"
                      >
                        <Col
                          className="container"
                          data-testid="landing-food-safety-container"
                        >
                          <img
                            alt={I18n.t('APP_LANDING_PAGE_FOOD_SAFETY_CARD')}
                            className="app-card-icon report-icon"
                            data-testid="landing-food-safety-app-card-icon-report-icon"
                            src={
                              require('../../assets/icons/report.svg').default
                            }
                          />
                        </Col>
                        <h3 data-testid="landing-food-safety-app-landing-page-food-safety-card">
                          {I18n.t('APP_LANDING_PAGE_FOOD_SAFETY_CARD')}
                        </h3>
                      </Card>
                    </Link>
                  </Col>
                )}
                {hasSmartShopAccess && (
                  <Col data-testid="landing-lvr-col">
                    <Link
                      to={addLocationNumberToUrl('/smart-shop')}
                      className="container landing__link"
                      data-testid="landing-lvr-container-landing-link"
                    >
                      <Card
                        className="app-card"
                        data-testid="landing-lvr-app-card"
                      >
                        <Col
                          className="container"
                          data-testid="landing-lvr-container"
                        >
                          <img
                            alt={I18n.t('APP_LANDING_PAGE_FOOD_SAFETY_CARD')}
                            className="app-card-icon report-icon"
                            data-testid="landing-lvr-app-card-icon-report-icon"
                            src={
                              require('../../assets/icons/smart-shop.svg')
                                .default
                            }
                          />
                        </Col>
                        <div className="landing__title_container">
                          <Typography
                            variant="h3"
                            fontWeight="extralight"
                            className="landing__tile_title"
                            data-testid="landing-lvr-licensee-visit-report-title"
                          >
                            {I18n.t('APP_SMART_SHOP_REPORT')}
                          </Typography>
                          <BetaBadge />
                        </div>
                      </Card>
                    </Link>
                  </Col>
                )}
                {(userToken?.userType === 'Staff' || hasDevTeamAccess) && (
                  <Col data-testid="landing-tableau-dashboard-col">
                    <Link
                      target="_blank"
                      to={{
                        pathname:
                          'https://tableau.cfahome.com/#/views/FoodSafetyDashboard/PerformanceLevelDistribution?:iid=1',
                      }}
                      className="container landing__link"
                      data-testid="landing-tableau-dashboard-container-landing-link"
                    >
                      <Card
                        className="app-card "
                        data-testid="landing-tableau-dashboard-app-card"
                      >
                        <Col
                          className="container"
                          data-testid="landing-tableau-dashboard-container"
                        >
                          <img
                            alt={I18n.t('APP_LANDING_PAGE_TABLEAU_DASHBOARD')}
                            className="app-card-icon report-icon"
                            data-testid="landing-tableau-dashboard-app-card-icon-report-icon"
                            src={
                              require('../../assets/icons/stars-icon.svg')
                                .default
                            }
                          />
                        </Col>
                        <h3 data-testid="landing-tableau-dashboard-app-landing-page-tableau-dashboard">
                          {I18n.t('APP_LANDING_PAGE_TABLEAU_DASHBOARD')}
                        </h3>
                      </Card>
                    </Link>
                  </Col>
                )}
                {isLicenseeLocation &&
                  (!userToken?.smartShopAdmin || hasDevTeamAccess) && (
                    <Col data-testid="landing-lvr-col">
                      <Link
                        to={addLocationNumberToUrl('/assessment')}
                        className="container landing__link"
                        data-testid="landing-lvr-container-landing-link"
                      >
                        <Card
                          className="app-card"
                          data-testid="landing-lvr-app-card"
                        >
                          <Col
                            className="container"
                            data-testid="landing-lvr-container"
                          >
                            <img
                              alt={I18n.t('APP_LANDING_PAGE_FOOD_SAFETY_CARD')}
                              className="app-card-icon report-icon"
                              data-testid="landing-lvr-app-card-icon-report-icon"
                              src={
                                require('../../assets/icons/lvr.svg').default
                              }
                            />
                          </Col>
                          <h3 data-testid="landing-lvr-licensee-visit-report-title">
                            Licensee Visit Report
                          </h3>
                        </Card>
                      </Link>
                    </Col>
                  )}
                {claimsRequiredRole(['PROGRAM_SAFE']) && (
                  <Col data-testid="landing-health-prompt-col">
                    <a
                      href={`${healthPromptDomain}?locationNumber=${locationNumber}`}
                      target="_blank"
                      rel="noreferrer"
                      className="container landing__link"
                      data-testid="landing-health-container-landing-link"
                      onClick={() => {
                        if (userToken?.userRole) {
                          trackEvent(
                            'health-prompt-link-clicked',
                            userToken?.userRole,
                          );
                        }
                      }}
                    >
                      <Card
                        className="app-card"
                        data-testid="landing-health-app-card"
                      >
                        <Col
                          className="container"
                          data-testid="landing-health-container"
                        >
                          <img
                            alt={I18n.t('APP_LANDING_PAGE_HEALTH_CARD')}
                            className="app-card-icon report-icon"
                            data-testid="landing-health-app-card-icon-report-icon"
                            src={
                              require('../../assets/icons/health_hygiene.svg')
                                .default
                            }
                          />
                        </Col>
                        <h3 data-testid="landing-health-app-landing-page-health-card">
                          {I18n.t('APP_LANDING_PAGE_HEALTH_CARD')}
                        </h3>
                      </Card>
                    </a>
                  </Col>
                )}
                <Col data-testid="landing-lvr-col">
                  <Link
                    target="_blank"
                    to={{
                      pathname: 'https://aha.smartrest.cfahome.com/home',
                    }}
                    className="container landing__link"
                    data-testid="landing-lvr-container-landing-link"
                  >
                    <Card
                      className="app-card"
                      data-testid="landing-lvr-app-card"
                    >
                      <Col
                        className="container"
                        data-testid="landing-lvr-container"
                      >
                        <img
                          alt={I18n.t('APP_LANDING_PAGE_FOOD_SAFETY_CARD')}
                          className="app-card-icon report-icon"
                          style={{
                            width: '8rem',
                            marginBottom: '1rem',
                            transform: 'scaleY(-1)',
                          }}
                          data-testid="landing-lvr-app-card-icon-report-icon"
                          src={require('../../assets/icons/aha.svg').default}
                        />
                      </Col>
                      <h3 data-testid="landing-lvr-licensee-visit-report-title">
                        Automated Holding Assistant (AHA)
                      </h3>
                    </Card>
                  </Link>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    ),
    [
      addLocationNumberToUrl,
      availableLocations,
      isLicenseeLocation,
      locationsLoading,
      userToken,
      hasSmartShopAccess,
      hasDevTeamAccess,
    ],
  );
};

export default Landing;
