import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { claimsAdminRole, claimsLicenseeLeadRole } from '../AuthUtils';
import useSelectedLocation from './useSelectedLocation';
import { routeToNewApp } from '../redirectUtil';

const useLvrAccess = () => {
  const history = useHistory();
  const { locationNumber } = useParams();
  const { authState } = useOktaAuth();
  const { loading, isLicenseeLocation } = useSelector(
    (state) => state.location,
  );
  const userToken = useSelector((state) => state.auth.userToken);
  const selectedLocation = useSelectedLocation();

  // Redirect to landing page if not a licensee location
  useEffect(() => {
    if (loading || !selectedLocation) return;

    if (isLicenseeLocation !== null && !isLicenseeLocation) {
      routeToNewApp(`/${selectedLocation.locationNumber}/landing`);
    }
  }, [history, isLicenseeLocation, loading, locationNumber, selectedLocation]);

  const checkClaim = (claimFunction) =>
    authState?.accessToken && claimFunction(authState.accessToken.claims);

  const isTeamMember = userToken?.userType === 'Team Member';
  const isOperator = userToken?.userType === 'Operator';
  const isOpenLocation = selectedLocation?.location?.openStatus === 'open';
  const hasLicenseeLeadRole = checkClaim(claimsLicenseeLeadRole);
  const hasAdminRole = checkClaim(claimsAdminRole);

  return {
    hasLvrFormAccess:
      isOpenLocation && !isTeamMember && (isOperator || hasAdminRole),
    hasOCNotesAccess: hasLicenseeLeadRole || isOperator || hasAdminRole,
  };
};

export default useLvrAccess;
