import { useMemo, useEffect } from 'react';
import { TextField } from 'cfa-react-components';
import { useLVRStore, useLVRDispatch, actions } from '../../store/reducers';
import { getIdTokenClaims } from '../../../../utils/getToken';
import { putAutoSaveAssessment } from '../../services/autoSaveApi';

import './GeneralInformationForm.scss';

const ReadOnlyFields = () => {
  const lvrStore = useLVRStore();
  const dispatch = useLVRDispatch();

  useEffect(() => {
    if (!lvrStore?.questionnaireId && !lvrStore?.locationNumber) return;

    const fetchAndUpdateUserData = async () => {
      const { assessorName } = getIdTokenClaims();

      // Only proceed if questionnaireId is available
      // Check if the operatorConsultant needs to be updated
      if (lvrStore?.supplementaryData?.operatorConsultant !== assessorName) {
        dispatch({
          type: actions.updateOperatorConsultant,
          data: assessorName,
        });

        // Prepare the updated state for auto-save
        const updatedState = {
          ...lvrStore,
          supplementaryData: {
            ...lvrStore.supplementaryData,
            operatorConsultant: assessorName,
          },
        };

        if (lvrStore.questionnaireId) {
          // Trigger auto-save with the updated state
          await putAutoSaveAssessment({
            locationNumber: lvrStore.locationNumber,
            questionnaireId: lvrStore.questionnaireId,
            form: lvrStore.form,
            saveData: updatedState,
          });
        }
      }
    };

    fetchAndUpdateUserData();
  }, [
    dispatch,
    lvrStore?.locationNumber,
    lvrStore?.questionnaireId,
    lvrStore.form,
  ]);

  const {
    supplementaryData: {
      operatorConsultant,
      restaurantName,
      restaurantNumber,
      foodServiceProvider,
      licensingLead,
    },
  } = useLVRStore();

  return useMemo(
    () => (
      <>
        <TextField
          className="lvr-gen-info__input"
          data-testid="read-only-fields-operator-consultant-name-lvr-gen-info-input"
          value={operatorConsultant}
          label="Operator Consultant Name"
          fullWidth
          readOnly
        />
        <div
          className="lvr-gen-info__row"
          data-testid="read-only-fields-restaurant-lvr-gen-info-row"
        >
          <TextField
            className="lvr-gen-info__input"
            data-testid="read-only-fields-licensed-restaurant-name-lvr-gen-info-input"
            value={restaurantName}
            label="Licensed Restaurant Name"
            fullWidth
            readOnly
          />
          <TextField
            className="lvr-gen-info__input"
            data-testid="read-only-fields-licensed-restaurant-number-lvr-gen-info-input"
            value={restaurantNumber}
            label="Licensed Restaurant Number"
            fullWidth
            readOnly
          />
        </div>
        <TextField
          className="lvr-gen-info__input"
          data-testid="read-only-fields-food-service-provider-lvr-gen-info-input"
          value={foodServiceProvider}
          label="Food Service Provider"
          fullWidth
          readOnly
        />
        <TextField
          className="lvr-gen-info__input"
          data-testid="read-only-fields-licensing-lead-lvr-gen-info-input"
          value={licensingLead}
          placeholder="Licensing Lead"
          label="Licensing Lead"
          fullWidth
          readOnly
        />
      </>
    ),
    [
      foodServiceProvider,
      licensingLead,
      operatorConsultant,
      restaurantName,
      restaurantNumber,
    ],
  );
};

export default ReadOnlyFields;
