import { createContext, useReducer, useContext } from 'react';
import getIdTokenClaims from '../../../utils/getToken';

const { cfaGuid, assessorName } = getIdTokenClaims();

const initialState = {
  assessorId: cfaGuid,
  uiFormStatus: {
    openCategories: null,
  },
  form: '',
  inspectionTimestamp: null,
  locationNumber: '',
  program: '',
  provider: '',
  questionnaireId: null,
  questionnaireQuestions: null,
  responseValidationStatuses: {},
  responses: {},
  round: null,
  submissionFailed: false,
  supplementaryData: {
    cfaTraining: '',
    dayPart: null,
    foodServiceProvider: '',
    licensingLead: '',
    operatorConsultant: assessorName,
    orderExperience: null,
    seasonalQuestion: '',
    specifyCfaTraining: '',
    restaurantName: '',
    restaurantNumber: '',
    visitDate: null,
    visitTime: '',
    visitType: null,
  },
};

export const actions = {
  updateQuestionnaireId: 'updateQuestionnaireId',
  updateUiFormStatus: 'updateUiFormStatus',
  updateInspectionTimestamp: 'updateInspectionTimestamp',
  updateLocationNumber: 'updateLocationNumber',
  updateResponseValidationStatuses: 'updateResponseValidationStatuses',
  updateResponses: 'updateResponses',
  updateRound: 'updateRound',
  updateSubmissionFailed: 'updateSubmissionFailed',
  updateSupplementaryData: 'updateSupplementaryData',
  dangerouslyUpdateStore: 'dangerouslyUpdateStore',
  resetStore: 'resetStore',
  updateOperatorConsultant: 'updateOperatorConsultant',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.updateOperatorConsultant:
      return {
        ...state,
        supplementaryData: {
          ...state.supplementaryData,
          operatorConsultant: action.data,
        },
      };
    case actions.updateInspectionTimestamp:
      return {
        ...state,
        inspectionTimestamp: action.data,
      };
    case actions.updateLocationNumber:
      return {
        ...state,
        locationNumber: action.data,
      };
    case actions.updateUiFormStatus:
      return {
        ...state,
        uiFormStatus: {
          ...state.uiFormStatus,
          ...action.data,
        },
      };
    case actions.updateQuestionnaireId:
      return {
        ...state,
        questionnaireId: action.data,
      };
    case actions.updateResponseValidationStatuses:
      return {
        ...state,
        responseValidationStatuses: {
          ...state.responseValidationStatuses,
          ...action.data,
        },
      };
    case actions.updateResponses:
      return {
        ...state,
        responses: { ...state.responses, ...action.data },
      };
    case actions.updateRound:
      return {
        ...state,
        round: action.data,
      };
    case actions.updateSubmissionFailed:
      return {
        ...state,
        submissionFailed: action.data,
      };
    case actions.updateSupplementaryData:
      return {
        ...state,
        supplementaryData: {
          ...state.supplementaryData,
          ...action.data,
        },
      };
    case actions.resetStore:
      // Hardcode values required here b/c I believe the StoreContext
      // uses the initialState object as a pointer, otherwise
      // the store will not reset
      return {
        ...state,
        assessorId: cfaGuid,
        uiFormStatus: {
          openCategories: null,
        },
        form: '',
        inspectionTimestamp: null,
        locationNumber: '',
        program: '',
        provider: '',
        questionnaireId: null,
        questionnaireQuestions: null,
        responseValidationStatuses: {},
        responses: {},
        round: null,
        submissionFailed: false,
        supplementaryData: {
          cfaTraining: '',
          dayPart: null,
          foodServiceProvider: '',
          licensingLead: '',
          operatorConsultant: assessorName,
          orderExperience: null,
          seasonalQuestion: '',
          specifyCfaTraining: '',
          restaurantName: '',
          restaurantNumber: '',
          visitDate: null,
          visitTime: '',
          visitType: null,
        },
      };
    case actions.dangerouslyUpdateStore:
      return {
        ...state,
        ...action.data,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const StoreContext = createContext(initialState);
const DispatchContext = createContext(undefined);

export const LVRStoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DispatchContext.Provider
      data-testid="reducers-dispatch-context-provider"
      value={dispatch}
    >
      <StoreContext.Provider
        data-testid="reducers-store-context-provider"
        value={state}
      >
        {children}
      </StoreContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useLVRStore = () => useContext(StoreContext);
export const useLVRDispatch = () => useContext(DispatchContext);
