import { useEffect } from 'react';
import { AccordionItem, Typography } from 'cfa-react-components';
import { API_GET } from '../../../../utils';
import ReadOnlyFields from './ReadOnlyFields';
import useSelectedLocation from '../../../../utils/hooks/useSelectedLocation';
import GeneralQuestions from './GeneralQuestions';
import { useLVRDispatch } from '../../store/reducers';
import { updateSupplementaryData } from '../../utils/questionsUtils';

import './GeneralInformationForm.scss';

const GeneralInformationForm = () => {
  const lvrDispatch = useLVRDispatch();

  const { locationNumber, location } = useSelectedLocation();

  const getPrefilledData = async (locNumber) => {
    const path = `${process.env.REACT_APP_SERVER_URL}/location/${locNumber}/licensee-leaders`;
    try {
      const response = await API_GET(path);
      updateSupplementaryData({
        dispatch: lvrDispatch,
        data: {
          restaurantName: location?.locationName,
          restaurantNumber: locationNumber,
          foodServiceProvider: response.data.foodServiceProviderName,
          licensingLead: response.data.licensedBusinessConsultantName,
        },
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (locationNumber) {
      getPrefilledData(locationNumber);
    }
  }, [locationNumber]);

  return (
    <AccordionItem
      id="General Information"
      className="questionnaire-category"
      data-testid="general-information-accordion-item"
      itemKey="General Information"
      header={
        <Typography
          variant="h3"
          className="ml-2 mb-1 lvr-gen-info__title"
          data-testid="general-information-general-information-title"
        >
          General Information
        </Typography>
      }
    >
      <div
        className="mb-2"
        data-testid="general-information-read-only-fields-container"
      >
        <ReadOnlyFields data-testid="general-information-read-only-fields" />
      </div>
      <GeneralQuestions data-testid="general-information-general-questions" />
    </AccordionItem>
  );
};

export default GeneralInformationForm;
