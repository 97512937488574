/**
 * All commented out code in this file are related
 * to vendor data, which is not currently available.
 */

import { useEffect, useState, useCallback } from 'react';
import { withRouter, useParams, useHistory, Link } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import {
  Col,
  Dropdown,
  LoadingIndicator,
  Row,
  Typography,
  Button,
  Icon,
} from 'cfa-react-components';
import { Celebrate } from '@cfa-icons/system';
import I18n from '../../../../i18n/utils';
import useSelectedLocation from '../../../utils/hooks/useSelectedLocation';
import './SmartShop.scss';
import LBRDownloadModal from './LBRDownloadModal';

const SmartShop = ({ assessments = [], isLoading = true }) => {
  const history = useHistory();
  const { assessmentId } = useParams();
  const { locationNumber } = useSelectedLocation();
  const [selectedAssessment, setSelectedAssessment] = useState(null);

  const addLocationNumberToUrl = useCallback(
    (urlSubstring) => `/${locationNumber}${urlSubstring}`,
    [locationNumber],
  );

  const setHistory = (assessment = null, startInquiry = false) => {
    if (assessment) {
      const routeToForm = startInquiry ? '/inquiry-form' : '';
      history.replace(
        `/${locationNumber}/smart-shop/${assessment.assessmentId}${routeToForm}`,
      );
    }
  };

  useEffect(() => {
    if (assessments.length > 0) {
      const assessment = assessmentId
        ? assessments.find((r) => r.assessmentId === Number(assessmentId))
        : assessments[0];
      setSelectedAssessment(assessment);
      setHistory(assessment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessments, assessmentId]);

  return isLoading ? (
    <LoadingIndicator variant="page" />
  ) : (
    <div data-testid="smart-shop-container">
      <Row>
        <Col
          className="smart-shop__inquiry-button-container"
          lg="9"
          md="8"
          xs="12"
        >
          <Typography
            className="smart-shop__page-label"
            data-testid="smart-shop-page-title"
            variant="h3"
            gutterBottom
          >
            {I18n.t('APP_SMART_SHOP_REPORT')}
          </Typography>
          <div className="smart-shop__button-group">
            <Button
              size="md"
              variant="filled"
              color="secondary"
              disabled={!selectedAssessment}
              onClick={() => setHistory(selectedAssessment, true)}
              className="smart-shop__inquiry-button"
            >
              {I18n.t('APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_START_INQUIRY')}
            </Button>
            <LBRDownloadModal assessments={assessments} />
          </div>
        </Col>
        <Col
          lg={{ span: 3 }}
          md="4"
          xs="12"
          className="all-findings__dropdown-container"
        >
          <Dropdown
            compact
            fullWidth
            label="Visit"
            options={assessments}
            disabled={isLoading || assessments.length === 0}
            value={selectedAssessment}
            className="all-findings__dropdown"
            getOptionId={(option) => option.id}
            renderOption={(option) => `${option.date} - ${option.assessmentId}`}
            onChange={(assessment) => {
              setHistory(assessment);
              setSelectedAssessment(assessment);
            }}
            data-testid="smart-shop-all-findings-assessment-dropdown"
          />
        </Col>
      </Row>
      <Row className="smart-shop__coming-soon-row">
        <Col className="smart-shop__coming-soon-icon-col" lg="6" md="9" xs="12">
          <div
            className="smart-shop__coming-soon-icon"
            style={{ marginBottom: '1em' }}
          >
            <Typography align="center" gutterbottom>
              <Icon className="smart-shop__icon" size="xl" icon={Celebrate} />
            </Typography>
          </div>
          <div className="smart-shop__coming-soon-header">
            <Typography
              variant="h3"
              align="center"
              fontWeight="bold"
              gutterBottom
            >
              {I18n.t('APP_SMART_SHOP_COMING_SOON_HEADER')}
            </Typography>
          </div>
          <div className="smart-shop__coming-soon-details">
            <Typography variant="body1" align="center" gutterBottom>
              {I18n.t('APP_SMART_SHOP_COMING_SOON_DETAILS')}
            </Typography>
          </div>
          <div className="smart-shop__coming-soon-explore-more">
            <Typography variant="overline1" align="center">
              {I18n.t('APP_SMART_SHOP_COMING_SOON_ADDITIONAL_LINKS_LABEL')}
            </Typography>
            <Typography variant="body1" align="center">
              <div className="smart-shop__coming-soon-explore-more-links">
                <div className="smart-shop__coming-soon-explore-more-all-findings">
                  <Link
                    className="smart-shop__coming-soon-all-findings-link"
                    to={addLocationNumberToUrl('/smart-shop/findings')}
                    size="body1"
                    variant="inline"
                  >
                    {I18n.t('APP_SMART_SHOP_COMING_SOON_ALL_FINDINGS_LINK')}
                  </Link>
                  {I18n.t(
                    'APP_SMART_SHOP_COMING_SOON_ALL_FINDINGS_DESCRIPTION',
                  )}
                </div>
                <div className="smart-shop__coming-soon-explore-more-all-findings">
                  <Link
                    className="smart-shop__coming-soon-all-findings-link"
                    to={{
                      pathname:
                        'https://analytics.cfahome.com/dashboard/smart-shop',
                    }}
                    size="body1"
                    target="_blank"
                    variant="inline"
                  >
                    {I18n.t('APP_SMART_SHOP_COMING_SOON_DASHBOARD_LINK')}
                  </Link>
                  {I18n.t('APP_SMART_SHOP_COMING_SOON_DASHBOARD_DESCRIPTION')}
                </div>
              </div>
            </Typography>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withOktaAuth(withRouter(SmartShop));
