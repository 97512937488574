import { useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Typography,
  TextField,
} from 'cfa-react-components';
import debounce from 'lodash.debounce';
import {
  API_POST,
  showFullTopSuccessNotification,
  showFailureNotification,
} from '../../../../utils';

const NeedHelpModal = ({ show, onClose }) => {
  const [question, setQuestion] = useState('');
  const { locationNumber } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true); // Start of submission, disable the button
    // Build the URL and payload object for submitting the question
    const url = `${process.env.REACT_APP_SERVER_URL}/finding/general-question`;
    const payload = {
      locationNumber,
      questionBody: question,
    };

    try {
      await API_POST(url, payload);
      showFullTopSuccessNotification({
        message: 'Your question has been submitted successfully',
      });
      setQuestion(''); // Clear the question field
      onClose(); // Close the modal after successful submission
    } catch (error) {
      showFailureNotification({
        message:
          'Sorry, we could not submit your question at this time. Please check your internet connection and try again. If the problem persists, contact our support team for assistance.',
      });
    } finally {
      setIsSubmitting(false); // Reset the submission state
    }
  }, [locationNumber, question, onClose]);

  // Debounce the handleSubmit function
  const handleSubmitDebounced = useMemo(
    () => debounce(handleSubmit, 300),
    [handleSubmit],
  );

  return (
    <Modal show={show} onClose={onClose} size="md">
      <ModalHeader style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Typography gutterBottom variant="h3">
          Ask a general question
        </Typography>
      </ModalHeader>
      <ModalBody>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography align="left" variant="body2">
            Question for the Food Safety Team
          </Typography>
          <div style={{ marginLeft: '0.2rem' }}>
            <Typography color="primary" variant="body2">
              *
            </Typography>
          </div>
        </div>
        <TextField
          fullWidth
          helperText="Maximum 500 characters"
          maxLength={500}
          multiline
          placeholder="Write a question..."
          rows={3}
          textAlign="start"
          value={question}
          onChange={handleQuestionChange}
        />
      </ModalBody>
      <ModalFooter style={{ display: 'flex', justifyContent: 'right' }}>
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          size="sm"
        >
          Cancel
        </Button>
        <Button
          disabled={!question || isSubmitting}
          onClick={handleSubmitDebounced}
          color="secondary"
          variant="filled"
          size="sm"
        >
          {isSubmitting ? 'Submitting...' : 'Submit Question'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NeedHelpModal;
