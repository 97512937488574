import { Typography } from 'cfa-react-components';
import I18n from '../../../../i18n/utils';
import './BetaBadge.scss';

const BetaBadge = () => (
  <div className="beta">
    <Typography
      variant="caption1"
      fontWeight="medium"
      color="tertiary"
      className="beta__badge-text"
    >
      {I18n.t('APP_BETA')}
    </Typography>
  </div>
);

export default BetaBadge;
