import './ManageSafeScore.scss';
import {
  Button,
  Checkbox,
  Col,
  Container,
  LoadingIndicator,
  MessageBlock,
  MessageBlockContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Switch,
  Drawer,
  IconButton,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import {
  API_GET,
  API_PUT,
  formatDateAbbreviatedMonthDayYear,
} from '../../../../utils';
import { ScoreInsights } from './ScoreInsights';

export function ManageSafeScoreModal({ audit, onClose }) {
  const [activeScore, setActiveScore] = useState(null);
  const [scoreHistoryLoading, setScoreHistoryLoading] = useState(false);
  const [safeScoreHistory, setSafeScoreHistory] = useState(null);
  const [recalcConfirmationSelected, setRecalcConfirmationSelected] =
    useState(false);
  const [scoreRecalculatingLoading, setScoreRecalculatingLoading] =
    useState(false);
  const [showScoreParameters, setShowScoreParameters] = useState(false);

  useEffect(() => {
    fetchSafeScoreHistory(true);
  }, []);

  const openDrawer = () => {
    setShowScoreParameters(true);
  };

  const closeDrawer = () => {
    setShowScoreParameters(false);
  };

  const fetchSafeScoreHistory = async (showLoadingSymbol) => {
    if (audit) {
      setScoreHistoryLoading(showLoadingSymbol);
      const scoreHistory = (
        await API_GET(
          `${process.env.REACT_APP_SERVER_URL}/safe-score/${audit.locationNumber}/${audit.round}`,
        )
      ).data;
      setSafeScoreHistory(
        scoreHistory.sort(
          (a, b) =>
            new Date(b.calculatedTimestamp) - new Date(a.calculatedTimestamp),
        ),
      );
      setScoreHistoryLoading(false);
      const score = scoreHistory.find((item) => item.isActive === true);
      setActiveScore(score);
    }
  };

  const designateActiveScore = async (score) => {
    try {
      await API_PUT(
        `${process.env.REACT_APP_SERVER_URL}/safe-score/${audit.id}/active`,
        { safeScoreId: score.id },
      );
      setActiveScore(score);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleSwitchAction = (score) => {
    return () => {
      designateActiveScore(score);
    };
  };

  const recalculateScore = async () => {
    setScoreRecalculatingLoading(true);
    setRecalcConfirmationSelected(false);
    await API_PUT(
      `${process.env.REACT_APP_SERVER_URL}/safe-score/recalculate`,
      [audit.externalId],
    );
    await fetchSafeScoreHistory(false);
    setScoreRecalculatingLoading(false);
  };

  return (
    <div className="manage-safe-score-container">
      <ModalHeader>Manage SAFE Score</ModalHeader>
      {scoreHistoryLoading ? (
        <div className="score-history-loading-indicator">
          <LoadingIndicator variant="page" size="lg" />
        </div>
      ) : (
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Is Active</th>
                      <th>Performance Level</th>
                      <th>Raw Score</th>
                      <th>Calculated Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {safeScoreHistory?.map((safeScore) => (
                      <tr key={safeScore.id}>
                        <td>
                          <Switch
                            checked={activeScore === safeScore}
                            onChange={toggleSwitchAction(safeScore)}
                            disabled={scoreRecalculatingLoading}
                            icon="check"
                          />
                        </td>
                        <td>{safeScore.performanceLevel}</td>
                        <td>
                          {safeScore.rawScore}
                          <IconButton
                            color="secondary"
                            icon="infoOutline"
                            size="sm"
                            onClick={openDrawer}
                          />
                          <Drawer
                            size="lg"
                            content={
                              <>
                                <IconButton
                                  icon="close"
                                  onClick={closeDrawer}
                                />
                                <ScoreInsights
                                  activeScore={activeScore}
                                  audit={audit}
                                />
                              </>
                            }
                            open={showScoreParameters}
                          />
                        </td>
                        <td>
                          {formatDateAbbreviatedMonthDayYear(
                            safeScore.calculatedTimestamp,
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row>
              <Col>
                <MessageBlock severity="warning">
                  <MessageBlockContent>
                    Recalculating the score will also recalculate any scored
                    SAFE audits for this location{' '}
                    <strong>({audit?.locationNumber})</strong> that have
                    occurred after this audit <strong>({audit?.round})</strong>.
                  </MessageBlockContent>
                </MessageBlock>

                <br />

                <Checkbox
                  label="I confirm that recalculating this score may recalculate additional SAFE scores for this location."
                  checked={recalcConfirmationSelected}
                  onChange={() =>
                    setRecalcConfirmationSelected(!recalcConfirmationSelected)
                  }
                  disabled={scoreRecalculatingLoading}
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
      )}

      <ModalFooter>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={scoreRecalculatingLoading}
        >
          Close
        </Button>
        <Button
          variant="filled"
          color="secondary"
          onClick={recalculateScore}
          disabled={!recalcConfirmationSelected || scoreRecalculatingLoading}
        >
          {scoreRecalculatingLoading ? (
            <LoadingIndicator size="sm" variant="inline" />
          ) : (
            'Recalculate'
          )}
        </Button>
      </ModalFooter>
    </div>
  );
}
