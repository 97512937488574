/* eslint-disable no-continue */
// TODO: review eslint rule
import { getYear, getMonth } from './DateUtil';

// Returns an array of round options from "Q1-2021" to the current round + 1
export function getRoundOptions() {
  const options = [];
  const currentYear = parseInt(getYear(), 10);
  const currentMonth = parseInt(getMonth(), 10);

  for (let i = 2021; i <= currentYear; i++) {
    if (i !== currentYear) {
      options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
      options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
      options.push({ value: `Q3-${i}`, label: `Q3-${i}` });
      options.push({ value: `Q4-${i}`, label: `Q4-${i}` });
      continue;
    } else {
      if (currentMonth <= 2) {
        options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
        options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
      }
      if (currentMonth > 2 && currentMonth <= 5) {
        options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
        options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
        options.push({ value: `Q3-${i}`, label: `Q3-${i}` });
      }
      if (currentMonth > 5 && currentMonth <= 8) {
        options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
        options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
        options.push({ value: `Q3-${i}`, label: `Q3-${i}` });
        options.push({ value: `Q4-${i}`, label: `Q4-${i}` });
      }
      if (currentMonth > 8 && currentMonth <= 11) {
        options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
        options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
        options.push({ value: `Q3-${i}`, label: `Q3-${i}` });
        options.push({ value: `Q4-${i}`, label: `Q4-${i}` });
        options.push({ value: `Q1-${i + 1}`, label: `Q1-${i + 1}` });
      }
    }
  }
  return options;
}

// Returns an array of round options with the last item being a "-"
export function getFormRoundOptions() {
  const options = [];
  const currentYear = parseInt(getYear(), 10);
  const currentMonth = parseInt(getMonth(), 10);

  for (let i = 2021; i <= currentYear; i++) {
    if (i !== currentYear) {
      options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
      options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
      options.push({ value: `Q3-${i}`, label: `Q3-${i}` });
      options.push({ value: `Q4-${i}`, label: `Q4-${i}` });
      continue;
    } else {
      if (currentMonth <= 2) {
        options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
        options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
      }
      if (currentMonth > 2 && currentMonth <= 5) {
        options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
        options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
        options.push({ value: `Q3-${i}`, label: `Q3-${i}` });
      }
      if (currentMonth > 5 && currentMonth <= 8) {
        options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
        options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
        options.push({ value: `Q3-${i}`, label: `Q3-${i}` });
        options.push({ value: `Q4-${i}`, label: `Q4-${i}` });
      }
      if (currentMonth > 8 && currentMonth <= 11) {
        options.push({ value: `Q1-${i}`, label: `Q1-${i}` });
        options.push({ value: `Q2-${i}`, label: `Q2-${i}` });
        options.push({ value: `Q3-${i}`, label: `Q3-${i}` });
        options.push({ value: `Q4-${i}`, label: `Q4-${i}` });
        options.push({ value: `Q1-${i + 1}`, label: `Q1-${i + 1}` });
      }
    }
  }
  options.push({ value: null, label: '-' });
  return options;
}
