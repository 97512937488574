import { API_GET, API_POST } from '../utils';

import {
  GET_AMENDMENDT_PENDING,
  GET_AMENDMENDT_PENDING_SUCCESS,
  GET_AMENDMENDT_PENDING_FAIL,
  GET_AMENDMENDT_APPROVED,
  GET_AMENDMENDT_APPROVED_SUCCESS,
  GET_AMENDMENDT_APPROVED_FAIL,
  GET_AMENDMENDT_DROPDOWN,
  GET_AMENDMENDT_DROPDOWN_SUCCESS,
  GET_AMENDMENDT_DROPDOWN_FAIL,
  GET_AMENDMENT_COMMENTS,
  GET_AMENDMENT_COMMENTS_SUCCESS,
  GET_AMENDMENT_COMMENTS_FAIL,
  POST_AMENDMENDT_COMMENT,
  POST_AMENDMENDT_COMMENT_SUCCESS,
  POST_AMENDMENDT_COMMENT_FAIL,
  POST_AMENDMENT_REVIEW_REQUEST,
  POST_AMENDMENT_REVIEW_REQUEST_SUCCESS,
  POST_AMENDMENT_REVIEW_REQUEST_FAIL,
  POST_AMENDMENDT_REQUEST,
  POST_AMENDMENDT_REQUEST_SUCCESS,
  POST_AMENDMENDT_REQUEST_FAIL,
  GET_AMENDMENDT_FINDINGS,
  GET_AMENDMENDT_FINDINGS_SUCCESS,
  GET_AMENDMENDT_FINDINGS_FAIL,
  GET_APPROVED_AMENDMENT_COUNT,
  GET_APPROVED_AMENDMENT_COUNT_SUCCESS,
  GET_APPROVED_AMENDMENT_COUNT_FAIL,
  POST_CONSULTATION_REQUEST,
  POST_CONSULTATION_REQUEST_SUCCESS,
  POST_CONSULTATION_REQUEST_FAIL,
} from '.';
import I18n from '../../i18n/utils';
import { showFullTopSuccessNotification } from '../utils/Notification';
import history from '../utils/history';

const FOOD_SAFETY_API_BASE_URL = process.env.REACT_APP_SERVER_URL;

export const getPendingAmendments = (pageNumber) => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments?statuses=PENDING&page=${pageNumber}&pageSize=10`;
    dispatch({
      type: GET_AMENDMENDT_PENDING,
    });
    try {
      const { data } = await API_GET(url);
      dispatch({
        type: GET_AMENDMENDT_PENDING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.error(' Get amendments PENDING error :', error);
      dispatch({
        type: GET_AMENDMENDT_PENDING_FAIL,
      });
    }
  };
};

export const getDropDownOptions = () => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/count`;
    dispatch({
      type: GET_AMENDMENDT_DROPDOWN,
    });
    try {
      const { data } = await API_GET(url);
      dispatch({
        type: GET_AMENDMENDT_DROPDOWN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.error('Get amendments Dropdown optiona error :', error);
      dispatch({
        type: GET_AMENDMENDT_DROPDOWN_FAIL,
      });
    }
  };
};

export const getAmendmentComments = (amendmentRequestId) => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/comments/${amendmentRequestId}`;
    dispatch({
      type: GET_AMENDMENT_COMMENTS,
    });
    try {
      const { data } = await API_GET(url);
      dispatch({
        type: GET_AMENDMENT_COMMENTS_SUCCESS,
        payload: data,
        amendmentRequestId,
      });
    } catch (error) {
      console.error('Get Amendment Comments error :', error);
      dispatch({
        type: GET_AMENDMENT_COMMENTS_FAIL,
      });
    }
  };
};

export const getApprovedAmendments = (pageNumber, reportRound) => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments?statuses=APPROVED&statuses=DENIED&rounds=${reportRound}&page=${pageNumber}&pageSize=10`;
    dispatch({
      type: GET_AMENDMENDT_APPROVED,
    });
    try {
      const { data } = await API_GET(url);
      dispatch({
        type: GET_AMENDMENDT_APPROVED_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.error(' Get amendments APPROVED error :', error);
      dispatch({
        type: GET_AMENDMENDT_APPROVED_FAIL,
      });
    }
  };
};

// POST Comment

export const postAmendmentComment = (amendmentComment) => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/comments`;
    dispatch({
      type: POST_AMENDMENDT_COMMENT,
    });
    try {
      const { data } = await API_POST(url, amendmentComment);
      dispatch({
        type: POST_AMENDMENDT_COMMENT_SUCCESS,
        payload: data,
        comment: amendmentComment,
      });
    } catch (error) {
      console.error(' POST amendment COMMENT error :', error);
      dispatch({
        type: POST_AMENDMENDT_COMMENT_FAIL,
      });
    }
  };
};

export const getAmendmentFindings = (uuid, status, requestDate) => {
  return async (dispatch) => {
    let url = `${FOOD_SAFETY_API_BASE_URL}/audit/${uuid}/get-diff?status=${status}`;
    if (requestDate) {
      url += `&amendmentRequestDate=${requestDate}`;
    }
    dispatch({
      type: GET_AMENDMENDT_FINDINGS,
    });
    try {
      const { data } = await API_GET(url);
      dispatch({
        type: GET_AMENDMENDT_FINDINGS_SUCCESS,
        payload: {
          key: `${uuid}-${status}-${requestDate}`,
          data,
        },
      });
    } catch (error) {
      console.error(' Get amendments Findings error :', error);
      dispatch({
        type: GET_AMENDMENDT_FINDINGS_FAIL,
      });
    }
  };
};

export const postAmendmentReviewRequest = (amendmentRequestId) => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/review/${amendmentRequestId}`;
    dispatch({
      type: POST_AMENDMENT_REVIEW_REQUEST,
    });
    try {
      await API_POST(url);
      dispatch({
        type: POST_AMENDMENT_REVIEW_REQUEST_SUCCESS,
      });
      showFullTopSuccessNotification({
        message: I18n.t('APP_ADMIN_AMENDMENTS_REVIEW_REQUEST_NOTIFICATION'),
      });
    } catch (error) {
      console.error(' POST amendment review request error :', error);
      dispatch({
        type: POST_AMENDMENT_REVIEW_REQUEST_FAIL,
      });
    }
  };
};

export const postAmendmentRequest = (payload) => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/`;

    dispatch({
      type: POST_AMENDMENDT_REQUEST,
    });
    try {
      const { data } = await API_POST(url, payload);
      dispatch({
        type: POST_AMENDMENDT_REQUEST_SUCCESS,
      });
      showFullTopSuccessNotification({ message: 'Appeal Submitted!' });
      history.goBack();
    } catch (error) {
      console.error(error);
      dispatch({
        type: POST_AMENDMENDT_REQUEST_FAIL,
      });
    }
  };
};

export const getApprovedAmendmentCount = (location, round) => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/count/${location}/${round}?status=APPROVED`;
    dispatch({
      type: GET_APPROVED_AMENDMENT_COUNT,
    });
    try {
      const { data } = await API_GET(url);
      dispatch({
        type: GET_APPROVED_AMENDMENT_COUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.error('Get Approved Amendment Count error: ', error);
      dispatch({
        type: GET_APPROVED_AMENDMENT_COUNT_FAIL,
      });
    }
  };
};

export const postConsultationRequest = (payload) => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/finding/clarifying-question`;
    dispatch({
      type: POST_CONSULTATION_REQUEST,
    });
    try {
      await API_POST(url, payload);
      dispatch({
        type: POST_CONSULTATION_REQUEST_SUCCESS,
      });
      showFullTopSuccessNotification({ message: 'Question Submitted!' });
      history.goBack();
    } catch (error) {
      console.error('Post Consultation Request Error:', error);
      dispatch({
        type: POST_CONSULTATION_REQUEST_FAIL,
      });
    }
  };
};
