import PropTypes from 'prop-types';
import { Typography } from 'cfa-react-components';

import './OccurrenceCard.scss';

// OccurrenceCard displays information about a specific finding occurrence
export default function OccurrenceCard({
  issue,
  zone,
  item,
  selected,
  index,
  onClickHandler,
  isDisabled,
}) {
  // if the occurrence is selected then style it with the selected class
  const isSelected = selected ? 'occurrence-card__occurrence-selected' : '';
  const className = `occurrence-card ${isSelected}`;

  return (
    <div
      className={className}
      data-testid="occurrence-card-container"
      onClick={isDisabled ? undefined : onClickHandler(index)}
    >
      <div
        className="occurrence-card__info-container"
        data-testid="occurrence-card-zone-info-container"
      >
        <Typography
          variant="caption1"
          className="occurrence-card__label"
          data-testid="occurrence-card-zone-label"
        >
          Zone:
        </Typography>
        <Typography
          variant="caption1"
          gutterBottom
          className="occurrence-card__description"
          data-testid="occurrence-card-zone-description"
        >
          {' '}
          {zone}
        </Typography>
      </div>
      <div
        className="occurrence-card__info-container"
        data-testid="occurrence-card-item-info-container"
      >
        <Typography
          variant="caption1"
          className="occurrence-card__label"
          data-testid="occurrence-card-item-label"
        >
          Item:
        </Typography>
        <Typography
          variant="caption1"
          gutterBottom
          className="occurrence-card__description"
          data-testid="occurrence-card-item-description"
        >
          {item}
        </Typography>
      </div>
      <div
        className="occurrence-card__info-container"
        data-testid="occurrence-card-issue-info-container"
      >
        <Typography
          variant="caption1"
          className="occurrence-card__label"
          data-testid="occurrence-card-issue-label"
        >
          Issue:{' '}
        </Typography>
        <Typography
          gutterBottom
          variant="caption1"
          className="occurrence-card__description"
          data-testid="occurrence-card-issue-description"
        >
          {issue}
        </Typography>
      </div>
    </div>
  );
}

OccurrenceCard.propTypes = {
  issue: PropTypes.string,
  zone: PropTypes.string,
  item: PropTypes.string,
  selected: PropTypes.bool,
  index: PropTypes.number,
  onClickHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
};
