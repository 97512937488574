import I18n from 'i18n-js';
import { store } from '../app/store';
import { clearAuditSelection } from '../app/actions';
import en from './en';
import es from './es';

export const initLanguageLocale = () => {
  const defaultLocale = localStorage.getItem('languageLocale');

  I18n.locale = defaultLocale || 'en';

  I18n.fallbacks = true;
  I18n.translations = {
    en,
    es,
  };
};

// Get Language Locale string for
export const getLanguageLocale = () => {
  const item = localStorage.getItem('languageLocale');
  return item || 'en';
};

// Map the Local string to the corresponding string to be appended to the API path of an endpoint
const mapLanguageLocaleString = (locale) => {
  return {
    en: 'EN',
    es: 'ES',
    // 'fr': 'FR_CA'
  }[locale];
};

// Assmeble string to append to API endpoint in API.js
export const getAPILanguageLocaleString = () => {
  const locale = getLanguageLocale();
  return mapLanguageLocaleString(locale);
};

// Set location variable and handle routing
export const updateLanguageLocale = (localeToSet) => {
  // Save Language in Localstorage
  localStorage.setItem('languageLocale', localeToSet);
  // Clear Audit selection in Redux
  store.dispatch(clearAuditSelection());
  // Navigate back to home and refresh
  const { origin } = window.location;
  window.location.replace(origin);
};

export default I18n;
