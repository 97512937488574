import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { Button } from 'cfa-react-components';

import { getEffectiveQuestions } from '../../utils/api/APICalls';

import FSModal from '../Modal/FSModal';

import './ModifyAppealModal.scss';

function ModifyAppealModal({
  isOpen,
  round,
  handleIsOpen,
  appealObj,
  modifyAction,
}) {
  const [questionOptionsLoading, setQuestionOptionsLoading] = useState(true);
  const [questionOptionsList, setQuestionOptionsList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [reasonInput, setReasonInput] = useState('');

  useEffect(() => {
    if (isOpen === true) {
      const roundValues = round.split('-');
      getEffectiveQuestions(roundValues[0], roundValues[1]).then((response) => {
        if (Array.isArray(response)) {
          const optionsList = response.map((question) => {
            return {
              value: question.safeReportId,
              label: `${question.safeReportId}:  ${question.text}`,
            };
          });
          setQuestionOptionsList(optionsList);
        }
        setQuestionOptionsLoading(false);
      });
    }
  }, [isOpen]);

  const handleReasonInput = (event) => {
    setReasonInput(event.target.value);
  };

  const handleSelectedQuestion = (value) => {
    setSelectedQuestion(value);
  };

  const handleSubmitModify = () => {
    const payload = {
      status: 'MODIFY',
      updateReason: reasonInput,
      newSelectedQuestionId: selectedQuestion.value,
    };
    modifyAction(payload);
  };

  return (
    <FSModal data-testid="modify-appeal-modal-fs-modal" isOpen={isOpen}>
      <div data-testid="modify-appeal-modal-fs-modal-sub">
        <h4
          className="modify-appeal-modal__header-title"
          data-testid="modify-appeal-modal-header-title"
        >
          Modify Appeal
        </h4>
        <p
          className="modify-appeal-modal__header-sub-title"
          data-testid="modify-appeal-modal-header-sub-title"
        >
          Please provide a reason for modifying this appeal. This reason will be
          sent to the Operator via emial.
        </p>
        <div
          className="modify-appeal-modal__section-container"
          data-testid="modify-appeal-modal-selected-finding-section-container"
        >
          <p
            className="modify-appeal-modal__section-title"
            data-testid="modify-appeal-modal-selected-finding-section-title"
          >
            Selected Finding
          </p>
          <p
            className="modify-appeal-modal__section-text"
            data-testid="modify-appeal-modal-selected-finding-section-text"
          >
            {appealObj?.questionSafeReportId}: {appealObj?.findingDescription}
          </p>
        </div>
        <div
          className="modify-appeal-modal__section-container"
          data-testid="modify-appeal-modal-selected-round-section-container"
        >
          <p
            className="modify-appeal-modal__section-title"
            data-testid="modify-appeal-modal-selected-round-section-title"
          >
            Selected Round:
          </p>
          <p
            className="modify-appeal-modal__section-text"
            data-testid="modify-appeal-modal-selected-round-section-text"
          >
            {appealObj?.round}
          </p>
        </div>
        <div
          className="modify-appeal-modal__section-container modify-appeal-modal__section-container--short-space"
          data-testid="modify-appeal-modal-zone-section-container"
        >
          <p
            className="modify-appeal-modal__section-title"
            data-testid="modify-appeal-modal-zone-section-title"
          >
            Zone:
          </p>
          <p
            className="modify-appeal-modal__section-text"
            data-testid="modify-appeal-modal-zone-section-text"
          >
            {appealObj?.zone}
          </p>
        </div>
        <div
          className="modify-appeal-modal__section-container short-space modify-appeal-modal__section-container--short-space"
          data-testid="modify-appeal-modal-item-section-container"
        >
          <p
            className="modify-appeal-modal__section-title"
            data-testid="modify-appeal-modal-item-section-title"
          >
            Item:
          </p>
          <p
            className="modify-appeal-modal__section-text"
            data-testid="modify-appeal-modal-item-section-text"
          >
            {appealObj?.item}
          </p>
        </div>
        <div
          className="modify-appeal-modal__section-container"
          data-testid="modify-appeal-modal-issue-section-container"
        >
          <p
            className="modify-appeal-modal__section-title"
            data-testid="modify-appeal-modal-issue-section-title"
          >
            Issue:
          </p>
          <p
            className="modify-appeal-modal__section-text"
            data-testid="modify-appeal-modal-issue-section-text"
          >
            {appealObj?.issue}
          </p>
        </div>
        <div
          className="modify-appeal-modal__section-container"
          data-testid="modify-appeal-modal-store-section-container"
        >
          <p
            className="modify-appeal-modal__section-title"
            data-testid="modify-appeal-modal-store-section-title"
          >
            Store(s):
          </p>
          <p
            className="modify-appeal-modal__section-text"
            data-testid="modify-appeal-modal-store-section-text"
          >
            {appealObj?.locationNumber} {appealObj?.locationName}
          </p>
        </div>
        <div data-testid="modify-appeal-modal-modify-finding-container">
          <h6
            className="modify-appeal-modal__dropdown-findings-title"
            data-testid="modify-appeal-modal-dropdown-findings-title"
          >
            Modify Finding
          </h6>
          <Select
            className="select-audit-occurance"
            data-testid="modify-appeal-modal-selected-audit-occurance"
            options={questionOptionsList}
            onChange={handleSelectedQuestion}
            isSearchable
            placeholder="Select a finding"
            isLoading={questionOptionsLoading}
          />
        </div>
        <div data-testid="modify-appeal-modal-reason-for-change-container">
          <h6
            className="modify-appeal-modal__text-ipunt-title"
            data-testid="modify-appeal-modal-reason-for-change-title"
          >
            Reason for change
          </h6>
          <textarea
            onChange={handleReasonInput}
            type="text"
            name="reason-for-modify"
            id="reason-for-modify"
            rows="4"
            maxLength="500"
            placeholder="Add a reason..."
            className="modify-appeal-modal__textbox"
            data-testid="modify-appeal-modal-reason-for-change-textarea"
          />
          <p
            className="modify-appeal-modal__charcount"
            data-testid="modify-appeal-modal-reason-for-change-charcount"
          >
            {reasonInput.length}/500 Characters
          </p>
        </div>
        <div
          className="modify-appeal-modal__btn-container"
          data-testid="modify-appeal-modal-button-container"
        >
          <button
            type="button"
            color="secondary"
            className="modify-appeal-modal__cancel-btn"
            data-testid="modify-appeal-modal-cancel-button"
            onClick={handleIsOpen}
          >
            Cancel
          </button>
          <Button
            data-testid="modify-appeal-modal-modify-button"
            color="secondary"
            size="sm"
            style={{ height: 40 }}
            onClick={handleSubmitModify}
            disabled={reasonInput.length === 0 || selectedQuestion.length === 0}
          >
            Modify
          </Button>
        </div>
      </div>
    </FSModal>
  );
}

ModifyAppealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleIsOpen: PropTypes.func.isRequired,
  appealObj: PropTypes.object.isRequired,
  modifyAction: PropTypes.func,
};

export default ModifyAppealModal;
