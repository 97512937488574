import {
  GET_AMENDMENDT_PENDING,
  GET_AMENDMENDT_PENDING_SUCCESS,
  GET_AMENDMENDT_PENDING_FAIL,
  GET_AMENDMENDT_APPROVED,
  GET_AMENDMENDT_APPROVED_SUCCESS,
  GET_AMENDMENDT_APPROVED_FAIL,
  GET_AMENDMENDT_DROPDOWN_SUCCESS,
  CLEAR_AMENDMENDT_DATA,
  GET_AMENDMENDT_FINDINGS_SUCCESS,
  POST_AMENDMENDT_COMMENT,
  POST_AMENDMENDT_COMMENT_SUCCESS,
  POST_AMENDMENDT_COMMENT_FAIL,
  GET_AMENDMENT_COMMENTS,
  GET_AMENDMENT_COMMENTS_SUCCESS,
  GET_AMENDMENT_COMMENTS_FAIL,
  POST_AMENDMENT_REVIEW_REQUEST,
  POST_AMENDMENT_REVIEW_REQUEST_SUCCESS,
  POST_AMENDMENT_REVIEW_REQUEST_FAIL,
  POST_AMENDMENDT_REQUEST,
  POST_AMENDMENDT_REQUEST_SUCCESS,
  POST_AMENDMENDT_REQUEST_FAIL,
  GET_APPROVED_AMENDMENT_COUNT,
  GET_APPROVED_AMENDMENT_COUNT_SUCCESS,
  GET_APPROVED_AMENDMENT_COUNT_FAIL,
  POST_CONSULTATION_REQUEST,
  POST_CONSULTATION_REQUEST_SUCCESS,
  POST_CONSULTATION_REQUEST_FAIL,
} from '../actions';

const initialState = {
  loading: false,
  fetchingAction: false,
  pendingAmendments: {},
  approvedAmendments: {},
  amendmentComments: {},
  comments: {},
  amendmentStatus: {
    loading: false,
  },
  dropdownOptions: [],
  findings: {},
  requestAmendmentForm: {
    loading: false,
  },
  approvedAmendmentCount: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AMENDMENDT_PENDING:
      return { ...state, loading: true };

    case GET_AMENDMENDT_PENDING_SUCCESS:
      return { ...state, loading: false, pendingAmendments: action.payload };

    case GET_AMENDMENDT_PENDING_FAIL:
      return { ...state, loading: false };

    case GET_AMENDMENDT_APPROVED:
      return { ...state, loading: true };

    case GET_AMENDMENDT_APPROVED_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingAction: false,
        approvedAmendments: action.payload,
      };

    case GET_AMENDMENDT_APPROVED_FAIL:
      return { ...state, loading: false, fetching: false };

    case GET_AMENDMENDT_DROPDOWN_SUCCESS:
      return {
        ...state,
        dropdownOptions: action.payload,
      };

    case GET_AMENDMENDT_FINDINGS_SUCCESS:
      return {
        ...state,
        findings: handleAmendmentFindings(
          action.payload.key,
          action.payload.data,
          state.findings,
        ),
      };

    case GET_AMENDMENT_COMMENTS:
      return {
        ...state,
        fetchingAction: true,
      };

    case GET_AMENDMENT_COMMENTS_SUCCESS:
      return {
        ...state,
        fetchingAction: false,
        amendmentComments: {
          ...state.amendmentComments,
          [`${action.amendmentRequestId}`]: action.payload,
        },
      };

    case GET_AMENDMENT_COMMENTS_FAIL:
      return {
        ...state,
        fetchingAction: false,
      };

    case POST_AMENDMENDT_COMMENT:
      return {
        ...state,
        fetchingAction: true,
      };

    case POST_AMENDMENDT_COMMENT_SUCCESS:
      return {
        ...state,
        fetchingAction: false,
        amendmentComments: {
          ...state.amendmentComments,
          [`${action.comment.amendmentRequestId}`]:
            updateAmendmentCommentRecord(
              action.comment,
              state.amendmentComments[`${action.comment.amendmentRequestId}`],
            ),
        },
      };

    case POST_AMENDMENDT_COMMENT_FAIL:
      return {
        ...state,
        fetchingAction: false,
      };

    case POST_AMENDMENT_REVIEW_REQUEST:
      return {
        ...state,
        fetchingAction: true,
      };

    case POST_AMENDMENT_REVIEW_REQUEST_SUCCESS:
      return {
        ...state,
        fetchingAction: false,
      };

    case POST_AMENDMENT_REVIEW_REQUEST_FAIL:
      return {
        ...state,
        fetchingAction: false,
      };
    case POST_AMENDMENDT_REQUEST:
      return {
        ...state,
        requestAmendmentForm: {
          ...state.requestAmendmentForm,
          loading: true,
        },
      };

    case POST_AMENDMENDT_REQUEST_SUCCESS:
      return {
        ...state,
        requestAmendmentForm: {
          ...state.requestAmendmentForm,
          loading: false,
        },
      };

    case POST_AMENDMENDT_REQUEST_FAIL:
      return {
        ...state,
        requestAmendmentForm: {
          ...state.requestAmendmentForm,
          loading: false,
        },
      };

    case GET_APPROVED_AMENDMENT_COUNT:
      return {
        ...state,
        fetchingAction: true,
      };

    case GET_APPROVED_AMENDMENT_COUNT_SUCCESS:
      return {
        ...state,
        approvedAmendmentCount: action.payload,
        fetchingAction: false,
      };

    case GET_APPROVED_AMENDMENT_COUNT_FAIL:
      return {
        ...state,
        fetchingAction: false,
      };

    case POST_CONSULTATION_REQUEST:
      return {
        ...state,
        fetchingAction: true,
      };

    case POST_CONSULTATION_REQUEST_SUCCESS:
      return {
        ...state,
        fetchingAction: false,
      };

    case POST_CONSULTATION_REQUEST_FAIL:
      return {
        ...state,
        fetchingAction: false,
      };

    case CLEAR_AMENDMENDT_DATA:
      return initialState;

    default:
      return state;
  }
};

// Local helper functions:

// function removePendingAmendment(amendmentId, amendmentList) {
//   return amendmentList.filter((store) => store.id !== amendmentId);
// }

function updateAmendmentCommentRecord(commentData, commentList) {
  const comment = {
    commenterDisplayName: commentData.username,
    comment: commentData.comment,
    lastModifiedTimestamp: commentData.commentTimestamp,
  };
  commentList.push(comment);
  return commentList;
}

// function updateStoreCommentRecord(commentData, amendmentList) {
//   return amendmentList.map((store) => {
//     if (
//       store.uuid === commentData.auditUuid &&
//       store.amendmentRequestDate === commentData.amendmentRequestDate
//     ) {
//       store.comment = commentData;
//     }
//     return store;
//   });
// }

function handleAmendmentFindings(key, payload, findings) {
  return {
    ...findings,
    [key]: payload,
  };
}
