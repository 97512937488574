import PropTypes from 'prop-types';
import AppealsCard from './AppealsCard';

const AppealsCardContainer = ({
  appealsList,
  statusChangeCallback,
  loadingAppealsCallback,
  cardType,
  disableActionBtn,
  isHistorical = false,
}) => {
  return appealsList.map((appeal, i) => (
    <AppealsCard
      data-testid="appeal-card-container-appeal-card"
      key={`${i}-${appeal.id}`}
      cardType={cardType}
      appeal={appeal}
      loadingAppealsCallback={loadingAppealsCallback}
      statusChangeCallback={statusChangeCallback}
      disableActionBtn={disableActionBtn}
      isHistorical={isHistorical}
    />
  ));
};

AppealsCardContainer.propTypes = {
  appealsList: PropTypes.instanceOf(Array),
};

export default AppealsCardContainer;
