import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import {
  Button,
  Dropdown,
  LoadingIndicator,
  Switch,
  Typography,
} from 'cfa-react-components';
import I18n from '../../../../i18n/utils';
import { renderParamRisk } from '../../../utils/FindingsUtil';
import { getAllAudits, getSelectedAuditFindings } from '../../../actions';
import RoundSelector from '../../../components/FoodSafetyDashboard/RoundSelector';
import { PerfectAuditCard } from '../../../components/common';
import { RISK_LEVELS_LONG } from '../../../constants/constants';
import FilterBoxContainer from '../../../components/common/FilterBox/FilterBoxContainer';
import AuditNotAvailable from '../AuditNotAvailable/AuditNotAvailable';
import FindingOverview from './FindingOverview/FindingOverview';
import SelectedFinding from './SelectedFinding/SelectedFinding';
import ListCard from '../../../components/ListCard/ListCard';

import './AllFindings.scss';

/**
 * The AllFindings page displays findings from the selected audit, and allows a user to sort and filter
 * the findings, or select a different audit quarter.
 */
const AllFindings = ({ location }) => {
  // A local name for the useDispatch function fom react-redux.
  const dispatch = useDispatch();

  const {
    loading,
    selectedAuditFindingsLoading,
    selectedAudit,
    selectedAuditFindings,
  } = useSelector((state) => state.audit);

  const [filteredAuditFindings, setFilteredAuditFindings] = useState(
    selectedAuditFindings,
  );
  const [showResolved, setShowResolved] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [showFilterBox, setShowFilterBox] = useState(false);

  // Combined Bool for loading variables, display the spiner if any are true
  const displaySpinner = loading || selectedAuditFindingsLoading;

  const urlParams = useParams();
  const history = useHistory();

  const basePageUrl = `/${urlParams.locationNumber}/report-dashboard/${urlParams.round}/findings`;

  useEffect(() => {
    if (
      selectedAudit &&
      !selectedAuditFindings?.length &&
      !selectedAuditFindingsLoading
    ) {
      dispatch(getSelectedAuditFindings(selectedAudit));
    }
  }, [selectedAudit]);

  // Function to retrieve urlParams from the url bar
  const getRiskParam = () => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const riskLevel = params.get('riskLevel');
    const filterLabel = renderParamRisk(riskLevel);
    if (filterLabel) return { value: filterLabel, label: filterLabel };
    return false;
  };

  /**
   * riskLevelFilterOptions, categoryFilterOptions, and sortOptions are data objects that
   * hold the filter options found in the dropdown Select components on the AllFindings page
   */
  const riskLevelFilterOptions = [
    {
      value: 'Immediate Action',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_IMMEDIATE'),
    },
    {
      value: 'High',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_HIGH'),
    },
    {
      value: 'Medium',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_MEDIUM'),
    },
    {
      value: 'Low',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_LOW'),
    },
    {
      value: 'Informational',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_INFORMATIONAL'),
    },
  ];
  const categoryFilterOptions = [
    {
      value: 'Cleaning & Sanitation',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_CLEANING_SANITATION'),
    },
    {
      value: 'Cross Contamination',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_CROSS_CONTAMINATION'),
    },
    {
      value: 'Health & Hygiene',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_HEALTH_HYGIENE'),
    },
    {
      value: 'Informational Only',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_INFORMATIONAL_ONLY'),
    },
    {
      value: 'Pests',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_PESTS'),
    },
    {
      value: 'Time & Temperature',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_TIME_TEMPERATURE'),
    },
  ];
  const sortOptions = [
    {
      value: 'riskLevel',
      label: I18n.t('APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_RISK_LEVEL'),
    },
    {
      value: 'category',
      label: I18n.t('APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_CATEGORY'),
    },
    {
      value: 'number',
      label: I18n.t('APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_FINDING_ID'),
    },
  ];

  // Holds filters that have been applied
  const [appliedFilters, setAppliedFilters] = useState({});

  // Function to filter the auditFindings
  const filterAuditFindings = () => {
    let findings = [...selectedAuditFindings];

    if (appliedFilters.selectedCategory) {
      findings = findings.filter(
        (finding) =>
          finding.category.name === appliedFilters.selectedCategory.value,
      );
    }

    if (appliedFilters.selectedRiskLevel) {
      findings = findings.filter(
        (finding) =>
          finding.riskLevel === appliedFilters.selectedRiskLevel.value,
      );
    }

    if (appliedFilters.selectedFindingId) {
      findings = findings.filter(
        (finding) =>
          finding.questionSafeReportId ===
          appliedFilters.selectedFindingId.value,
      );
    }

    if (selectedSortOption) {
      findings = findings.sort(sortAuditFindingsHelper());
    }

    if (showResolved === false) {
      findings = findings.filter((finding) => finding.isResolved === false);
    }

    return findings;
  };

  // Helper function that does the work for sortAuditFindings
  const sortAuditFindingsHelper = () => {
    return (a, b) => {
      switch (selectedSortOption.value) {
        case 'riskLevel':
          if (
            RISK_LEVELS_LONG.indexOf(a.riskLevel) <
            RISK_LEVELS_LONG.indexOf(b.riskLevel)
          )
            return -1;
          break;
        case 'category':
          if (a.category.name < b.category.name) return -1;
          break;
        case 'number':
          if (a.questionSafeReportId < b.questionSafeReportId) return -1;
          break;
      }
      return 1;
    };
  };

  // Static array, each object in the array will define the filters of the FilterBoxContainer.
  const allFindingsFiltersSupplier = [
    {
      filterName: 'selectedFindingId',
      initialValue: null,
      filterLabel: `${I18n.t('APP_REPORT_DASHBOARD_FILTERS_FINDING_ID')}`,
      filterType: 'dropdown',
      filterOptions: selectedAuditFindings
        // TODO: review disabled eslint rule
        // eslint-disable-next-line array-callback-return
        .filter((finding) => {
          if (showResolved === true) {
            return finding;
          }
          if (finding.isResolved === false) {
            return finding;
          }
        })
        .sort((a, b) =>
          a.questionSafeReportId > b.questionSafeReportId ? 1 : -1,
        )
        .map((value) => {
          return {
            value: value.questionSafeReportId,
            label: value.questionSafeReportId,
          };
        }),
    },
    {
      filterName: 'selectedCategory',
      initialValue: null,
      filterLabel: `${I18n.t('APP_REPORT_DASHBOARD_FILTERS_CATEGORY')}`,
      filterType: 'dropdown',
      filterOptions: categoryFilterOptions,
    },
    {
      filterName: 'selectedRiskLevel',
      initialValue: null,
      filterLabel: `${I18n.t(
        'APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_RISK_LEVEL',
      )}`,
      filterType: 'dropdown',
      filterOptions: riskLevelFilterOptions,
      getUrlParam: getRiskParam(),
    },
  ];

  // useEffect to immediately apply filters on a change to any of the dependency array
  useEffect(() => {
    setFilteredAuditFindings(filterAuditFindings());
  }, [appliedFilters, selectedAuditFindings, selectedSortOption, showResolved]);

  // useEffect to re-grab the audit if the location number changes
  useEffect(() => {
    dispatch(getAllAudits(urlParams.locationNumber, urlParams.round));
  }, [urlParams.locationNumber, urlParams.round]);

  // Component Did Mount Effect that initializes the filteredAuditFindings list.
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!selectedAudit) {
      dispatch(getAllAudits(urlParams.locationNumber, urlParams.round));
    }
  }, []);

  // Returns the html for the AllFindings Page
  return (
    <>
      <div
        className="all-findings__header-container"
        data-testid="all-findings-header-container"
      >
        <div
          className="all-findings__title-container"
          data-testid="all-findings-title-container"
        >
          <div data-testid="all-findings-title-container-sub">
            <Typography
              data-testid="all-findings-findings-all-title"
              color="default"
              gutterBottom={false}
              variant="h3"
            >
              {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_ALL')}
            </Typography>
            {!displaySpinner && (
              <p
                className="all-findings__findings-count"
                data-testid="all-findings-findings-count"
              >
                {filteredAuditFindings?.length > 0
                  ? filteredAuditFindings?.length
                  : 'No'}{' '}
                {I18n.t('APP_REPORT_DASHBOARD_FILTERS_FINDINGS')}
              </p>
            )}
          </div>
          <Switch
            className="all-findings__switch"
            data-testid="all-findings-switch"
            checked={showResolved}
            disabled={displaySpinner}
            icon="check"
            onChange={() => setShowResolved(!showResolved)}
            label={I18n.t('APP_REPORT_DASHBOARD_FINDINGS_SHOW_PAST')}
          />
        </div>
        <div
          className="all-findings__filter-container"
          data-testid="all-findings-filter-container"
        >
          <div
            className="all-findings__sort-dropdown"
            data-testid="all-findings-sort-dropdown-container"
          >
            <Dropdown
              compact
              fullWidth
              className="all-findings__sort-dropdown"
              data-testid="all-findings-sort-dropdown"
              label={I18n.t('APP_REPORT_DASHBOARD_FILTERS_SORT_BY')}
              options={sortOptions}
              renderOption={(option) => option.label}
              value={selectedSortOption}
              onChange={setSelectedSortOption}
              disabled={displaySpinner}
              placeholder={I18n.t(
                'APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_PLACEHOLDER',
              )}
            />
          </div>
          <RoundSelector
            data-testid="all-findings-round-selector"
            loading={displaySpinner}
            buildRoundBasedUrl={(round) =>
              `/${selectedAudit.locationNumber}/report-dashboard/${round}/findings`
            }
          />
          <Button
            className="all-findings__filter-btn"
            data-testid="all-findings-filter-button"
            color="secondary"
            variant="outlined"
            size="lg"
            disabled={displaySpinner}
            onClick={() => setShowFilterBox(!showFilterBox)}
          >
            {I18n.t('APP_REPORT_DASHBOARD_FILTERS')}
          </Button>
        </div>
      </div>
      <FilterBoxContainer
        data-testid="all-findings-filter-box-container"
        filterArray={allFindingsFiltersSupplier}
        applyFiltersCallback={setAppliedFilters}
        hideFilterBoxCallback={() => setShowFilterBox(false)}
        showFilterBox={showFilterBox}
      />
      {displaySpinner && (
        <div data-testid="all-findings-loading-indicator-container">
          <LoadingIndicator
            data-testid="all-findings-loading-indicator"
            variant="page"
          />
        </div>
      )}
      {!selectedAudit && !displaySpinner && (
        <AuditNotAvailable
          data-testid="all-findings-audit-not-available"
          type="findings"
        />
      )}
      {selectedAudit?.isPerfectAudit && !displaySpinner && (
        <PerfectAuditCard
          data-testid="all-findings-perfect-audit-card"
          selectedAudit={selectedAudit}
        />
      )}
      {filteredAuditFindings?.length !== 0 && !displaySpinner && (
        <div
          className="all-findings__findings-container"
          data-testid="all-findings-findings-container"
        >
          {filteredAuditFindings.map((finding) => (
            <ListCard
              data-testid="all-findings-filtered-audit-findings-list-card"
              key={finding.questionUUID}
              cardId={finding.questionUUID}
              cardContent={
                <FindingOverview
                  data-testid="all-findings-list-card-finding-overview"
                  finding={finding}
                />
              }
              drawerContent={
                <SelectedFinding
                  data-testid="all-findings-list-card-selected-findings"
                  finding={finding}
                />
              }
              onDrawerOpened={() =>
                history.push(`${basePageUrl}/${finding.questionUUID}`)
              }
              onDrawerClosed={() => history.push(basePageUrl)}
              riskLevel={finding.riskLevel}
              style={{
                backgroundColor: `${
                  finding?.auditRound !== selectedAudit?.round
                    ? '#eaeaea'
                    : 'white'
                }`,
              }}
            />
          ))}
        </div>
      )}
      {selectedAudit &&
        filteredAuditFindings?.length === 0 &&
        !selectedAudit?.isPerfectAudit &&
        !displaySpinner && (
          <div
            className="all-findings__no-results"
            data-testid="all-findings-no-results-container"
          >
            <Typography
              data-testid="all-findings-no-results"
              color="default"
              gutterBottom={false}
              variant="h3"
            >
              {I18n.t('APP_REPORT_DASHBORAD_ALL_FINDINGS_NO_RESULTS')}
            </Typography>
            <Typography
              data-testid="all-findings-no-results-msg"
              color="default"
              gutterBottom={false}
              variant="h4"
            >
              {I18n.t('APP_REPORT_DASHBORAD_ALL_FINDINGS_NO_RESULTS_MSG')}
            </Typography>
          </div>
        )}
    </>
  );
};

export default withOktaAuth(withRouter(AllFindings));
