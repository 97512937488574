import { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip, Icon } from 'cfa-react-components';
import { InfoOutline } from '@cfa-icons/system';
import {
  initializeResponse,
  updateResponseData,
  getIdsToClearFromStore,
} from '../../../utils/questionsUtils';
import { useLVRDispatch, useLVRStore, actions } from '../../../store/reducers';
import ErrorText from '../../ErrorText';

import '../MultipleChoiceQuestion.scss';
import './CheckboxComponent.scss';

const CheckboxComponent = ({ question }) => {
  const { responseUniqueIdentifier, questionData } = question;

  const lvrDispatch = useLVRDispatch();
  const lvrStore = useLVRStore();

  const [selectedValues, setSelectedValues] = useState([]);
  const [errorText, setErrorText] = useState(null);
  const [selectionMade, setSelectionMade] = useState(false);

  const existingResponse = useMemo(
    () => lvrStore.responses?.[responseUniqueIdentifier]?.responseData,
    [lvrStore.responses, responseUniqueIdentifier],
  );

  const checkIfChoiceMade = useCallback(
    (choiceId) => {
      existingResponse?.choicesMade?.map((c) => c.id).includes(choiceId);
    },
    [existingResponse?.choicesMade],
  );

  const toggleSelectedCheckbox = (response) => {
    setSelectionMade(true);
    const responseId = response.target.value;

    const exclusiveChoiceIds =
      questionData?.choices?.filter((c) => c.exclusive)?.map((c) => c.id) || []; // Added a fallback in case it's undefined

    if (selectedValues.includes(responseId.toString())) {
      const adjustedValues = selectedValues.filter(
        (val) => val !== responseId.toString(),
      );
      setSelectedValues(adjustedValues);
    } else {
      if (exclusiveChoiceIds.includes(responseId)) {
        setSelectedValues([responseId]);
      } else if (selectedValues.some((sv) => exclusiveChoiceIds.includes(sv))) {
        const adjustedValues = selectedValues.filter(
          (val) => !exclusiveChoiceIds.includes(val),
        );
        setSelectedValues([...adjustedValues, responseId]);
      } else {
        setSelectedValues([...selectedValues, responseId]);
      }
    }
  };

  const validateResponse = useCallback(() => {
    if (question?.questionAttributes?.includes('REQUIRED')) {
      if (selectedValues.length === 0) {
        lvrDispatch({
          type: actions.updateResponseValidationStatuses,
          data: { [responseUniqueIdentifier]: false },
        });

        setErrorText(`Response cannot be blank.`);
        return;
      }
    }

    lvrDispatch({
      type: actions.updateResponseValidationStatuses,
      data: { [responseUniqueIdentifier]: true },
    });

    setErrorText(null);
  }, [
    responseUniqueIdentifier,
    lvrDispatch,
    question?.questionAttributes,
    selectedValues,
  ]);

  const putResponseValue = useCallback(() => {
    const choices = questionData?.choices || [];

    const choicesMade = choices.filter((c) => selectedValues.includes(c.id));
    const rootIdsToClearFromStore = choices
      .flatMap((c) =>
        !selectedValues.includes(c.id) ? c.responseUniqueIdentifiers : [],
      )
      .filter(Boolean);

    const idsToClearFromStore = getIdsToClearFromStore({
      store: lvrStore,
      rootIds: rootIdsToClearFromStore,
      exclusionIds: selectedValues
        .flatMap((value) => value?.responseUniqueIdentifiers)
        .filter(Boolean),
    });

    idsToClearFromStore.forEach((id) => {
      lvrDispatch({
        type: actions.updateResponses,
        data: { [id]: null },
      });

      lvrDispatch({
        type: actions.updateResponseValidationStatuses,
        data: { [id]: null },
      });
    });

    updateResponseData({
      dispatch: lvrDispatch,
      question,
      data: choicesMade,
    });

    validateResponse();
  }, [
    lvrDispatch,
    question,
    questionData?.choices,
    selectedValues,
    validateResponse,
  ]);

  useEffect(() => {
    if (existingResponse?.choicesMade) {
      const choiceIds = existingResponse?.choicesMade.map((c) => c.id);
      setSelectedValues(choiceIds);
    } else {
      initializeResponse({ dispatch: lvrDispatch, question });
    }
  }, []);

  useEffect(() => {
    if (!selectionMade) return;
    putResponseValue();
  }, [putResponseValue, selectionMade]);

  useEffect(() => {
    // Break out if they've not attempted submission yet for a happier form.
    if (lvrStore.submissionFailed) {
      validateResponse();
    }
  }, [
    question?.questionAttributes,
    lvrStore?.submissionFailed,
    validateResponse,
  ]);

  return (
    <form
      className="multiple-choice-question__form"
      data-testid="checkbox-component-form"
      style={{ marginTop: errorText ? 0 : '1rem' }}
    >
      <ErrorText
        data-testid="checkbox-component-error-text"
        error={errorText}
        gutterBottom
      />
      {question.questionData.choices.map(({ id, text, toolTip }) => (
        <div
          key={id}
          className="multiple-choice-question__checkbox-wrapper"
          data-testid="checkbox-component-checkbox-wrapper"
        >
          <Checkbox
            data-testid="checkbox-component-checkbox"
            value={id}
            label={text}
            defaultChecked={checkIfChoiceMade(id)}
            checked={selectedValues.includes(id)}
            onChange={(e) => toggleSelectedCheckbox(e)}
          />
          {toolTip && (
            <Tooltip
              data-testid="checkbox-component-tooltip"
              content={toolTip}
              showOnElementEvents={['hover']}
            >
              <span
                className="multiple-choice-question__tooltip"
                data-testid="checkbox-component-tooltip-span"
              >
                <Icon
                  data-testid="checkbox-component-tooltip-icon"
                  icon={InfoOutline}
                />
              </span>
            </Tooltip>
          )}
        </div>
      ))}
    </form>
  );
};

CheckboxComponent.propTypes = {
  question: PropTypes.object,
};

export default CheckboxComponent;
