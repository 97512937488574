import { useHistory } from 'react-router-dom';
import I18n from 'i18n-js';
import TabLayout from '../../components/Layout/TabLayout/TabLayout';
import PendingAppeals from './PendingAppeals/PendingAppeals';
import HistoricalAppeals from './HistoricalAppeals/HistoricalAppeals';
import { useRequireAdmin } from '../../utils/AuthUtils';

const SmartShopAmendmentsDashboard = () => {
  const history = useHistory();
  useRequireAdmin();
  const TABS = {
    PENDINGAPPEALS: {
      label: I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_PENDING_APPEALS'),
      basePathBuilder: (path) => path,
      component: <PendingAppeals />,
    },
    HISTORICALAPPEALS: {
      label: I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_HISTORICAL_APPEALS'),
      basePathBuilder: (path) => `${path}/historical-appeals`,
      component: <HistoricalAppeals />,
      optPathParam: 'assessmentId',
    },
  };

  const buttonProps = {
    color: 'secondary',
    variant: 'outlined',
    showOnTab: ['PENDINGAPPEALS', 'HISTORICALAPPEALS'],
    text: '+ Admin Amendment Form',
    onClick: () =>
      history.replace(`/admin/smart-shop-amendments/admin-amendment-form`),
  };

  return (
    <TabLayout
      isAdminPath
      content={TABS}
      buttonProps={buttonProps}
      title="Smart Shop Amendments"
      baseUrl="/smart-shop-amendments"
      data-testid="smart-shop-amendments-tab-layout"
      homeUrl="/admin"
    />
  );
};

export default SmartShopAmendmentsDashboard;
