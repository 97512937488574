import { Typography } from 'cfa-react-components';

const renderFindings = (findings = {}) =>
  findings.map((finding) => (
    <div key={finding.parentFinding.id}>
      <Typography>{finding.parentFinding.questionText}</Typography>
      <ul>
        {finding.parentFinding.responseData.flattenAnswers.map(
          (response, i) => (
            <li key={i}>{response}</li>
          ),
        )}
      </ul>
      {finding.childrenFindings.length > 0 && (
        <div>{renderFindings(finding.childrenFindings)}</div>
      )}
    </div>
  ));

const FindingsQuestionChain = ({ childrenFindings = [] }) => (
  <div style={{ marginTop: '1rem' }}>
    <Typography color="disabled" className="selected-finding__badge-label">
      Issue
    </Typography>
    <div>{renderFindings(childrenFindings)}</div>
  </div>
);

export default FindingsQuestionChain;
