// import { createBrowserHistory } from 'history';
import { endUserSession } from '../../actions';
import { store } from '../../store';
import I18n from '../../../i18n/utils';
import { showFailureNotification } from '..';
import { routeToNewApp } from '../redirectUtil';

const errorHandler = (error) => {
  const { status } = error;

  switch (status) {
    case 401:
      terminateSession();
      break;
    case 403:
      showForbiddenAccessError();
      setTimeout(() => {
        redirectToLanding();
      }, 3000);
      break;
    case 500:
      showAPIError();
      break;
    case 503:
      showAPIError();
      break;
    case 504:
      showAPIError();
      break;
    default:
    // show error modal
  }
  return error;
};

export const redirectToLanding = () => {
  // const history = createBrowserHistory();
  if (window.location.pathname !== '/') {
    routeToNewApp();
  }
};

const showForbiddenAccessError = () => {
  showFailureNotification({
    message: I18n.t('APP_API_PERMISSIONS_FAILURE'),
    persistNotification: true,
  });
};

const showAPIError = () => {
  showFailureNotification({ message: I18n.t('APP_API_DATA_FAILURE') });
};

// Method to end user session, can be called on a 401
const terminateSession = async () => {
  // Dispatch endSession auth action creator to end user session
  store.dispatch(endUserSession());
};

export default errorHandler;
