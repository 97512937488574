import { Button, Col, Row } from 'cfa-react-components';
import { useCallback, useState } from 'react';
import { API_PUT } from '../../../../../utils';

import './SendReminder.scss';

const FOOD_SAFETY_API_BASE_URL = process.env.REACT_APP_SERVER_URL;

const SendReminder = ({ locationNumber }) => {
  const [isLoading, setIsLoading] = useState(false);

  const sendReminder = useCallback(async () => {
    setIsLoading(true);
    const path = `${FOOD_SAFETY_API_BASE_URL}/assessment/lvr/send-staffing-email/${locationNumber}`;
    try {
      await API_PUT(path);
    } catch (e) {
      console.log('Sending error: ', e.message);
    } finally {
      setIsLoading(false);
    }
  }, [locationNumber]);

  return (
    <>
      <Row className="send-reminder-description-row">
        <Col>
          If this data is incorrect, please notify the restaurant to update
          their leadership information by selecting the button below.
        </Col>
      </Row>
      <Row className="send-reminder-button-row">
        <Col>
          <Button
            size="md"
            color="secondary"
            variant="outlined"
            onClick={() => sendReminder()}
            loading={isLoading}
            disabled={isLoading}
            className="send-reminder-button"
          >
            Send Location Reminder
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SendReminder;
