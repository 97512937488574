import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';

import './BackArrow.scss';

const BackArrow = ({ title, path }) => {
  const linkTitle = title || 'Back';

  const routePath = path || '/';

  return (
    <Row className="container" data-testid="back-arrow-container-row">
      <Link
        to={routePath}
        className="desktop-back-container"
        data-testid="back-arrow-desktop-back-container"
      >
        <img
          alt=""
          src={require('../../../assets/icons/left-arrow.svg').default}
          height={14}
          width={14}
          className="desktop-back-arrow"
          data-testid="back-arrow-desktop-back-arrow"
        />
        <span
          className="desktop-back-link"
          data-testid="back-arrow-desktop-back-link"
        >
          {linkTitle}
        </span>
      </Link>
    </Row>
  );
};

BackArrow.propTypes = {
  title: PropTypes.string.isRequired,
};

export { BackArrow };
