import { Store } from 'react-notifications-component';
import { MessageBlock } from 'cfa-react-components';
import I18n from '../../i18n/utils';

const CONTAINER_POSITION = 'top-right';
const DURATION = 7000;

const Notification = ({ severity, title, message, onClose }) => (
  <MessageBlock
    severity={severity}
    title={title}
    onClose={onClose}
    style={{ flex: 1 }}
  >
    <div>{message}</div>
  </MessageBlock>
);

export const showSuccessNotification = ({
  message,
  showTitle = true,
  container = CONTAINER_POSITION,
}) => {
  Store.removeAllNotifications();

  Store.addNotification({
    container,
    dismiss: { duration: DURATION },
    content: (
      <Notification
        severity="success"
        title={showTitle ? I18n.t('APP_NOTIFICATION_SUCCESS_TITLE') : ''}
        message={message || I18n.t('APP_NOTIFICATION_SUCCESS_TITLE')}
      />
    ),
  });
};

export const showAutoSaveSuccessNotification = ({ message }) => {
  Store.removeAllNotifications();

  Store.addNotification({
    container: CONTAINER_POSITION,
    slidingExit: { duration: 0 },
    dismiss: { duration: DURATION },
    content: (
      <Notification
        severity="success"
        message={message || I18n.t('APP_NOTIFICATION_SUCCESS_TITLE')}
      />
    ),
  });
};

export const showFullTopSuccessNotification = ({
  message,
  showTitle = true,
  container = CONTAINER_POSITION,
}) => {
  Store.addNotification({
    container,
    dismiss: { duration: DURATION },
    content: (
      <Notification
        severity="success"
        title={showTitle ? I18n.t('APP_NOTIFICATION_SUCCESS_TITLE') : ''}
        message={message || I18n.t('APP_NOTIFICATION_SUCCESS_TITLE')}
        onClose={() => {}}
      />
    ),
  });
};

export const showFailureNotification = ({
  message,
  showTitle = true,
  persistNotification,
}) => {
  Store.removeAllNotifications();

  const notification = {
    container: CONTAINER_POSITION,
    content: (
      <Notification
        severity="error"
        title={showTitle ? I18n.t('APP_NOTIFICATION_ERROR_TITLE') : ''}
        message={message || I18n.t('APP_NOTIFICATION_ERROR_TITLE')}
        onClose={() => {}}
      />
    ),
  };

  if (!persistNotification) {
    notification.dismiss = {
      duration: DURATION,
      pauseOnHover: true,
    };
  }

  Store.addNotification(notification);
};

export const showServerUpStatusNotification = ({ message }) => {
  Store.removeAllNotifications();

  const notification = {
    container: CONTAINER_POSITION,
    slidingExit: { duration: 0 },
    dismiss: {
      duration: DURATION,
      pauseOnHover: true,
    },
    content: (
      <Notification
        severity="error"
        message={message || I18n.t('APP_NOTIFICATION_ERROR_TITLE')}
        onClose={() => {}}
      />
    ),
  };

  Store.addNotification(notification);
};
