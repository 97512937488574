import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioGroup } from 'cfa-react-components';
import {
  initializeResponse,
  updateResponseData,
  getIdsToClearFromStore,
} from '../../../utils/questionsUtils';
import { useLVRDispatch, useLVRStore, actions } from '../../../store/reducers';

const RadioComponent = ({ question }) => {
  const { responseUniqueIdentifier, questionData } = question;

  const lvrDispatch = useLVRDispatch();
  const lvrStore = useLVRStore();

  const [response, setResponse] = useState('');
  const [errorText, setErrorText] = useState('');

  const existingResponse = useMemo(
    () => lvrStore.responses?.[responseUniqueIdentifier]?.responseData,
    [lvrStore.responses, responseUniqueIdentifier],
  );

  const validateResponse = useCallback(() => {
    if (question?.questionAttributes?.includes('REQUIRED')) {
      if (response === '') {
        lvrDispatch({
          type: actions.updateResponseValidationStatuses,
          data: { [responseUniqueIdentifier]: false },
        });
        setErrorText(`A value must be selected.`);
        return;
      }
    }

    lvrDispatch({
      type: actions.updateResponseValidationStatuses,
      data: { [responseUniqueIdentifier]: true },
    });

    setErrorText('');
  }, [
    responseUniqueIdentifier,
    lvrDispatch,
    question?.questionAttributes,
    response,
  ]);

  const putResponseValue = useCallback(() => {
    const choiceMade = questionData.choices.find((c) => c.id === response);
    const rootIdsToClearFromStore = questionData.choices
      .flatMap((c) => (c.id !== response ? c.responseUniqueIdentifiers : []))
      .filter(Boolean);

    const idsToClearFromStore = getIdsToClearFromStore({
      store: lvrStore,
      rootIds: rootIdsToClearFromStore,
      exclusionIds: choiceMade?.responseUniqueIdentifiers,
    });

    idsToClearFromStore.forEach((id) => {
      lvrDispatch({
        type: actions.updateResponses,
        data: { [id]: null },
      });

      lvrDispatch({
        type: actions.updateResponseValidationStatuses,
        data: { [id]: null },
      });
    });

    updateResponseData({
      dispatch: lvrDispatch,
      question,
      data: [choiceMade],
    });

    validateResponse();
  }, [lvrDispatch, question, questionData.choices, response, validateResponse]);

  useEffect(() => {
    if (existingResponse?.choicesMade?.[0]) {
      setResponse(existingResponse?.choicesMade[0].id);
    } else {
      initializeResponse({ dispatch: lvrDispatch, question });
    }
  }, []);

  useEffect(() => {
    if (!response) return;
    putResponseValue();
  }, [putResponseValue, response]);

  useEffect(() => {
    // Break out if they've not attempted submission yet for a happier form.
    if (lvrStore.submissionFailed) {
      validateResponse();
    }
  }, [question?.questionAttributes, lvrStore.submissionFailed]);

  return (
    <RadioGroup
      className="multiple-choice-question__radio-group"
      data-testid="radio-component-radio-group"
      name="choices"
      helperText={questionData.toolTip}
      errorText={errorText}
      value={response}
      onChange={(e) => {
        setResponse(e.target.value);
      }}
    >
      {questionData.choices.map((choice) => (
        <RadioButton
          data-testid="radio-component-radio-button"
          value={choice.id}
          label={choice.text}
          key={choice.id}
        />
      ))}
    </RadioGroup>
  );
};

RadioComponent.propTypes = {
  question: PropTypes.object,
};

export default RadioComponent;
