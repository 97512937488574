// This File is for constants used in the Pending/Historical/Requested Amendments Pages

export const AMENDMENT_CARD_TYPE = {
  OPERATOR: 'OPERATOR',
  HISTORICAL: 'HISTORICAL',
  PENDING: 'PENDING',
};

export const sortFilterOptions = [
  {
    value: 'LOCATION',
    label: 'Location Number',
  },
  {
    value: 'REQUEST_TIMESTAMP',
    label: 'Appeal Date',
  },
  {
    value: 'RISK_LEVEL',
    label: 'Risk Level',
  },
  {
    value: 'QUESTION_ID',
    label: 'Finding ID',
  },
];

export const categoryFilterOptions = [
  {
    value: 'Cleaning & Sanitation',
    label: 'Cleaning & Sanitation',
  },
  {
    value: 'Cross Contamination',
    label: 'Cross Contamination',
  },
  {
    value: 'Health & Hygiene',
    label: 'Health & Hygiene',
  },
  {
    value: 'Informational Only',
    label: 'Informational Only',
  },
  {
    value: 'Pests',
    label: 'Pests',
  },
  {
    value: 'Time & Temperature',
    label: 'Time & Temperature',
  },
];

export const riskLevelFilterOptions = [
  {
    value: 'Immediate',
    label: 'Immediate Action',
  },
  {
    value: 'High',
    label: 'High',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: 'Low',
    label: 'Low',
  },
  {
    value: 'Information',
    label: 'Informational',
  },
];

export const historicalAppealsBadgeFilterOptions = [
  {
    label: 'Approved',
    value: '&statuses=APPROVED&isAdmin=false&isModified=false',
  },
  {
    label: 'Upheld',
    value: '&statuses=DENIED',
  },
  {
    label: 'Removed',
    value: '&isAdmin=true&isBulk=false',
  },
  {
    label: 'Bulk Removed',
    value: '&isBulk=true',
  },
  {
    label: 'Modified',
    value: '&isModified=true',
  },
];
