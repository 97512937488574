import { Typography } from 'cfa-react-components';
import StatusIcon from './StatusIcon';

import '../FileUploader.scss';

const FileItem = ({ name, file, isLoading, isError, onDelete }) => (
  <div className="file-uploader__file-item" data-testid="file-item-file-item">
    <Typography
      className="file-uploader__file-item__file-name"
      data-testid="file-item-file-name"
      title={name}
    >
      {name}
    </Typography>
    {isLoading && <Typography>Uploading!</Typography>}
    <div className="file-uploader__file-item__flex-container">
      <StatusIcon isLoading={isLoading} isError={isError} />
      <button
        type="button"
        className="file-uploader__file-item__delete-btn"
        data-testid="file-item-delete-button"
        onClick={() => onDelete(file)}
      >
        Delete
      </button>
    </div>
  </div>
);

export default FileItem;
