const english = {
  LANGUAGE_SELECTION_HEADING: 'Select a language',
  LANGUAGE_LOADING: 'Loading Translation...',
  LANGUAGE_ENGLISH: 'English',
  LANGUAGE_SPANISH: 'Español',
  // APP
  APP_NAME: 'Operations Hub',
  APP_SHORT_NAME: 'Ops Hub',
  APP_SIGN_IN: 'Sign in',

  APP_COMING_SOON: 'Coming Soon',

  // <b></b> tags parse-able with the parse() plugin from react-html-parser

  APP_UNAUTHORIZED_MESSAGE: 'Unauthorized to view this Application',
  APP_UNDEFINED_LOCATION_MESSAGE: 'Please search for a location',

  APP_NOTIFICATION_SUCCESS_TITLE: 'Success',
  APP_NOTIFICATION_ERROR_TITLE: 'Error',
  APP_NOTIFICATION_FORBIDDEN_TITLE: 'Forbidden',
  APP_API_DATA_FAILURE:
    'Oops! Failed to fetch data from the server, please try again later',
  APP_API_PERMISSIONS_FAILURE:
    'Oops! Looks like you need permissions to access this site. Please contact your Administrator.',

  // Nav Menu
  APP_SHORT_TITLE: 'Operations Hub',
  APP_MENU_LANDING: 'Landing',
  APP_MENU_ADMIN: 'Admin',
  APP_MENU_SIGN_OUT: 'Sign out',
  APP_PROFILE_TOOLTIP: 'Profile',
  APP_SELECT_LOCATION: 'Select Location',

  // Landing Page
  APP_LANDING_PAGE_TITLE: 'Operations Hub',
  APP_LANDING_PAGE_WELCOME: 'Welcome',
  APP_LANDING_PAGE_SELECT_APP: 'Select an Application',
  APP_LANDING_PAGE_SELECT_LOCATION: 'Please select a location to continue',
  APP_LANDING_PAGE_FOOD_SAFETY_CARD: 'Food Safety Report',
  APP_LANDING_PAGE_HEALTH_CARD: 'Health Prompt',
  APP_LANDING_PAGE_TABLEAU_DASHBOARD: 'Food Safety Tableau Dashboard',
  APP_LANDING_PAGE_BEST_PRACTICE_TOOL_KIT_TITLE: 'Best Practices Toolkit',
  APP_LANDING_PAGE_SMART_SHOP_AMENDMENTS: 'Smart Shop Appeals & Amendments',
  APP_LANDING_PAGE_403_FORBIDDEN:
    'You do not have access to this site. Please contact an administrator.',

  // Admin Dashboard
  APP_ADMIN_DASHBOARD_TITLE: 'Admin Dashboard',
  APP_ADMIN_SELF_ASSESSMENT_TITLE: 'Self Assessment',
  APP_ADMIN_AMENDMENTS_TITLE: 'Amendments',
  APP_ADMIN_AMENDMENTS_CONTAINER_TITLE: 'Appealed SAFE Audits',
  APP_ADMIN_SAFE_QUESTIONS_TITLE: 'SAFE Question Management',
  APP_ADMIN_AMENDMENTS_DESCRIPTION:
    'Review a history of Amendments from Restaurants below and approve or deny such requests',
  APP_ADMIN_AMENDMENTS_ACCEPT: 'Approve',
  APP_ADMIN_AMENDMENTS_APPROVE: 'Approve',
  APP_ADMIN_AMENDMENTS_DENY: 'Deny',
  APP_ADMIN_AMENDMENTS_REVIEW_REQUEST_NOTIFICATION:
    'Successfully requested review from vendor',
  APP_ADMIN_AMENDMENTS_EMPTY_MESSAGE: 'There are no items to review!',
  APP_ADMIN_AMENDMENTS_ADD_COMMENT_HEADER: 'Add Comment',
  APP_ADMIN_AMENDMENTS_SMART_SHOP: 'Smart Shop Amendments',
  APP_ADMIN_AMENDMENTS_MODIFY: 'Modify',
  APP_ADMIN_AMENDMENTS_REVIEW_REQUEST: 'Request Review',
  APP_ADMIN_AMENDMENTS_APPEAL: 'Appeal',
  APP_ADMIN_AMENDMENTS_REASON_APPROVE:
    'Please provide a reason for approving this appeal. This reason will be sent to the Operator via email.',
  APP_ADMIN_AMENDMENTS_REASON_DENY:
    'Please provide a reason for denying this appeal. This reason will be sent to the Operator via email.',
  APP_ADMIN_AMENDMENTS_REASON_MODIFY:
    'Please provide a reason for modifying this appeal. This reason will be sent to the Operator via email.',
  APP_ADMIN_AMENDMENTS_SELECTED_FINDING: 'Selected Finding',
  APP_ADMIN_AMENDMENTS_DATE: 'Date',
  APP_ADMIN_AMENDMENTS_STORE: 'Store',
  APP_ADMIN_AMENDMENTS_MODIFY_FINDING: 'Modify Finding',
  APP_ADMIN_AMENDMENTS_REASON: 'Reason',
  APP_ADMIN_AMENDMENTS_FOR_CHANGE: 'for change',
  APP_ADMIN_AMENDMENTS_MAX_CHARACTERS: 'Maximum 500 characters',

  APP_ADMIN_AMENDMENTS_CURRENT_TAB_SUB_HEADER:
    'Amendments from Restaurants below and approve or deny such requests',
  APP_ADMIN_AMENDMENTS_HISTORIC_TAB_SUB_HEADER:
    'View historical amendments by quarter. Use the dropdown to seltect the quarter you want to view.',
  APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION:
    'Amendment accepted sucessfully. Moved to Historical Amendments',
  APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_DENIED:
    'Appeal Denied Successfully',
  APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_APPROVED:
    'Appeal Approved Successfully',
  APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_MODIFY:
    'Appeal Modified Successfully',
  APP_ADMIN_QUESTION_UPDATE_SUCCESS_NOTIFICATION:
    'Successfully updated SAFE Question',
  APP_ADMIN_QUESTION_UPDATE_FAILURE_NOTIFICATION:
    'Failed to update SAFE Question',

  // Health and Hygiene
  APP_HEALTH_AND_HYGIENE_TITLE: 'Health & Hygiene',
  APP_HEALTH_AND_HYGIENE_HEALTH_PROMPT: 'Health Prompt',
  APP_HEALTH_AND_HYGIENE_VENDOR_TRACING: 'Vendor Tracing',

  // SAFE Question Management
  APP_SAFE_QUESTION_TITLE: 'Manage Questions',
  APP_SAFE_QUESTION_BACK: 'Admin Dashboard',

  // Location Selector
  APP_LOCATION_SELECTOR_SELECT: 'Please Select a location',

  // Report Dashboard
  APP_REPORT_DASHBOARD_NO_AUDITS:
    'Looks like  your report isn’t ready yet. Check back after you receive a notification that it’s ready',
  APP_REPORT_DASHBOARD_SELECT_LOCATION: 'Please select a location to continue',
  APP_REPORT_DASHBOARD_TITLE: 'Food Safety Report',
  APP_REPORT_DASHBOARD_BACK_TO_HOME: 'Home',
  APP_REPORT_DASHBOARD_PERFORMANCE: 'Performance',
  APP_REPORT_DASHBOARD_PERFORMANCE_LEARN: 'Learn more about Performance Levels',
  APP_REPORT_DASHBOARD_NEEDING_IMPROVEMENT: 'Needing Improvement',
  APP_REPORT_DASHBOARD_PERFORMANCE_LEVEL_LABEL: 'Performance Level',
  APP_REPORT_DASHBOARD_TOTAL_FINDINGS_GRAPH_LABEL: 'Total Findings',
  APP_REPORT_DASHBOARD_IMMEDIATE_FINDINGS_GRAPH_LABEL: 'Immediate Findings',
  APP_REPORT_DASHBOARD_HIGH_FINDINGS_GRAPH_LABEL: 'High Findings',
  APP_REPORT_DASHBOARD_MEDIUM_FINDINGS_GRAPH_LABEL: 'Medium Findings',
  APP_REPORT_DASHBOARD_LOW_FINDINGS_GRAPH_LABEL: 'Low Findings',
  APP_REPORT_DASHBOARD_HISTORICAL_PERFORMANCE: 'Historical Trends',
  APP_REPORT_DASHBOARD_FINDINGS_SUMMARY: 'Findings Summary',
  APP_REPORT_DASHBOARD_FINDINGS_SUMMARY_EMPTY: 'No Findings to display',
  APP_REPORT_DASHBOARD_FINDINGS_SEARCH_EMPTY: 'No results to match the search',
  APP_REPORT_DASHBOARD_NEW: 'New',
  APP_REPORT_DASHBOARD_REPEAT: 'Repeat',
  APP_REPORT_DASHBOARD_TOTAL: 'Total',
  APP_REPORT_DASHBOARD_IMMEDIATE: 'Immediate',
  APP_REPORT_DASHBOARD_HIGH: 'High',
  APP_REPORT_DASHBOARD_MEDIUM: 'Medium',
  APP_REPORT_DASHBOARD_LOW: 'Low',
  APP_REPORT_DASHBOARD_INFORMATIONAL: 'Informational',
  APP_REPORT_DASHBOARD_VIEW: 'View',
  APP_REPORT_DASHBOARD_LEAVE_BEHIND: 'Leave Behind Report',
  APP_REPORT_DASHBOARD_VIEW_FINDINGS: 'View Findings',
  APP_REPORT_DASHBOARD_PERFECT_AUDIT:
    'There were no findings this audit. Keep up the good work!',
  APP_REPORT_DASHBOARD_VIEW_PAST_FINDINGS: 'View Past Findings',
  APP_REPORT_DASHBOARD_PRINT_REPORT: 'Print Report',
  APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_NO_FINDINGS:
    'There are no Findings in the report to begin inquiry.',
  APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_PASSED_DEADLINE:
    'The deadline %{deadlineDate} has passed to submit inquiries.',
  APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_WITHIN_DEADLINE:
    'Inquiries can be submitted until %{deadlineDate}.',
  APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_BUTTON_TEXT: 'Begin Inquiry',
  APP_REPORT_DASHBOARD_AMENDMENTS_APPROVED: 'Appeal Approved',
  APP_REPORT_DASHBOARD_APPROVED_APPEALS: 'Approved Appeals',

  APP_REPORT_DASHBOARD_PERFORMANCE_SCORE_NOT_AVAILABLE: `Two quarters of data are required for a Performance Level to generate`,

  // All Findings
  APP_REPORT_DASHBOARD_FINDINGS_NEW: 'New',
  APP_REPORT_DASHBOARD_FINDINGS_PAST: 'Past',
  APP_REPORT_DASHBOARD_FINDINGS_ALL: 'All Findings',
  APP_REPORT_DASHBOARD_FINDINGS_AUDIT: 'Audit',
  APP_REPORT_DASHBOARD_DETAILS: 'Details',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_NAVIGATION: 'Report Dashboard',

  // ALL FINDINGS FILTER
  APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_PLACEHOLDER: 'Sort by',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_RISK_LEVEL: 'Risk Level',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_CATEGORY: 'Category',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_FINDING_ID: 'Finding Id',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_CATEGORY_FILTER_PLACEHOLDER:
    'Filter by Category',
  APP_REPORT_DASHBOARD_ALL_FINDINGS_RISK_LEVEL_FILTER_PLACEHOLDER:
    'Filter by Priority',
  APP_REPORT_DASHBORAD_ALL_FINDINGS_RESET: 'Reset Filters',
  APP_REPORT_DASHBORAD_ALL_FINDINGS_NO_RESULTS: 'No Results Found',
  APP_REPORT_DASHBORAD_ALL_FINDINGS_NO_RESULTS_MSG:
    'Try applying different filters.',

  // Findings Card
  APP_REPORT_DASHBOARD_FINDING_CARD_WHEN_LABEL: 'When',
  APP_REPORT_DASHBOARD_FINDING_CARD_CATEGORY_LABEL: 'Category',
  APP_REPORT_DASHBOARD_FINDING_CARD_REPEAT_LABEL: 'REPEAT',

  // LVR Findings Details
  APP_LVR_FINDING_DETAILS_RESPONSE_LABEL: 'Response',
  APP_LVR_FINDING_DETAILS_RESPONSES_LABEL: 'Responses',
  APP_LVR_FINDING_DETAILS_COMPLIANCE_LABEL: 'Compliance',
  APP_LVR_FINDING_STAFF: 'Staff',
  APP_LVR_FINDING_OPERATOR_CONSULTING_NOTES: 'Operator Consulting Notes',
  APP_LVR_FINDING_GREAT_FOOD: 'Great Food',
  APP_LVR_FINDING_CLEAN_SAFE_ENVIRONMENT: 'Clean & Safe Environment',
  APP_LVR_FINDING_FAST_ACCURATE_SERVICE: 'Fast & Accurate Service',
  APP_LVR_FINDING_GUEST_EXPERIENCE: 'Guest Experience',
  APP_LVR_FINDING_TOP_FOCUS_NO_DATA: 'No Noncompliant Findings Available',

  // Selected Findings
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_SUBTITLE: 'Finding',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_CARD_TITLE: 'Finding Details',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_RESOURCES:
    'Resources to help determine your action plan.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_PATHWAY: 'Pathway Procedures',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_PATHWAY_DESCRIPTION:
    "Consider reviewing Pathway procedures for the problems you've identified.",
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_BEST_PRACTICES: 'Best Practice Links',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_BEST_PRACTICES_DESCRIPTION:
    "If you've followed proper procedures but need additional measures, visit the Best Practices Toolkit.",
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL: 'Additional Support',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_OTHER:
    'Analyze the root cause of the finding.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_OTHER_DESCRIPTION:
    'If you need additional measures or root cause assistance, use the below resources.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED: 'Need Help?',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED_CAPTION:
    'If you have a general Food Safety question, please use the button below to contact the Food Safety team',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_NEED_DESCRIPTION:
    'If you need additional measures or root cause assistance, use the below resources.',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_ROOT_CAUSE:
    'Root Cause Analysis',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_CORRECTIVE:
    'Corrective Action Planning',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_FREE_CTE:
    'Free CTE Request',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_ADDITIONAL_SAFE_CTE:
    'Additional SAFE/CTE',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_HISTORICAL_ACTIONS_TITLE:
    'Historical Actions',
  APP_REPORT_DASHBOARD_SELECTED_FINDINGS_HISTORICAL_ACTIONS_DESCRIPTION:
    'An overview of the timeline of this finding and the previous corrective action plan.',
  APP_REPORT_DASHBOARD_NEED_HELP_GENERAL_QUESTION: 'Ask a general question',
  APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_TITLE:
    'Best Practices Toolkit',
  APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_DESCRIPTION:
    'Helps Restaurants understand why a problem is likely occurring (root cause) and connects them to a library of expert tips and vetted best practices from top-performing Restaurants.',
  APP_REPORT_DASHBOARD_NEED_HELP_BEST_PRACTICE_TOOLKIT_LINK_CAPTION:
    'Explore the Toolkit here',
  APP_REPORT_DASHBOARD_NEED_HELP_REQUEST_ECOSURE:
    'Request Additional EcoSure Visits',
  APP_REPORT_DASHBOARD_NEED_HELP_ANNUAL_CTE: 'Annual Complementary CTE Request',
  APP_REPORT_DASHBOARD_NEED_HELP_ADDITIONAL_SAFE_CTE:
    'Additional SAFE/CTE Request',

  // Historical Actions
  LINK_PATHWAY_PROCEDURES: 'Pathway Procedures Followed',
  LINK_BEST_PRACTICES: 'Best Practices Followed',
  LINK_CTE_FREE: 'Free CTE Request Opened',
  LINK_CTE_ADDITIONAL: 'Additional/Safe CTE Opened',
  LINK_CORRECTIVE_ACTION_PLANNING: 'Corrective Action Planning Opened',
  LINK_ROOT_CAUSE_ANALYSIS: 'Root Cause Analysis Opened',

  // Findings Filter
  APP_REPORT_DASHBOARD_FINDINGS_SORT: 'Sort By:',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL: 'Risk Level Only',
  APP_REPORT_DASHBOARD_FINDINGS_CUSTOM: 'Custom',
  APP_REPORT_DASHBOARD_FINDINGS_SHOW_PAST: 'Show Past Findings',
  APP_REPORT_DASHBOARD_FINDINGS_FILTER_RISK_LEVEL: 'Filter By Risk Level:',
  APP_REPORT_DASHBOARD_FINDINGS_FILTER_CATEGORY: 'Filter By Food Safety 5:',
  APP_REPORT_DASHBOARD_FINDINGS_FILTER_ZONE: 'Filter By Zone:',
  APP_REPORT_DASHBOARD_FINDINGS_OCCURRENCES: 'Occurrences',

  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_IMMEDIATE: 'Immediate',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_HIGH: 'High',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_MEDIUM: 'Medium',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_LOW: 'Low',
  APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_INFORMATIONAL: 'Informational',

  APP_REPORT_DASHBOARD_FINDINGS_CROSS_CONTAMINATION: 'Cross Contamination',
  APP_REPORT_DASHBOARD_FINDINGS_HEALTH_HYGIENE: 'Health & Hygiene',
  APP_REPORT_DASHBOARD_FINDINGS_INFORMATIONAL_ONLY: 'Informational Only',
  APP_REPORT_DASHBOARD_FINDINGS_PESTS: 'Pests',
  APP_REPORT_DASHBOARD_FINDINGS_TIME_TEMPERATURE: 'Time & Temperature',
  APP_REPORT_DASHBOARD_FINDINGS_CLEANING_SANITATION: 'Cleaning & Sanitation',
  APP_REPORT_DASHBOARD_CLEAR_CATEGORY_FILTER: 'Clear Filters',

  APP_REPORT_DASHBOARD_FINDINGS_ZONE_BOARDS: 'Boards',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_BREADING_TABLE: 'Breading Table',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_COOLER: 'Cooler',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_DINING_ROOM: 'Dining Room',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_DRIVE_THRU: 'Drive Thru',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_FREEZER: 'Freezer',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_FRONT_COUNTER: 'Front Counter',
  APP_REPORT_DASHBOARD_FINDINGS_ZONE_SALAD_PREP: 'Salad Prep',

  APP_REPORT_DASHBOARD_SELECTED_FINDING_RESOURCES: 'Resources',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_NO_RESOURCES: 'No Resources',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_ERROR: `This finding's details are not viewable with the selected location and/or the selected audit. Please try again later.`,

  APP_REPORT_DASHBOARD_SELECTED_FINDING_BEST_PRACTICE_LINKS:
    'Best Practice Links',

  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_STANDARD: 'Standard',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_SUBQUESTION_ID: 'Detail ID',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_QUARTER: 'Quarter',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ZONE: 'Zone',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_TEMP: 'Temp',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ITEM: 'Item',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OPEN_COMMENTS: 'Open Comments',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ISSUE: 'Issue',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OTHER_INFORMATION:
    'Other Information',
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OTHER_NOTES: "Auditor's Notes",
  APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_PHOTO: 'Photo',

  // Audit Amendments
  APP_REPORT_DASHBOARD_AUDIT_AMENDMENTS_TITLE: 'SAFE Appeals & Amendments',
  APP_REPORT_DASHBOARD_AUDIT_AMENDMENTS_STATUS_AMMENDED: 'Amended',
  APP_REPORT_DASHBOARD_AUDIT_AMENDMENTS_EMPTY: 'No Amendments',

  // PDF Export
  APP_PDF_EXPORT_FINDINGS_TITLE: 'Findings',
  APP_PDF_EXPORT_NO_FINDINGS: 'No findings on this audit',

  // Performance Level
  APP_PERFORMANCE_LEVEL_CATEGORY_ELITE: 'Elite',
  APP_PERFORMANCE_LEVEL_CATEGORY_ELITE_DEFINITION:
    'Among the best in the chain! Obvious demonstration of food safety culture through robust systems and processes to ensure consistent compliance with food safety principles. Minimal risk for a potential foodborne illness outbreak. Continue to place a strong emphasis on maintaining food safe practices.',
  APP_PERFORMANCE_LEVEL_CATEGORY_GOOD: 'Good',
  APP_PERFORMANCE_LEVEL_CATEGORY_GOOD_DEFINITION:
    'Shows a thorough understanding and execution of food safe practices. Lower risk for a potential foodborne illness outbreak. Focus on consistently implementing food safety systems and processes to continue to lower risk in your Restaurant.',
  APP_PERFORMANCE_LEVEL_CATEGORY_FAIR: 'Fair',
  APP_PERFORMANCE_LEVEL_CATEGORY_FAIR_DEFINITION:
    'Demonstrates fair, but incomplete or inconsistent, knowledge and/or execution of food safety systems and processes. Moderate risk for a potential foodborne illness outbreak as compared to other restaurants within the chain. Focus on strengthening a broader understanding of food safety principles in your Restaurant.',
  APP_PERFORMANCE_LEVEL_CATEGORY_UNSATISFACTORY: 'Unsatisfactory',
  APP_PERFORMANCE_LEVEL_CATEGORY_UNSATISFACTORY_DEFINITION:
    'Demonstrates an opportunity to improve food safety systems and processes. Above average risk for a potential foodborne illness outbreak as compared to other Restaurants within the chain. Ask for help to address the opportunities found.',
  APP_PERFORMANCE_LEVEL_CATEGORY_SUBSTANDARD: 'Substandard',
  APP_PERFORMANCE_LEVEL_CATEGORY_SUBSTANDARD_DEFINITION:
    'Demonstrates a need to take action to implement food safety systems and processes to meet Chick-fil-A standards. Elevated risk of a potential foodborne illness outbreak as compared to other Restaurants within the chain. Ask your Business Consultant for help to address the opportunities found.',

  // Audit Summary Card
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_TOTAL_FINDINGS: 'Total Findings',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_TOP_FOCUS_AREA: 'Top Focus Areas',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_NO_IMPROVEMENTS:
    'There are no areas needing improvement for this audit!',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_GOOD_JOB: 'Good Job!',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_VIEW_FINDINGS: 'View Findings',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_START_INQUIRY: 'Start Inquiry',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_PRINT_FS_REPORT: 'Print FS Report',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_LEAVE_BEHIND_REPORT:
    'Leave Behind Report',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_INQUIRY_DEADLINE: 'Inquiry Deadline',
  APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_PREVIOUS_ROUND: 'Previous Round',

  // Requested Appeals (AKA amendments)
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS: 'Requested Appeals',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_VIEW_APPEALS: 'View Appeals',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FINDINGS: 'Findings',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_STATUS: 'Status',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FILTERS_BADGE: 'Badge',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_FILTERS_FINDING_ID: 'Finding ID',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_APPROVED: 'Approved',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_DENIED: 'Denied',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_UPHELD: 'Upheld',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_MODIFIED: 'Modified',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REQUESTS: 'Requests',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REMOVED: 'Removed',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_IN_REVIEW: 'In Review',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_NO_FILTERED_RESULTS:
    'No requested appeals for the applied filters',
  APP_REPORT_DASHBOARD_REQUESTED_APPEALS_NO_RESULTS: 'No requested appeals in',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_CREATED: 'Created',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_SUBMITTED_BY: 'Submitted by',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_VISIT_DATE: 'Visit Date',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_REASON_FOR_REVIEW: 'Reason for Review',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_SUPPORTING_DOCUMENTS:
    'Supporting Documents',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_ADMIN_COMMENTS: 'Admin Comments',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_AUDITORS_NOTES: "Auditor's Notes",
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_ZONE: 'Zone',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_ITEM: 'Item',
  APP_REPORT_DASHBOARD_AMENDMENT_CARD_ISSUE: 'Issue',
  APP_REPORT_DASHBOARD_AMENDMENT_PENDING_APPEALS: 'Pending Appeals',
  APP_REPORT_DASHBOARD_AMENDMENT_UNIQUE_STORES: 'Unique Stores',
  APP_REPORT_DASHBOARD_AMENDMENT_HISTORICAL_APPEALS: 'Historical Appeals',

  // Filters
  APP_REPORT_DASHBOARD_FILTERS: 'Filters',
  APP_REPORT_DASHBOARD_FILTERS_SORT_BY: 'Sort by',
  APP_REPORT_DASHBOARD_FILTERS_ROUND: 'Round',
  APP_REPORT_DASHBOARD_FILTERS_FINDINGS: 'Findings',
  APP_REPORT_DASHBOARD_FILTERS_FILTER_BY: 'Filter By',
  APP_REPORT_DASHBOARD_FILTERS_FINDING_ID: 'Finding ID',
  APP_REPORT_DASHBOARD_FILTERS_CATEGORY: 'Category',
  APP_REPORT_DASHBOARD_FILTERS_PRIORITY: 'Priority',
  APP_REPORT_DASHBOARD_FILTERS_APPLY: 'Apply',
  APP_REPORT_DASHBOARD_FILTERS_RESET: 'Reset',

  // Assessment
  APP_ASSESSMENT_LANDING_PAGE_TITLE: 'Licensee Visit Report',
  APP_ASSESSMENT_LANDING_PAGE_SUB_TITLE:
    'Please submit the report within 24 hours of your visit to provide timely and actionable feedback to the restaurant leaders and partners.',
  APP_ASSESSMENT_LANDING_START_REPORT: 'Start LVR',
  APP_ASSESSMENT_LANDING_LVR_CONVERSATION: 'LVR Conversation',
  APP_ASSESSMENT_LICENSEE_VISIT_REPORT_FORM_TITLE: 'Licensee Visit Report',

  APP_VISITS: 'Visit(s)',
  APP_CONVERSATIONS: 'Conversation(s)',
  APP_TOTAL_VISITS_THIS_MONTH: 'Total visits this month',
  APP_LAST_CONVERSATION: 'Last Conversation',
  APP_LAST_VISIT: 'Last Visit',

  // Smart Shop
  APP_SMART_SHOP_LANDING_PAGE_TITLE: 'Quality Report',
  APP_SMART_SHOP_ALL_FINDINGS_NO_FINDINGS: 'No Reported findings.',
  APP_SMART_SHOP_CLEAN_AND_SAFE_ENVIRONMENT: 'Clean & Safe Environment',
  APP_SMART_SHOP_FAST_ACCURATE: 'Fast & Accurate Service',
  APP_SMART_SHOP_GENERAL_INFORMATION: 'General Information',
  APP_SMART_SHOP_GENUINE_HOSPITALITY: 'Genuine Hospitality',
  APP_SMART_SHOP_GREAT_FOOD: 'Great Food',
  APP_SMART_SHOP_DINE_IN: 'Dine-In',
  APP_SMART_SHOP_DRIVE_THRU: 'Drive-Thru',
  APP_SMART_SHOP_CARRY_OUT: 'Carry-out',
  APP_SMART_SHOP_MOBILE_CARRY_OUT: 'Mobile Carry-out',
  APP_SMART_SHOP_SALES_CHANNEL: 'Sales Channel',
  APP_SMART_SHOP_NO_REPORT: 'No reports created yet!',
  APP_SMART_SHOP_ANALYTICS_HUB_LINK: 'Analytics Hub',
  APP_SMART_SHOP_ANALYTICS_HUB_MSG_PT1: 'Visit the',
  APP_SMART_SHOP_ANALYTICS_HUB_MSG_PT2:
    'to preview the coming Smart Shop dashboard.',
  APP_SMART_SHOP_LBR_MSG:
    'Please select the LBR file you would like to download.',
  APP_SMART_SHOP_LBR_NO_FILES_MSG: 'There are no files available for download.',
  APP_SMART_SHOP_LBR_NO_REPORTS_MSG: 'No reports available yet!',
  APP_SMART_SHOP_NO_RESULTS_FOUND: 'No Results Found',
  APP_SMART_SHOP_TRY_APPLY_DIFFERENT_FILTERS: 'Try applying different filters.',

  APP_SMART_SHOP_COMING_SOON_HEADER:
    'Exciting Updates Coming Soon to Smart Shop!',
  APP_SMART_SHOP_COMING_SOON_DETAILS:
    'Stay tuned for enhanced features and more! In the meantime, please use the button above to download comprehensive Leave Behind Reports.',
  APP_SMART_SHOP_COMING_SOON_ADDITIONAL_LINKS_LABEL: 'Explore More:',
  APP_SMART_SHOP_COMING_SOON_ALL_FINDINGS_LINK: 'All Findings: ',
  APP_SMART_SHOP_COMING_SOON_ALL_FINDINGS_DESCRIPTION:
    'Review detailed findings from each Smart Shop visit.',
  APP_SMART_SHOP_COMING_SOON_DASHBOARD_LINK: 'Smart Shop Dashboard: ',
  APP_SMART_SHOP_COMING_SOON_DASHBOARD_DESCRIPTION:
    'Access the interactive dashboard for a complete overview.',

  // past findings
  APP_PAST_FINDINGS_PAST: 'Past',
  APP_PAST_FINDINGS_RESPONSE: 'Response',
  APP_PAST_FINDINGS_NO_DATA:
    'No previous occurrences found in the last 3 months.',

  // smart shop inquiry form
  APP_SMART_SHOP_REPORT: 'Smart Shop Report',
  APP_YES_BUTTON: 'Yes',
  APP_NO_BUTTON: 'No',
  APP_PROCEED_BUTTON: 'Proceed',
  APP_NEXT_BUTTON: 'Next',
  APP_NEXT_CANCEL: 'Cancel',
  APP_SUBMIT_BUTTON: 'Submit',
  APP_QUESTION_DETAILS: 'Question Details',
  APP_ASK_A_QUESTION: 'Ask a Question',
  APP_SMART_SHOP_INQUIRY_FORM: 'Inquiry Form',
  APP_CHOOSE_FINDING_LABEL: 'Choose a finding',
  APP_SMART_SHOP_INQUIRY_STEP_1_HEADER: 'Welcome!',
  APP_APPEAL_REQUEST_DETAILS: 'Appeal Request Details',
  APP_PROVIDE_REASON_FOR_REVIEW: 'Provide Reason for Review',
  APP_PROCEED_WITH_APPEAL_REQUEST: 'Proceed with Appeal Request',
  APP_SMART_SHOP_INQUIRY_STEP_1_BODY:
    'Welcome to the Smart Shop Inquiry Form. There are two paths available. You can ask a question or request an appeal for a specific finding. Appeals must be submitted within 5 days of your report distribution.',
  APP_SMART_SHOP_INQUIRY_COMPLIANT_WARNING:
    'This finding can’t be appealed as it is not non-compliant.',
  APP_SMART_SHOP_INQUIRY_COMPLIANT_INFORMATIONAL:
    'This is an informational finding. To request an Appeal please select an non-compliant finding.',
  APP_SMART_SHOP_INQUIRY_ASK_QUESTION_MESSAGE:
    'Is there an aspect of this procedure you would like to better understand with consultation from the Smart Shop Team?',
  APP_SMART_SHOP_INQUIRY_ASK_QUESTION_MESSAGE_AND_APPEAL:
    'Is there an aspect of this procedure you would like to better understand with consultation from the Smart Shop Team, or would you like to proceed with submitting an appeal?',
  APP_SMART_SHOP_INQUIRY_ORG_QUESTION:
    'The ORG provides step-by-step processes to achieve Smart Shop requirements. Have you reviewed the ORG link for this finding?',
  APP_SMART_SHOP_INQUIRY_ORG_LINK: 'Review The ORG link',
  APP_APPEAL_REQUEST_SUBMITTED: 'Appeal Request Submitted!',
  APP_APPEAL_REQUEST_FAIL: 'Failed to Submit Appeal Request.',
  APP_QUESTION_SUBMITTED: 'Successfully submitted a Question!',
  APP_QUESTION_SUBMITTION_FAIL: 'Failed to submitted a Question.',
  APP_UPLOAD_SUPPORTING_DOCUMENTS: 'Upload Supporting Documents ',
  APP_OPTIONAL: '(Optional)',
  APP_SEARCH_FINDING_PLACEHOLDER: 'Type a question ID or keyword',
  APP_OPERATIONAL_REQUIREMENTS_GUIDE_LINK:
    'Operational Requirements Guide Link',
  APP_SMART_SHOP_5_DAY_LIMIT:
    'The 5 day deadline has passed to submit inquiries.',
  APP_BROWSE: 'Browse',
  APP_FILE_UPLOAD_INSTRUCTIONS: 'Drag your supporting files(s) or',
  APP_FILE_UPLOAD_WAIT: 'This could take a few moments...',
  APP_FILE_UPLOADING: 'Uploading file...',

  APP_ISSUE_TEXT: 'Issue Text',
  APP_TOUCHPOINT: 'Touchpoint',
  APP_BETA: 'BETA',

  APP_DOWNLOAD: 'Download',
  APP_LBR_DOWNLOAD_PDF: 'Download Leave Behind Report',
  APP_LBR_DROPDOWN_LABEL: 'Select a report',

  // Restaurant Sales Card
  APP_LVR_RESTAURANT_SALES_NO_DATA: 'No Sales Data available for this month',
  APP_LVR_RESTAURANT_SALES_TOTAL_SALES: 'Total sales in',
  APP_LVR_RESTAURANT_SALES_LAST_YEAR: 'Last year in',
  APP_LVR_RESTAURANT_SALES_LATEST_UPDATE: 'The latest update was',
  APP_LVR_RESTAURANT_SALES_LICENSEE_REMIND:
    'Use the button below to remind the Licensee to update sales in the Licensee Sales Portal.',
  APP_LVR_RESTAURANT_SALES_SEND_LINK: 'Send a Link',
  APP_LOADING_FINDINGS: 'Loading Findings...',
  APP_REASON_TEXT_ERROR: 'Reason must contain text.',
  APP_ADMIN_AMENDMENT_SUCCESS: 'Appeal Request Submitted Successfully.',
  APP_START_DATE: 'Start Date',
  APP_END_DATE: 'End Date',
  APP_CHOOSE_STORES: 'Choose Store(s)',
  APP_CHOOSE_FINDING: 'Choose Finding',
  APP_REASON_FOR_CHANGE: 'Reason for change',
  APP_REASON_PLACEHOLDER: 'Add a reason...',
  App_REASON_HELPERTEXT: 'Maximum 700 characters',
  APP_ADMIN_AMENDMENT_FORM: 'Admin Amendment Form',
  APP_ADMIN_AMMENDMENT_FORM_DESCRIPTION:
    'This form is used to make an amendment to the Smart Shop Report outside the 5 day window that Restaurants have to submit an Appeal. This form includes single store amendment or multiple store (bulk) amendment.',
  APP_REQUESTED_APPEAL_OPERATOR_COMMENTS: 'Operator Comments',
  APP_REQUESTED_APPEAL_ADMIN_COMMENTS: 'Admin Comments',
  APP_SEARCH: 'Search',
  APP_FINDING_PENDING_APPEAL: 'This finding has an appeal pending',
  APP_FINDING_ALREADY_APPEALED: 'This finding has already been appealed',
  APP_FINDING_HAS_PENDING_APPEAL: 'This finding has an appeal pending.',
  APP_FINDING_HAS_ALREADY_BEEN_APPEALED:
    'This finding has already been appealed. Check the Requested Appeals tab.',
  APP_REASON_FOR_APPEAL: 'Reason for Appeal',
  APP_DECISION_REASON: 'Decision Reason',
  APP_VISIT: 'Visit',
};

export default english;
