import {
  Button,
  Col,
  Dropdown,
  LoadingIndicator,
  Row,
  Typography,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  LVR_CLEAN_SAFE,
  LVR_GUEST_EXPERIENCE,
  LVR_GREAT_FOOD,
  LVR_OPERATOR_CONSULTING_NOTES,
  LVR_SORT_OPTIONS,
  LVR_STAFF,
} from '../../../constants/constants';
import FilterBoxContainer from '../../../components/common/FilterBox/FilterBoxContainer';
import I18n from '../../../../i18n/utils';
import './AllFindings.scss';
import AllFindingsCards from './AllFindingsCards/AllFindingsCards';
import { sortFindings } from './helpers';
import useLvrAssessment from '../../../utils/hooks/useLvrAssessment';

const AssessmentAllFindings = ({ reports = [], isFetchingReports = true }) => {
  const { assessmentId } = useParams();
  const { findings, setLvrHistory, isFetchingFindings } = useLvrAssessment();

  const [sortOption, setSortOption] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [selectedReport, setSelectedReport] = useState(null);
  const [filteredFindings, setFilteredFindings] = useState([]);

  const isLoading = isFetchingReports || isFetchingFindings;

  const categoriesFilterOptions = [
    {
      label: I18n.t('APP_LVR_FINDING_GUEST_EXPERIENCE'),
      value: LVR_GUEST_EXPERIENCE,
    },
    {
      label: I18n.t('APP_LVR_FINDING_GREAT_FOOD'),
      value: LVR_GREAT_FOOD,
    },
    {
      label: I18n.t('APP_LVR_FINDING_CLEAN_SAFE_ENVIRONMENT'),
      value: LVR_CLEAN_SAFE,
    },
    {
      label: I18n.t('APP_LVR_FINDING_STAFF'),
      value: LVR_STAFF,
    },
    {
      label: I18n.t('APP_LVR_FINDING_OPERATOR_CONSULTING_NOTES'),
      value: LVR_OPERATOR_CONSULTING_NOTES,
    },
  ];

  const riskLevelFilterOptions = [
    {
      value: 'Immediate Action',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_IMMEDIATE'),
    },
    {
      value: 'High',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_HIGH'),
    },
    {
      value: 'Medium',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_MEDIUM'),
    },
    {
      value: 'Low',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_LOW'),
    },
    {
      value: 'Informational',
      label: I18n.t('APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_INFORMATIONAL'),
    },
  ];

  const allFindingsFiltersSupplier = [
    {
      filterName: 'selectedRiskLevel',
      initialValue: null,
      filterLabel: `${I18n.t('APP_REPORT_DASHBOARD_FILTERS_PRIORITY')}`,
      filterType: 'dropdown',
      filterOptions: riskLevelFilterOptions,
    },
    {
      filterName: 'selectedCategory',
      initialValue: null,
      filterLabel: `${I18n.t('APP_REPORT_DASHBOARD_FILTERS_CATEGORY')}`,
      filterType: 'dropdown',
      filterOptions: categoriesFilterOptions,
    },
  ];

  const filterAuditFindings = () => {
    let filtered = [...findings];
    if (sortOption) {
      filtered = [...findings.sort(sortFindings(sortOption.value))];
    }

    if (appliedFilters.selectedCategory) {
      filtered = filtered.filter(
        (finding) =>
          finding.categoryName === appliedFilters.selectedCategory.value,
      );
    }

    if (appliedFilters.selectedRiskLevel) {
      filtered = filtered.filter(
        (finding) =>
          finding.riskLevel === appliedFilters.selectedRiskLevel.value,
      );
    }

    return filtered;
  };

  useEffect(() => {
    if (reports.length > 0) {
      const report = assessmentId
        ? reports.find((r) => r.assessmentId === Number(assessmentId))
        : reports[0];
      setSelectedReport(report);
      setLvrHistory(report);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  useEffect(() => {
    setFilteredFindings(filterAuditFindings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption, appliedFilters, findings]);

  return (
    <div>
      <Row className="all-findings__header">
        <Col lg={{ span: 4 }} md="4" xs="12">
          <Typography variant="h3">
            {I18n.t('APP_REPORT_DASHBOARD_FINDINGS_ALL')}
          </Typography>
          <Typography className="all-findings__findings-count">
            {`${findings.length} ${I18n.t(
              'APP_REPORT_DASHBOARD_FILTERS_FINDINGS',
            )}`}
          </Typography>
        </Col>
        <Col
          lg={{ span: 8 }}
          md="8"
          xs="12"
          className="all-findings__dropdown-n-filter-btn-container"
        >
          <Dropdown
            compact
            fullWidth
            value={sortOption}
            disabled={isLoading || reports.length === 0}
            options={LVR_SORT_OPTIONS}
            onChange={setSortOption}
            getOptionId={(option) => option.id}
            renderOption={(option) => option.label}
            className="all-findings__dropdown"
            placeholder={I18n.t(
              'APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_PLACEHOLDER',
            )}
            label={I18n.t('APP_REPORT_DASHBOARD_FILTERS_SORT_BY')}
          />
          <Dropdown
            compact
            fullWidth
            label="Date"
            options={reports}
            disabled={isLoading || reports.length === 0}
            value={selectedReport}
            className="all-findings__dropdown"
            getOptionId={(option) => option.id}
            renderOption={(option) => option.date}
            onChange={(newValue) => {
              setSelectedReport(newValue);
              setLvrHistory(newValue);
            }}
          />
          <Button
            className="all-findings__filter-btn"
            color="secondary"
            variant="outlined"
            size="lg"
            disabled={isLoading || reports.length === 0}
            onClick={() => setShowFilters(!showFilters)}
          >
            {I18n.t('APP_REPORT_DASHBOARD_FILTERS')}
          </Button>
        </Col>
      </Row>
      <FilterBoxContainer
        filterArray={allFindingsFiltersSupplier}
        applyFiltersCallback={setAppliedFilters}
        hideFilterBoxCallback={() => setShowFilters(false)}
        showFilterBox={showFilters}
      />
      {isLoading ? (
        <LoadingIndicator variant="page" />
      ) : (
        <div className="findings-list-container">
          {filteredFindings.length > 0 && (
            <AllFindingsCards findings={filteredFindings} />
          )}
        </div>
      )}
    </div>
  );
};

export default AssessmentAllFindings;
