import { Tooltip } from 'cfa-react-components';

const LevelLegendTooltip = ({ level, children, ...props }) => (
  <Tooltip
    {...props}
    content={level}
    showOnElementEvents={['hover']}
    placement="right"
  >
    {children}
  </Tooltip>
);

export default LevelLegendTooltip;
