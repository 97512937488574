import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveBar } from '@nivo/bar';
import { LoadingIndicator, Typography } from 'cfa-react-components';
import cloneDeep from 'lodash/cloneDeep';
import { baseTheme } from '../../constants/constants';

import I18n from '../../../i18n/utils';

import { getAuditSummaryDataGraph } from '../../actions/AuditActions';

import './TotalFindingsChart.scss';
import './RiskLevelBarChart.scss';

const theme = cloneDeep(baseTheme);

const riskLevelObj = {
  immediateFindings: {
    label: 'APP_REPORT_DASHBOARD_IMMEDIATE_FINDINGS_GRAPH_LABEL',
    level: 'Immediate Action',
  },
  highFindings: {
    label: 'APP_REPORT_DASHBOARD_HIGH_FINDINGS_GRAPH_LABEL',
    level: 'High',
  },
  mediumFindings: {
    label: 'APP_REPORT_DASHBOARD_MEDIUM_FINDINGS_GRAPH_LABEL',
    level: 'Medium',
  },
  lowFindings: {
    label: 'APP_REPORT_DASHBOARD_LOW_FINDINGS_GRAPH_LABEL',
    level: 'Low',
  },
};
function RiskLevelBarChart({ levelTitle, colors, isMobileView, ...props }) {
  const dispatch = useDispatch();
  const { loadingAuditSummaryGraph, auditSummaryGraphData } = useSelector(
    (state) => state.audit,
  );

  const riskLevelData = parseRiskLevelFindingGraph(
    auditSummaryGraphData,
    riskLevelObj[levelTitle].level,
  );
  const largestFindingsNumber = Math.max(
    ...riskLevelData.map((audit) => audit.totalRiskFinings),
  );
  const riskLevelGraphData = [
    {
      id: '01',
      data: riskLevelData,
    },
  ];

  // Component Did Mount
  useEffect(() => {
    if (auditSummaryGraphData.length === 0) {
      dispatch(getAuditSummaryDataGraph());
    }
  }, []);

  const tooltipMethod = (tooltipProps) => {
    const { data } = tooltipProps;
    const { totalRiskFinings } = data;
    const repeatFindings = data.repeatCount || 0;
    const newCounts = data.newCount || 0;

    return (
      <div className="risk-level-bar-graph__tooltip-container">
        <p className="risk-level-bar-graph__tooltip--title">
          Total {riskLevelObj[levelTitle].level} findings: {totalRiskFinings}
        </p>
        <p style={{ marginBottom: '0.43100em' }}>
          <span
            className="risk-level-bar-graph__tooltip--bullet"
            style={{ backgroundColor: colors[1] }}
          />
          Repeat: {repeatFindings}
        </p>
        <p>
          <span
            className="risk-level-bar-graph__tooltip--bullet"
            style={{ backgroundColor: colors[0] }}
          />
          New: {newCounts}
        </p>
      </div>
    );
  };

  return (
    <div {...props}>
      {loadingAuditSummaryGraph === true && (
        <div>
          <LoadingIndicator variant="inline" />
        </div>
      )}

      {loadingAuditSummaryGraph === false &&
        riskLevelGraphData[0]?.data?.length > 1 && (
          <div className="total-findings-chart__container">
            <Typography
              fontWeight="bold"
              className="total-findings-chart__left-axis-title"
            >
              {I18n.t(riskLevelObj[levelTitle].label)}
            </Typography>
            <div className="risk-level-bar-graph__graph-container">
              <ResponsiveBar
                data={riskLevelData}
                maxValue={
                  largestFindingsNumber > 10 ? largestFindingsNumber + 1 : 10
                }
                width={isMobileView ? 451 : 700}
                height={isMobileView ? 420 : 470}
                keys={['newCount', 'repeatCount']}
                indexBy="round"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={riskLevelData.length <= 3 ? 0.8 : 0.67}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={colors}
                axisTop={null}
                axisLeft={{
                  format: (axisValue) =>
                    Math.floor(axisValue) === axisValue && axisValue,
                  tickSize: 0,
                }}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: isMobileView ? 0 : 3,
                  tickPadding: isMobileView ? 8 : 15,
                  tickRotation: isMobileView ? -53 : 0,
                }}
                tooltip={tooltipMethod}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate
                motionStiffness={90}
                motionDamping={15}
                theme={theme}
              />
            </div>
          </div>
        )}

      {loadingAuditSummaryGraph === false &&
        riskLevelGraphData[0]?.data?.length <= 1 && <h1>Comming Soon</h1>}
    </div>
  );
}

export default RiskLevelBarChart;

RiskLevelBarChart.propTypes = {
  levelTitle: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobileView: PropTypes.bool,
};

function parseRiskLevelFindingGraph([...graphData], riskLevelType) {
  return graphData
    .map((data) => {
      return {
        round: data.round,
        totalRiskFinings: data[riskLevelType]?.nonResolvedTotalCount,
        newCount: data[riskLevelType].newCount,
        repeatCount: data[riskLevelType].repeatCount,
      };
    })
    .reverse();
}
