import './ConsultationRequest.scss';

export default function ConsultationRequest({
  handleConsultationTextInput,
  consultationRequestTextValue,
  disabledConsultationTextInput,
}) {
  return (
    <div
      className="consultation-request__container"
      data-testid="consultation-request-container"
    >
      <h4
        className="consultation-request__title"
        data-testid="consultation-request-title"
      >
        Question for the Food Safety Team
      </h4>
      <p
        className="consultation-request__sub-title"
        data-testid="consultation-request-sub-title"
      >
        Please enter your question below
      </p>
      <textarea
        className="consultation-request__input-box"
        data-testid="consultation-request-input-box"
        onChange={handleConsultationTextInput}
        value={consultationRequestTextValue}
        disabled={disabledConsultationTextInput}
      />
    </div>
  );
}
