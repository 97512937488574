import { ResponsiveBar } from '@nivo/bar';
import cloneDeep from 'lodash/cloneDeep';
import { Typography } from 'cfa-react-components';
import { baseTheme } from '../../../constants/constants';
import ChartLegend from '../common/ChartLegend';
import ChartContainer from '../common/ChartContainer';
import TooltipContainer from '../../common/TooltipContainer/TooltipContainer';

const theme = cloneDeep(baseTheme);

theme.axis.domain = {
  line: {
    stroke: '#ECEDEE',
    strokeWidth: 2,
  },
};
theme.axis.legend.text.fill = '#A7ACAF';

const findLongestWait = (chartData = []) => {
  let longestWait = 0;
  chartData.forEach((e) => {
    const localHighest = Math.abs(
      e['In line'] > e['To receive food'] ? e['In line'] : e['To receive food'],
    );
    if (localHighest > longestWait) {
      longestWait = localHighest;
    }
  });
  return longestWait;
};

const generateTickValues = (longestWait = 10) => {
  const evenNumbers = [];
  for (let i = 2; i <= longestWait; i += 2) {
    evenNumbers.push(i);
  }
  return evenNumbers;
};

// The date returned from the API is in the format of "6:07 PM Dec 04, 2023"
// This function will remove the time from the date string
const extractDate = (datetimeStr) => {
  const regex = /\d+:\d+\s+[APM]+/i;
  return datetimeStr.replace(regex, '').trim();
};

const legendKeys = [
  { label: 'In line', backgroundColor: '#5B6770' },
  { label: 'To receive food', backgroundColor: '#3EB1C8' },
];

const colorIcon = {
  width: '1rem',
  height: '1rem',
  marginRight: '.25rem',
  borderRadius: '0.625rem',
};

const toolTip = ({
  label = '',
  color = '#5B6770',
  id = 'In line',
  data = {},
}) => {
  const { waitTimeForFood = '00:00', waitTimeForService = '00:00' } = data;
  const value = id === 'In line' ? waitTimeForService : waitTimeForFood;
  return (
    <TooltipContainer data-testid="faas-chart-tooltip-container">
      <div
        data-testid="faas-chart-tooltip-container-sub"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          data-testid="faas-chart-tooltip-container-background-color"
          style={{ ...colorIcon, backgroundColor: color }}
        />

        <Typography data-testid="faas-chart-tooltip-label">{`${label} :`}</Typography>
        <Typography
          data-testid="faas-chart-tooltip-value"
          style={{
            fontWeight: 'bold',
            marginLeft: '.2rem',
          }}
        >
          {value}
        </Typography>
      </div>
    </TooltipContainer>
  );
};

const FAASChart = ({ isLoading = true, chartData = [] }) => {
  const longestWait = findLongestWait(chartData);
  const tickValues =
    longestWait === 1 ? [0, 1] : generateTickValues(longestWait);

  return (
    <ChartContainer
      data-testid="faas-chart-chart-container"
      isLoading={isLoading}
      isEmptyChart={chartData.length === 0}
      style={{ height: '31.25rem' }}
    >
      <div
        data-testid="faas-chart-chart-body"
        id="chart-body"
        style={{ height: '100%', marginLeft: '1rem' }}
      >
        <ChartLegend data-testid="faas-chart-chart-legend" keys={legendKeys} />
        <ResponsiveBar
          data-testid="faas-chart-responsive-bar"
          data={chartData}
          keys={['In line', 'To receive food']}
          indexBy="assessmentDate"
          groupMode="grouped"
          layout="horizontal"
          axisBottom={{
            tickValues,
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            truncateTickAt: 0,
            legend: 'Wait Time (mins)',
            legendPosition: 'middle',
            legendOffset: 45,
          }}
          axisLeft={{
            format: (value) => extractDate(value),
            tickSize: 0,
            tickPadding: 14,
            tickRotation: 0,
          }}
          gridXValues={tickValues}
          enableGridX
          enableGridY={false}
          enableLabel={false}
          motionStiffness={90}
          motionDamping={15}
          borderRadius={4}
          innerPadding={10}
          colors={['#003E59', '#3EB1C8']}
          theme={theme}
          margin={{ top: 10, right: 30, bottom: 70, left: 90 }}
          padding={0.4}
          tooltip={toolTip}
        />
      </div>
    </ChartContainer>
  );
};

export default FAASChart;
