import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_GET } from '../api/API';
import { addPropertiesToFinding } from '../../pages/SmartShop/helpers';

const domain = process.env.REACT_APP_SERVER_URL;

const useSmartShopFindings = (includeAll = false) => {
  const { assessmentId } = useParams();
  const [findings, setFindings] = useState([]);
  const [findingIds, setFindingIds] = useState([]);
  const [isLoadingFindings, setIsFetchingFindings] = useState(false);

  useEffect(() => {
    if (!assessmentId) return;
    let isMounted = true;
    const getData = async () => {
      try {
        setIsFetchingFindings(true);
        const path = `${domain}/assessment/${assessmentId}/responses`;
        const { data } = await API_GET(path);
        const nonGeneralInfoFindings = !includeAll
          ? [...data].filter(
              (x) =>
                x.questionSupplementaryData.category !==
                  'General Information' &&
                x.questionSupplementaryData.category !== 'SC - Informational',
            )
          : data;
        const allFindingIds = addPropertiesToFinding(nonGeneralInfoFindings);

        if (isMounted) {
          setFindings(nonGeneralInfoFindings);
          setFindingIds(allFindingIds);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) setIsFetchingFindings(false);
      }
    };
    getData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId]);

  return { findings, findingIds, isLoadingFindings };
};

export default useSmartShopFindings;
