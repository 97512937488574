import { Typography } from 'cfa-react-components';
import { FoodSafetyLabel } from '../../../../components/common/FoodSafetyLabel/FoodSafetyLabel';
import {
  lowercaseWithDashes,
  renderFindingColor,
  uppercaseWithUnderscores,
} from '../../../../utils/FindingsUtil';
import I18n from '../../../../../i18n/utils';
import './FindingOverview.scss';
import { formatDateMonthsAndFullYear } from '../../../../utils';
import { INFORMATIONAL_RISK_SHORT } from '../../../../constants/constants';

const FindingOverview = ({
  finding = {},
  compact = false,
  hasChildren = false,
}) => {
  return (
    <div className="finding-overview-container">
      <div>
        {!compact && (
          <div className="finding-overview-container__badges">
            <FoodSafetyLabel
              title={formatDateMonthsAndFullYear(finding.createdTimestamp)}
              type={INFORMATIONAL_RISK_SHORT}
              margin
            />
            <FoodSafetyLabel
              title={I18n.t(
                `APP_LVR_FINDING_${uppercaseWithUnderscores(
                  finding.categoryName,
                )}`,
              ).toUpperCase()}
              type={lowercaseWithDashes(finding.categoryName)}
              margin
            />
            <FoodSafetyLabel
              title={I18n.t(
                `APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_${renderFindingColor(
                  finding?.riskLevel,
                )?.toUpperCase()}`,
              )}
              type={lowercaseWithDashes(renderFindingColor(finding?.riskLevel))}
              margin
            />
            <FoodSafetyLabel
              title={finding.complianceStatus}
              type={INFORMATIONAL_RISK_SHORT}
              margin
            />
          </div>
        )}
        <Typography
          fontWeight="bold"
          variant={compact ? 'body2' : 'h3'}
          color={compact ? 'secondary' : 'default'}
        >
          {finding.questionText}
        </Typography>
        <div className="finding-overview-container__answer-container">
          <Typography
            fontWeight="bold"
            variant={compact ? 'body2' : 'h4'}
            color={compact ? 'secondary' : 'default'}
          >
            {I18n.t('APP_LVR_FINDING_DETAILS_RESPONSE_LABEL')}
          </Typography>
          <div className="finding-overview-container__answer-values">
            {finding?.responseData?.flattenAnswers?.length > 0 && (
              <ul>
                {finding?.responseData?.flattenAnswers?.map((answer, i) => (
                  <li key={i} style={{ color: compact ? '#004f71' : '' }}>
                    <Typography
                      variant={compact ? 'body2' : 'body1'}
                      color={compact ? 'secondary' : 'default'}
                    >
                      {answer}
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {hasChildren && (
          <Typography disabled style={{ fontStyle: 'italic' }}>
            Click to view additional findings
          </Typography>
        )}
      </div>
    </div>
  );
};

export default FindingOverview;
