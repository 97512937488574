import { Link } from 'react-router-dom';
import { Card, Col, Row, CardBody } from 'reactstrap';
import I18n from '../../../../i18n/utils';

import './PerfectAuditCard.scss';

const PerfectAuditCard = ({ selectedAudit, showFindingsLink }) => {
  return (
    <Row data-testid="perfect-audit-card-container-row">
      <Col className="d-block" data-testid="perfect-audit-card-d-block-col">
        <Card
          className="dashboard-card perfect-audit-card"
          data-testid="perfect-audit-card-dashboard-card"
        >
          <CardBody data-testid="perfect-audit-card-dashboard-card-body">
            <Row data-testid="perfect-audit-card-dashboard-card-body-row">
              <Col
                sm="2"
                className="perfect-audit-logo-col"
                data-testid="perfect-audit-card-logo-col"
              >
                <Row
                  className="perfect-audit-logo-row"
                  data-testid="perfect-audit-card-log-row"
                >
                  <img
                    src={
                      require('../../../assets/icons/check-circle.svg').default
                    }
                    width="72"
                    height="72"
                    className="check-circle-logo"
                    data-testid="perfect-audit-card-check-circle-logo"
                    alt="CFA Logo"
                  />
                </Row>
              </Col>
              <Col
                sm="10"
                data-testid="perfect-audit-card-perfect-audit-title-col"
              >
                <Row data-testid="perfect-audit-card-perfect-audit-title-row">
                  <h2 data-testid="perfect-audit-card-perfect-audit-title-text">
                    {I18n.t('APP_REPORT_DASHBOARD_PERFECT_AUDIT')}
                  </h2>
                </Row>
                {showFindingsLink && (
                  <Row data-testid="perfect-audit-card-view-past-findings-button-row">
                    <Link
                      className="view-past-findings-button"
                      data-testid="perfect-audit-card-view-past-findings-button-link"
                      to={`/${selectedAudit.locationNumber}/report-dashboard/${selectedAudit.round}/findings`}
                    >
                      {I18n.t('APP_REPORT_DASHBOARD_VIEW_PAST_FINDINGS')}
                    </Link>
                  </Row>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export { PerfectAuditCard };
