import './PerformanceLevelLegend.scss';

const PerformanceLevelLegend = () => {
  return (
    <div
      className="performance-level-graph__left-axis-container"
      data-testid="performance-level-legend-left-axis-container"
    >
      <div data-testid="performance-level-legend-left-axis-container-sub">
        <div
          className="performance-level-graph__left-axis-value-container--elite"
          data-testid="performance-level-legend-left-axis-value-container-elite"
        >
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-1"
          >
            1
          </p>
          <p data-testid="performance-level-legend-left-axis-text-elite">
            Elite
          </p>
        </div>

        <div
          className="performance-level-graph__left-axis-value-container--good"
          data-testid="performance-level-legend-left-axis-value-container-good"
        >
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-2"
          >
            2
          </p>
          <p data-testid="performance-level-legend-left-axis-text-good">Good</p>
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-3"
          >
            3
          </p>
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-4"
          >
            4
          </p>
        </div>

        <div
          className="performance-level-graph__left-axis-value-container--fair"
          data-testid="performance-level-legend-left-axis-value-container-fair"
        >
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-5"
          >
            5
          </p>
          <p data-testid="performance-level-legend-left-axis-text-fair">Fair</p>
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-6"
          >
            6
          </p>
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-7"
          >
            7
          </p>
        </div>

        <div
          className="performance-level-graph__left-axis-value-container--unsatisfactory"
          data-testid="performance-level-legend-left-axis-value-container-unsatisfactory"
        >
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-8"
          >
            8
          </p>
          <p data-testid="performance-level-legend-left-axis-text-unsatisfactory">
            Unsatisfactory
          </p>
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-9"
          >
            9
          </p>
        </div>

        <div
          className="performance-level-graph__left-axis-value-container--sub-standard"
          data-testid="performance-level-legend-left-axis-value-container-sub-standard"
        >
          <p
            className="performance-level-graph__left-axis-text-number"
            data-testid="performance-level-legend-left-axis-text-number-10"
          >
            10
          </p>
          <p data-testid="performance-level-legend-left-axis-text-sub-standard">
            Sub-Standard
          </p>
        </div>
      </div>
    </div>
  );
};

export default PerformanceLevelLegend;
