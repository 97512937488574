const isLocal = window.location.origin === 'http://localhost:4200';

// Method to track routes
export const trackPage = () => {
  // Don't track on localhost

  if (!isLocal) {
    // Using a settimeout to allow for GA to be instantiated in index.html when refrshing
    // a page that calls this method in its 'componentDidMount' lifecycle method
    setTimeout(() => {
      window.gtag('event', 'page_view', {
        page_location: window.location.pathname + window.location.search,
      });
    }, 500);
  }
};

// Method to actionable events
export const trackEvent = (action, userRole) => {
  // Don't track on localhost
  if (!isLocal) {
    // Action is a string, i.e. 'Print Report' or 'Pathway Links'
    setTimeout(() => {
      window.gtag('event', action, {
        user_role: userRole,
      });
    }, 500);
  }
};
