import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import I18n from '../../../i18n/utils';

import { getDateToday } from '../index';
import {
  IMMEDIATE_RISK_LONG,
  HIGH_RISK_LONG,
  MEDIUM_RISK_LONG,
  LOW_RISK_LONG,
  INFORMATIONAL_RISK_LONG,
  CLEANING_SANITATION,
  CROSS_CONTAMINATION,
  HEALTH_HYGIENE,
  PESTS,
  TIME_TEMPERATURE,
  renderPerformanceCategory,
} from '../../constants/constants';

import { store } from '../../store';

import apercuRegString from '../../assets/fonts/apercu-regular';
import apercuBoldString from '../../assets/fonts/apercu-bold';

const { vfs } = vfsFonts.pdfMake;
pdfMake.vfs = vfs;
pdfMake.vfs['Apercu-Regular.otf'] = apercuRegString;
pdfMake.vfs['Apercu-Bold.otf'] = apercuBoldString;
pdfMake.fonts = {
  ApercuRegular: {
    normal: 'Apercu-Regular.otf',
    bold: 'Apercu-Regular.otf',
    italics: 'Apercu-Regular.otf',
    bolditalics: 'Apercu-Regular.otf',
  },
  ApercuBold: {
    normal: 'Apercu-Bold.otf',
    bold: 'Apercu-Bold.otf',
    italics: 'Apercu-Bold.otf',
    bolditalics: 'Apercu-Bold.otf',
  },
};

const fetchImage = async (imgUrl) => {
  return fetch(imgUrl)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );
};

// Method to generate base64 img of the performance level UI chart.
// const getChartImgBase64 = (performaceLevelDomElement, elementHeight) => {
//   return html2canvas(performaceLevelDomElement, {
//     width: 554,
//     height: elementHeight,
//     scale: 0.51,
//     allowTaint: false,
//   }).then((canvas) => {
//     const imgBase64 = canvas.toDataURL('image/jpeg');
//     return imgBase64;
//   });
// };

// Method to build a PDF
export const buildPdfData = async () => {
  // Gather Export PDF Data
  const audit = store.getState().audit.selectedAudit;
  const { location } = store.getState().location.selectedLocation;
  const { needsImprovement } = store.getState().audit.auditSummary;
  const findings = store.getState().audit.selectedAuditFindings;
  const performanceLevelData = {
    performanceLevelNumber: audit.performanceLevel,
    ...renderPerformanceCategory(audit.performanceLevel),
  };

  //
  // 22-11-17 Commenting out history level chart for 1ts phase deployment to be updated later
  //

  // const performaceLevelDomElement = document.getElementById('peformance-level-chart-container');
  // const performaceHistoryLevelDomElement = document.getElementById('performance-history-chart');
  // const performaceLevelChartImgBase64 = performaceLevelDomElement !== null ?
  //   await getChartImgBase64(performaceLevelDomElement, 250).then(img64 => img64) : false;
  // const performaceHistoryLevelChartImgBase64 = performaceHistoryLevelDomElement !== null ?
  //   await getChartImgBase64(performaceHistoryLevelDomElement, 300).then(img64 => img64) : false;
  // const imageObj = {
  //   performaceLevelChartImgBase64,
  //   performaceHistoryLevelChartImgBase64
  // };
  // assemblePDF(auditData, imageObj);

  const auditData = {
    audit,
    location,
    findings,
    needsImprovement,
    performanceLevelData,
  };

  assemblePDF(auditData);
};

const assemblePDF = async (auditData) => {
  const imagePromises = [];
  // Init base template for PDF
  const pdfTemplate = {
    header: {
      columns: [
        // Store Information
        {
          ul: [
            {
              text: `${auditData.location.locationNumber} | ${auditData.location.locationName}`,
              font: 'ApercuBold',
              listType: 'none',
            },
            {
              text: `${auditData.location.address} ${auditData.location.city}, ${auditData.location.state} ${auditData.location.zip}`,
              listType: 'none',
            },
          ],
          style: 'left-header',
        },
        // Audit Date
        {
          ul: [
            {
              text: `${auditData.audit.round}`,
              font: 'ApercuBold',
              listType: 'none',
            },
            {
              text: getDateToday(),
              listType: 'none',
            },
          ],
          style: 'right-header',
        },
      ],
    },
    content: [
      // Performace Level chart
      {
        text: `${I18n.t('APP_REPORT_DASHBOARD_PERFORMANCE_LEVEL_LABEL')}`,
        style: 'findings-title',
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 0,
            x2: 515,
            y2: 0,
            lineWidth: 0.1,
            lineColor: '#8e969c',
          },
        ],
        margin: [0, 0, 0, 15],
      },

      {
        alignment: 'center',
        margin: [0, 20, 0, 0],
        // alignment: 'justify',
        columns: [
          {
            width: 200,
            text: '',
          },

          {
            width: '*',
            table: {
              alignment: 'center',
              heights: [70, 15],
              widths: [80, 'auto'],

              body: [
                [
                  {
                    text: `${auditData.performanceLevelData.performanceLevelNumber} \n`,
                    style: 'findings-title',
                    alignment: 'center',
                    fontSize: 34,
                    bold: true,
                    border: [true, true, true, false],
                  },
                ],
                [
                  {
                    text: `  ${auditData.performanceLevelData.category}  `,
                    fontSize: 14,
                    alignment: 'center',
                    border: [true, false, true, true],
                    color: '#ffffff',
                    fillColor: `${auditData.performanceLevelData.color}`,
                  },
                ],
              ],
            },
          },
          {
            width: '*',
            text: '',
          },
        ],
      },

      //
      // 22-11-17 Commenting out history level chart for phase 1 of the dashboard refresh deployment. To be updated later.
      //

      // {
      //   ...(imageObj.performaceLevelChartImgBase64 !== false && {
      //     image: imageObj.performaceLevelChartImgBase64, margin: [0, 10, 0, 5]
      //   })
      // },
      // Performace History Level Chart
      //
      // 22-11-17 Commenting out history level chart for 1ts phase deployment to be updated later
      //
      // {
      //   text: I18n.t('APP_REPORT_DASHBOARD_HISTORICAL_PERFORMANCE'),
      //   style: 'findings-title'
      // },
      // {
      //   canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.1, lineColor: '#8e969c' }], margin: [0, 0, 0, 15],
      // },
      // {
      //   ...(imageObj.performaceHistoryLevelChartImgBase64 !== false && { image: imageObj.performaceHistoryLevelChartImgBase64, margin: [0, 10, 0, 5] })
      // },
      // {
      //   text: `\n\n ${I18n.t('APP_REPORT_DASHBOARD_NEEDING_IMPROVEMENT')} \n`, style: 'findings-title',
      // },
      // {
      //   canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.1, lineColor: '#8e969c' }], margin: [0, 0, 0, 15],
      // },
      // {
      //   ...(auditData.needsImprovement?.length > 0 && {
      //     ul: [
      //       ...auditData.needsImprovement.map(improvement => {
      //         return {
      //           text: `${improvement.questionSafeReportId + ": " + improvement.description} \n`,
      //           style: 'finding-round',
      //         };
      //       })],
      //     margin: [0, 0, 0, 15],
      //     // pageBreak: 'after' ( This comment is here for future Reference jjir 2021-06-25)
      //   })
      // },

      // Findings Title
      {
        text: `${I18n.t('APP_PDF_EXPORT_FINDINGS_TITLE')}`,
        style: 'findings-title',
      },
      // Divider
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 0,
            x2: 515,
            y2: 0,
            lineWidth: 0.1,
            lineColor: '#8e969c',
          },
        ],
        margin: [0, 0, 0, 5],
      },
    ],
    images: {},
    footer: (page) => {
      return {
        columns: [
          {
            text: page.toString(),
            style: 'page-count-footer',
            alignment: 'right',
          },
        ],
        margin: [0, 0, 45, 0],
      };
    },
    // [left, top, right, bottom]
    padeSize: 'A4',
    pageMargins: [40, 60, 40, 60],
    styles: {
      // Header Styles
      'left-header': {
        alignment: 'left',
        width: 'auto',
        margin: [25, 25, 35, 50],
        fontSize: 11,
      },
      'right-header': {
        alignment: 'right',
        width: 'auto',
        margin: [25, 25, 35, 50],
        fontSize: 11,
      },
      'resources-header': {
        fontSize: 12,
        font: 'ApercuBold',
        margin: [0, 15, 0, 0],
      },
      'best-practices-header': {
        fontSize: 12,
        font: 'ApercuBold',
        margin: [0, 15, 0, 0],
      },
      'findings-title': {
        fontSize: 16,
        font: 'ApercuBold',
        margin: [0, 15],
      },
      // Findings Cards
      'finding-round': {
        margin: [0, 5],
      },
      'finding-category': {
        fontSize: 12,
        font: 'ApercuBold',
        margin: [0, 0],
      },
      'finding-description': {
        fontSize: 11,
        margin: [0, 5],
      },
      'finding-detail': {
        fontSize: 10.5,
        margin: [0, 2],
      },
      'finding-card': {
        background: '#FF0000',
      },
      'no-findings-message': {
        fontSize: 11,
        margin: [0, 5],
      },
      pathwayLink: {
        fontSize: 12,
        // alignment: 'center',
        font: 'ApercuBold',
        margin: [20, 0, 0, 30],
      },
      // Document Footer Styles
      'document-footer': {
        alignment: 'left',
        margin: [45, 0, 0, 0],
        fontSize: 9,
      },
      'page-count-footer': {
        alignment: 'right',
        fontSize: 9,
      },
    },
    defaultStyle: {
      fontSize: 12,
      font: 'ApercuRegular',
      color: '#5b6770',
    },
  };

  // Loop Thru and append Findings
  if (auditData.findings.length) {
    auditData.findings.forEach((finding) => {
      pdfTemplate.content.push(
        // Finding Card
        {
          columns: [
            {
              text: getRiskLevelTranslation(finding.riskLevel),
              style: {
                // Custom colors based on risk level
                color: getFindingColor(finding.riskLevel),
                font: 'ApercuBold',
                margin: [0, 20],
              },
              alignment: 'left',
            },
            {
              text: finding.auditRound,
              style: 'finding-round',
              alignment: 'right',
            },
          ],
        },
        {
          text: getCategoryTranslation(finding.category.name),
          style: 'finding-category',
        },
        {
          text: finding.description,
          style: 'finding-description',
        },
      );

      if (finding.findingHistory) {
        // Loop thru and add history details
        finding.findingHistory.forEach((detail) => {
          if (detail.details) {
            pdfTemplate.content.push(
              // Space for multiple detail sets
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 515,
                    y2: 0,
                    lineWidth: 0.1,
                    lineColor: '#FFFFFF',
                  },
                ],
                margin: [0, 0, 0, 5],
              },
              // Quarter
              {
                ...(detail.round && {
                  text: [
                    {
                      text: `${I18n.t(
                        'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_QUARTER',
                      )}: `,
                      font: 'ApercuBold',
                    },
                    { text: detail.round },
                  ],
                  style: 'finding-detail',
                }),
              },
              // Detail Id
              {
                ...(detail.details.safeDetailId && {
                  text: [
                    {
                      text: `${I18n.t(
                        'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_SUBQUESTION_ID',
                      )}: `,
                      font: 'ApercuBold',
                    },
                    { text: detail.details.safeDetailId },
                  ],
                  style: 'finding-detail',
                }),
              },
              // Zone
              {
                ...(detail.details.standard && {
                  text: [
                    {
                      text: `${I18n.t(
                        'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ZONE',
                      )}: `,
                      font: 'ApercuBold',
                    },
                    { text: detail.zone },
                  ],
                  style: 'finding-detail',
                }),
              },
              // Temp
              {
                ...(detail.details.temp && {
                  text: [
                    {
                      text: `${I18n.t(
                        'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_TEMP',
                      )}: `,
                      font: 'ApercuBold',
                    },
                    { text: detail.details.temp },
                  ],
                  style: 'finding-detail',
                }),
              },
              // Item
              {
                ...(detail.item && {
                  text: [
                    {
                      text: `${I18n.t(
                        'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ITEM',
                      )}: `,
                      font: 'ApercuBold',
                    },
                    { text: detail.item },
                  ],
                  style: 'finding-detail',
                }),
              },
              // Open Comments
              {
                ...(detail.details.openComments && {
                  text: [
                    {
                      text: `${I18n.t(
                        'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OPEN_COMMENTS',
                      )}: `,
                      font: 'ApercuBold',
                    },
                    { text: detail.details.openComments },
                  ],
                  style: 'finding-detail',
                }),
              },
              // Issue
              {
                ...(detail.issue && {
                  text: [
                    {
                      text: `${I18n.t(
                        'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ISSUE',
                      )}: `,
                      font: 'ApercuBold',
                    },
                    { text: detail.issue },
                  ],
                  style: 'finding-detail',
                }),
              },
              // Other Information
              {
                ...(detail.details.otherInformation && {
                  text: [
                    {
                      text: `${I18n.t(
                        'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OTHER_INFORMATION',
                      )}: `,
                      font: 'ApercuBold',
                    },
                    { text: detail.details.otherInformation },
                  ],
                  style: 'finding-detail',
                }),
              },
            );
          }
          // add finding images if they exist
          if (detail.imageList) {
            detail.imageList.forEach((imgUrl, index) => {
              pdfTemplate.content.push({
                image: `${detail.details.safeDetailId}${index}`,
                width: 350,
                margin: [0, 0, 0, 5], // add a bottom margin of 5
              });
              imagePromises.push(
                fetchImage(imgUrl).then(
                  // TODO: review disabled eslint rule
                  // eslint-disable-next-line no-return-assign
                  (img) =>
                    (pdfTemplate.images[
                      `${detail.details.safeDetailId}${index}`
                    ] = img),
                ),
              );
            });
          }
          // display pathway Link per Detail Card
          if (detail.pathwayLinks) {
            pdfTemplate.content.push(
              {
                text: 'Resources',
                style: 'resources-header',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 58,
                    y2: 0,
                    lineWidth: 0.1,
                    lineColor: '#8e969c',
                  },
                ],
                margin: [0, 0, 0, 15],
              },
            );
            detail.pathwayLinks.forEach((pwayLink) => {
              pdfTemplate.content.push({
                columns: [
                  {
                    text: [
                      {
                        text: pwayLink.pathwayName,
                        link: pwayLink.pathwayURL,
                        font: 'ApercuBold',
                      },
                    ],
                    margin: [0, 10],
                  },
                  { qr: pwayLink.pathwayURL, fit: '35', width: 37 },
                ],
                margin: [0, 0, 0, 5], // add a bottom margin of 5
              });
            });
          }
          if (finding.bestPracticeLinks.length !== 0) {
            pdfTemplate.content.push(
              {
                text: 'Best Practices',
                style: 'best-practices-header',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 80,
                    y2: 0,
                    lineWidth: 0.1,
                    lineColor: '#8e969c',
                  },
                ],
                margin: [0, 0, 0, 15],
              },
            );
            finding.bestPracticeLinks.forEach((bpLink) => {
              pdfTemplate.content.push({
                columns: [
                  {
                    text: [
                      {
                        text: bpLink.title,
                        link: bpLink.url,
                        font: 'ApercuBold',
                      },
                    ],
                    margin: [0, 10],
                  },
                  { qr: bpLink.url, fit: '35', width: 37 },
                ],
                margin: [0, 0, 0, 5], // add a bottom margin of 5
              });
            });
          }
        });
      }

      // Divider
      pdfTemplate.content.push({
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 0,
            x2: 515,
            y2: 0,
            lineWidth: 0.1,
            lineColor: '#8e969c',
          },
        ],
        margin: [0, 10, 0, 5],
      });
    });
  } else {
    // No findings data, detail that
    pdfTemplate.content.push({
      text: I18n.t('APP_PDF_EXPORT_NO_FINDINGS'),
      style: 'no-findings-message',
    });
  }

  const filename = `${auditData.audit.locationNumber} - ${auditData.audit.round} Food Safety Report.pdf`;

  await Promise.all(imagePromises);

  // Send Generated PDF to Download Method
  downloadReportPDF(pdfTemplate, filename);
};

// Method to Download PDF once template is generated
const downloadReportPDF = (pdfTemplate, filename) => {
  pdfMake.createPdf(pdfTemplate).download(filename);
};

// Translate risk level of findings to shortened css class string
const getFindingColor = (type) => {
  return {
    [IMMEDIATE_RISK_LONG]: '#6e3b3b',
    [HIGH_RISK_LONG]: '#af272f',
    [MEDIUM_RISK_LONG]: '#d18d2a',
    [LOW_RISK_LONG]: '#d18d2a',
    [INFORMATIONAL_RISK_LONG]: '#004f71',
  }[type];
};

const getRiskLevelTranslation = (type) => {
  return {
    [IMMEDIATE_RISK_LONG]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_IMMEDIATE',
    )}`,
    [HIGH_RISK_LONG]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_HIGH',
    )}`,
    [MEDIUM_RISK_LONG]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_MEDIUM',
    )}`,
    [LOW_RISK_LONG]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_LOW',
    )}`,
    [INFORMATIONAL_RISK_LONG]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_INFORMATIONAL',
    )}`,
  }[type];
};

const getCategoryTranslation = (type) => {
  return {
    [CLEANING_SANITATION]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_CLEANING_SANITATION',
    )}`,
    [CROSS_CONTAMINATION]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_CROSS_CONTAMINATION',
    )}`,
    [HEALTH_HYGIENE]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_HEALTH_HYGIENE',
    )}`,
    [PESTS]: `${I18n.t('APP_REPORT_DASHBOARD_FINDINGS_PESTS')}`,
    [TIME_TEMPERATURE]: `${I18n.t(
      'APP_REPORT_DASHBOARD_FINDINGS_TIME_TEMPERATURE',
    )}`,
  }[type];
};
