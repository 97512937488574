/* eslint-disable react/destructuring-assignment */
// TODO: review disabled eslint rules
import { Component } from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import './HealthAndHygiene.scss';
import { Card, Col, Container, Row } from 'reactstrap';
import NavHeader from '../../components/NavHeader/NavHeader';
import I18n from '../../../i18n/utils';
import { BackArrow } from '../../components/common';
import CFASpinner from '../../components/Spinner/CFASpinner';
import { trackEvent } from '../../utils';

const HEALTH_PROMPT_DOMAIN = process.env.REACT_APP_HEALTH_PROMPT_URL;

class HealthAndHygiene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocation: null,
      loading: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const update = {};

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    // Update User Token
    if (props.userToken !== state.userToken) {
      update.userToken = props.userToken;
    }

    // Update Selected Location
    if (props.selectedLocation !== state.selectedLocation) {
      update.selectedLocation = props.selectedLocation;
    }

    return update;
  }
  render() {
    return (
      <>
        <NavHeader
          data-testid="health-and-hygiene-nav-header"
          buildLocationChangeRedirectUrl={(locationNumber) =>
            `/${locationNumber}/health-and-hygiene`
          }
        />
        <Container
          className="HealthAndHygiene"
          data-testid="health-and-hygiene-health-and-hygiene-container"
        >
          <BackArrow
            data-testid="health-and-hygiene-app-report-dashboard-back-to-home-back-arrow"
            title={I18n.t('APP_REPORT_DASHBOARD_BACK_TO_HOME')}
            path={`/${this.state.selectedLocation?.locationNumber}/landing`}
          />
          <Row data-testid="health-and-hygiene-app-health-and-hygiene-title-row">
            <Col data-testid="health-and-hygiene-app-health-and-hygiene-title-col">
              <h2 data-testid="health-and-hygiene-app-health-and-hygiene-title">
                {I18n.t('APP_HEALTH_AND_HYGIENE_TITLE')}
              </h2>
            </Col>
          </Row>
          <Row data-testid="health-and-hygiene-health-prompt-row">
            {this.state.userToken && this.state.userToken.healthPrompt ? (
              <Col data-testid="health-and-hygiene-health-prompt-col">
                <a
                  href={`${HEALTH_PROMPT_DOMAIN}?locationNumber=${this.state.selectedLocation?.locationNumber}`}
                  target="_blank"
                  rel="noreferrer"
                  className="container"
                  data-testid="health-and-hygiene-health-prompt-container"
                  onClick={() => {
                    if (this.state?.userToken?.userRole) {
                      trackEvent(
                        'health-prompt-link-clicked',
                        this.state.userToken.userRole,
                      );
                    }
                  }}
                >
                  <Card
                    className="app-card"
                    data-testid="health-and-hygiene-health-prompt-app-card"
                  >
                    <h3 data-testid="health-and-hygiene-health-prompt-app-health-and-hygiene-health-prompt">
                      {I18n.t('APP_HEALTH_AND_HYGIENE_HEALTH_PROMPT')}
                    </h3>
                    <Col
                      className="container"
                      data-testid="health-and-hygiene-health-prompt-container"
                    >
                      <img
                        alt=""
                        className="app-card-icon"
                        data-testid="health-and-hygiene-health-prompot-app-card-icon"
                        src={require('../../assets/icons/mobile.svg').default}
                      />
                    </Col>
                  </Card>
                </a>
              </Col>
            ) : (
              <CFASpinner data-testid="health-and-hygiene-cfa-spinner" margin />
            )}
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { userToken } = state.auth;
  const { selectedLocation } = state.location;
  return { userToken, selectedLocation };
};

export default withOktaAuth(connect(mapStateToProps, null)(HealthAndHygiene));
