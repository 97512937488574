import {
  SEND_SELF_ASSESSMENT,
  SEND_SELF_ASSESSMENT_SUCCESS,
  SEND_SELF_ASSESSMENT_FAILURE,
  //
  UPDATE_SELECTED_SAFEQUESTION_PUT,
  UPDATE_SELECTED_SAFEQUESTION_SUCCESS,
  UPDATE_SELECTED_SAFEQUESTION_FAILURE,
  //
  GET_VENDOR_QUESTIONS,
  GET_VENDOR_QUESTIONS_SUCCESS,
  GET_VENDOR_QUESTIONS_FAILURE,
  //
  UPDATE_SELECTED_ROUND,
  //
  CLEAR_ADMIN_STORE,
} from '.';

import {
  showFullTopSuccessNotification,
  showFailureNotification,
} from '../utils/Notification';
import { API_POST, API_GET } from '../utils';
import history from '../utils/history';
import {
  GET_ALL_QUESTIONS,
  GET_ALL_QUESTIONS_SUCCESS,
  GET_ALL_QUESTIONS_FAILURE,
  GET_SELECTED_SAFEQUESTION_DETAILS,
  GET_SELECTED_SAFEQUESTION_DETAILS_SUCCESS,
  SET_SELECTED_SAFEQUESTION,
} from './types/Admin.types';
import I18n from '../../i18n/utils';

const SELF_ASSESSMENT_BASE_URL =
  process.env.REACT_APP_SELF_ASSESSMENT_API_BASE_URL;
const FOOD_SAFETY_API_BASE_URL = process.env.REACT_APP_SERVER_URL;

export const sendSelfAssessmentChainPrompt = (formData) => {
  return async (dispatch) => {
    dispatch({ type: SEND_SELF_ASSESSMENT });
    // Place it in the params
    const path = `${SELF_ASSESSMENT_BASE_URL}/closure/chain`;
    // Set the body for the request
    const body = formData;

    try {
      const { data } = await API_POST(path, body);
      const { message } = data;
      dispatch({ type: SEND_SELF_ASSESSMENT_SUCCESS, payload: message });
    } catch (error) {
      dispatch({ type: SEND_SELF_ASSESSMENT_FAILURE, error });
    }
  };
};

export const sendMultipleSelfAssessmentPrompts = (formData) => {
  return async (dispatch) => {
    dispatch({ type: SEND_SELF_ASSESSMENT });
    // Separate location_number from body
    const { selectedLocations } = formData;

    // Loop through all selected locations and send a req
    for (let i = 0; i < selectedLocations.length; i++) {
      const locationNumber = selectedLocations[i].label;
      // Build the path and body for the request
      const path = `${SELF_ASSESSMENT_BASE_URL}/closure/location/${locationNumber}`;
      const { ...body } = formData;

      try {
        // TODO: review disabled eslint rule
        // eslint-disable-next-line no-await-in-loop
        const { data } = await API_POST(path, body);
        if (selectedLocations.length === i + 1) {
          const { message } = data;
          dispatch({ type: SEND_SELF_ASSESSMENT_SUCCESS, payload: message });
          return data;
        }
      } catch (error) {
        dispatch({ type: SEND_SELF_ASSESSMENT_FAILURE, error });
        // Break loop
        return error;
      }
    }
  };
};

export const getSelectedRoundQuestions = (round) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_QUESTIONS });
    dispatch({ type: UPDATE_SELECTED_ROUND, payload: round });

    // Assemble URL Path
    const path =
      round.value === 'ineffective'
        ? `${FOOD_SAFETY_API_BASE_URL}/question/ineffective`
        : `${FOOD_SAFETY_API_BASE_URL}/question?round=${round.value}`;

    try {
      const { data } = await API_GET(path);
      dispatch({ type: GET_ALL_QUESTIONS_SUCCESS, payload: data });
      // Set selected question, if list has questions
      if (data.length) {
        const selectedQuestion = data[0];
        dispatch(setSelectedQuestion(selectedQuestion));
      }
    } catch (err) {
      dispatch({ type: GET_ALL_QUESTIONS_FAILURE });
    }
  };
};

export const setSelectedQuestion = (question) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_SAFEQUESTION, payload: question });
    // Auto-fetch the selected findings of am audit
    dispatch(getSelectedQuestionDetails(question));
  };
};

// Fetch details of selected question from Redux
export const getSelectedQuestionDetails = (safeReportID) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_SELECTED_SAFEQUESTION_DETAILS });

    // TODO: We need to check for existence of getState().admin.allQuestions before trying to access
    const data = getState().admin.allQuestions.find(
      (question) => question.safeReportId === safeReportID,
    );
    dispatch({
      type: GET_SELECTED_SAFEQUESTION_DETAILS_SUCCESS,
      payload: data,
    });
    return data;
  };
};

export const clearAssessmentPrompt = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ADMIN_STORE });
  };
};

export const updateSelectedQuestion = (form) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SELECTED_SAFEQUESTION_PUT });
    const url = `${FOOD_SAFETY_API_BASE_URL}/question/${form.questionUuid}`;

    try {
      await API_POST(url, form);
      dispatch({ type: UPDATE_SELECTED_SAFEQUESTION_SUCCESS });
      showFullTopSuccessNotification({
        message: I18n.t('APP_ADMIN_QUESTION_UPDATE_SUCCESS_NOTIFICATION'),
      });
      history.goBack();
    } catch (err) {
      console.log(`Failed to Update ${form.questionUuid}: `, err);
      dispatch({ type: UPDATE_SELECTED_SAFEQUESTION_FAILURE });
      showFailureNotification({
        message: I18n.t('APP_ADMIN_QUESTION_UPDATE_FAILURE_NOTIFICATION'),
      });
    }
  };
};

export const getVendorQuestions = () => {
  return async (dispatch) => {
    dispatch({ type: GET_VENDOR_QUESTIONS });

    try {
      const { data: hdiData } = await API_GET(
        `${FOOD_SAFETY_API_BASE_URL}/vendor-question/search?serviceName=HDI`,
      );
      const { data: sdcData } = await API_GET(
        `${FOOD_SAFETY_API_BASE_URL}/vendor-question/search?serviceName=SDC`,
      );
      const payloadData = {
        hdiQuestions: hdiData.map((hdiQuestion) => hdiQuestion.externalId),
        sdcQuestions: sdcData.map((sdcQuestion) => sdcQuestion.externalId),
      };

      dispatch({ type: GET_VENDOR_QUESTIONS_SUCCESS, payload: payloadData });
    } catch (err) {
      console.log(`Failed to Get Vendor Questions`, err);
      dispatch({ type: GET_VENDOR_QUESTIONS_FAILURE });
    }
  };
};
