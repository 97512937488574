import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { trackEvent } from '../../../utils';
import I18n from '../../../../i18n/utils';
import useAuditSummary from '../../../utils/hooks/useAuditSummary';

import CancelNextButtons from './CancelNextButtons';

import './ConsultationQuestion.scss';

const ConsultationQuestion = ({ findingTitle, handleCancel, handleNext }) => {
  const { inquiryDeadlineDate, canSubmitInquiries } = useAuditSummary();

  const { userToken } = useSelector((state) => state.auth);

  const [answerState, setAnswerState] = useState({
    yes: undefined,
    no: undefined,
    answer: undefined,
  });

  const handleYesBtn = () => {
    trackEvent('clicked-ask-consultation-question-btn', userToken.userRole);
    setAnswerState({ yes: !answerState.yes, no: false, answer: 'YES' });
  };

  const handleNoBtn = () => {
    if (!canSubmitInquiries) return;
    trackEvent('clicked-proceed-appeal-request-btn', userToken.userRole);
    setAnswerState({ yes: false, no: !answerState.no, answer: 'NO' });
  };

  const isNextButtonDisabled = useMemo(() => {
    if (answerState.yes) return false;
    return (
      !canSubmitInquiries ||
      (answerState.yes === undefined && answerState.no === undefined)
    );
  }, [answerState?.no, answerState?.yes, canSubmitInquiries]);

  return (
    <div data-testid="consultation-question-container">
      <p
        className="consultation-question__finding-title"
        data-testid="consultation-question-finding-title"
      >
        {findingTitle}
      </p>
      <p
        className="consultation-question__question-text"
        data-testid="consultation-question-question-text"
      >
        Is there an aspect of this procedure you would like to better understand
        with consultation from the Food Safety Team, or would you like to
        proceed with submitting an appeal?
      </p>

      <div
        className="consultation-question__button-container"
        data-testid="consultation-question-button-container"
      >
        <button
          type="button"
          className={`consultation-question__button ${
            answerState.yes ? 'consultation-question__button--active' : ''
          }`}
          data-testid="consultation-question-ask-food-safety-team-question-button"
          onClick={handleYesBtn}
        >
          Ask the Food Safety Team a question
        </button>
        <button
          type="button"
          id="appeal-request-button"
          className={`consultation-question__button ${
            answerState.no ? 'consultation-question__button--active' : ''
          }`}
          data-testid="consultation-question-proceed-with-appeal-request-button"
          onClick={handleNoBtn}
        >
          Proceed with Appeal Request
        </button>
        {!canSubmitInquiries && (
          <UncontrolledTooltip
            data-testid="consultation-question-uncontrolled-tooltip"
            placement="top"
            target="appeal-request-button"
          >
            {I18n.t('APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_PASSED_DEADLINE', {
              deadlineDate: inquiryDeadlineDate,
            })}
          </UncontrolledTooltip>
        )}
      </div>

      <CancelNextButtons
        data-testid="consultation-question-cancel-next-buttons"
        handleCancel={handleCancel}
        handleNext={handleNext('CONSULTATION_QUESTION', answerState.answer)}
        isDisabled={isNextButtonDisabled}
      />
    </div>
  );
};

ConsultationQuestion.propTypes = {
  findingTitle: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default ConsultationQuestion;
