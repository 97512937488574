import { API_GET } from '../../../utils/api/API';
import { convertQueriesToString } from '../utils/dataUtils';

export const getPdfDownloadUrls = async ({ locationNumber, assessmentId }) => {
  try {
    const response = await API_GET(
      `${process.env.REACT_APP_SERVER_URL}/assessment/lvr/pdf/download/${locationNumber}/${assessmentId}`,
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAssessments = async ({
  locationNumber,
  program = null,
  formGroups = null,
}) => {
  const queryString =
    program || formGroups
      ? convertQueriesToString({
          locationNumbers: locationNumber,
          programs: program,
          formGroups,
        })
      : '';

  try {
    const response = await API_GET(
      `${process.env.REACT_APP_SERVER_URL}/assessment${queryString}`,
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
