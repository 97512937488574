import {
  GET_ALL_AUDITS,
  GET_ALL_AUDITS_SUCCESS,
  GET_ALL_AUDITS_SUCCESS_EMPTY,
  GET_ALL_AUDITS_FAILURE,
  //
  GET_AUDIT_SUMMARY,
  GET_AUDIT_SUMMARY_SUCCESS,
  GET_AUDIT_SUMMARY_FAILURE,
  //
  SET_SELECTED_AUDIT,
  //
  GET_SELECTED_AUDIT_FINDINGS,
  GET_SELECTED_AUDIT_FINDINGS_SUCCESS,
  GET_SELECTED_AUDIT_FINDINGS_FAILURE,
  //
  SET_SELECTED_FINDING,
  //
  GET_SELECTED_FINDING_DETAILS,
  GET_SELECTED_FINDING_DETAILS_SUCCESS,
  GET_SELECTED_FINDING_DETAILS_FAILURE,
  //
  GET_LEAVE_BEHIND_REPORT_PDF,
  GET_LEAVE_BEHIND_REPORT_PDF_SUCCESS,
  GET_LEAVE_BEHIND_REPORT_PDF_FAILURE,
  //
  SET_SELECTED_LOCATION_AMENDMENTS,
  //
  CLEAR_AUDIT_SELECTION,
  //
  END_USER_SESSION,
  //
  GET_HISTORICAL_ACTIONS,
  GET_HISTORICAL_ACTIONS_SUCCESS,
  GET_HISTORICAL_ACTIONS_FAILURE,
  //
  GET_SELECTED_HISTORICAL_ACTIONS,
  GET_SELECTED_HISTORICAL_ACTIONS_SUCCESS,
  GET_SELECTED_HISTORICAL_ACTIONS_FAILURE,
  //
  PUT_HISTORICAL_ACTION,
  PUT_HISTORICAL_ACTION_SUCCESS,
  PUT_HISTORICAL_ACTION_FAILURE,
  CLEAR_SELECTED_AUDIT_FINDINGS,
  GET_FINDINGS_SUMMARY,
  GET_FINDINGS_SUMMARY_SUCCESS,
} from '../actions';

const defaultArrayValue = [];

const initialState = {
  loading: false,
  auditSummaryLoading: null,
  selectedAuditFindingsLoading: false,
  allAudits: defaultArrayValue,
  allAuditAmendments: null,
  selectedAuditDiff: null,
  selectedLocationAmendments: null,
  selectedAudit: null,
  selectedAuditFindings: [],
  selectedFinding: null,
  selectedFindingDetails: null,
  auditSummary: null,
  historicalActions: null,
  selectedHistoricalActions: null,
  hasFoodSafetyAudits: true,
  error: null,
  auditRound: '',
  locationNumber: '',
  locationAuditsLoading: false,
  auditSummaryGraphData: [],
  loadingAuditSummaryGraph: false,
};

const doActions = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_AUDITS:
      return {
        ...state,
        loading: true,
        locationAuditsLoading: true,
        error: null,
      };

    case GET_ALL_AUDITS_SUCCESS:
      return {
        ...state,
        loading: false,
        locationAuditsLoading: false,
        allAudits: action.payload,
        hasFoodSafetyAudits: true,
        error: null,
      };

    case GET_ALL_AUDITS_SUCCESS_EMPTY:
      return {
        ...state,
        loading: false,
        locationAuditsLoading: false,
        allAudits: action.payload,
        hasFoodSafetyAudits: false,
        error: null,
      };

    case GET_ALL_AUDITS_FAILURE:
      return {
        ...state,
        loading: false,
        locationAuditsLoading: false,
        allAudits: defaultArrayValue,
        error: action.payload,
      };

    case GET_AUDIT_SUMMARY:
      return {
        ...state,
        auditSummaryLoading: true,
        error: null,
      };

    case GET_AUDIT_SUMMARY_SUCCESS:
      return {
        ...state,
        auditSummaryLoading: false,
        auditSummary: action.payload,
        hasFoodSafetyAudits: true,
        error: null,
      };

    case GET_AUDIT_SUMMARY_FAILURE:
      return {
        ...state,
        auditSummaryLoading: false,
        auditSummary: null,
        error: action.payload,
      };

    case SET_SELECTED_AUDIT:
      return {
        ...state,
        selectedAudit: action.payload,
      };

    case GET_SELECTED_AUDIT_FINDINGS:
      return {
        ...state,
        selectedAuditFindings: [],
        selectedAuditFindingsLoading: true,
        error: null,
        loading: true,
      };

    case GET_SELECTED_AUDIT_FINDINGS_SUCCESS:
      return {
        ...state,
        selectedAuditFindingsLoading: false,
        selectedAuditFindings: action.payload,
        hasFoodSafetyAudits: true,
        error: null,
        auditRound: action.payload[0]?.auditRound,
        locationNumber: action.payload[0]?.locationNumber,
        loading: false,
      };

    case GET_SELECTED_AUDIT_FINDINGS_FAILURE:
      return {
        ...state,
        selectedAuditFindingsLoading: false,
        selectedAuditFindings: [],
        error: true,
        loading: false,
      };

    case CLEAR_SELECTED_AUDIT_FINDINGS:
      return {
        ...state,
        selectedAuditFindings: [],
        historicalActions: null,
      };

    case SET_SELECTED_FINDING:
      return {
        ...state,
        selectedFinding: action.payload,
        error: null,
      };

    case GET_SELECTED_FINDING_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SELECTED_FINDING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedFindingDetails: action.payload,
        hasFoodSafetyAudits: true,
        error: null,
      };

    case GET_SELECTED_FINDING_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedFindingDetails: null,
        error: action.payload,
      };

    case GET_LEAVE_BEHIND_REPORT_PDF:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case GET_LEAVE_BEHIND_REPORT_PDF_SUCCESS:
    case GET_LEAVE_BEHIND_REPORT_PDF_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case SET_SELECTED_LOCATION_AMENDMENTS:
      return {
        ...state,
        selectedLocationAmendments: action.payload,
        error: null,
      };

    case CLEAR_AUDIT_SELECTION:
      return {
        ...initialState,
      };

    case END_USER_SESSION:
      return {
        ...initialState,
      };

    case GET_HISTORICAL_ACTIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_HISTORICAL_ACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        historicalActions: action.payload,
      };

    case GET_HISTORICAL_ACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        historicalActions: null,
      };

    case GET_SELECTED_HISTORICAL_ACTIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_SELECTED_HISTORICAL_ACTIONS_SUCCESS:
      return {
        ...state,
        selectedHistoricalActions: action.payload,
        loading: false,
      };

    case GET_SELECTED_HISTORICAL_ACTIONS_FAILURE:
      return {
        ...state,
        selectedHistoricalActions: null,
        loading: false,
      };
    //
    case PUT_HISTORICAL_ACTION:
      return {
        ...state,
        loading: true,
      };

    case PUT_HISTORICAL_ACTION_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case PUT_HISTORICAL_ACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_FINDINGS_SUMMARY:
      return {
        ...state,
        loadingAuditSummaryGraph: true,
      };

    case GET_FINDINGS_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingAuditSummaryGraph: false,
        auditSummaryGraphData: action.payload,
      };

    default:
      return state;
  }
};
export default doActions;
