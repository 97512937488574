import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Typography } from 'cfa-react-components';
import dayjs from 'dayjs';
import I18n from '../../../i18n/utils';
import useSmartShopAdminFindings from '../../utils/hooks/useSmartShopAdminFindings';

import './AppealModal.scss';

const AppealModal = ({
  cancelClicked,
  appeal,
  modalType,
  handleAction,
  isOpen,
}) => {
  const [questionOptionsLoading, setQuestionOptionsLoading] = useState(true);
  const [questionOptionsList, setQuestionOptionsList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [commentInput, setInputValue] = useState('');
  const { getFindings } = useSmartShopAdminFindings();

  useEffect(() => {
    let isMounted = true;
    if (isOpen === true && modalType === 'Modify') {
      getFindings(
        dayjs(appeal.responseInspectionTimestamp).startOf('month'),
        dayjs(appeal.responseInspectionTimestamp).add(1, 'month'),
      ).then((findings) => {
        if (Array.isArray(findings)) {
          const optionsList = findings
            .map(({ questionInternalId, label }) => {
              return {
                value: questionInternalId,
                label,
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
          setQuestionOptionsList(optionsList);
        }

        setQuestionOptionsLoading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const handleSelectedQuestion = (value) => {
    setSelectedQuestion(value);
  };

  const onChangeHandler = (event) => {
    setInputValue(event.target.value);
  };
  const onDoneHandler = () => {
    const response = {
      updateReason: commentInput,
    };
    if (modalType === 'Modify') {
      response.newSelectedQuestionId = selectedQuestion.value;
    }
    handleAction(response);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="appeal-modal__container"
      data-testid="appeal-modal-container"
    >
      <div className="appeal-modal" data-testid="appeal-modal-sub-container">
        <div
          className="appeal-modal__controls"
          data-testid="appeal-modal-controls"
        >
          <button
            type="button"
            className="appeal-modal__close-button"
            data-testid="appeal-modal-close-button"
            onClick={cancelClicked}
          >
            <img
              data-testid="appeal-modal-appeal-button-img"
              alt="Deny appeal button"
              src={require('../../assets/icons/X.svg').default}
              height={14}
              width={17}
            />
          </button>
        </div>
        <div data-testid="appeal-modal-title-container">
          <p className="appeal-modal__title" data-testid="appeal-modal-title">
            {I18n.t(`APP_ADMIN_AMENDMENTS_${modalType.toUpperCase()}`)}{' '}
            {I18n.t('APP_ADMIN_AMENDMENTS_APPEAL')}
          </p>
        </div>
        <p
          className="appeal-modal__subtitle"
          data-testid="appeal-modal-subtitle"
        >
          {I18n.t(`APP_ADMIN_AMENDMENTS_REASON_${modalType.toUpperCase()}`)}
        </p>
        <div data-testid="appeal-modal-finding-container">
          <div
            className="appeal-modal__info-container"
            data-testid="appeal-modal-info-container"
          >
            <div
              className="appeal-modal__section-container"
              data-testid="appeal-modal-store-section-container"
            >
              <p
                className="appeal-modal__section-title"
                data-testid="appeal-modal-store-section-title"
              >
                {I18n.t('APP_ADMIN_AMENDMENTS_SELECTED_FINDING')}:
              </p>
              <p
                className="appeal-modal__section-text"
                data-testid="appeal-modal-store-section-text"
              >
                {appeal.questionInternalId}: {appeal.findingDescription}
              </p>
            </div>
            <div
              className="appeal-modal__section-container"
              data-testid="appeal-modal-store-section-container"
            >
              <p
                className="appeal-modal__section-title"
                data-testid="appeal-modal-store-section-title"
              >
                {I18n.t('APP_ADMIN_AMENDMENTS_DATE')}:
              </p>
              <p
                className="appeal-modal__section-text"
                data-testid="appeal-modal-store-section-text"
              >
                {dayjs(appeal.responseInspectionTimestamp).format('MMMM YYYY')}
              </p>
            </div>
            <div
              className="appeal-modal__section-container"
              data-testid="appeal-modal-store-section-container"
            >
              <p
                className="appeal-modal__section-title"
                data-testid="appeal-modal-store-section-title"
              >
                {I18n.t('APP_ADMIN_AMENDMENTS_STORE')}:
              </p>
              <p
                className="appeal-modal__section-text"
                data-testid="appeal-modal-store-section-text"
              >
                {appeal.locationNumber} {appeal.locationName}
              </p>
            </div>
          </div>
          {modalType === 'Modify' && (
            <div data-testid="appeal-modal-finding-container">
              <Typography
                as="h6"
                className="appeal-modal__text"
                data-testid="appeal-modal-dropdown-findings-title"
              >
                {I18n.t('APP_ADMIN_AMENDMENTS_MODIFY_FINDING')}
              </Typography>
              <Select
                className="select-audit-occurance"
                data-testid="appeal-modal-selected-audit-occurance"
                options={questionOptionsList}
                onChange={handleSelectedQuestion}
                isSearchable
                placeholder="Select a finding"
                isLoading={questionOptionsLoading}
              />
            </div>
          )}
          <p
            className="appeal-modal__text appeal-modal__reason"
            data-testid="appeal-modal-reason-will-be-sent-subtitle"
          >
            {I18n.t('APP_ADMIN_AMENDMENTS_REASON')}{' '}
            {modalType === 'Modify'
              ? I18n.t('APP_ADMIN_AMENDMENTS_FOR_CHANGE')
              : ''}
          </p>
          <textarea
            onChange={onChangeHandler}
            type="text"
            name="reason"
            id="reason"
            rows="4"
            maxLength="500"
            placeholder="Write a reason or any additional details..."
            className="appeal-modal__textbox"
            data-testid="appeal-modal-textbox"
          />
          <p
            className="appeal-modal__charcount"
            data-testid="appeal-modal-reason-charcount"
          >
            {I18n.t('APP_ADMIN_AMENDMENTS_MAX_CHARACTERS')}
          </p>
          <div
            className="appeal-modal__buttons-container"
            data-testid="appeal-modal-buttons-container"
          >
            <button
              type="button"
              className="appeal-modal__button appeal-modal__cancel-button"
              data-testid="appeal-modal-cancel-button"
              onClick={cancelClicked}
            >
              {I18n.t('APP_NEXT_CANCEL')}
            </button>
            <button
              type="button"
              className={`appeal-modal__button ${
                commentInput.length === 0
                  ? 'appeal-modal__done-button-disabled'
                  : 'appeal-modal__done-button'
              }`}
              data-testid="appeal-modal-done-button"
              onClick={onDoneHandler}
              disabled={commentInput.length === 0}
            >
              {I18n.t(`APP_ADMIN_AMENDMENTS_${modalType.toUpperCase()}`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppealModal;
