import { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Dropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Icon,
  Typography,
} from 'cfa-react-components';
import { IconDownload } from '@tabler/icons-react';
import debounce from 'lodash.debounce';
import { getPdfDownloadUrls } from '../services/pdfDownloadApi';
import { showFailureNotification } from '../../../utils/Notification';
import useLvrAccess from '../../../utils/hooks/useLvrAccess';

import './PdfDownload.scss';

const LvrPdfDownload = ({ locationNumber, reports }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const { hasOCNotesAccess } = useLvrAccess();

  const handleDownload = useCallback(
    async ({ includeConsultingNarrative }) => {
      const { data } = await getPdfDownloadUrls({
        locationNumber,
        assessmentId: selectedValue?.assessmentId,
      });

      // exit early if no data with error message
      if (!data) {
        return showFailureNotification({
          message: 'Unable to download report. Please try again.',
        });
      }

      const path = includeConsultingNarrative
        ? data.consultingNarrativeSignedUrl
        : data.pdfSignedUrl;

      // open the pdf in a new tab and download
      window.open(path, '_blank');
      setIsOpen(false);
    },
    [selectedValue, locationNumber],
  );

  const handleDownloadDebounced = useMemo(
    () => debounce(handleDownload, 300),
    [handleDownload],
  );

  useEffect(() => {
    if (!reports.length) return;
    // set the selected value to the most recent report
    setSelectedValue(reports[0]);
  }, [reports]);

  return (
    <div data-testid="pdf-download-container">
      <Button
        data-testid="pdf-download-download-lvr-pdf-button"
        size="md"
        color="secondary"
        variant="outlined"
        onClick={() => setIsOpen(true)}
      >
        <Icon
          className="download-button__icon"
          data-testid="pdf-download-download-lvr-pdf-button-icon"
          icon={IconDownload}
        />
        Download LVR PDFs
      </Button>
      <Modal
        data-testid="pdf-download-modal"
        show={isOpen}
        size="lg"
        onClose={() => setIsOpen(false)}
      >
        <ModalHeader
          className="download-modal__header"
          data-testid="pdf-download-modal-header"
        >
          Download LVR Report
        </ModalHeader>
        <ModalBody
          className="download-modal__body"
          data-testid="pdf-download-modal-body"
        >
          <Typography data-testid="pdf-download-lvr-select-text" gutterBottom>
            Please select the LVR you would like to download.
          </Typography>
          <Dropdown
            data-testid="pdf-download-dropdown"
            fullWidth
            label="Select a Report"
            value={selectedValue}
            onChange={(newValue) => setSelectedValue(newValue)}
            getOptionId={(option) => option.id}
            options={reports}
            renderOption={(option) => option.date}
          />
        </ModalBody>
        <ModalFooter
          className="download-modal__footer"
          data-testid="pdf-download-modal-footer"
        >
          {hasOCNotesAccess && (
            <Button
              data-testid="pdf-download-download-with-consulting-narrative-button"
              variant="outlined"
              color="secondary"
              disabled={!selectedValue}
              onClick={() =>
                handleDownloadDebounced({ includeConsultingNarrative: true })
              }
            >
              Download with Consulting Narrative
            </Button>
          )}
          <Button
            data-testid="pdf-download-download-button"
            color="secondary"
            disabled={!selectedValue}
            onClick={handleDownloadDebounced}
          >
            Download
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LvrPdfDownload;
