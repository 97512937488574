import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import './AppealBadges.scss';

const AppealBadges = ({ value, tagColor = 'default-colors' }) => {
  return (
    <div
      className="appeal-badges__container"
      data-testid="appeal-badges-container"
    >
      <Badge
        className={`appeal-badges__tag appeal-badges__${tagColor}`}
        data-testid="appeal-badges-round-badge"
      >
        {value}
      </Badge>
    </div>
  );
};

AppealBadges.propTypes = {
  value: PropTypes.string,
};

export default AppealBadges;
