export const GET_AMENDMENDT = 'GET_AMENDMENDT';
export const GET_AMENDMENDT_SUCCESS = 'GET_AMENDMENDT_SUCCESS';
export const GET_AMENDMENDT_FAIL = 'GET_AMENDMENDT_FAIL';
export const GET_AMENDMENDT_PENDING = 'GET_AMENDMENDT_PENDING';
export const GET_AMENDMENDT_PENDING_SUCCESS = 'GET_AMENDMENDT_PENDING_SUCCESS';
export const GET_AMENDMENDT_PENDING_FAIL = 'GET_AMENDMENDT_PENDING_FAIL';
export const GET_AMENDMENDT_APPROVED = 'GET_AMENDMENDT_APPROVED';
export const GET_AMENDMENDT_APPROVED_SUCCESS =
  'GET_AMENDMENDT_APPROVED_SUCCESS';
export const GET_AMENDMENDT_APPROVED_FAIL = 'GET_AMENDMENDT_APPROVED_FAIL';
export const GET_AMENDMENDT_DROPDOWN = 'GET_AMENDMENDT_DROPDOWN';
export const GET_AMENDMENDT_DROPDOWN_SUCCESS =
  'GET_AMENDMENDT_DROPDOWN_SUCCESS';
export const GET_AMENDMENDT_DROPDOWN_FAIL = 'GET_AMENDMENDT_DROPDOWN_FAIL';
export const GET_AMENDMENT_COMMENTS = 'GET_AMENDMENT_COMMENTS';
export const GET_AMENDMENT_COMMENTS_SUCCESS = 'GET_AMENDMENT_COMMENTS_SUCCESS';
export const GET_AMENDMENT_COMMENTS_FAIL = 'GET_AMENDMENT_COMMENTS_FAIL';
export const PUT_AMENDMENDT_COMMENT = 'PUT_AMENDMENDT_COMMENT';
export const PUT_AMENDMENDT_COMMENT_SUCCESS = 'PUT_AMENDMENDT_COMMENT_SUCCESS';
export const PUT_AMENDMENDT_COMMENT_FAIL = 'PUT_AMENDMENDT_COMMENT_FAIL';
export const CLEAR_AMENDMENDT_DATA = 'CLEAR_AMENDMENDT_DATA';
export const GET_AMENDMENDT_FINDINGS = 'GET_AMENDMENDT_FINDINGS';
export const GET_AMENDMENDT_FINDINGS_SUCCESS =
  'GET_AMENDMENDT_FINDINGS_SUCCESS';
export const GET_AMENDMENDT_FINDINGS_FAIL = 'GET_AMENDMENDT_FINDINGS_FAIL';
export const POST_AMENDMENDT_COMMENT = 'POST_AMENDMENDT_COMMENT';
export const POST_AMENDMENDT_COMMENT_SUCCESS =
  'POST_AMENDMENDT_COMMENT_SUCCESS';
export const POST_AMENDMENDT_COMMENT_FAIL = 'POST_AMENDMENDT_COMMENT_FAIL';
export const POST_AMENDMENT_REVIEW_REQUEST = 'POST_AMENDMENT_REVIEW_REQUEST';
export const POST_AMENDMENT_REVIEW_REQUEST_SUCCESS =
  'POST_AMENDMENT_REVIEW_REQUEST_SUCCESS';
export const POST_AMENDMENT_REVIEW_REQUEST_FAIL =
  'POST_AMENDMENT_REVIEW_REQUEST_FAIL';
export const POST_AMENDMENDT_REQUEST = 'POST_AMENDMENDT_REQUEST';
export const POST_AMENDMENDT_REQUEST_SUCCESS =
  'POST_AMENDMENDT_REQUEST_SUCCESS';
export const POST_AMENDMENDT_REQUEST_FAIL = 'POST_AMENDMENDT_REQUEST_FAIL';
export const GET_APPROVED_AMENDMENT_COUNT = 'GET_APPROVED_AMENDMENT_COUNT';
export const GET_APPROVED_AMENDMENT_COUNT_SUCCESS =
  'GET_APPROVED_AMENDMENT_COUNT_SUCCESS';
export const GET_APPROVED_AMENDMENT_COUNT_FAIL =
  'GET_APPROVED_AMENDMENT_COUNT_FAIL';
export const POST_CONSULTATION_REQUEST = 'POST_CONSULTATION_REQUEST';
export const POST_CONSULTATION_REQUEST_SUCCESS =
  'POST_CONSULTATION_REQUEST_SUCCESS';
export const POST_CONSULTATION_REQUEST_FAIL = 'POST_CONSULTATION_REQUEST_FAIL';
