import isEqual from 'lodash/isEqual';
import {
  CLEAR_AUDIT_SELECTION,
  GET_LOCATION_FEATURE_PERMISSIONS,
  GET_LOCATION_FEATURE_PERMISSIONS_FAIL,
  GET_LOCATION_FEATURE_PERMISSIONS_SUCCESS,
  SET_SMARTSHOP_LOCATIONS,
  GET_STORE_LOCATIONS,
  GET_STORE_LOCATIONS_FAILURE,
  GET_STORE_LOCATIONS_SUCCESS,
  SET_IS_LICENSEE_LOCATION,
  SET_LOCATION_COUNTRY_CODE,
  SET_SELECTED_LOCATION,
  updateUserPermissions,
} from '.';
import { API_GET } from '../utils';

const LOCATION_API_BASE_URL = process.env.REACT_APP_LOCATION_API_BASE_URL;
const FOOD_SAFETY_API_BASE_URL = process.env.REACT_APP_SERVER_URL;

export const getStoreLocations = (locationNumber) => {
  return async (dispatch, getState) => {
    // Fetch Available Locations from redux before API
    let { availableLocations, selectedLocation } = [];
    const { userToken } = getState().auth;

    if (getState().location) {
      availableLocations = getState().location.availableLocations;
      selectedLocation = getState().location.selectedLocation;
    }

    const getSelectLocation = (locations) => {
      const locationsMap = new Map(
        locations.map((loc) => [loc.locationNumber, loc]),
      );
      return locationsMap.get(locationNumber) || locations[0];
    };

    // If available locations exist, no need to fetch again
    if (availableLocations === null) {
      dispatch({ type: GET_STORE_LOCATIONS });
      try {
        const { data } = await API_GET(LOCATION_API_BASE_URL);
        const locationData = Object.values(data);

        // Sort by location number
        const locations = locationData.sort((a, b) =>
          a.locationNumber > b.locationNumber ? 1 : -1,
        );
        // Dispatch to Redux
        dispatch({ type: GET_STORE_LOCATIONS_SUCCESS, payload: locations });

        const selectLocation = getSelectLocation(locations);
        dispatch(setSelectedLocation(selectLocation));
      } catch (err) {
        dispatch({ type: GET_STORE_LOCATIONS_FAILURE, payload: err });
      }
    } else if (availableLocations) {
      dispatch({
        type: GET_STORE_LOCATIONS_SUCCESS,
        payload: availableLocations,
      });
      // Re-set selected location to update user token permissions for that location, else
      // auto-choose the first location in the array of available locations
      if (selectedLocation == null) {
        const selectLocation = getSelectLocation(availableLocations);
        dispatch(setSelectedLocation(selectLocation));
      }
    }
  };
};

export const setSelectedLocation = (selectedLocation) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (isEqual(selectedLocation, state.location.selectedLocation)) {
      return;
    }

    // Dispatch Location
    dispatch({ type: SET_SELECTED_LOCATION, payload: selectedLocation });

    // Clear Audit Selections to prevent caching bugs
    if (
      state.location.selectedLocation !== null &&
      selectedLocation !== state.location?.selectedLocation?.locationNumber
    ) {
      dispatch({ type: CLEAR_AUDIT_SELECTION });
    }

    if (selectedLocation) {
      const { appFeaturePermissions } = selectedLocation;

      // Update User Token/Feature Flags
      dispatch(updateUserPermissions(appFeaturePermissions));
    }

    // Determine Country Code for Location
    dispatch(setLocationCountryCode(selectedLocation));
    // Determine if Licensee Location
    dispatch(setIsLicenseeLocation(selectedLocation));
  };
};

export const setLocationCountryCode = (selectedLocation) => {
  return (dispatch) => {
    let isCanadaStore = false;

    if (selectedLocation) {
      const { locationNumber } = selectedLocation;

      const parsedLocationNum = parseInt(locationNumber, 10);

      if (parsedLocationNum >= 30000 && parsedLocationNum <= 39999) {
        // Location is a Canada store, set bool to true
        isCanadaStore = true;
      }
    }

    const countryCode = isCanadaStore ? 'CA' : 'US';
    dispatch({ type: SET_LOCATION_COUNTRY_CODE, payload: countryCode });
  };
};

export const setIsLicenseeLocation = (selectedLocation) => {
  return (dispatch) => {
    let isLicenseeLocation = null;
    const { locationNumber } = selectedLocation;

    const parsedLocationNum = parseInt(locationNumber, 10);

    if (parsedLocationNum >= 80000 && parsedLocationNum <= 89999) {
      // Location is a Licensee store, set bool to true
      isLicenseeLocation = true;
    } else {
      // Location is not a Licensee store, set bool to false
      isLicenseeLocation = false;
    }

    dispatch({ type: SET_IS_LICENSEE_LOCATION, payload: isLicenseeLocation });
  };
};

export const setSelectedLocationByLocationNumber = (locationNumber) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (state.location.selectedLocation?.locationNumber === locationNumber) {
      return;
    }

    if (!getState().location.availableLocations?.length) {
      await dispatch(getStoreLocations(locationNumber));
    } else {
      const availableLocations = new Map(
        state.location.availableLocations.map((location) => [
          location.locationNumber,
          location,
        ]),
      );
      const location =
        availableLocations.get(locationNumber) ||
        state.location.availableLocations[0];
      await dispatch(setSelectedLocation(location));
    }
  };
};

export const getLocationAppealFlag = () => {
  return async (dispatch) => {
    const url = `${FOOD_SAFETY_API_BASE_URL}/amendments/form-flag-locations`;
    dispatch({
      type: GET_LOCATION_FEATURE_PERMISSIONS,
    });
    try {
      const { data } = await API_GET(url);
      dispatch({
        type: GET_LOCATION_FEATURE_PERMISSIONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_LOCATION_FEATURE_PERMISSIONS_FAIL });
    }
  };
};
