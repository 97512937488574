import { useEffect, useState } from 'react';
import { API_GET } from '../../../../../utils/api/API';
import useSelectedLocation from '../../../../../utils/hooks/useSelectedLocation';
import StaffingCard from './StaffingCard';

const Staffing = () => {
  const { locationNumber } = useSelectedLocation();
  const [content, setContent] = useState({});

  useEffect(() => {
    if (!locationNumber) return;

    const getStaffingData = async () => {
      const path = `${process.env.REACT_APP_SERVER_URL}/location/${locationNumber}/licensee-leaders`;
      try {
        const { data } = await API_GET(path);
        setContent(data);
      } catch (err) {
        console.log('err', err);
      }
    };

    getStaffingData();
  }, [locationNumber]);

  return content ? (
    <StaffingCard
      data-testid="staffing-staffing-card"
      content={content}
      locationNumber={locationNumber}
    />
  ) : null;
};

export default Staffing;
