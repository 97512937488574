import { Link } from 'react-router-dom';
import { Card, Row, Col, Typography } from 'cfa-react-components';
import { useOktaAuth } from '@okta/okta-react';
import { useRequireAdmin, claimsOneRole } from '../../utils/AuthUtils';
import I18n from '../../../i18n/utils';
import NavHeader from '../../components/NavHeader/NavHeader';
import './Admin.scss';

const Admin = () => {
  const { authState } = useOktaAuth();
  useRequireAdmin();

  // function that checks if the user's access token claims at least one of the required
  // WAS roles
  const claimsRequiredRole = (requiredRoles) =>
    claimsOneRole(authState?.accessToken?.claims, requiredRoles);

  return (
    <>
      <NavHeader />
      <Row className="admin__page-title-row">
        <Typography variant="h2" gutterBottom>
          {I18n.t('APP_MENU_ADMIN')}
        </Typography>
      </Row>
      <Row className="admin__page-subtitle-row">
        <Typography variant="h3" fontWeight="regular" gutterBottom>
          {I18n.t('APP_LANDING_PAGE_SELECT_APP')}
        </Typography>
      </Row>
      <Row className="admin__navigation-row">
        {/* only show SAFE Audit Amendments if user is a safeAdmin */}
        {authState?.accessToken &&
          claimsRequiredRole(['PROGRAM_ADMIN_SAFE']) && (
            <Col className="admin__navigation-column" xl={3}>
              <Link to="/admin/appeals" className="admin__navigation-link">
                <Card className="admin__navigation-card">
                  <img
                    alt=""
                    className="admin__navigation-card-image"
                    src={require('../../assets/icons/amendments.svg').default}
                  />
                  <Typography variant="h3" align="center" gutterBottom>
                    {I18n.t('APP_REPORT_DASHBOARD_AUDIT_AMENDMENTS_TITLE')}
                  </Typography>
                </Card>
              </Link>
            </Col>
          )}

        {/* only show self assessment admin if the user is a selfAssessmentAdmin */}
        {authState?.accessToken && claimsRequiredRole(['SA_ADMIN']) && (
          <Col className="admin__navigation-column" xl={3}>
            <Link
              to="/admin/self-assessment"
              className="admin__navigation-link"
            >
              <Card className="admin__navigation-card">
                <img
                  alt=""
                  className="admin__navigation-card-image"
                  src={require('../../assets/icons/assess.svg').default}
                />
                <Typography variant="h3" align="center" gutterBottom>
                  {I18n.t('APP_ADMIN_SELF_ASSESSMENT_TITLE')}
                </Typography>
              </Card>
            </Link>
          </Col>
        )}
      </Row>
      <Row className="admin__navigation-row">
        {/* only show SAFE Questions Admin if user is a safeAdmin */}
        {authState?.accessToken &&
          claimsRequiredRole(['PROGRAM_ADMIN_SAFE']) && (
            <Col className="admin__navigation-column" xl={3}>
              <Link
                to="/admin/safe-question"
                className="admin__navigation-link"
              >
                <Card className="admin__navigation-card">
                  <img
                    alt=""
                    className="admin__navigation-card-image"
                    src={require('../../assets/icons/question.svg').default}
                  />
                  <Typography variant="h3" align="center" gutterBottom>
                    {I18n.t('APP_ADMIN_SAFE_QUESTIONS_TITLE')}
                  </Typography>
                </Card>
              </Link>
            </Col>
          )}
        {authState?.accessToken &&
          claimsRequiredRole(['PROGRAM_ADMIN_SMTSHP']) && (
            <Col className="admin__navigation-column" xl={3}>
              <Link
                to="/admin/smart-shop-amendments"
                className="admin__navigation-link"
              >
                <Card className="admin__navigation-card">
                  <img
                    alt=""
                    className="admin__navigation-card-image"
                    src={require('../../assets/icons/square-stack.svg').default}
                  />
                  <Typography align="center" variant="h3" gutterBottom>
                    {I18n.t('APP_LANDING_PAGE_SMART_SHOP_AMENDMENTS')}
                  </Typography>
                </Card>
              </Link>
            </Col>
          )}
      </Row>
    </>
  );
};

export default Admin;
