// Reducers
import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import LocationReducer from './LocationReducer';
import AuditReducer from './AuditReducer';
import AdminReducer from './AdminReducer';
import VendorTracingReducer from './VendorTracingReducer';
import AmendmentReducer from './AmendmendtReducer';

// Combine all reducers
const appReducer = combineReducers({
  auth: AuthReducer,
  location: LocationReducer,
  audit: AuditReducer,
  admin: AdminReducer,
  vendorTracing: VendorTracingReducer,
  amendments: AmendmentReducer,
});

// Parent Reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
