import { useRef, useEffect } from 'react';
import { TextField } from 'cfa-react-components';

import './FreeResponseQuestion.scss';

const FreeResponseTextarea = ({
  label,
  value,
  placeholder,
  helperText,
  errorText,
  onChange,
  ...rest
}) => {
  const textareaRef = useRef(null);

  // Dynamically updates input height for new lines
  useEffect(() => {
    if (textareaRef.current) {
      // Reset the height to get the correct scrollHeight
      textareaRef.current.style.height = '0px';
      const { scrollHeight } = textareaRef.current;
      // set height directly outside render loop
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [value]);

  return (
    <TextField
      ref={textareaRef}
      className="free-response-question__textarea"
      data-testid="free-response-textarea-textfield"
      fullWidth
      multiline
      rows={1}
      placeholder={placeholder}
      helperText={helperText}
      value={value}
      errorText={errorText}
      onChange={onChange}
      {...rest}
    />
  );
};

export default FreeResponseTextarea;
