import { useCallback, useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Icon,
  Button,
  Typography,
  Drawer,
  IconButton,
} from 'cfa-react-components';
import { IconDownload } from '@tabler/icons-react';
import {
  ChevronRight,
  CircleCrossFilled,
  Close,
  SuccessFilled,
} from '@cfa-icons/system';
import { useHistory } from 'react-router-dom';
import { API_PUT, showFullTopSuccessNotification } from '../../utils';
import I18n from '../../../i18n/utils';
import { formatDateNoDay, formatDateWithFullYear } from '../../utils/DateUtil';
import AppealBadges from './AppealBadges';
import CFASmallSpinner from '../Spinner/CFASmallSpinner';
import AppealModal from './AppealModal';
import SelectedAppeal from './SelectedAppeal';
import LbrDownloadButton from '../Ammendments/LbrDownloadButton';

import './AppealsCard.scss';
import { getLbrData } from '../Assessment/services/smartShopApi';

const FOOD_SAFETY_API_BASE_URL = process.env.REACT_APP_SERVER_URL;

const appealStatusColors = {
  Approved: '',
  Denied: '',
  Upheld: '',
};

const getFriendlyAttachmentNameFromURL = (url) => {
  if (url != null) {
    const path = new URL(url).pathname;
    const fileName = path.split('/').at(-1).split('.').slice(1).join('.');
    /* Regex to replace + with spaces */
    return decodeURI(fileName.replace(/[+]/g, ' '));
  }
  return 'Filename not found';
};

const AppealsCard = ({
  appeal,
  loadingAppealsCallback,
  statusChangeCallback,
  disableActionBtn,
  isHistorical,
}) => {
  const [showSpinner, setSpinner] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [lbrFile, setLbrFile] = useState(null);

  const history = useHistory();

  const basePageUrl = isHistorical
    ? '/admin/smart-shop-amendments/historical-appeals'
    : '/admin/smart-shop-amendments';

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const data = await getLbrData(appeal.assessmentId);

        if (isMounted) {
          setLbrFile(data.filter((file) => !file.responseId)[0] || null);
        }
      } catch (error) {
        console.error(error);
      }
    };
    appeal && getData();
    return () => {
      isMounted = false;
    };
  }, [appeal]);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
    history.push(`${basePageUrl}/${appeal.assessmentId}`);
  }, [setIsDrawerOpen, appeal.assessmentId, basePageUrl, history]);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    history.push(basePageUrl);
  }, [setIsDrawerOpen, basePageUrl, history]);

  // Called when a user Approves or Denies an appeal through its respective modal
  const updateAppealBtn = async (updateStatus, updateReason) => {
    setSpinner(true);
    loadingAppealsCallback(true);

    updateStatus === 'APPROVED'
      ? handleShowHideApproveModal()
      : handleShowHideDenyModal();

    const url = `${FOOD_SAFETY_API_BASE_URL}/assessment/amendments/update`;
    const payload = {
      status: updateStatus,
      requestId: appeal.id,
      updateReason,
    };

    try {
      await API_PUT(url, payload);
      showFullTopSuccessNotification({
        message: I18n.t(
          `APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_${updateStatus}`,
        ),
        container: 'top-center',
      });
    } catch (error) {
      console.error('PUT appeal STATUS error :', error);
    }

    statusChangeCallback();
  };

  const handleModifyAppeal = async (payloadObj) => {
    setSpinner(true);
    loadingAppealsCallback(true);

    const url = `${FOOD_SAFETY_API_BASE_URL}/assessment/amendments/update`;
    const payload = {
      status: 'APPROVED',
      requestId: appeal.id,
      updateReason: payloadObj.updateReason,
      newQuestionInternalId: payloadObj.newSelectedQuestionId,
    };

    try {
      await API_PUT(url, payload);
      showFullTopSuccessNotification({
        message: I18n.t(`APP_ADMIN_AMENDMENTS_SUCCESS_NOTIFICATION_MODIFY`),
        container: 'top-center',
      });
    } catch (error) {
      console.error(' PUT appeal STATUS error :', error);
    }

    statusChangeCallback();
  };

  const handleDenyAppeal = (payloadObj) => {
    updateAppealBtn('DENIED', payloadObj.updateReason);
  };

  const handleApproveAppeal = (payloadObj) => {
    updateAppealBtn('APPROVED', payloadObj.updateReason);
  };

  const handleShowModifyAppealModal = () => {
    setShowModifyModal(!showModifyModal);
  };

  const handleShowHideDenyModal = () => {
    setShowDenyModal(!showDenyModal);
  };

  const handleShowHideApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };

  const round = useMemo(() => {
    const [year, quarter] = appeal.round.split('.');
    if (quarter) {
      return `${quarter}-${year}`;
    }
    return null;
  }, [appeal.round]);

  return (
    <>
      <div
        className="appeals-card__container"
        data-testid="appeal-card-container"
      >
        <div className="appeals-card__content">
          <div onClick={openDrawer}>
            <div
              className="appeals-card__header"
              data-testid="appeal-card-header"
            >
              <div
                className="appeals-card__tag-header"
                data-testid="appeal-card-tag-header"
              >
                <AppealBadges
                  data-testid="appeal-card-appeal-badges"
                  value={formatDateNoDay(appeal.responseInspectionTimestamp)}
                />
                {isHistorical && (
                  <AppealBadges
                    data-testid="appeal-card-status-badge"
                    value={`${I18n.t(
                      `APP_REPORT_DASHBOARD_REQUESTED_APPEALS_${appeal.amendmentStatus.toUpperCase()}`,
                    )} - ${formatDateWithFullYear(
                      appeal.lastModifiedTimestamp,
                    )}`}
                    tagColor={appealStatusColors[appeal.amendmentStatus]}
                  />
                )}
                {appeal.requesterDisplayName && (
                  <div
                    className="appeals-card__tag-container"
                    data-testid="appeal-card-submitted-by-tag-container"
                  >
                    <div
                      className="appeals-card__tag-title"
                      data-testid="appeal-card-submitted-by-tag-title"
                    >
                      {I18n.t(
                        'APP_REPORT_DASHBOARD_AMENDMENT_CARD_SUBMITTED_BY',
                      )}
                      :
                    </div>
                    <div
                      className="appeals-card__tag-data"
                      data-testid="appeal-card-submitted-by-tag-data"
                    >
                      {appeal.requesterDisplayName}
                    </div>
                  </div>
                )}
                <div
                  className="appeals-card__tag-container"
                  data-testid="appeal-card-created-tag-container"
                >
                  <p
                    className="appeals-card__tag-title"
                    data-testid="appeal-card-created-tag-title"
                  >
                    {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_CARD_CREATED')}:{' '}
                  </p>
                  <p
                    className="appeals-card__tag-data"
                    data-testid="appeal-card-created-tag-data"
                  >
                    {formatDateWithFullYear(appeal.requestedTimestamp)}
                  </p>
                </div>
              </div>

              {lbrFile ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  download={lbrFile.label}
                  href={lbrFile.uploadedFileSignedUrl}
                  onClick={(e) => e.stopPropagation()}
                  className="appeals-card__attachment-url"
                >
                  <Icon className="download-button__icon" icon={IconDownload} />
                  Download LBR
                </a>
              ) : (
                <Typography fontWeight="bold">
                  {I18n.t('APP_SMART_SHOP_LBR_NO_REPORTS_MSG')}
                </Typography>
              )}
            </div>

            <div
              className=" appeals-card__header-container"
              data-testid="appeal-card-question-safe-report-id-header-container"
            >
              <Typography
                as="h2"
                className="appeals-card__finding-title"
                data-testid="appeal-card-question-safe-report-id-finding-title"
              >
                {' '}
                {appeal.questionSafeReportId || appeal.questionInternalId}:{' '}
                {appeal.findingDescription}
              </Typography>
            </div>
            <div
              className=" appeals-card__header-container"
              data-testid="appeal-card-store-container"
            >
              <p
                className="appeals-card__store-title"
                data-testid="appeal-card-store-title"
              >
                {' '}
                {appeal.locationNumber} {appeal.locationName}
              </p>
            </div>
            {appeal.reasonForRequest && (
              <div
                className="appeals-card__details-info"
                data-testid="appeal-card-reason-for-review-details-info"
              >
                <div
                  className="appeals-card__details-info-container"
                  data-testid="appeal-card-reason-for-review-details-info-container"
                >
                  <div
                    className="appeals-card__details-info-title"
                    data-testid="appeal-card-reason-for-review-details-info-title"
                  >
                    {I18n.t(
                      'APP_REPORT_DASHBOARD_AMENDMENT_CARD_REASON_FOR_REVIEW',
                    )}
                    :
                  </div>
                  <div
                    className="appeals-card__details-info-data"
                    data-testid="appeal-card-reason-for-review-details-info-data"
                  >
                    {appeal.reasonForRequest}
                  </div>
                </div>
              </div>
            )}
            {appeal.attachmentUrls && (
              <div
                className="appeals-card__details-info"
                data-testid="appeal-card-supporting-documents-details-info"
              >
                <div
                  className="appeals-card__details-info-container"
                  data-testid="appeal-card-supporting-documents-details-info-container"
                >
                  <div
                    className="appeals-card__details-info-title"
                    data-testid="appeal-card-supporting-documents-details-info-title"
                  >
                    {I18n.t(
                      'APP_REPORT_DASHBOARD_AMENDMENT_CARD_SUPPORTING_DOCUMENTS',
                    )}
                    :
                  </div>
                  <div
                    className="appeals-card__details-attachment-container"
                    data-testid="appeal-card-supporting-documents-details-attachment-container"
                  >
                    {appeal.attachmentUrls.length !== 0 &&
                      appeal.attachmentUrls.map((attachment) => (
                        <a
                          key={attachment}
                          href={attachment}
                          className="appeals-card__details-attachment-link"
                          data-testid="appeal-card-supporting-documents-details-attachment-link"
                        >
                          {getFriendlyAttachmentNameFromURL(attachment)}
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isHistorical && (
            <>
              <div
                className="appeals-card__buttons-divider"
                data-testid="appeal-card-buttons-divider"
              />
              <div
                className="appeals-card__comments-findings-btns-container"
                data-testid="appeal-card-comments-findings-buttons-container"
              >
                <div
                  className="appeals-card__actions-buttons-container"
                  data-testid="appeal-card-modify-actions-buttons-container"
                >
                  {showSpinner && disableActionBtn && (
                    <div
                      data-testid="appeal-card-disabled-action-button-spinner-container"
                      style={{ marginLeft: '-3.5em' }}
                    >
                      <CFASmallSpinner data-testid="appeal-card-disabled-action-button-spinner" />
                    </div>
                  )}
                  <div
                    className="appeals-card__flex-button"
                    data-testid="appeal-card-modify-flex-button"
                  >
                    <Button
                      variant="text"
                      color="secondary"
                      size="sm"
                      className="appeals-card__modify-btn"
                      data-testid="appeal-card-modify-button"
                      onClick={handleShowModifyAppealModal}
                      disabled={disableActionBtn}
                    >
                      <Typography
                        data-testid="appeal-card-modify-text"
                        variant="body1"
                        color="secondary"
                      >
                        {I18n.t('APP_ADMIN_AMENDMENTS_MODIFY')}
                      </Typography>
                    </Button>
                  </div>

                  <div
                    className="appeals-card__flex-button"
                    data-testid="appeal-card-deny-flex-button"
                  >
                    <Button
                      data-testid="appeal-card-deny-button"
                      id="appeals-deny-modal__open"
                      variant="outlined"
                      size="md"
                      onClick={handleShowHideDenyModal}
                      disabled={disableActionBtn}
                    >
                      <Icon
                        alt="Deny appeal button"
                        icon={CircleCrossFilled}
                        className="appeals-card__icon--deny"
                        data-testid="appeal-card-deny-button-icon"
                        style={disableActionBtn ? { opacity: 0.33 } : null}
                      />
                      <Typography
                        variant="body1"
                        className="appeals-card__actions-btn-deny-text"
                        data-testid="appeal-card-deny-button-text"
                        style={disableActionBtn ? { opacity: 0.33 } : null}
                      >
                        {I18n.t('APP_ADMIN_AMENDMENTS_DENY')}
                      </Typography>
                    </Button>
                  </div>
                  <div
                    className="appeals-card__flex-button"
                    data-testid="appeal-card-appprove-flex-button"
                  >
                    <Button
                      variant="outlined"
                      size="md"
                      className="appeals-card__actions-btn--approve"
                      data-testid="appeal-card-approve-button"
                      onClick={handleShowHideApproveModal}
                      disabled={disableActionBtn}
                    >
                      <Icon
                        alt="Approve appeal button"
                        icon={SuccessFilled}
                        className="appeals-card__icon--approve"
                        data-testid="appeal-card-approve-button-icon"
                        style={disableActionBtn ? { opacity: 0.33 } : null}
                      />
                      <Typography
                        variant="body1"
                        className="appeals-card__actions-btn-approve-text"
                        data-testid="appeal-card-approve-button-text"
                        style={disableActionBtn ? { opacity: 0.33 } : null}
                      >
                        {I18n.t('APP_ADMIN_AMENDMENTS_ACCEPT')}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="appeals-card__right-arrow"
          data-testid="appeals-card-right-arrow"
        >
          <Icon
            data-testid="list-card-icon"
            icon={ChevronRight}
            onClick={openDrawer}
          />
        </div>
      </div>

      <AppealModal
        data-testid="appeal-card-modify-appeal-modal"
        isOpen={showModifyModal}
        cancelClicked={handleShowModifyAppealModal}
        handleAction={handleModifyAppeal}
        appeal={appeal}
        modalType="Modify"
      />

      <AppealModal
        data-testid="appeal-card-appeal-deny-modal"
        isOpen={showDenyModal}
        cancelClicked={handleShowHideDenyModal}
        handleAction={handleDenyAppeal}
        appeal={appeal}
        modalType="Deny"
      />

      <AppealModal
        data-testid="appeal-card-appeal-approve-modal"
        isOpen={showApproveModal}
        cancelClicked={handleShowHideApproveModal}
        handleAction={handleApproveAppeal}
        appeal={appeal}
        modalType="Approve"
      />

      <Drawer
        id={`${appeal.id}_drawer`}
        data-testid="list-card-drawer"
        size="lg"
        content={
          <>
            <IconButton
              size="xs"
              color="default"
              onClick={closeDrawer}
              aria-label="close"
              className="list-card__close-button"
              data-testid="list-card-close-button"
            >
              <Icon data-testid="list-card-close-button-icon" icon={Close} />
            </IconButton>
            <SelectedAppeal appeal={appeal} />
          </>
        }
        open={isDrawerOpen}
        onClose={closeDrawer}
      />
    </>
  );
};

AppealsCard.propTypes = {
  appeal: PropTypes.shape({}),
};

export default AppealsCard;
