import PropTypes from 'prop-types';

import './FoodSafetyLabel.scss';

const FoodSafetyLabel = ({ title, type, isLarge, margin, ...props }) => {
  const containerType = isLarge
    ? 'food-label__container__large'
    : 'food-label__container__normal';
  const titleType = isLarge
    ? 'food-label__title__large'
    : 'food-label__title__normal';
  const needsMargin = margin ? 'food-label__needs-margin' : '';

  return (
    <div
      className={`food-label__container ${containerType} food-label__${type} ${needsMargin}`}
      {...props}
    >
      <p
        className={`food-label__title ${titleType} food-label__${type}__title`}
      >
        {' '}
        {title.toUpperCase()}{' '}
      </p>
    </div>
  );
};

FoodSafetyLabel.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
};

export { FoodSafetyLabel };
