import PropTypes from 'prop-types';
import { Button, Typography } from 'cfa-react-components';

import './SubmissionCard.scss';

export default function SubmissionCard({
  selectedQuestion,
  resetForm,
  goToAppealsPage,
}) {
  return (
    <div className="submission-card" data-testid="submission-card-container">
      <img
        alt="green check"
        className="app-card-icon report-icon"
        data-testid="submission-card-report-icon"
        src={require('../../../assets/icons/big-green-check.svg').default}
        height={48}
        width={48}
      />
      <Typography
        gutterBottom
        variant="h3"
        className="submission-card__title"
        data-testid="submission-card-title"
      >
        Appeal Request Submitted Successfully
      </Typography>
      <Typography
        className="submission-card__finding"
        data-testid="submission-card-finding"
      >
        {selectedQuestion.safeReportId}: {selectedQuestion.text}
      </Typography>
      <div
        className="submission-card__button-container"
        data-testid="submission-card-button-container"
      >
        <Button
          className="submission-card__button"
          data-testid="submission-card-historical-appeals-button"
          color="secondary"
          variant="outlined"
          onClick={goToAppealsPage('Historical')}
        >
          Go to Historical Appeals
        </Button>
        <Button
          className="submission-card__button"
          data-testid="submission-card-submit-more-appeals-button"
          color="secondary"
          onClick={resetForm()}
        >
          Submit more Appeals
        </Button>
      </div>
    </div>
  );
}

SubmissionCard.propTypes = {
  selectedQuestion: PropTypes.object,
  resetForm: PropTypes.func,
  goToAppealsPage: PropTypes.func,
};
