import { useEffect, useState } from 'react';
import useSelectedLocation from '../../../../../utils/hooks/useSelectedLocation';
import VisitsCard from './VisitsCard';
import './Visits.scss';
import { getAssessments } from '../../../../../components/Assessment/services/pdfDownloadApi';
import { transformAndFilterReportsData } from '../../../../../components/Assessment/PdfDownload/utils/dataUtils';
import { recordsCurrentMonth } from '../../../../../utils';

const Visits = ({ reports = [] }) => {
  const { locationNumber } = useSelectedLocation();
  const [conversations, setConversations] = useState({});
  const [content, setContents] = useState({});

  useEffect(() => {
    if (!locationNumber) return;
    const getLvrConversations = async () => {
      try {
        const { data } = await getAssessments({
          locationNumber,
          program: 'LVR',
          formGroups: 'CONVERSATION_LVR',
        });

        const allReports = transformAndFilterReportsData(data, false);
        setConversations(allReports);
      } catch (e) {
        console.error('LVR: error fetching reports', e.message);
      }
    };

    getLvrConversations();
  }, [locationNumber]);

  useEffect(() => {
    setContents({
      lastReport: reports[0],
      lastConversation: conversations[0],
      visitsThisMonth: recordsCurrentMonth(reports),
      conversationsThisMonth: recordsCurrentMonth(conversations),
    });
  }, [reports, conversations]);

  return <VisitsCard data-testid="visits-visits-card" content={content} />;
};

export default Visits;
