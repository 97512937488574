import { useContext, useEffect, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import './AuditSummaryCard.scss';
import {
  BannerMessage,
  Button,
  Card,
  CardContent,
  Container,
  Icon,
  IconButton,
  LoadingIndicator,
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuItem,
  PopoverMenuToggleButton,
  Row,
  Typography,
} from 'cfa-react-components';
import { ActionsHorizontal, Download } from '@cfa-icons/system';
import { useDispatch, useSelector } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { useHistory, withRouter } from 'react-router-dom';
import { IconConfetti, IconPrinter } from '@tabler/icons-react';
import I18n from '../../../../../i18n/utils';
import { renderPerformanceCategory } from '../../../../constants/constants';
import { buildPdfData, trackEvent } from '../../../../utils';
import { getLeaveBehindReportPDF } from '../../../../actions';
import { getSelectedAuditFindings } from '../../../../actions/AuditActions';
import ListCard from '../../../../components/ListCard/ListCard';
import SelectedFinding from '../../AllFindings/SelectedFinding/SelectedFinding';
import { SelectedTabContext } from '../../../../components/Layout/TabLayout/TabLayout';
import useAuditSummary from '../../../../utils/hooks/useAuditSummary';

const AuditSummaryCard = () => {
  const {
    auditSummary,
    inquiryDeadlineDate,
    canSubmitInquiries,
    isPerfectAudit,
  } = useAuditSummary();

  const history = useHistory();
  const dispatch = useDispatch();

  const { audit } = auditSummary;
  const performanceHistory = auditSummary?.performanceHistory;

  const [totalFindingCount, setTotalFindingCount] = useState(null);
  const [performanceLevel] = useState(
    renderPerformanceCategory(audit.performanceLevel),
  );
  const [loadingPDFReport, setLoadingPDFReport] = useState(false);

  const { selectedLocation } = useSelector((state) => state.location);
  const { userToken } = useSelector((state) => state.auth);
  const { selectedAuditFindings, selectedAudit } = useSelector(
    (state) => state.audit,
  );

  const [, setSelectedTab] = useContext(SelectedTabContext);

  useEffect(() => {
    if (auditSummary) {
      const findingsCount = Object.values(auditSummary.findingsSummary)
        .map((e) => e.nonResolvedTotalCount)
        .reduce((a, b) => a + b, 0);
      setTotalFindingCount(findingsCount);
    }
  }, [auditSummary]);

  const downloadLbr = () => {
    dispatch(getLeaveBehindReportPDF());
  };

  const getPreviousAuditPerformanceLevel = () => {
    if (performanceHistory?.length < 2) return null;
    return performanceHistory[performanceHistory.length - 2].performanceLevel;
  };

  const startInquiry = () => {
    trackEvent('start-inquiry', userToken.userRole);
    history.push(
      `/${selectedLocation.locationNumber}/report-dashboard/${audit.round}/inquiry-form`,
    );
  };

  const handlePrintReport = async () => {
    if (selectedAuditFindings?.length === 0) {
      setLoadingPDFReport(true);
      await dispatch(getSelectedAuditFindings(selectedAudit));
    }

    await buildPdfData();
    setLoadingPDFReport(false);
  };

  return (
    <Card
      className={`audit-summary-card ${
        canSubmitInquiries ? 'banner-applied' : ''
      }`}
      data-testid="audit-summary-card-can-submit-inquiries-card"
    >
      <BannerMessage
        data-testid="audit-summary-card-can-submit-inquiries-banner-message"
        severity="info"
        show={canSubmitInquiries}
      >
        {I18n.t('APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_INQUIRY_DEADLINE')}:{' '}
        {inquiryDeadlineDate}
      </BannerMessage>
      <CardContent data-testid="audit-summary-card-can-submit-inquiries-card-content">
        <Container
          className="summary-data-wrapper"
          data-testid="audit-summary-card-summary-data-wrapper"
        >
          <Row data-testid="audit-summary-card-summary-data-wrapper-row">
            <div
              id="audit-info-properties"
              data-testid="audit-summary-card-audit-info-properties"
            >
              <Typography
                data-testid="audit-summary-card-selected-location-name"
                variant="h4"
                fontWeight="bold"
                align="left"
              >
                {selectedLocation.location.locationName}
              </Typography>

              <Typography
                data-testid="audit-summary-card-audit-location-number-round"
                variant="body2"
                align="left"
              >
                {audit.locationNumber} ({audit.round})
              </Typography>

              <Typography
                data-testid="audit-summary-card-total-findings-count"
                id="total-findings-count"
                variant="body2"
                align="left"
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_TOTAL_FINDINGS',
                )}
                : {totalFindingCount}
              </Typography>

              {getPreviousAuditPerformanceLevel() != null && (
                <Typography
                  data-testid="audit-summary-card-previous-audit-performance-level"
                  variant="body2"
                  align="left"
                >
                  {I18n.t(
                    'APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_PREVIOUS_ROUND',
                  )}
                  : {getPreviousAuditPerformanceLevel()} (
                  {
                    renderPerformanceCategory(
                      getPreviousAuditPerformanceLevel(),
                    ).category
                  }
                  )
                </Typography>
              )}
            </div>

            {
              performanceLevel && (
                <div
                  id="score-hover-description"
                  data-testid="audit-summary-card-score-hover-description"
                >
                  <div data-testid="audit-summary-card-score-hover-description-sub">
                    <Typography
                      data-testid="audit-summary-card-audit-performance-level"
                      variant="h1"
                    >
                      {audit.performanceLevel}
                    </Typography>
                    <div
                      className={`score-rating ${
                        audit.performanceLevel < 4 || audit.performanceLevel > 7
                          ? 'score-light-text'
                          : 'score-dark-text'
                      }`}
                      data-testid="audit-summary-card-score-rating-audit-performance-level"
                      style={{ backgroundColor: performanceLevel.color }}
                    >
                      {performanceLevel.category}
                    </div>
                  </div>
                </div>
              )
              // TODO some conditional indicating that the score will be available after a second audit
              // :
              // <h4 className="unavailable-score">{parseHTML(I18n.t('APP_REPORT_DASHBOARD_PERFORMANCE_SCORE_NOT_AVAILABLE'))}</h4>
            }
          </Row>

          <hr data-testid="audit-summary-card-horizontal-rule" />

          <Row data-testid="audit-summary-card-summary-data-wrapper-row-top-focus-area">
            <Typography
              data-testid="audit-summary-card-top-focus-area"
              variant="h4"
              align="left"
              fontWeight="bold"
            >
              {I18n.t('APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_TOP_FOCUS_AREA')}
            </Typography>
          </Row>

          <Row
            className="needs-improvement"
            data-testid="audit-summary-card-summary-data-wrapper-row-needs-improvement"
          >
            {auditSummary.needsImprovement?.length ? (
              auditSummary.needsImprovement?.map((f) => (
                <ListCard
                  data-testid="audit-summary-card-needs-improvement-list-card"
                  key={f.questionUUID}
                  cardId={f.questionUUID}
                  cardContent={
                    <Typography
                      data-testid="audit-summary-card-question-safe-report-id-description"
                      variant="body2"
                      align="left"
                      color="secondary"
                      fontWeight="regular"
                    >
                      {f.questionSafeReportId}: {f.description}
                    </Typography>
                  }
                  drawerContent={
                    <SelectedFinding
                      finding={f}
                      data-testid="audit-summary-card-drawer-content-selected-finding"
                    />
                  }
                  riskLevel={f.riskLevel}
                  compact
                />
              ))
            ) : (
              <div
                id="no-findings-wrapper"
                data-testid="audit-summary-card-no-findings-wrapper"
              >
                <Typography
                  data-testid="audit-summary-card-icon-confetti-wrapper"
                  color="secondary"
                >
                  <IconConfetti
                    data-testid="audit-summary-card-icon-confetti"
                    size={48}
                  />
                </Typography>
                <Typography
                  data-testid="audit-summary-card-good-job"
                  variant="h4"
                  fontWeight="bold"
                >
                  {I18n.t('APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_GOOD_JOB')}
                </Typography>
                <Typography
                  data-testid="audit-summary-card-no-improvements"
                  variant="body2"
                >
                  {I18n.t(
                    'APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_NO_IMPROVEMENTS',
                  )}
                </Typography>
              </div>
            )}
          </Row>

          <Row
            className="action-buttons"
            data-testid="audit-summary-card-summary-data-wrapper-row-action-buttons"
          >
            <Button
              data-testid="audit-summary-card-set-selected-tab-all-findings-button"
              as="a"
              size="sm"
              color="secondary"
              onClick={() => setSelectedTab('ALLFINDINGS')}
            >
              {I18n.t('APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_VIEW_FINDINGS')}
            </Button>

            <div
              id="start-inquiry-button"
              data-testid="audit-summary-card-start-inquiry-button-container"
            >
              <Button
                data-testid="audit-summary-card-start-inquiry-button"
                as="a"
                size="sm"
                variant="text"
                color="secondary"
                disabled={isPerfectAudit}
                onClick={startInquiry}
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_START_INQUIRY',
                )}
              </Button>
            </div>

            <div
              className="menu-button-wrapper"
              data-testid="audit-summary-card-menu-button-wrapper"
            >
              {loadingPDFReport === true ? (
                <div
                  className="audit-summary-card__toast-spinner-container"
                  data-testid="audit-summary-card-toast-spinner-container"
                >
                  <LoadingIndicator
                    data-testid="audit-summary-card-toast-spinner-loading-indicator"
                    size="sm"
                    variant="inline"
                  />
                </div>
              ) : (
                <PopoverMenu
                  data-testid="audit-summary-card-popover-menu"
                  style={{ marginLeft: 'auto' }}
                >
                  <PopoverMenuToggleButton
                    data-testid="audit-summary-card-popover-menu-toggle-button"
                    as={IconButton}
                    size="md"
                  >
                    <Icon
                      data-testid="audit-summary-card-popover-menu-toggle-button-icon-actions-horizontal"
                      color="secondary"
                      icon={ActionsHorizontal}
                    />
                  </PopoverMenuToggleButton>
                  <PopoverMenuContent data-testid="audit-summary-card-popover-menu-content">
                    <PopoverMenuItem
                      data-testid="audit-summary-card-popover-menu-item-print-report"
                      id="printReport"
                      onClick={handlePrintReport}
                    >
                      <IconPrinter
                        data-testid="audit-summary-card-print-fs-report"
                        size={16}
                      />{' '}
                      {I18n.t(
                        'APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_PRINT_FS_REPORT',
                      )}
                    </PopoverMenuItem>

                    <PopoverMenuItem
                      data-testid="audit-summary-card-popover-menu-item-download-lbr"
                      id="downloadLbr"
                      onClick={downloadLbr}
                    >
                      <Icon
                        data-testid="audit-summary-card-popover-menu-item-download-lbr-icon"
                        icon={Download}
                      />{' '}
                      {I18n.t(
                        'APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_LEAVE_BEHIND_REPORT',
                      )}
                    </PopoverMenuItem>
                  </PopoverMenuContent>
                </PopoverMenu>
              )}
            </div>
          </Row>
        </Container>
        {isPerfectAudit && (
          <UncontrolledTooltip
            data-testid="audit-summary-card-uncontrolled-tooltip-start-inquiry-button"
            placement="top"
            target="start-inquiry-button"
          >
            {I18n.t('APP_REPORT_DASHBOARD_REQUEST_AMENDMENTS_NO_FINDINGS')}
          </UncontrolledTooltip>
        )}

        {/* Definition Hover Tooltip */}
        {performanceLevel && (
          <UncontrolledTooltip
            data-testid="audit-summary-card-uncontrolled-tooltip-score-hover-description"
            placement="bottom"
            target="score-hover-description"
            // Custom Styles
            style={{
              backgroundColor: 'white',
              color: '#3c3c3c',
              border: '0.5px solid #f0eded',
              boxShadow:
                '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
              minWidth: '400px',
              textAlign: 'left',
              zIndex: 10,
              padding: '30px 15px 10px 15px',
            }}
          >
            <p data-testid="audit-summary-card-uncontrolled-tooltip-score-hover-description-p">
              {performanceLevel.definition}
            </p>
          </UncontrolledTooltip>
        )}
      </CardContent>
    </Card>
  );
};

export default withOktaAuth(withRouter(AuditSummaryCard));
