/**
 * Filters the findingHistory of the passed in findingsArray for occurrences that do NOT have an active appeal request,
 * AND that occurred in the selectedAuditRound. It also removes findings from the findingsArray that have would have no
 * occurrences left after filtering and returns the resulting array of appealableFindings.
 */
export function occurrencesFilter(findingsArray, subjectAudit) {
  return findingsArray
    .filter((finding) => finding.auditRound === subjectAudit.round) // we only care about findings from subject audit
    .reduce((appealableFindings, findingItem) => {
      // For each finding in the findingsArray filter out unappealable findings from its findingHistory.
      const filteredOccurrences =
        findingItem.findingHistory?.filter(
          (occurrenceItem) =>
            occurrenceItem.hasExistingAmendment === false &&
            occurrenceItem.round === findingItem.auditRound,
        ) || [];

      // If it isn't resolved and there are still occurrences after filtering, add the finding to appealableFindings.
      if (filteredOccurrences.length > 0 && findingItem.isResolved === false) {
        appealableFindings.push({
          ...findingItem,
          findingHistory: filteredOccurrences,
        });
      }
      return appealableFindings;
    }, []);
}

// takes in array of objects and returns array of unique objects by given property key
export const uniqueByKey = (items = [], key = '') => [
  ...new Map(items.map((item) => [item[key], item])).values(),
];

// to be use for onSearch prop on CFA SearchDropdown
export const noApiFilter = (list = [], filterCallBack = null) =>
  new Promise((resolve) => {
    resolve(
      filterCallBack
        ? {
            canLoadMore: false,
            results: list.filter(filterCallBack),
          }
        : null,
    );
  });

export const searchObject = (appeal, searchTerm) => {
  if (!appeal && searchTerm === '') {
    return true;
  }
  const keys = Object.keys(appeal || {});
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key !== 'findingUUID') {
      const value = appeal[key];
      if (typeof value === 'object') {
        if (searchObject(value, searchTerm)) {
          return true;
        }
      } else if (
        typeof value === 'string' &&
        value.toUpperCase().indexOf(searchTerm) !== -1
      ) {
        return true;
      }
    }
  }
  return false;
};
