import { useEffect } from 'react';
import dayjs from 'dayjs';
import DatePicker from './components/GeneralinformationDatePicker';
import TimePicker from './components/GeneralInformationTimePicker';
import RadioGroup from './components/GeneralInfomationRadioGroup';
import { actions, useLVRDispatch, useLVRStore } from '../../store/reducers';
import { mergeDateAndTime } from '../../utils/dataUtils';

import './GeneralInformationForm.scss';

const QUESTIONS_GENERAL = [
  {
    type: 'radio',
    required: true,
    formKey: 'visitType',
    name: 'Visit Type',
    label: 'Visit Type',
    options: [
      { label: 'Announced', value: 'Announced' },
      { label: 'Unannounced', value: 'Unannounced' },
    ],
  },
  {
    type: 'radio',
    required: true,
    formKey: 'dayPart',
    name: 'Day Part',
    label:
      'For which daypart of the menu are you performing the Licensee Visit Report?',
    options: [
      { label: 'Breakfast Daypart', value: 'Breakfast' },
      { label: 'Lunch/Dinner Daypart', value: 'Lunch' },
      {
        label: 'Transition (Breakfast/Lunch)',
        value: 'Transition',
      },
    ],
  },
  {
    type: 'radio',
    required: true,
    formKey: 'orderExperience',
    name: 'Ordering Experience',
    label: 'What was your ordering experience?',
    options: [
      { label: 'Front Counter', value: 'Front-Counter' },
      { label: 'Kiosk', value: 'Kiosk' },
      { label: 'Mobile', value: 'Mobile' },
      { label: 'Delivery', value: 'Delivery' },
      { label: 'Self-Service', value: 'Self-Service' },
    ],
  },
];

const GeneralQuestions = () => {
  const lvrDispatch = useLVRDispatch();
  const lvrStore = useLVRStore();

  // set values for PDF generation
  useEffect(() => {
    if (
      lvrStore?.supplementaryData?.visitDate &&
      lvrStore?.supplementaryData?.visitTime
    ) {
      try {
        const mergedDateAndTime = mergeDateAndTime(
          lvrStore?.supplementaryData?.visitDate,
          lvrStore?.supplementaryData?.visitTime,
        );
        lvrDispatch({
          type: actions.updateInspectionTimestamp,
          data: mergedDateAndTime,
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (lvrStore?.supplementaryData?.visitDate) {
      lvrDispatch({
        type: actions.updateRound,
        data: dayjs(lvrStore?.supplementaryData?.visitDate).format('MMM-YYYY'),
      });
    }
  }, [
    lvrDispatch,
    lvrStore?.supplementaryData?.visitDate,
    lvrStore?.supplementaryData?.visitTime,
  ]);

  return (
    <>
      <div
        className="lvr-gen-info__row"
        data-testid="general-questions-lvr-gen-info-row"
      >
        <div
          className="lvr-gen-info__row_group"
          data-testid="general-questions-lvr-gen-info-row-group"
        >
          <div
            className="lvr-gen-info__flex1"
            data-testid="general-questions-lvr-gen-info-flex1"
          >
            <DatePicker data-testid="general-questions-date-picker" />
          </div>
          <div
            className="lvr-gen-info__flex1"
            data-testid="general-questions-lvr-gen-info-flex1-2"
          >
            <TimePicker data-testid="general-questions-time-picker" />
          </div>
        </div>
      </div>
      {QUESTIONS_GENERAL.map(({ formKey, name, label, required, options }) => (
        <div data-testid="general-questions-radio-container" key={name}>
          <RadioGroup
            data-testid="general-questions-radio-group"
            formKey={formKey}
            name={name}
            label={label}
            required={required}
            options={options}
          />
        </div>
      ))}
    </>
  );
};

export default GeneralQuestions;
