import { Tooltip } from 'cfa-react-components';

const MissingDataToolTip = () => (
  <Tooltip placement="left-start" content="No Assessment Data Available">
    <div
      style={{
        position: 'absolute',
        top: '7rem',
        right: '4.3rem',
        height: '18rem',
        width: '.3rem',
      }}
    />
  </Tooltip>
);

export default MissingDataToolTip;
