import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { Col } from 'reactstrap';

import { Typography } from 'cfa-react-components';
import I18n from '../../../../i18n/utils';
import { trackEvent } from '../../../utils';
import { FoodSafetyLabel } from '../../common/FoodSafetyLabel/FoodSafetyLabel';
import { putHistoricalAction } from '../../../actions/AuditActions';
import {
  lowercaseWithDashes,
  uppercaseWithUnderscores,
  removeDashes,
  renderFindingColor,
} from '../../../utils/FindingsUtil';
import './FindingDetailsCard.scss';

/**
 * FindingDetailsCard is a component that returns a card displaying information
 * about one detail of the selectedAuditFinding.
 *
 * detail: an object containing many of the details to be displayed.
 * auditUUID: a string holding the UUID of the currently selected Audit used for creating historical actions.
 * questionUUID: a string holding the UUID of the currently selected Finding used for creating historical actions.
 * riskLevel: a string holding the riskLevel associated with the currently seleted Finding.
 * category: a string holding the category associated with the currently selected Finding.
 * bestPracticeLinks: an array of URLs pointing to best practices assocaited with the selected Finding.
 * findingHistoryIndex: a number holding the index of the finding History array that the FindingDetailsCard
 *                      component is displaying, used to give it a unique key.
 */
function FindingDetailsCard({
  detail,
  auditUUID,
  questionUUID,
  riskLevel,
  category,
  bestPracticeLinks,
  findingHistoryIndex,
}) {
  // A local name for the useDispatch function fom react-redux.
  const dispatch = useDispatch();

  const { userToken } = useSelector((state) => state.auth);

  // A function used to retrieve Images from the imgList param.
  const getImgItems = (imgList) => {
    return imgList.map(mapImgUrls);
  };

  // Does the work for getImgItems by mapping the imgUrl as the value with the "original" key.
  const mapImgUrls = (imgUrl) => {
    return {
      original: imgUrl,
    };
  };

  // Calls a dispatch to redux to put a Historical Action Object in the database.
  const addHistoricalAction = (auditID, questionID, type, content) => {
    return () => {
      dispatch(putHistoricalAction(auditID, questionID, type, content));
    };
  };

  // Returns a list of links based on either the best practice links or pathway links
  // associated with the finding detail, that when clicked create a new Historical Action.
  const renderLinks = (links, type, historyIndex) => {
    const htmlLinks = [];

    links.forEach((link, index) => {
      const linkUrl = type === 'best-practice' ? link.url : link.pathwayURL;
      const linkTitle =
        type === 'best-practice' ? link.title : link.pathwayName;
      const linkType =
        type === 'best-practice'
          ? 'LINK_BEST_PRACTICES'
          : 'LINK_PATHWAY_PROCEDURES';
      const content = `{"url": "${linkUrl}", "name": "${linkTitle}"}`;

      htmlLinks.push(
        <li
          className="finding-details-card__links-list-item"
          data-testid="finding-details-card-links-list-item"
          key={`${detail.questionUUID}-${historyIndex}-${linkType}-${index}`}
        >
          <a
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="finding-details-card__links-url"
            data-testid="finding-details-card-links-url"
            onClick={() => {
              if (type === 'pathway') {
                trackEvent('pathway-link-clicked', userToken.userRole);
              }
              addHistoricalAction(auditUUID, questionUUID, linkType, content);
            }}
          >
            {linkTitle}
          </a>
        </li>,
      );
    });
    return htmlLinks;
  };

  // Returns the html for the FindingDetailsCard component.
  return (
    <div
      className="finding-details-card"
      data-testid="finding-details-card-finding-details-card"
    >
      <div
        className="finding-details-card__details-container"
        data-testid="finding-details-card-details-container"
      >
        <Typography
          data-testid="finding-details-card-selected-findings-card-title"
          color="default"
          gutterBottom={false}
          variant="h3"
        >
          {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_CARD_TITLE')}
        </Typography>
        <div
          className="finding-details-card__labels-container"
          data-testid="finding-details-card-labels-container"
        >
          <div
            className="finding-details-card__detail-container"
            data-testid="finding-details-card-finding-card-when-label-detail-container"
          >
            <Typography
              className="finding-details-card__detail-label force-cfa-gray"
              data-testid="finding-details-card-finding-card-when-label-detail-label"
              color="default"
              gutterBottom={false}
              variant="body1"
            >
              {I18n.t('APP_REPORT_DASHBOARD_FINDING_CARD_WHEN_LABEL')}
            </Typography>
            <FoodSafetyLabel
              data-testid="finding-details-card-round-food-safety-label"
              title={removeDashes(detail.round)}
              type="round"
            />
          </div>
          <div
            className="finding-details-card__detail-container"
            data-testid="finding-details-card-finds-sort-by-risk-level-detail-container"
          >
            <Typography
              className="finding-details-card__detail-label force-cfa-gray"
              data-testid="finding-details-card-find-sort-by-risk-level-detail-label"
              color="default"
              gutterBottom={false}
              variant="body1"
            >
              {I18n.t('APP_REPORT_DASHBOARD_ALL_FINDINGS_SORT_BY_RISK_LEVEL')}
            </Typography>
            <FoodSafetyLabel
              data-testid="finding-details-card-risk-level-food-safety-label"
              title={I18n.t(
                `APP_REPORT_DASHBOARD_FINDINGS_RISK_LEVEL_${renderFindingColor(
                  riskLevel,
                ).toUpperCase()}`,
              )}
              type={lowercaseWithDashes(renderFindingColor(riskLevel))}
            />
          </div>
          <div
            className="finding-details-card__detail-container"
            data-testid="finding-details-card-category-label-detail-container"
          >
            <Typography
              className="finding-details-card__detail-label force-cfa-gray"
              data-testid="finding-details-card-category-label-detail-label"
              color="default"
              gutterBottom={false}
              variant="body1"
            >
              {I18n.t('APP_REPORT_DASHBOARD_FINDING_CARD_CATEGORY_LABEL')}
            </Typography>
            <FoodSafetyLabel
              data-testid="finding-details-card-category-label-food-safety-label"
              title={I18n.t(
                `APP_REPORT_DASHBOARD_FINDINGS_${uppercaseWithUnderscores(
                  category,
                )}`,
              )}
              type={lowercaseWithDashes(category)}
            />
          </div>
        </div>
        <div
          className="finding-details-card__detail-element-container"
          data-testid="finding-details-card-detail-element-container"
        >
          {detail.zone && (
            <div
              className="finding-details-card__detail-element"
              data-testid="finding-details-card-zone-detail-element"
            >
              <Typography
                className="finding-details-card__detail-label force-cfa-gray"
                data-testid="finding-details-card-zone-detail-label"
                color="default"
                gutterBottom={false}
                variant="body1"
              >
                {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ZONE')}
              </Typography>
              <Typography
                className="finding-details-card__detail-text"
                data-testid="finding-details-card-zone-detail-text"
                color="default"
                gutterBottom
                variant="body1"
              >
                {detail.zone}
              </Typography>
            </div>
          )}
          {detail.item && (
            <div
              className="finding-details-card__detail-element"
              data-testid="finding-details-card-item-detail-element"
            >
              <Typography
                className="finding-details-card__detail-label force-cfa-gray"
                data-testid="finding-details-card-item-detail-label"
                color="default"
                gutterBottom={false}
                variant="body1"
              >
                {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ITEM')}
              </Typography>
              <Typography
                className="finding-details-card__detail-text"
                data-testid="finding-details-card-item-detail-text"
                color="default"
                gutterBottom
                variant="body1"
              >
                {detail.item}
              </Typography>
            </div>
          )}
          {detail.issue && (
            <div
              className="finding-details-card__detail-element"
              data-testid="finding-details-card-issue-detail-element"
            >
              <Typography
                className="finding-details-card__detail-label force-cfa-gray"
                data-testid="finding-details-card-issue-detail-label"
                color="default"
                gutterBottom={false}
                variant="body1"
              >
                {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_ISSUE')}
              </Typography>
              <Typography
                className="finding-details-card__detail-text"
                data-testid="finding-details-card-issue-detail-text"
                color="default"
                gutterBottom
                variant="body1"
              >
                {detail.issue}
              </Typography>
            </div>
          )}
          {detail.details.temperature && (
            <div
              className="finding-details-card__detail-element"
              data-testid="finding-details-card-temp-detail-element"
            >
              <Typography
                className="finding-details-card__detail-label force-cfa-gray"
                data-testid="finding-details-card-temp-detail-label"
                color="default"
                gutterBottom={false}
                variant="body1"
              >
                {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_TEMP')}
              </Typography>
              <Typography
                className="finding-details-card__detail-text"
                data-testid="finding-details-card-temp-detail-text"
                color="default"
                gutterBottom
                variant="body1"
              >
                {detail.details.temperature}
              </Typography>
            </div>
          )}
          {detail.imageList && (
            <div
              className="finding-details-card__detail-element"
              data-testid="finding-details-card-photo-detail-element"
            >
              <Typography
                className="finding-details-card__detail-label force-cfa-gray"
                data-testid="finding-details-card-photo-detail-label"
                color="default"
                gutterBottom={false}
                variant="body1"
              >
                {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_PHOTO')}
              </Typography>
              <Col
                xs="5"
                className="finding-details-card__image-gallery-container"
                data-testid="finding-details-card-photo-image-gallery-container"
              >
                <ImageGallery
                  data-testid="finding-details-card-photo-image-gallery"
                  items={getImgItems(detail.imageList)}
                  original
                  showIndex
                  showPlayButton={false}
                  showThumbnails={false}
                />
              </Col>
            </div>
          )}
        </div>
        {detail.details.otherInformation && (
          <div data-testid="finding-details-card-other-note-detail-element">
            <Typography
              className="finding-details-card__detail-label force-cfa-gray"
              data-testid="finding-details-card-other-notes-detail-label"
              color="default"
              gutterBottom={false}
              variant="body1"
            >
              {I18n.t(
                'APP_REPORT_DASHBOARD_SELECTED_FINDING_DETAILS_OTHER_NOTES',
              )}
            </Typography>
            <Typography
              className="finding-details-card__detail-text"
              data-testid="finding-details-card-other-notes-detail-text"
              color="default"
              gutterBottom
              variant="body1"
            >
              {detail.details.otherInformation}
            </Typography>
          </div>
        )}
      </div>
      {detail.pathwayLinks?.length || bestPracticeLinks?.length ? (
        <div
          className="finding-details-card__links-container"
          data-testid="finding-details-card-resources-links-container"
        >
          <Typography
            className="finding-details-card__links-title"
            data-testid="finding-details-card-resources-links-title"
            color="default"
            gutterBottom={false}
            variant="h3"
          >
            {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_RESOURCES')}
          </Typography>
          {detail.pathwayLinks?.length !== 0 && detail.pathwayLinks && (
            <div
              className="finding-details-card__links-box"
              data-testid="finding-details-card-pathway-links-box"
            >
              <Typography color="default" gutterBottom={false} variant="h4">
                <img
                  alt="pathways"
                  className="finding-details-card__links-icon"
                  data-testid="finding-details-card-pathway-links-icon"
                  src={
                    require(`../../../assets/icons/pathway-links.svg`).default
                  }
                />
                {I18n.t('APP_REPORT_DASHBOARD_SELECTED_FINDINGS_PATHWAY')}
              </Typography>
              <Typography
                className="force-cfa-gray"
                data-testid="finding-details-card-pathway-description"
                color="default"
                gutterBottom={false}
                variant="body2"
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_PATHWAY_DESCRIPTION',
                )}
              </Typography>
              <ul
                className="finding-details-card__links-list"
                data-testid="finding-details-card-pathway-links-list"
              >
                {renderLinks(
                  detail.pathwayLinks,
                  'pathway',
                  findingHistoryIndex,
                )}
              </ul>
            </div>
          )}
          {bestPracticeLinks?.length !== 0 && (
            <div
              className="finding-details-card__links-box"
              data-testid="finding-details-card-best-practices-links-box"
            >
              <Typography color="default" gutterBottom={false} variant="h4">
                <img
                  alt="best practice"
                  className="finding-details-card__links-icon"
                  data-testid="finding-details-card-best-practices-links-icon"
                  src={
                    require(`../../../assets/icons/best-practice-links.svg`)
                      .default
                  }
                />
                {I18n.t(
                  'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_BEST_PRACTICES',
                )}
              </Typography>
              <Typography
                className="force-cfa-gray"
                data-testid="finding-details-card-best-practices-description"
                color="default"
                gutterBottom={false}
                variant="body2"
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_SELECTED_FINDINGS_BEST_PRACTICES_DESCRIPTION',
                )}
              </Typography>
              <ul
                className="finding-details-card__links-list"
                data-testid="finding-details-card-best-practices-links-list"
              >
                {renderLinks(
                  bestPracticeLinks,
                  'best-practice',
                  findingHistoryIndex,
                )}
              </ul>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

FindingDetailsCard.propTypes = {
  detail: PropTypes.object,
  auditUUID: PropTypes.string,
  questionUUID: PropTypes.string,
  riskLevel: PropTypes.string,
  category: PropTypes.string,
  bestPracticeLinks: PropTypes.array,
  findingHistoryIndex: PropTypes.number,
};

export default FindingDetailsCard;
