import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSelectedLocation from './useSelectedLocation';
import { API_GET } from '../api/API';
import {
  buildQuestionChain,
  categoryIdToName,
  sortFindings,
} from '../../pages/Assessment/AllFindings/helpers';

const domain = process.env.REACT_APP_SERVER_URL;

const useLvrAssessment = () => {
  const history = useHistory();
  const location = useLocation();
  const { assessmentId, id } = useParams();
  const { locationNumber } = useSelectedLocation();

  const [findings, setFindings] = useState([]);
  const [isFetchingFindings, setIsFetchingFindings] = useState(true);

  const isAllFindingsTab = location.pathname.includes('findings');

  const setLvrHistory = (report = null) => {
    if (report) {
      const isDrawerOpen = id ? `/${id}` : '';
      const allFindingsPath = isAllFindingsTab
        ? `/findings${isDrawerOpen}`
        : '';

      history.replace(
        `/${locationNumber}/assessment/${report.assessmentId}${allFindingsPath}`,
      );
    }
  };

  const processTopFocusData = (data = []) => {
    const noncompliantFindings = data.filter(
      (finding) => finding.complianceStatus === 'NONCOMPLIANT',
    );
    const sortedData = [
      ...noncompliantFindings.sort(sortFindings('highRiskLevel')),
    ];
    return buildQuestionChain(sortedData).slice(0, 3);
  };

  useEffect(() => {
    if (!assessmentId) {
      setIsFetchingFindings(false);
      return;
    }
    let isMounted = true;
    const getData = async () => {
      try {
        setIsFetchingFindings(true);
        const path = `${domain}/assessment/${assessmentId}/responses`;
        const { data } = await API_GET(path);
        data.forEach((finding) => {
          finding.categoryName = categoryIdToName(
            finding.questionInternalId.split('-')[0],
          );
        });
        if (isMounted) {
          setFindings(isAllFindingsTab ? data : processTopFocusData(data));
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) setIsFetchingFindings(false);
      }
    };
    getData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId]);

  return {
    findings,
    setLvrHistory,
    isFetchingFindings,
  };
};

export default useLvrAssessment;
