import { ResponsiveLine } from '@nivo/line';
import { Typography } from 'cfa-react-components';
import cloneDeep from 'lodash/cloneDeep';
import ChartContainer from '../common/ChartContainer';
import { baseTheme } from '../../../constants/constants';
import TooltipContainer from '../../common/TooltipContainer/TooltipContainer';

const theme = cloneDeep(baseTheme);

const CustomTooltip = ({ point }) => {
  if (!point || !point.data) {
    return <div>No data available</div>;
  }
  // Filter and map the findings
  const findings = Object.entries(point.data)
    .filter(
      ([key]) =>
        !['x', 'y', 'yStacked', 'xFormatted', 'yFormatted'].includes(key),
    )
    .map(([key, value]) => (
      <Typography variant="body2" key={key}>
        {`${key}: ${value}`}
      </Typography>
    ));

  return (
    <TooltipContainer
      carrotOffset={90}
      offset={-38.5}
      spaceBetweenPoint={5}
      contentPaddingLeft={5}
    >
      <Typography fontWeight="bold" variant="body2">
        Total: {point.data.y}
      </Typography>
      {findings}
    </TooltipContainer>
  );
};

// Customize the theme for point labels
theme.dots = {
  ...theme.dots,
  text: {
    ...theme.dots?.text,
    fill: 'white', // Set point label color to white
  },
};

const findGreatestTotalFindings = (chartData = []) => {
  let greatestTotal = 0;
  chartData.forEach((e) => {
    if (e.y > greatestTotal) {
      greatestTotal = e.y;
    }
  });
  return greatestTotal;
};

const generateTickValues = (maxValue) => {
  const tickValues = [];
  // Adjust the step based on the total findings range for the chart UX
  // This line sets the step increments to 1 when the max total finding is 5 or less
  // makes the chart look better for smaller value, larger values maintain a step value of 2
  const step = maxValue < 6 ? 1 : 2;

  // Round up maxValue to the nearest even number if it's odd
  const roundedMax = maxValue + (maxValue % 2);

  // push the tick values by the selected step
  for (let i = 0; i <= roundedMax; i += step) {
    tickValues.push(i);
  }

  return tickValues;
};

const PreviousVisitFindingsChart = ({ apiData, isLoading }) => {
  const greatestFindings =
    apiData && apiData.length > 0
      ? findGreatestTotalFindings(apiData[0].data)
      : 0;

  const tickValues =
    greatestFindings === 1 ? [0, 1] : generateTickValues(greatestFindings);

  // Determine if there's meaningful data
  const hasMeaningfulData =
    apiData.length > 0 && apiData[0].data.some((point) => point.y !== null);

  return (
    <ChartContainer
      isLoading={isLoading}
      isEmptyChart={!hasMeaningfulData}
      emptyChartMessage="No Findings Data Available"
      style={{ height: '31.25rem' }}
    >
      <ResponsiveLine
        data={apiData}
        tooltip={CustomTooltip}
        margin={{ top: 30, right: 40, bottom: 100, left: 80 }}
        enablePointLabel
        pointLabelYOffset={5}
        axisBottom={{
          tickRotation: window.innerWidth <= 991 ? -40 : 0,
          tickPadding: 30,
          tickFontSize: 10,
          orient: 'bottom',
          tickSize: 0,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickValues,
          legend: 'Total Findings',
          legendOffset: -60,
          legendPosition: 'middle',
        }}
        yScale={{
          min: 0,
          max: tickValues[tickValues.length - 1],
          type: 'linear',
        }}
        gridYValues={tickValues}
        colors={() => '#003E59'}
        theme={theme}
        pointSize={25}
        pointColor={{ from: 'color' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        useMesh
      />
    </ChartContainer>
  );
};

export default PreviousVisitFindingsChart;
