import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Button } from 'cfa-react-components';
import I18n from '../../../../i18n/utils';

import NavHeader from '../../../components/NavHeader/NavHeader';
import MobileNestedHeader from '../../../components/NavHeader/MobileNestedHeader/MobileNestedHeader';
import { getDropDownOptions } from '../../../actions/AmendmentActions';

import './AmendmentContainer.scss';
import HistoricalAmendments from './HistoricalAmendments';
import PendingAmendments from './PendingAmendments';

export default function AmendmentContainer({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [toggleAmendmentsTab, setToggleAmendmentsTab] = useState(false);
  const dropdownOptions = useSelector(
    (state) => state.amendments.dropdownOptions,
  );

  // Function to retrieve urlParams from the url bar
  const getTabParam = () => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const tab = params.get('tab');
    if (tab === 'Historical') {
      setToggleAmendmentsTab(true);
    }
  };

  // Fetch the urlParam "tab" and fetch the dropdownOptions
  useEffect(() => {
    getTabParam();
    dispatch(getDropDownOptions());
  }, []);

  const handleTabToggle = (value) => {
    if (dropdownOptions.length === 0) {
      return;
    }
    return () => {
      setToggleAmendmentsTab(value);
    };
  };

  const handleAppealFormRedirect = () => {
    history.push('/admin/appeals/appeal-form');
  };

  return (
    <div>
      <div className="d-none d-sm-block">
        <NavHeader />
      </div>
      <div className="d-block d-sm-none">
        <MobileNestedHeader
          title={I18n.t('APP_ADMIN_AMENDMENTS_CONTAINER_TITLE')}
          navigateBackRoute={false}
        />
      </div>
      <Col className="amendment-container container">
        <div className="amendments-header-container">
          <Row className="d-none d-sm-block container">
            <Link to="/admin">
              <img
                alt=""
                src={require('../../../assets/icons/left-arrow.svg').default}
                height={15}
                width={15}
                className="desktop-back-arrow"
              />
              <span className="desktop-back-link">
                {I18n.t('APP_ADMIN_DASHBOARD_TITLE')}
              </span>
            </Link>
          </Row>
          <Row className="desktop-sub-header">
            <Col>
              <h2 className="d-none d-sm-block amendments-title">
                {I18n.t('APP_ADMIN_AMENDMENTS_CONTAINER_TITLE')}
              </h2>
              <h3 className="mobile-selector-label d-block d-sm-none">
                {I18n.t('APP_ADMIN_AMENDMENTS_TITLE')}
              </h3>
            </Col>
            <Col className="appeal-form-button-container">
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleAppealFormRedirect}
              >
                + Admin Appeal Form
              </Button>
            </Col>
          </Row>
        </div>
        <Row className="amendments-tab-links__container">
          <div className="amendments_tab-col">
            <p
              onClick={handleTabToggle(false)}
              className={
                toggleAmendmentsTab === false
                  ? 'amendments-active-tab amendments-tab-links__title'
                  : 'amendments-tab-links__title'
              }
            >
              Pending Appeals
            </p>
          </div>
          <div
            className={
              dropdownOptions.length === 0
                ? 'amendments-tab--disabled amendments_tab-col'
                : 'amendments_tab-col'
            }
          >
            <p
              onClick={handleTabToggle(true)}
              className={
                toggleAmendmentsTab === true
                  ? 'amendments-active-tab amendments-tab-links__title'
                  : 'amendments-tab-links__title'
              }
            >
              Historical Appeals
            </p>
          </div>
          <div className="amendments-tab-links__container--border" />
        </Row>
        {/*
              Tab navegation logic:
              This logic can be updated with a switch stament to handle more tab. jjir 2021-08-05
          */}
        {toggleAmendmentsTab === true ? (
          <HistoricalAmendments />
        ) : (
          <PendingAmendments />
        )}
      </Col>
    </div>
  );
}
