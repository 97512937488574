import * as dayjs from 'dayjs';

export const formatDateNoDay = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MMMM YYYY');
  }
};

export const formatDateNoDayComma = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MMMM, YYYY');
  }
};

export const formatDate = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MM-D-YY');
  }
};

export const formatReverseDate = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('YYYY-MM-DD');
  }
};

export const formatDateWithFullYear = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MMMM DD, YYYY');
  }
};

export const formatDateMonthsAndFullYear = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MMM DD, YYYY');
  }
};

export const formatDateWithFullYearAndTime = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MMMM DD, YYYY hA');
  }
};

export const formatDateWithFullYearAndFullTime = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MMMM DD, YYYY h:mm A');
  }
};
export const formatDatePickerDate = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MM/DD/YYYY');
  }
};

export const checkSameDay = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs().isSame(date, 'day');
  }
};

export const formatDateAndTime = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('M-D-YY, h:MM A');
  }
};

export const formatDateAbbreviatedMonthDayYear = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    return dayjs(date).format('MMM DD, YYYY h:mm A');
  }
};

export const getDateToday = () => {
  return dayjs().startOf('day').format('MMMM D, YYYY');
};

export const getLastWeekDate = () => {
  // Return string format for date-range widget
  return dayjs().startOf('day').subtract(1, 'week').$d;
};

export const getYear = () => {
  return dayjs().year();
};

export const getMonth = () => {
  return dayjs().month();
};

export const getCurrentTime = () => {
  return dayjs();
};

export const addTenDays = (date) => {
  const tenDaysDateObj = dayjs(date).add(10, 'day');

  return {
    formatedDate: tenDaysDateObj.format('MMM DD, YYYY'),
    DateObj: tenDaysDateObj,
  };
};

export const isWithinTendays = (date) => {
  // if round date is same as today or the last date of the deadline
  if (
    dayjs(date).isSame(dayjs().format(), 'day') ||
    dayjs().isSame(addTenDays(date).DateObj.format(), 'day')
  ) {
    return true;
  }
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  return dayjs().isBetween(date, addTenDays(date).DateObj.format(), 'day');
};

export const getAdminAppealFormYears = () => {
  const currentYear = dayjs();
  const lastYear = currentYear.subtract(1, 'year');
  return [currentYear.get('year').toString(), lastYear.get('year').toString()];
};

export const disablePastDates = (current) => {
  const start = new Date();
  const end = new Date();
  start.setDate(start.getDate() - 30);
  end.setHours(0, 0, 0, 0);
  return current > end || current < start;
};

export const allowSmartShopAmendment = (date = '', isDevTeam = false) => {
  const today = dayjs();
  const inspectionDate = dayjs(date);

  // Alow DEV_TEAM to appeal 2 months of assessments
  const publishMonth = inspectionDate.month() + 1 + (isDevTeam ? 1 : 0);

  // as the usual publishing date is the 10th day of the next
  // month in respect to the inspection date.
  const publishDay = 10;
  const publishDate = dayjs(inspectionDate)
    .set('month', publishMonth)
    .set('date', publishDay);
  const lastDayAllowed = dayjs(publishDate).add(5, 'day');

  return (
    today.isSame(lastDayAllowed, 'day') || today.isBefore(lastDayAllowed, 'day')
  );
};

// never publish data before 01/01/2024
// publish previous months data and never the current month
// for May 2024 publish April data after or on 2024-05-14
export const shouldPublishAssessment = (date = '', isDevTeam = false) =>
  !isCurrentMonth(date) &&
  !isBeforeStartDate(date) &&
  (isDevTeam || showLastMonthData(date));

export const showLastMonthData = (date = '') => {
  const inspectionDate = dayjs(date);
  const today = dayjs();

  // This is the usual publishing date of the 10th for the current month
  const publishDate = dayjs().set('date', 10);
  if (
    today.month() === publishDate.month() &&
    today.year() === publishDate.year() &&
    inspectionDate.month() === publishDate.month() - 1
  ) {
    return today.date() >= publishDate.date();
  }

  return true;
};

export const isCurrentMonth = (date) => {
  if (typeof date !== 'undefined' && dayjs(date).isValid()) {
    const today = dayjs();
    const reportDate = dayjs(date);
    return (
      today.month() === reportDate.month() && today.year() === reportDate.year()
    );
  }
  return false;
};

export const isBeforeStartDate = (current = '', start = '2024-01-01') =>
  dayjs(current).isBefore(dayjs(start));

export const recordsCurrentMonth = (records = []) => {
  if (records.length === 0) return 0;

  let count = 0;
  const today = dayjs();

  for (let i = 0; i < records.length; i++) {
    const current = dayjs(records[i].date);
    if (today.year() !== current.year() || today.month() !== current.month()) {
      return count;
    }
    count++;
  }

  return count;
};
