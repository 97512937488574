import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import I18n from '../../../../../i18n/utils';
import './RequestedAppealsWidget.scss';

const RequestedAppealCardLabel = ({ appeal }) => {
  const getBadgeLabel = () => {
    if (appeal.amendmentType === 'MODIFICATION') {
      return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_MODIFIED')}`;
    }
    if (appeal.isAdminRequested) {
      return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REMOVED')}`;
    }
    if (appeal.amendmentStatus === 'DENIED') {
      return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_UPHELD')}`;
    }
    if (appeal.amendmentStatus === 'PENDING') {
      return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_IN_REVIEW')}`;
    }
    return `${I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_APPROVED')}`;
  };

  return (
    <Badge
      className={`requested-appeals-widget__label requested-appeals-widget__${appeal.amendmentStatus.toLowerCase()}-label`}
      data-testid="requested-appeals-card-label-badge-label"
    >
      {getBadgeLabel()}
    </Badge>
  );
};

RequestedAppealCardLabel.propTypes = {
  appeal: PropTypes.object.isRequired,
};

export default RequestedAppealCardLabel;
