import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  LoadingIndicator,
  Typography,
} from 'cfa-react-components';
import ListCard from '../../../../../components/ListCard/ListCard';
import FindingOverview from '../../../AllFindings/FindingOverview/FindingOverview';
import SelectedFinding from '../../../AllFindings/SelectedFinding/SelectedFinding';
import I18n from '../../../../../../i18n/utils';
import LvrWidgetTitle from '../../LicenseeVisitReportWidgetTitle';
import './TopFocusArea.scss';
import useSelectedLocation from '../../../../../utils/hooks/useSelectedLocation';

const TopFocusArea = ({
  findings = [],
  isLoading = true,
  selectedReport = {},
}) => {
  const history = useHistory();
  const { locationNumber } = useSelectedLocation();
  return (
    <Card style={{ height: '100%' }}>
      <CardContent className="top-focus-area">
        <LvrWidgetTitle
          title={I18n.t(
            'APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_TOP_FOCUS_AREA',
          )}
          marginBottom="0.5rem"
        />
        {isLoading ? (
          <LoadingIndicator variant="inline" />
        ) : findings.length === 0 ? (
          <div style={{ flexGrow: 1 }}>
            <Typography
              style={{ textAlign: 'center', marginTop: '40%' }}
              variant="h4"
              className="top-focus-area-no-data"
            >
              {I18n.t('APP_LVR_FINDING_TOP_FOCUS_NO_DATA')}
            </Typography>
          </div>
        ) : (
          <>
            <div style={{ minHeight: '22.6rem' }}>
              {findings.map(({ parentFinding, childrenFindings = [] }) => (
                <ListCard
                  compact
                  riskLevel={parentFinding.riskLevel}
                  key={parentFinding.questionInternalId}
                  cardId={parentFinding.questionInternalId}
                  cardContent={
                    <FindingOverview
                      compact
                      finding={parentFinding}
                      hasChildren={childrenFindings.length > 0}
                    />
                  }
                  drawerContent={
                    <SelectedFinding
                      finding={parentFinding}
                      childrenFindings={childrenFindings}
                    />
                  }
                />
              ))}
            </div>
            <div className="top-focus-area__action-buttons">
              <Button
                as="a"
                size="sm"
                color="secondary"
                onClick={() =>
                  history.push(
                    `/${locationNumber}/assessment/${selectedReport.assessmentId}/findings`,
                  )
                }
              >
                {I18n.t(
                  'APP_REPORT_DASHBOARD_AUDIT_SUMMARY_CARD_VIEW_FINDINGS',
                )}
              </Button>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TopFocusArea;
